import { ListRequest, LocalizationObject, LocalizationField, SimpleBase } from './base'
import { UserInfo } from './ht-member'
import { PostImage } from './community'

export interface PrivacyPolicyList extends LocalizationObject, ListRequest {
  version_name: string,
  expected_release_time: string,
  id: string,
  modified_by_user_info?: UserInfo,
  active_time?: string,
  created?: string,
  enabled?: boolean,
  active?: boolean,
  serial_number: number,
}

export interface PrivacyPolicyDetail extends LocalizationObject {
  version_name: string,
  expected_release_time: string,
  id?: string,
  enabled?: boolean,
  active?: boolean,
  serial_number?: number,
  modified_by_user_info?: UserInfo,
  modified_by?: string,
  active_time?: string,
  created?: string,
}

export interface PrivacyPolicyNew extends Pick<PrivacyPolicyDetail, 'version_name' | 'expected_release_time' | 'modified_by' | 'active_time' | 'created' | 'modified_by_user_info'> {
  url: LocalizationField,
  id?: string,
  serial_number?: number,
}

export interface Greeting extends LocalizationObject {
  active: boolean,
  created: string,
  created_by: string,
  for_morning: boolean,
  for_night: boolean,
  for_noon: boolean,
  id: string,
  is_deleted: boolean,
  message: string,
  modified: string,
  modified_by: string,
  serial_number: string,
}

export interface GreetingDetail extends LocalizationObject {
  active: boolean,
  created: string,
  created_by: string,
  for_morning: boolean,
  for_night: boolean,
  for_noon: boolean,
  id: string,
  modified: string,
  modified_by: string,
  serial_number: string,
}

export interface GreetingNew extends Pick<GreetingDetail, 'for_morning' | 'for_night' | 'for_noon'> {
  message: LocalizationField,
  id?: string,
}

export interface ShortcutType extends LocalizationObject, SimpleBase {
  ctive: boolean,
  code: string,
  created: string,
  is_deleted: boolean,
  modified: string,
  name: string,
  serial_number: number,
  tenant_only: boolean,
}

export type AdCategory =
  | 'none'
  | 'inside_web_url'
  | 'outside_web_url'
  | 'merchant'
  | 'announcement'
  | 'event'
  | 'gift'
  | 'time_limited_offer'
  | 'article'

export interface AdList extends LocalizationObject, SimpleBase {
  serial_number: number,
  start_time: string,
  end_time: string,
  category: AdCategory,
  web_url?: string,
  image_url?: string,
  related_location_type: string,
  related_locations: string[],
  active: boolean,
  created: string,
  modified: string,
  title: string,
  user_category: string,
  user_category_locations: string[],
}

export interface AdDetail
  extends Pick<
    AdList,
    | 'serial_number'
    | 'start_time'
    | 'end_time'
    | 'category'
    | 'active'
    | 'title'
    | 'image_url'
    | 'web_url'
    | 'user_category'
    | 'user_category_locations'
    | 'related_location_type'
    | 'related_locations'
  > {
  id?: string,
  campus?: string,
  picture: PostImage,
}

export interface AdContent
  extends Pick<
    AdDetail,
    | 'start_time'
    | 'end_time'
    | 'category'
    | 'active'
    | 'title'
    | 'web_url'
    | 'picture'
    | 'user_category'
    | 'id'
    | 'user_category_locations'
    | 'related_location_type'
    | 'related_locations'
  > {
  campus?: string,
}
// export type ScheduledPushNotificationStatus = 'SCHEDULED' | 'CANCELLED' | 'SENT' | 'FAILED'

export enum ScheduledPushNotificationStatus {
  SCHEDULED = 'scheduled',
  CANCELLED = 'cancelled',
  SENT = 'succeed',
  FAILED = 'failed',
}
export interface ScheduledPushNotificationList extends LocalizationObject, SimpleBase {
  schedule_time: string,
  serial_number: number,
  user_category: string,
  updated_at: string,
  status: ScheduledPushNotificationStatus,
  related_location_type: string, // ["global", "campus", "building"]
  related_locations: string[],
  application_id: string,
  channel: string,
  hero_image_url?: string,
  topics?: string[],
  created_by: string,
}

export interface ScheduledPushNotificationDetail
  extends Pick<
    ScheduledPushNotificationList,
    | 'id'
    | 'schedule_time'
    | 'serial_number'
    | 'user_category'
    | 'related_location_type'
    | 'related_locations'
    | 'application_id'
    | 'channel'
    | 'hero_image_url'
  >,
  LocalizationObject { }

export interface ScheduledPushNotificationContent
  extends Pick<
    ScheduledPushNotificationList,
    'user_category' | 'related_location_type' | 'related_locations' | 'application_id' | 'channel'
  >, LocalizationObject {
  schedule_time: string,
  hero_image_url?: PostImage | string,
}

export enum FeedStatus { }

export interface FeedList extends LocalizationObject, SimpleBase {
  serial_number: number,
  schedule_time: string,
  user_category?: string,
  user_category_locations?: string[],
  modified: string,
  picture: PostImage,
}

export interface FeedDetail
  extends Pick<
    FeedList,
    'localization' | 'schedule_time' | 'user_category' | 'user_category_locations' | 'serial_number' | 'picture'
  >,
  SimpleBase { }

export interface FeedContent extends Pick<FeedDetail, 'schedule_time' | 'user_category' | 'user_category_locations' | 'picture'> {
  description: LocalizationField,
  title: LocalizationField,
}
