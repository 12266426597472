import { createRequestActionStrings } from './helper'
import { RequestActionFunctions, action } from './index'
import { MerchantType, Types } from '../../schema'

export const ACTION_KEY_HT = {
  MERCHANT_TYPE: createRequestActionStrings('MERCHANT_TYPE'),
  REWARD_RULE_TYPES: createRequestActionStrings('REWARD_RULE_TYPES'),
}

// htMerchant-type
export const merchantType: RequestActionFunctions = {
  request: () => action(ACTION_KEY_HT.MERCHANT_TYPE.REQUEST),
  success: (merchantType: MerchantType[]) => action(ACTION_KEY_HT.MERCHANT_TYPE.SUCCESS, merchantType),
  failure: (error) => action(ACTION_KEY_HT.MERCHANT_TYPE.FAILURE, error),
}

// reward-rule-types
export const rewardRuleAllTypes: RequestActionFunctions = {
  request: () => action(ACTION_KEY_HT.REWARD_RULE_TYPES.REQUEST),
  success: (rewardRuleAllTypes: {
    rewardRuleTypes: Types[],
    ruleTypes: Types[],
    rewardTypes: Types[],
    rewardLimitTypes: Types[],
  }) => action(ACTION_KEY_HT.REWARD_RULE_TYPES.SUCCESS, rewardRuleAllTypes),
  failure: (error) => action(ACTION_KEY_HT.REWARD_RULE_TYPES.FAILURE, error),
}

export const HTActions = {
  merchantType,
  rewardRuleAllTypes,
}
