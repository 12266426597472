import React from 'react'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'

import Header from './Header/index'
import { COLLAPSED_DRAWER, FIXED_DRAWER, hrdpActions } from '@exaleap/common'

import ColorOption from 'containers/Customizer/ColorOption'
import { isIOS, isMobile } from 'react-device-detect'
import SideBar from '../../SideBar'
import { Toolbar } from '@material-ui/core'

const Vertical = props => {
  const dispatch = useDispatch()
  const { drawerType, navCollapsed, width } = useSelector(({ settings }) => settings)
  // const [isMouseOver,setIsMouseOver]=useState(false)
  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? 'fixed-drawer'
    : drawerType.includes(COLLAPSED_DRAWER)
    ? 'collapsible-drawer'
    : 'mini-drawer'

  //set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add('ios-mobile-view-height')
  } else if (document.body.classList.contains('ios-mobile-view-height')) {
    document.body.classList.remove('ios-mobile-view-height')
  }

  const handleMouseOver = () => {
    dispatch(hrdpActions.toggleCollapsedNav(true))
    if (drawerType.includes(FIXED_DRAWER)) {
      dispatch(hrdpActions.setDrawerType(COLLAPSED_DRAWER))
    }
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <Header navCollapsed={navCollapsed} />

      <div className={`app-container ${drawerStyle}`}>
        {/* <Tour /> */}

        <SideBar />
        {(((navCollapsed || width < 1200) && drawerType.includes(FIXED_DRAWER)) ||
          (!navCollapsed && drawerType.includes(COLLAPSED_DRAWER))) && (
          <div
            onMouseOver={handleMouseOver}
            style={{
              position: 'fixed',
              top: 50,
              left: 0,
              width: 24,
              height: 'calc(100% - 50px)',
              zIndex: 2000,
            }}
          ></div>
        )}
        <div
          className={clsx('app-main-container', {
            contentShift:
              (drawerType.includes(FIXED_DRAWER) && !navCollapsed) ||
              (drawerType.includes(FIXED_DRAWER) && width < 1200) ||
              drawerType.includes(COLLAPSED_DRAWER),
            // "contentShift":drawerType.includes(FIXED_DRAWER) && navCollapsed && width > 1200
          })}
        >
          <main className="app-main-content-wrapper">
            <Toolbar variant="dense" />
            <div className="app-main-content">{props.children}</div>
            {/* <Footer/> */}
          </main>
        </div>
        <ColorOption />
      </div>
    </div>
  )
}

export default Vertical
