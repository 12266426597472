import React from 'react'
import Button from '@material-ui/core/Button'
import IntlMessages from 'util/IntlMessages'

const Footer = () => {
    return (
      <footer className="app-footer">
        <span className="d-inline-block">Copyright Company Name &copy; 2018</span>
       
      </footer>
    )
  }


export default Footer
