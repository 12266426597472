import { AxiosInstance, AxiosResponse } from 'axios'
import { AccessiblePermissionRule, BuildingAccessPermission, SimpleDisplayObject } from '../../../schema'
import { PLATFORM_ADMIN_ROUTE } from '../../config'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result[0]
const parseResults = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

class AccessPermissionServiceEndpoints {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }
  subRoute = '/access-permission-service'

  createAccessiblePermissionRule = (params: { user_category_id: string, permission_type_id: string, floor_id: string }) =>
    this.client.post<APIHTTPResponse<{ id: string, new_added: boolean }>>(
      `${this.subRoute}${PLATFORM_ADMIN_ROUTE}/accessible-permissions/rules`,
      params,
    )
      .then(parseResult)
      .catch(throwError)

  createBuildingAccessPermission = (buildingId: string, params: {
    user_category_id: string,
    permission: {
      permission_type_id: string,
      permission_level: number,
      timezone: string,
      allow_locations: string[],
      exclude_locations: string[],
      mode: string,
      date_range: string[],
      exact_dates: string[],
      exclude_dates: string[],
      time_range: string[],
    },
  }) =>
    this.client.post<APIHTTPResponse<BuildingAccessPermission>>(
      `${this.subRoute}${PLATFORM_ADMIN_ROUTE}/permissions/buildings/${buildingId}`,
      params,
    )
      .then(parseResult)
      .catch(throwError)

  deleteAccessiblePermissionRule = (id: string) =>
    this.client.delete<APIHTTPResponse<{ affected_rows: number }>>(
      `${this.subRoute}${PLATFORM_ADMIN_ROUTE}/accessible-permissions/rules/${id}`,
    )
      .then(parseResult)
      .catch(throwError)

  deleteBuildingAccessPermission = (buildingId: string, id: string) =>
    this.client.delete<APIHTTPResponse<{ affected_rows: number, building_id: string, permission_id: string }>>(
      `${this.subRoute}${PLATFORM_ADMIN_ROUTE}/permissions/buildings/${buildingId}/permissions/${id}`,
    )
      .then(parseResult)
      .catch(throwError)

  getAccessiblePermissionRules = (params: { building_id: string, user_category_id: string }) =>
    this.client.get<APIHTTPResponse<AccessiblePermissionRule>>(
      `${this.subRoute}${PLATFORM_ADMIN_ROUTE}/accessible-permissions/rules`,
      { params }
    )
      .then(parseResults)
      .catch(throwError)

  getBuildingAccessPermissions = (id, params: { user_category_id: string }) =>
    this.client.get<APIHTTPResponse<BuildingAccessPermission>>(
      `${this.subRoute}${PLATFORM_ADMIN_ROUTE}/permissions/buildings/${id}`,
      { params }
    )
      .then(parseResults)
      .catch(throwError)

  getPermissionTypes = () =>
    this.client
      .get<APIHTTPResponse<SimpleDisplayObject>>(
        `${this.subRoute}${PLATFORM_ADMIN_ROUTE}/permission-types`,
      )
      .then(parseResults)
      .catch(throwError)
}

export default AccessPermissionServiceEndpoints