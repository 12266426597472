const SettingRoutes = [
  {
    path: "/app/setting",
    element: () => import("./setting"),
  },

]

export default SettingRoutes

