import { SimpleBuilding, SimpleCampus, SimpleSpace } from "../../../schema"
import { ACTION_KEY_BMS } from "../../actions/bms"
import { Action } from "../../actions"


interface CampusState {
    // reuqest login status
    success: boolean,
    loading: boolean,
    error: any,

    campuses: SimpleCampus[],
    campusDetail: {
        campus: SimpleCampus,
        buildings: SimpleBuilding[],
        spaces: SimpleSpace[],
    },

}

const initialState: CampusState = {
    success: false,
    loading: false,
    error: null,

    campuses: null,
    campusDetail: {
        campus: null,
        buildings: null,
        spaces: null
    },
}

const CampusReducer = function (state = initialState, action: Action): CampusState {
    switch (action.type) {


        case ACTION_KEY_BMS.CAMPUS_LIST.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                campuses: action.payload
            }
        }


        case ACTION_KEY_BMS.CAMPUS_DETAIL.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                campusDetail: action.payload
            }
        }



        case ACTION_KEY_BMS.CAMPUS_DETAIL_CREATE_BUILDING.REQUEST: {
            return {
                ...state,
                loading: true
            }
        }

        case ACTION_KEY_BMS.CAMPUS_DETAIL_CREATE_BUILDING.FAILURE: {
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            }
        }




        case ACTION_KEY_BMS.CAMPUS_DETAIL_CREATE_BUILDING.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                campusDetail: {
                    ...state.campusDetail,
                    buildings: [
                        ...state.campusDetail.buildings,
                        action.payload
                    ]
                }
            }
        }




        case ACTION_KEY_BMS.CAMPUS_DETAIL_CREATE_SPACE.REQUEST: {
            return {
                ...state,
                loading: true
            }
        }

        case ACTION_KEY_BMS.CAMPUS_DETAIL_CREATE_SPACE.FAILURE: {
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload
            }
        }


        case ACTION_KEY_BMS.CAMPUS_DETAIL_CREATE_SPACE.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                campusDetail: {
                    ...state.campusDetail,
                    spaces: [
                        ...state.campusDetail.spaces,
                        action.payload
                    ]
                }
            }
        }


        default: {
            return state
        }
    }
}

export default CampusReducer