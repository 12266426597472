import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import { VisitorRequest, CreateVisitorRequest, VisitorRequestResponse, VisitorRequestEdit } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for Visitor System module
 */
class PropertyVisitorServices {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    subRoute = '/properties-management-service/visitor-service'

    listRequests = (id?: string, page_number = 1, page_size = 50) =>
        this.client
            .get<APIHTTPResponse<VisitorRequest>>(`${this.subRoute}/get-requests`, {
                params: { id, page_number, page_size },
            })
            .then(parseResult)
            .catch(throwError)

    makeSelfServeRequest = (request: CreateVisitorRequest) =>
        this.client
            .post<APIHTTPResponse<VisitorRequestResponse>>(`${this.subRoute}/make-request`, request)
            .then(parseResult)
            .catch(throwError)

    cancelRequest = (id: string) =>
        this.client
            .delete<APIHTTPResponse<VisitorRequest>>(`${this.subRoute}/cancel-request`, {
                params: { id },
            })
            .then(parseResult)
            .catch(throwError)

    editRequest = (id: string, changes: VisitorRequestEdit) =>
        this.client
            .put<APIHTTPResponse<VisitorRequest>>(`${this.subRoute}/edit-request`, changes, {
                params: { id },
            })
            .then(parseResult)
            .catch(throwError)
}

export default PropertyVisitorServices
