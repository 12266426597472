import orange from '@mui/material/colors/orange'

const green = {
  100: '#F2FBF7',
  200: '#E6F8EE',
  300: '#ddf9e8',
  400: '#6fcf97',
  500: '#27AE60',
  600: '#219653',
  700: '#1C492F',
}

const grey = {
  10: '#121212',
  100: '#333333',
  200: '#4F4F4F',
  300: '#828282',
  400: '#BDBDBD',
  500: '#E0E0E0',
  600: '#F2F2F2',
  // 700: '#1C492F',
  700: '#1C3149',
  800: '#F5F5F5',
  900: '#F6F6F6',
  1000: '#ddd',
}

const red = {
  500: '#EB5757',
  700: '#F44336',
}

const blue = {
  500: '#B4D5FF',
}

const myOrange = {
  100: '#f2994a',
}

const black = {
  500: '#121212',
}

export default {
  spacing: 4,
  palette: {
    type: 'light',
    white: {
      main: '#fff',
      light: '#fff',
      dark: '#fff',
      contrastText: '#000',
    },
    black: {
      main: black[500],
      light: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
    greyTextBolder: {
      main: grey[300],
      light: grey[300],
      dark: grey[300],
      contrastText: '#fff',
    },
    blue: {
      main: blue[500],
      light: blue[500],
      dark: blue[500],
      contrastText: '#fff',
    },
    grey: {
      ...grey,
      main: grey[300],
      light: grey[500],
      dark: grey[100],
      contrastText: '#fff',
    },
    myOrange: {
      main: myOrange[100],
      light: myOrange[100],
      dark: myOrange[100],
      contrastText: '#fff',
    },
    primary: {
      ...green,
      light: green[400],
      main: green[500],
      dark: green[600],
      contrastText: '#fff',
    },
    secondary: {
      light: orange[300],
      main: orange['A700'],
      dark: orange[700],
      contrastText: '#fff',
    },
    error: {
      light: red[500],
      main: red[500],
      dark: red[700],
    },
    text: {
      primary: grey[200],
      secondary: grey[100],
    },
    background: {
      default: '#fff',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    h1: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 500,
      color: black[500],
    },
    h3: {
      fontSize: '1.25rem',
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 500,
      color: black[500],
    },
    h5: {
      fontSize: '1rem',
      color: black[500],
    },
    h6: {
      fontSize: '0.875rem',
    },
    subtitle1: {
      fontSize: '0.75rem',
    },
    button: {
      fontSize: '1rem',
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          display: 'flex !important',
          justifyContent: 'flex-start !important',
          padding: '8px 16px !important',
          '&:hover': {
            background: green[500],
            color: '#fff',
          },
          '&.Mui-selected': {
            background: 'transparent',
            '&:hover': {
              background: green[500],
              color: '#fff',
            },
            '&.Mui-focusVisible': {
              background: 'transparent',
              '&:hover': {
                background: green[500],
                color: '#fff',
              },
            },
          },
        },
      },
    },
    MuiPickersMonth: {
      styleOverrides: {
        root: {
          '.MuiPickersMonth-monthButton.Mui-disabled': {
            color: grey[300],
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: 16,
          '& input::placeholder': {
            fontSize: 16,
            color: grey[300],
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: grey[500],
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': { backgroundColor: 'transparent' },
          padding: 0,
        },
      },
    },
  },
}
