import { ACTION_KEY_NOTIFICATION } from './../actions'
import { SnackbarAction } from 'notistack'

export interface NotificationObject {
    key: string,
    action?: SnackbarAction,
    message: string | any,
    variant: 'error' | 'success',
    dismissed: boolean,
  }

const defaultState: { notifications: NotificationObject[] } = {
    notifications: [],
}

export default (state = defaultState, action) => {
    switch (action.type) {
        case ACTION_KEY_NOTIFICATION.ENQUEUE:


            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: new Date().getTime() + Math.random(),
                        action: action.payload.notification.action,
                        variant: action.payload.notification.type,
                        message: action.payload.notification.message,
                        dismissed: false,
                    },
                ],
            }

        case ACTION_KEY_NOTIFICATION.CLOSE:
            state.notifications.map(notification => {
                console.info(action.payload.dismissAll || notification.key === action.payload.key)
                console.info(notification.key, action.payload.key)
                return(
                (action.payload.dismissAll || notification.key === action.payload.key)
                    ? { ...notification, dismissed: true }
                    : { ...notification }
            )})
            return {
                ...state,
                notifications: state.notifications.map(notification => (
                    (action.payload.dismissAll || notification.key === action.payload.key)
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                )),
            }

        case ACTION_KEY_NOTIFICATION.REMOVE:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.payload.key,
                ),
            }

        default:
            return state
    }
}
