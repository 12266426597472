import { SimpleBase, LocalizationObject, ListRequest, LocalizationField } from './base'
import { PostImage } from './community'
import { Merchant, Asset } from './asset'

export type SessionState = 'continued' | 'cancelled' | 'delayed'

export type PostType = 'general' | 'campaign' | 'announcement'

export enum PostSubCategory {
  DisplayOnly = 'display_only',
  Quota_Ticket = 'quota_ticket',
  Quota_Coupon = 'quota_coupon',
  QrCode = 'qrcode',
}

export enum PostStatus {
  DRAFT = 'draft',
  WAIT_FOR_APPROVE = 'wait_for_approve',
  APPROVED = 'approved',
  LAUNCHED = 'launched',
  ENDED = 'ended',
  LOCKED = 'locked',
}

export enum PostCategory {
  ARTICLE = 'article',
  EVENT = 'event',
  GIFT = 'gift',
  RELAX = 'relax',
  TIME_LIMITED_OFFER = 'time_limited_offer',
  ACTIVITY = 'activity',
  PERK = 'perk',
  NEW = 'news',
  ANNOUNCEMENT = 'announcement',
}

export type Categories =
  | 'article'
  | 'event'
  | 'gift'
  | 'relax'
  | 'time_limited_offer'
  | 'activity'
  | 'perk'
  | 'news'
  | 'announcement'

export type PostListRequired = ListRequest & {
  order_by?: 'asc' | 'desc',
  sort_by?: 'created_at' | 'updated_at' | 'start_time' | 'end_time',
  states?: PostStatus[],
  type?: PostType,
  category: Categories,
  sub_category?: PostSubCategory,
  campus_ids?: string,
  building_ids?: string,
}

export interface CategoriesType extends LocalizationObject, SimpleBase {
  application_id: string,
  sub_category_ids: string[],
  name: string,
}

export interface CMSSubCategory extends LocalizationObject, SimpleBase {
  category?: Categories,
  index: number,
  enabled: boolean,
  name?: string,
}

export interface Templates extends LocalizationObject, SimpleBase {
  post_type: PostType,
  category: Categories,
  sub_category: string,
  application_id: string,
  name: string,
}

export interface Post extends LocalizationObject {
  id: string,
  type: PostType,
  category: PostCategory,
  sub_category: PostSubCategory,
  state: PostStatus,
  themes: any,
  schedule_start_time?: string,
  schedule_end_time?: string,
  start_time?: string,
  end_time?: string,
  author_id?: string,
  author_profile_url: string,
  author_display_name: string,
  last_editor_display_name: string,
  last_editor_profile_url: string,
  last_editor_id?: string,
  approver_id?: string,
  approver_display_name: string,
  approver_profile_url: string,
  created_at?: string,
  approved_at?: string,
  updated_at?: string,
  title: string,
  description: string,
  terms?: string,
  gallery?: PostImage[],
  hero?: PostImage[],
  locked_at?: string,
  locked_by?: string,
  locked_user_display_name?: string,
  redeem_type?: string,
  support_gain_point?: boolean,

  // new
  related_location_type?: string,
  related_locations?: string[],
  external_ref_link?: {
    type?: string,
    category?: string,
    link?: string,
  },
  auto_push_enabled: boolean,
  launched_by?: string,
  ended_by?: string,

  // who can view the post
  who_can_view?: {
    user_category?: string,
    user_category_locations?: string[],
  },

  user_category?: string,
  user_category_locations?: string[],
  template_id?: string,

  related_campaign: RelatedCampaign,
}

export interface PostRes extends LocalizationObject, SimpleBase {
  post: Post,
  campaign?: Campaign,
  sub_category?: string,
  global_location_type?: string,
  announcement?: CmsAnnouncement,
}

export interface PostSession extends LocalizationObject, SimpleBase {
  start_time: string,
  end_time: string,
  asset: Asset,
  enrollments?: Enrollments[],
  related_location_type?: string,
  enroll_start_time?: string,
  enroll_end_time?: string,
  enroll_type?: string,
  ref_merchant_ids?: string[],
  state?: SessionState,
  redemption_type?: string,
}

export interface Enrollments {
  member_grade: number,
  redeem_point?: number,
  redeem_method: string,
  early_bird_redeem_start_time?: string,
}

export interface EnrollmentConstraints {
  total: string,
  daily: string,
  session: number,
  session_daily: string,
}

export interface Campaign extends LocalizationObject, SimpleBase {
  merchant_id: string,
  start_time: string,
  end_time: string,
  related_locations: string[],
  who_can_join: {
    user_category: string,
    user_category_locations: string[],
  },
  sessions: PostSession[],
  enrollment_constraints?: EnrollmentConstraints,

  category: string,
  sub_category: string,
  state: string,
  related_location_type: string,
  terms: string,
  address?: string,
  ref_merchant_ids?: string[],
  enable_comment?: boolean,
  comment_reward_point?: number,
  text_comment_reward_point?: number,
  image_comment_reward_point?: number,
  comment_questions: CommentQuestions[],
}

export type CommentType = 'marking' | 'radio' | 'checkbox'

export interface CommentQuestions extends LocalizationObject {
  comment_type?: CommentType,
  is_required: boolean,
  option?: LocalizationField[],
}

export interface PostFrom {
  post: Pick<
    PostRes['post'],
    'localization' | 'schedule_start_time' | 'schedule_end_time' | 'who_can_view' | 'auto_push_enabled' | 'gallery'
  >,
  campaign?: Pick<
    PostRes['campaign'],
    'sessions' | 'comment_questions' |
    'merchant_id' | 'start_time' | 'end_time' | 'related_locations' | 'localization'
  >,
  announcement?: Pick<
    PostRes['announcement'],
    'localization' | 'attachments' | 'related_locations' | 'who_can_join' | 'icons'
  >,
  sub_category?: string,
}

export interface PostTenant_infos extends LocalizationObject {
  id: string,
  code: string,
  email_domains: string,
  description: string,
  profile_picture_url: string,
  created: string,
  modified: string,
  is_tenant_admin: boolean,
  display_name: LocalizationField,
}

export interface PostUserInfo extends SimpleBase, LocalizationObject {
  tenant_info: PostTenant_infos,
  user_id: string,
  first_name: string,
  last_name: string,
  display_name: string,
  preferred_language: string,
  receive_promotional_message: boolean,
  birth_month: string,
  email: string,
  profile_picture_url: string,
  created: string,
  modified: string,
  mobile_number: {
    area_code: string,
    number: string,
  },
}

export interface PostRefMerchantInfo extends SimpleBase, LocalizationObject {
  picture_url: string,
  active: true,
  merchantSubtypeId: string,
  display_name: string,
  introduction: string,
}

export interface CampaignUser extends SimpleBase, LocalizationObject {
  campaign_id: string,
  session_id: string,
  user_id: string,
  asset_id: string,
  voucher_id: string,
  voucher_number: string,
  state: string,
  ref_merchant_id: string,
  expiry_date: string,
  get_time: string,
  use_time: string,
  payment_id: string,
  payment_method: string,
  payment_currency: string,
  amount: number,
  price: number,
  point: number,
  is_check_in: boolean,
  check_in_time: string,
  session: PostSession,
  user_info: PostUserInfo,
  ref_merchant_info: PostRefMerchantInfo,
}

export interface PostListType extends SimpleBase, LocalizationObject {
  anonymous_read_count: number,
  approved_at: string,
  approver_id: string,
  author_id: string,
  auto_push_enabled: boolean,
  category: Categories,
  created_at: string,
  end_time: string,
  ended_by: string,
  hero: PostImage[],
  id: string,
  last_editor_id: string,
  launched_by: string,
  like_count: number,
  locked_at: string,
  locked_by: string,
  related_campaign: RelatedCampaign,
  related_locations: string[],
  schedule_end_time: string,
  schedule_start_time: string,
  start_time: string,
  state: PostStatus,
  sub_category: string,
  support_gain_point: boolean,
  themes: string[],
  title: string,
  total_read_count: number,
  type: string,
  updated_at: string,
  user_category: string,
}

export interface LatestNewsType extends SimpleBase, LocalizationObject {
  related_location_type: string,
  related_location: string,
  post_id: string,
  index: number,
  category: string,
  sub_category: string,
  start_time: string,
  end_time: string,
  support_gain_point: boolean,
  hero: PostImage,
  state: PostStatus,
}

export enum EnrollType {
  first_come_first_served = 'first_come_first_served',
  lucky_draw = 'lucky_draw',
}

export enum CampaignState {
  continued = 'continued',
  delayed = 'delayed',
  cancelled = 'cancelled',
}
interface RelatedCampaignLocalizedFields {
  address: string,
}

export enum LocationType {
  campus = 'campus',
  building = 'building',
}

interface RelatedLocation {
  related_location_type: LocationType,
  related_locations: string[],
}

export enum UserCategory {
  user = 'user',
  tenant_user = 'tenant_user',
  campus_specific_tenant_user = 'campus_specific_tenant_user',
  building_specific_tenant_user = 'building_specific_tenant_user',
}

interface UserCategoryObject {
  user_category?: string,
  user_category_locations?: string[],
}

export interface RelatedCampaign
  extends SimpleBase,
  RelatedCampaignLocalizedFields,
  LocalizationObject<RelatedCampaignLocalizedFields>,
  RelatedLocation,
  UserCategoryObject {
  category: string,
  sub_category: string,
  state: CampaignState,
  merchant_id?: string,
  start_time: string,
  end_time: string,
  enroll_start_time: string,
  enroll_end_time: string,
  enroll_type: EnrollType,
  total_constraints?: number,
  daily_constraints?: number,
  session_constraints?: number,
  session_daily_constraints?: number,
  who_can_join?: UserCategoryObject,
  enrollment_constraints?: EnrollmentConstraints,
  merchant?: Merchant,
  sessions?: PostSession[],

  is_free?: boolean,

  total_read_count?: number,
  user_read_count?: number,
  anonymous_read_count?: number,
  like_count?: number,

  session_id: string,
  session_state: SessionState,
}

export interface CmsAnnouncement extends LocalizationObject, SimpleBase {
  related_locations: string[],
  who_can_join: {
    user_category: string,
    user_category_locations: string[],
  },
  icons?: any[],
  attachments: CmsAnnouncementAttachments[],
}

export interface CmsAnnouncementAttachments extends LocalizationObject {
  index?: number,
}

export interface SessionTime {
  end_time: string,
  start_time: string,
  enroll_end_time: string,
  enroll_start_time: string,
  asset_start_time: string,
  asset_end_time: string,
}

export interface SeesionRequestRes extends LocalizationObject, SimpleBase {
  request_type: string,
  request_status: string,
  campaign_id: string,
  session_id: string,
  requestor_id: string,
  approver_id: string,
  previous_fields_values: SessionTime,
  updated_fields_values: SessionTime,
  notification: {
    channels: string[],
  } & LocalizationObject,
  created_at: string,
  approved_at: string,
  requestor_display_name: string,
  approver_display_name: string,
  requestor_profile_url: string,
  approver_profile_url: string,
}

export interface SessionTransition extends SimpleBase, LocalizationObject {
  voucher_id: string,
  user_id: string,
  voucher_number: string,
  state: string,
  ref_merchant_id: string,
  expiry_date: string,
  get_time: string,
  use_time: string,
  update_time: string,
  payment_id: string,
  payment_method: string,
  payment_currency: string,
  amount: number,
  price: number,
  point: number,
  user_display_name: string,
  user_profile_url: string,
  mobile_number: string,
  tenant_display_name: string,
}

export interface CommentAnswers extends LocalizationObject {
  answers?: number[],
  question_id?: string,
  is_required: boolean,
  comment_type: CommentType,
}
export interface CommentList extends SimpleBase, LocalizationObject {
  campaign_id: string,
  user_id: string,
  comment: string,
  comment_images: string[],
  comment_answers: CommentAnswers[],
  reward_point: number,
  user_display_name: string,
  user_profile_url: string,
  mobile_number: string,
  tenant_display_name: string,
}

export interface VendingMachine {
  barcode: string,
  exchanged: number,
  imageUrl: string,
  name: string,
  received: number,
  total: number,
}
