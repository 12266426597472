import APIRestfulProvider from "../../restful-provider"
import General from "./general"

/**
 * Visitor Service
 */


class ValueAddedServiceEndpoints {
    general: General

    constructor(provider: APIRestfulProvider) {
        this.general = new General(provider)
    }

}


export default ValueAddedServiceEndpoints