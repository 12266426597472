import { ACCESS_POLICY } from '@exaleap/common'

const HTAppFunctionsRoutes = [
  {
    path: '/app/leasing-mgmt/ht/app-common-functions/privacy-policy',
    element: () => import('./PrivacyPolicy'),
    auth: [ACCESS_POLICY.leasingManagement.appConfigService.privacyPolicy.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/app-common-functions/terms-condition',
    element: () => import('./TermsCondition'),
    auth: [ACCESS_POLICY.leasingManagement.appConfigService.termsAndConditions.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/app-common-functions/greeting',
    element: () => import('./Greeting'),
    auth: [ACCESS_POLICY.leasingManagement.appConfigService.greeting.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/app-common-functions/campus-management',
    element: () => import('./CampusManagement'),
    auth: [ACCESS_POLICY.leasingManagement.appConfigService.campusConfig.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/app-common-functions/scheduled-push-notification',
    element: () => import('../HTContentManagement/ScheduledPushNotification'),
    auth: [ACCESS_POLICY.leasingManagement.notificationService.generalAccess.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/app-common-functions/ad',
    element: () => import('../HTContentManagement/Ad'),
    auth: [ACCESS_POLICY.leasingManagement.appConfigService.generalAdvertisements],
  },
  {
    path: '/app/leasing-mgmt/ht/app-common-functions/feed',
    element: () => import('../HTContentManagement/Feed'),
    auth: [ACCESS_POLICY.leasingManagement.notificationService.generalAccess.fullAccess],
  },
  {
    path: `/app/leasing-mgmt/ht/app-common-functions/:contentType/:id`,
    element: () => import('../Cms/Content'),
    auth: [
      ACCESS_POLICY.leasingManagement.contentService.globalEditAccess,
      ACCESS_POLICY.leasingManagement.contentService.globalFullAccess,
    ],
  },
  {
    path: '/app/leasing-mgmt/ht/app-common-functions/announcement',
    element: () => import('./Announcement'),
    auth: [
      ACCESS_POLICY.leasingManagement.contentService.globalEditAccess,
      ACCESS_POLICY.leasingManagement.contentService.globalFullAccess,
    ],
  },

]

export default HTAppFunctionsRoutes
