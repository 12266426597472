import { call, put, takeEvery } from 'redux-saga/effects'
import { Action, NotificationActionFunctions, ACTION_KEY_OVERVIEW, OverviewActions } from '../actions'
import { apiService } from '../../..'
import { SimpleCampus, SimpleBuilding } from '../../schema'

function* requestCampusOverview(action: Action) {
    console.warn('[SAGA] requestCampusOverview')

    try {
        const campuses = yield call(apiService.platform.buildingService.getCampuses)

        const id = action.payload || campuses[0].id
        const details = yield call(apiService.platform.buildingService.getCampuses, id)

        const detail: SimpleCampus = details[0]
        const buildings: SimpleBuilding[] = yield call(apiService.platform.buildingService.getBuildingsInCampus, detail.id)

        yield put(OverviewActions.campusOverview.success(campuses, details[0], buildings))
    } catch (error) {
        console.warn('[SAGA] requestCampusOverview error', error)
        yield put(OverviewActions.campusOverview.failure(error))
        yield put(
            NotificationActionFunctions.enqueue({
                message: error,
                type: 'error',
            }),
        )
    }
}

export const overviewSagas = [takeEvery(ACTION_KEY_OVERVIEW.OVERVIEW_CAMPUS_DETAIL.REQUEST, requestCampusOverview)]
