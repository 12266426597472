import { combineReducers } from 'redux'
import Settings from './setting-reducer'
import Common from './common-reducer'
import {
    AuthReducer,
    IOTReducer,
    IAMReducer, BMSReducer, TenantRootReducer,
    NotificationReducer, OverviewReducer, PropertyMgmtReducer,
    DashboardReducer, LocationSelectReducer,
    AccessControlRootReducer, VisitorReducer, HTReducer, CMSReducer,
    GeneralDataReducer
} from '@exaleap/common'



export default (navigation) =>
    combineReducers({
        settings: Settings,
        auth: AuthReducer,
        commonData: Common, // todo: make use of here, should group all page actions into same section

        iam: IAMReducer,
        iot: IOTReducer,
        bms: BMSReducer,
        tenant: TenantRootReducer,
        notification: NotificationReducer,
        overview: OverviewReducer,
        dashboard: DashboardReducer,
        accessControl: AccessControlRootReducer,
        visitor: VisitorReducer,
        locationSelect: LocationSelectReducer,
        propertyMgmt: PropertyMgmtReducer,
        ht: HTReducer,
        cms: CMSReducer,
        generalData: GeneralDataReducer,
        navigation,

        // TenantPortal
        // PMCPortal
        // LeasingPortal
        // PlatformPortal

    })
