import APIRestfulProvider from '../../restful-provider'
import AccountRestful from './account'
import LeasingManagementRestful from './leasingManagement'
import SystemRestful from './system'
import PropertiesManagementRestful from './propertiesManagement'
import PlatformManagementRestful from './platformManagement'
import TenantServiceRestful from './tenantService'
import BuildingServiceRestful from './buildingService'

class PlatformServiceEndpoints {
  account: AccountRestful
  leasingManagement: LeasingManagementRestful
  system: SystemRestful
  propertiesManagement: PropertiesManagementRestful
  platformManagement: PlatformManagementRestful
  tenantService: TenantServiceRestful
  buildingService: BuildingServiceRestful

  constructor(provider: APIRestfulProvider) {
    this.account = new AccountRestful(provider)
    this.leasingManagement = new LeasingManagementRestful(provider)
    this.system = new SystemRestful(provider)
    this.propertiesManagement = new PropertiesManagementRestful(provider)
    this.platformManagement = new PlatformManagementRestful(provider)
    this.tenantService = new TenantServiceRestful(provider)
    this.buildingService = new BuildingServiceRestful(provider)
  }
}

export default PlatformServiceEndpoints
