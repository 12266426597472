import * as R from 'ramda'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import OtpInput from 'react-otp-input'
import useCountDown from 'react-countdown-hook'
import { useDispatch } from 'react-redux'
import {
  Button,
  Box,
} from '@mui/material'

import {
  styled, Theme, useTheme
} from '@mui/material/styles'

import {
  CommonDialog,
  CommonDialogActionsAtRight,
  CommonDialogContent,
  CommonDialogHeader,
  DialogInfoActionBtn,
  IconTextButton,
} from '@exaleap/react-component-lib'

import { AuthActions, hrdpActions } from '@exaleap/common'

import { useSelector } from 'state/root-store'

import { intl } from 'util/IntlHelper'

const StyledOTPInputContainer = styled(OtpInput)(({ theme: { palette, spacing } }: { theme: Theme }) => ({
  justifyContent: 'space-between',
  marginBottom: spacing(4),
  width: '100%',
  'input': {
    width: '2.5rem !important',
    height: '4rem',
    fontSize: '2rem',
    borderRadius: 4,
    borderColor: 'rgba(0,0,0,0.3)',
    outline: 'none',
    '&:focus': {
      border: `2px solid ${palette?.primary?.main}`,
    },
  }
}))

export const LoginSubmitBtn = styled(Button)(({ theme: { spacing } }: { theme: Theme }) => ({
  width: '100%',
  marginTop: spacing(2),
  fontSize: 15,
  height: 40,
  padding: 0,
  alignSelf: 'center'
}))

export const AppLoginContent = styled(Box)(({ theme: { spacing } }: { theme: Theme }) => ({
  padding: `${spacing(8)} ${spacing(24)}`,
  width: '100%',
}))

export const AppLoginForm = styled(Box)(({ theme: { spacing } }: { theme: Theme }) => ({
  '.MuiFormHelperText-root.Mui-error': {
    position: 'absolute',
    bottom: spacing(-8),
    left: spacing(-24.5),
  }
}))

const TIME = 60000

const LoginForm = props => {
  const { spacing } = useTheme()
  const { setStep, phone, areaCode, resendOtp } = props
  const { loader, regionCd, validCodeFailure, validCodeFailureTimer, validCodeFailureCode } = useSelector(
    (state) => state.auth,
  )
  const [counter, { start, pause }] = useCountDown(TIME, 1000)
  React.useEffect(() => {
    start()
    return () => pause()
  }, [start, pause])

  const handleResendOtp = () => {
    resendOtp()
    start(TIME)
  }
  const regionVersion = useRef(regionCd)
  regionVersion.current = useMemo(() => (R.includes('cn/signin', window?.location?.pathname ?? '') ? 'CN' : 'HK'), [])

  const [open, setOpen] = useState<boolean>(false)

  const [sms, setSms] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    if (sms?.length === 6) {
      dispatch(hrdpActions.showAuthLoader())
      dispatch(AuthActions.verifyToken.request({ areaCode, phone, sms }))
    }
  }, [sms])

  useEffect(() => {
    if (validCodeFailureTimer === 3) {
      setOpen(true)
    }
  }, [validCodeFailureTimer])

  return (
    <>
      <div className="animated slideInUpTiny animation-duration-3">
        <IconTextButton
          iconName="ArrowBack"
          style={{ fontSize: 15, margin: spacing(3), borderRadius: '5px', position: 'absolute' }}
          onClick={() => setStep('request-otp')}
        >
          {intl.formatMessage({ id: "appModule.back" })}
        </IconTextButton>

        <AppLoginContent>
          <p style={{ width: 288, padding: `${spacing(5)} 0` }}>
            {intl.formatMessage({ id: "appModule.login.notice" }, { areaCode, phone })}
          </p>
          <AppLoginForm>
            <form>
              <StyledOTPInputContainer
                isInputNum
                value={sms}
                onChange={code => setSms(code)}
                numInputs={6}
                shouldAutoFocus={true}
              />

              {sms?.length === 6 && loader && (
                <p style={{ paddingTop: spacing(3), textAlign: 'center' }}>
                  {intl.formatMessage({ id: "appModule.login.check" })}
                </p>
              )}
              {validCodeFailure && (
                <p style={{ paddingTop: spacing(3), textAlign: 'center', color: '#f00' }}>
                  {intl.formatMessage({ id: `api.error.${validCodeFailureCode ?? 'ERR10023'}` })}
                </p>
              )}

              <LoginSubmitBtn
                onClick={() => handleResendOtp()}
                disabled={counter > 0}
                color="primary"
                variant="text"
              >
                {intl.formatMessage({ id: "login.resend_sms" })}
                {counter > 0 ? ` (${counter / 1000})` : null}
              </LoginSubmitBtn>
            </form>
          </AppLoginForm>
        </AppLoginContent>
      </div>
      <CommonDialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {
          setOpen(false)
        }}
      >
        <CommonDialogHeader
          header={intl.formatMessage({ id: "login.sms.error" })}
          id="alert-dialog-title"
          handleClose={() => {
            setOpen(false)
          }}
        />
        <CommonDialogContent style={{ backgroundColor: '#fff', height: 120, width: 380 }}>
          <Box id="alert-dialog-description">
            {intl.formatMessage({ id: "login.get_new_code" })}
          </Box>
        </CommonDialogContent>
        <CommonDialogActionsAtRight>
          <DialogInfoActionBtn
            onClick={() => {
              setOpen(false)
              setStep('request-otp')
            }}
            color="primary"
          >
            {intl.formatMessage({ id: "generic.action.got_it" })}
          </DialogInfoActionBtn>
        </CommonDialogActionsAtRight>
      </CommonDialog>
    </>
  )
}

export default LoginForm
