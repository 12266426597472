import {
  // take,
  call,
  put,
  takeEvery,
  select,
} from 'redux-saga/effects'
import { Templates, CategoriesType } from '../../schema/cms'
import { ACTION_KEY_CMS, CmsActions, NotificationActionFunctions } from '../actions'
import { apiService, hrdpActions } from '../..'

function* TemplatesRequest() {
  console.group('[SAGA] MerchantTypeRequest')
  try {
    yield put(hrdpActions.fetchStart())
    const { regionCd } = yield select((state) => state.auth)
    const paramsKey = regionCd === 'HK' ? 'hc' : 'ht'
    const templates: Templates[] = yield call(apiService.leasing.cms.getPostTemplates, paramsKey) || []
    yield put(CmsActions.templates.success(templates))
    yield put(hrdpActions.fetchSuccess())
  } catch (error) {
    console.error('[SAGA] deviceDetailRequest', error)
    yield put(CmsActions.templates.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
    yield put(hrdpActions.fetchError(error))
  }
  console.groupEnd()
}

function* PostCategoryListRequest() {
  try {
    yield put(hrdpActions.fetchStart())
    const { regionCd } = yield select((state) => state.auth)
    const params = { application_id: regionCd === 'HK' ? 'hc' : 'ht' }
    const categoryList: CategoriesType[] = yield call(apiService.leasing.cms.getCategories, params) || []
    yield put(CmsActions.categoryList.success(categoryList))
    yield put(hrdpActions.fetchSuccess())
  } catch (error) {
    console.error('[SAGA] PostCategoryListRequest', error)
    yield put(CmsActions.categoryList.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
    yield put(hrdpActions.fetchError(error))
  }
}

export const cmsSagas = [
  takeEvery(ACTION_KEY_CMS.TEMPLATES.REQUEST, TemplatesRequest),
  takeEvery(ACTION_KEY_CMS.CATEGORY_LIST.REQUEST, PostCategoryListRequest),
]
