import { createBrowserHistory, createMemoryHistory } from 'history'
import { isReactNative } from './runtime'

let history = null

if (isReactNative) {
    history = createMemoryHistory()
} else {
    history = createBrowserHistory()
    // console.error('!@#!@#history ', window)
}


export default history