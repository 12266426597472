import { LocalizationObject } from './base'
// import { PostSession } from './asset'

// export type PostType = 'activity' | 'perk' | 'news'
export type PostState = 'draft' | 'wait_for_approve' | 'approved' | 'launched' | 'ended' | 'locked'
export type PostAction =
  | 'edit'
  | 'leave'
  | 'commit'
  | 'revoke'
  | 'reject'
  | 'approve'
  | 'launch'
  | 'end'
  | 'push_notification'
  | 'delay'
  | 'restart'
  | 'cancel'
  | 'preview_request'
  | 'view_redeem'

export enum PostSubType {
  DisplayOnly = 'display_only',
  QRCode = 'qrcode',
  Quota = 'quota',
}

export enum UserAccessPermissions {
  AllUsers = 'all_users',
  AllTenantsUsers = 'all_tenant_users',
  SpecificUsers = 'specific_tenant_users',
}

export enum UserAccessPermissionsV2 {
  CampusSpecificTenantsUser = 'campus_specific_tenant_user',
  CurrentLocationRegisterUser = 'current_location_register_user',
}

export enum UserAccessPermissionsV3 {
  TenantsUser = 'tenant_user',
  RegisterUser = 'register_user',
}

export enum PostUserAccessPermissions {
  Users = 'user',
  TenantUser = 'tenant_user',
  // CampusSpecificTenantUser = 'campus_specific_tenant_user',
  BuildingSpecificTenantUser = 'building_specific_tenant_user'
}

export const PostStateColor = (state: PostState) => {
  switch (state) {
    case "locked":
      return '#4F4F4F'
    case 'draft':
      return '#4F4F4F'
    case 'wait_for_approve':
      return '#F2994A'
    case 'approved':
      return '#219653'
    case 'launched':
      return '#219653'
    case 'ended':
      return '#828282'
  }
}

// export type PostListRequired = ListRequest & {
//   order_by?: 'asc' | 'desc',
//   sort_by?: 'created_at' | 'updated_at' | 'start_time' | 'end_time',
//   state?: PostState[],
//   type?: PostType,
// }

export type PostImage = {
  id: string,
  url?: string,
  key?: string,
  image_description?: string,
} & LocalizationObject

// export interface Post extends LocalizationObject {
//   id: string,
//   type: PostType,
//   sub_type: PostSubType,
//   category: string,
//   state: PostState,
//   schedule_start_time: string,
//   schedule_end_time: string,
//   start_time: string,
//   end_time: string,
//   author_id: string,
//   last_editor_id: string,
//   approver_id: string,
//   created_at: string,
//   approved_at: string,
//   updated_at: string,
//   title: string,
//   description: string,
//   terms?: string,
//   gallery?: PostImage[],
//   hero?: PostImage[],
//   sessions: PostSession[], // TODO: refactor
//   locked_at: string,
//   locked_by: string,
//   redeem_type?: string,

//   // who can view the post
//   permission_building_ids?: string[],
//   user_access_permission: UserAccessPermissions,

//   // Campaign related
//   campaign_building_id?: string,
//   campaign_start_time?: string,
//   campaign_end_time?: string,
//   enroll_start_time?: string,
//   enroll_end_time?: string,
//   pass_code?: string,
//   merchant_id?: string,
// }

export interface NotificationRecord {
  id: string,
  author_id: string,
  channel: 'push' | 'sms' | 'email',
  message: string,
  post_id: string,
  state: PostState,
  send_state: 'preparing' | 'sending' | 'sent' | 'failed',
  send_at: string,
  title: string,
  topic: string,
  transaction_key: string,
  // type: PostType,
}
