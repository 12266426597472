import AccessServiceEndpoints from './dataservice/access'
import AccessPermissionServiceEndpoints from './dataservice/access-permission'
import PlatformServiceEndpoints from './dataservice/platform'
import AnalyticServiceEndpoints from './dataservice/analytics/analytic-service'
import FacilityServiceEndpoints from './dataservice/facility'
import HTServiceEndpoints from './dataservice/ht'
import LeasingManagementEndpoints from './dataservice/leasing-mgmt'
import MarketServiceEndpoints from './dataservice/market'
import PlatformManagementEndpoints from './dataservice/platform-mgmt'
import PropertyManagementEndpoints from './dataservice/property-mgmt'
import ShuttleBusServiceEndpoints from './dataservice/shuttle-bus'
import TenantServiceEndpoints from './dataservice/tenant'
import ValueAddedServiceEndpoints from './dataservice/value-added'
import APIRestfulProvider from './restful-provider'
import VisitorServiceEndpoints from './dataservice/visitor'


export type SERVICE_DESTINATION =
  | 'tenant-service'
  | 'properties-management-service'
  | 'building-service'
  | 'platform-management'
  | 'leasing-management'

/**
 *
 * Should triage based on service destination -> and then based on features -> compose ->
 *
 *  > apiCall(feature: 'user-management')(destination: SERVICE_DESTINATION) => {
 *
 *
 * }
 *
 */

/**
 * Data Service to serve the app communication with server side
 * Underlying transportation is interchangable
 */
class apiService {
  provider: APIRestfulProvider

  access: AccessServiceEndpoints
  accessPemission: AccessPermissionServiceEndpoints
  analytic: AnalyticServiceEndpoints
  facility: FacilityServiceEndpoints
  ht: HTServiceEndpoints
  leasing: LeasingManagementEndpoints
  market: MarketServiceEndpoints
  platformManagement: PlatformManagementEndpoints
  platform: PlatformServiceEndpoints
  property: PropertyManagementEndpoints
  shuttleBus: ShuttleBusServiceEndpoints
  tenant: TenantServiceEndpoints
  va: ValueAddedServiceEndpoints
  visitor: VisitorServiceEndpoints

  /**
   * Config the endpoints with transportation
   */
  constructor() {
    const restfulProvider = new APIRestfulProvider()
    this.provider = restfulProvider

    this.access = new AccessServiceEndpoints(restfulProvider)
    this.accessPemission = new AccessPermissionServiceEndpoints(restfulProvider)
    this.analytic = new AnalyticServiceEndpoints(restfulProvider)
    this.facility = new FacilityServiceEndpoints(restfulProvider)
    this.ht = new HTServiceEndpoints(restfulProvider)
    this.leasing = new LeasingManagementEndpoints(restfulProvider)
    this.market = new MarketServiceEndpoints(restfulProvider)
    this.platformManagement = new PlatformManagementEndpoints(restfulProvider)
    this.platform = new PlatformServiceEndpoints(restfulProvider)
    this.property = new PropertyManagementEndpoints(restfulProvider)
    this.shuttleBus = new ShuttleBusServiceEndpoints(restfulProvider)
    this.tenant = new TenantServiceEndpoints(restfulProvider)
    this.va = new ValueAddedServiceEndpoints(restfulProvider)
    this.visitor = new VisitorServiceEndpoints(restfulProvider)
  }

  applyAuth = (object: any) => this.provider.applyAuth(object)
  removeAuth = () => this.provider.removeAuth()
}

export default new apiService()
