import { AxiosInstance } from 'axios'
import { omit } from 'ramda'
import {
  BaseUser,
  ListRequest,
  LocalizationObject,
  LocationPermission,
  PropertyManagementCompany,
  SimpleBuilding,
  SimpleCampus,
  SimpleFloor,
  SimpleSpace,
  SpaceType,
  Tenant,
  User,
  UserGroup
} from '../../../schema'
import { LEASING_MANAGEMENT_ROUTE, ACCESS_ROUTE } from '../../config'
import APIRestfulProvider, {
  APIHTTPResponse,
  PaginatedResponse,
  parseResults,
  throwError,
  convertToPaginatedResult
} from '../../restful-provider'

const PERMISSIONS_ROUTE = `${ACCESS_ROUTE}${LEASING_MANAGEMENT_ROUTE}/accesses/permissions`
const LOCATIONS_ROUTE = `${LEASING_MANAGEMENT_ROUTE}/locations`
const MANAGED_COMPANIES_ROUTE = `${LEASING_MANAGEMENT_ROUTE}/managed-companies`
const TENANTS_ROUTE = `${LEASING_MANAGEMENT_ROUTE}/tenants`
const USER_GROUPS_ROUTE = `${LEASING_MANAGEMENT_ROUTE}/user-groups`
const USERS_ROUTE = `${LEASING_MANAGEMENT_ROUTE}/users`

/**
 * Restful endpoints for the folloing
 * Accesses
 * Locations
 * Managed-companies
 * Tenants
 * UserGroups
 * Users
 */
class LeasingManagementRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  // Accesses
  setAccessPermission = (id: string, allow: string[], exclude: string[] = []) =>
    this.client
      .post<APIHTTPResponse<{
        allow: LocationPermission[],
        exclude: LocationPermission[],
        user_id: string,
      }>>(
        `${PERMISSIONS_ROUTE}/generic/users/${id}`, {
        allow,
        exclude,
      })
      .then(parseResults)
      .catch(throwError)

  // Locations
  getBuildings = (id?: string) =>
    this.client
      .get<APIHTTPResponse<SimpleBuilding>>(
        `${LOCATIONS_ROUTE}/buildings${id ? `/${id}` : ''}`,
      )
      .then(parseResults)

  getBuildingsInCampus = (id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleBuilding>>(
        `${LOCATIONS_ROUTE}/campuses/${id}/buildings`,
      )
      .then(parseResults)

  getCampuses = (id?: string) =>
    this.client
      .get<APIHTTPResponse<SimpleCampus>>(
        `${LOCATIONS_ROUTE}/campuses${id ? `/${id}` : ''}`,
        { params: { status: 'managed' } }
      )
      .then(parseResults)

  getCampusesByStatus = (params: { status: string }) =>
    this.client
      .get<APIHTTPResponse<SimpleCampus>>(
        `${LOCATIONS_ROUTE}/campuses`,
        { params }
      )
      .then(parseResults)

  getFloors = (id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleFloor>>(
        `${LOCATIONS_ROUTE}/floors/${id}`,
      )
      .then(parseResults)

  getFloorsInBuilding = (id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleFloor>>(
        `${LOCATIONS_ROUTE}/buildings/${id}/floors`,
      )
      .then(parseResults)

  // TO-DO: withTenant will be removed.
  getSpaces = (id: string, withTenant?: boolean, types?: SpaceType[]) =>
    this.client
      .get<APIHTTPResponse<SimpleSpace>>(
        `${LOCATIONS_ROUTE}/spaces/${id}`,
        { params: !!types ? { types: types.join(',') } : undefined },
      )
      .then(parseResults)

  // TO-DO: withTenant will be removed.
  getSpacesInBuilding = (id: string, withTenant?: boolean, types?: SpaceType[]) =>
    this.client
      .get<APIHTTPResponse<SimpleSpace>>(
        `${LOCATIONS_ROUTE}/buildings/${id}/spaces`,
        { params: !!types ? { types: types.join(',') } : undefined },
      )
      .then(parseResults)

  // TO-DO: withTenant will be removed.
  getSpacesInCampus = (id: string, withTenant?: boolean, types?: SpaceType[]) =>
    this.client
      .get<APIHTTPResponse<SimpleSpace>>(
        `${LOCATIONS_ROUTE}/campuses/${id}/spaces`,
        { params: !!types ? { types: types.join(',') } : undefined },
      )
      .then(parseResults)

  // TO-DO: withTenant will be removed.
  getSpacesInFloor = (
    id: string,
    withTenant?: boolean,
    types?: SpaceType[],
    status?: string,
  ) =>
    this.client
      .get<APIHTTPResponse<SimpleSpace>>(
        `${LOCATIONS_ROUTE}/floors/${id}/spaces`,
        {
          params: {
            status,
            types: !!types ? types.join(',') : undefined,
          }
        },
      )
      .then(parseResults)

  getSpacesInSpace = (id: string, withTenant?: boolean, types?: SpaceType[]) =>
    this.client
      .get<APIHTTPResponse<SimpleSpace>>(
        `${LOCATIONS_ROUTE}/spaces/${id}/spaces`,
        { params: !!types ? { types: types.join(',') } : undefined },
      )
      .then(parseResults)

  // Managed-companies
  associateCompanyWithCampus = (companyId: string, campus_id: string) =>
    this.client
      .post<APIHTTPResponse<any>>(`${MANAGED_COMPANIES_ROUTE}/${companyId}/campuses`, { campus_id })
      .then(parseResults)
      .catch(throwError)

  createCompany = (
    pmc: PropertyManagementCompany &
      LocalizationObject<Pick<PropertyManagementCompany, 'display_name'>>
  ) =>
    this.client
      .post<APIHTTPResponse<PropertyManagementCompany>>(MANAGED_COMPANIES_ROUTE, omit(['display_name'], pmc))
      .then(parseResults)
      .catch(throwError)

  getCompany = (companyId: string) =>
    this.client
      .get<APIHTTPResponse<PropertyManagementCompany>>(
        `${MANAGED_COMPANIES_ROUTE}/${companyId}`)
      .then(parseResults)
      .catch(throwError)

  getCompanyAdmins = (companyId: string) =>
    this.client
      .get<APIHTTPResponse<BaseUser>>(
        `${MANAGED_COMPANIES_ROUTE}/${companyId}/users`,
        { params: { type: 'admin' } }
      )
      .then(parseResults)
      .catch(throwError)

  getCompanies = (params?: { campus_id?: string }) =>
    this.client
      .get<APIHTTPResponse<PropertyManagementCompany>>(MANAGED_COMPANIES_ROUTE, { params })
      .then((e) => {
        return convertToPaginatedResult(e)
      })
      .catch(throwError)

  getCompanyUserGroups = (companyId: string, userGroupId?: string) =>
    this.client
      .get<APIHTTPResponse<UserGroup>>(
        `${MANAGED_COMPANIES_ROUTE}/${companyId}/user_groups${userGroupId ? `/${userGroupId}` : ''}`,
      )
      .then(parseResults)
      .catch(throwError)

  // TO-DO: should be add by phone number
  setUserAsCompanyAdmin = (companyId: string, userId: string) =>
    this.client
      .post<APIHTTPResponse<any>>(
        `${MANAGED_COMPANIES_ROUTE}/${companyId}/users/${userId}/admin`,
      )
      .then(parseResults)
      .catch(throwError)

  unassociateCompanyWithCampus = (companyId: string, campusId: string) =>
    this.client
      .delete<APIHTTPResponse<any>>(
        `${MANAGED_COMPANIES_ROUTE}/${companyId}/campuses/${campusId}`,
      )
      .then(parseResults)
      .catch(throwError)

  // Tenants
  associateTenantLocation = (tenantId: string, space_id: string) =>
    this.client
      .post<APIHTTPResponse<any>>(`${TENANTS_ROUTE}/${tenantId}/locations/spaces`, {
        space_id,
      })
      .then(parseResults)
      .catch(throwError)

  createTenant = (tenant: Tenant & LocalizationObject<Pick<Tenant, 'display_name'>>) =>
    this.client
      .post<APIHTTPResponse<Tenant>>(TENANTS_ROUTE, omit(['display_name'], tenant))
      .then(parseResults)
      .catch(throwError)

  getTenant = (tenantId: string) =>
    this.client
      .get<APIHTTPResponse<Tenant>>(`${TENANTS_ROUTE}/${tenantId}`)
      .then(parseResults)
      .catch(throwError)

  getTenantAdmins = (tenantId: string) =>
    this.client
      .get<APIHTTPResponse<User>>(
        `${TENANTS_ROUTE}/${tenantId}/users`,
        { params: { type: 'admin' } }
      )
      .then(parseResults)
      .catch(throwError)

  getTenantNumOfStaff = (tenantId: string) =>
    this.client
      .get<APIHTTPResponse<{ number_of_staff: number }>>(
        `${TENANTS_ROUTE}/${tenantId}/users`,
        { params: { mode: 'count', type: 'staff' } }
      )
      .then(parseResults)
      .catch(throwError)

  getTenants = (params?: { building_id?: string, campus_id?: string, occupy?: boolean }) =>
    this.client
      .get<APIHTTPResponse<Tenant>>(TENANTS_ROUTE, {
        params,
      })
      .then((e) => {
        return convertToPaginatedResult(e)
      })
      .catch(throwError)

  removeTenant = (tenantId: string) =>
    this.client
      .delete<APIHTTPResponse<any>>(`${TENANTS_ROUTE}/${tenantId}`)
      .then(parseResults)
      .catch(throwError)

  // TO-DO: should be add by phone number
  setUserAsTenantAdmin = (tenantId: string, userId: string) =>
    this.client
      .post<APIHTTPResponse<Tenant>>(
        `${TENANTS_ROUTE}/${tenantId}/admin`, { username: userId }
      )
      .then(parseResults)
      .catch(throwError)

  removeUserAsTenantAdmin = (tenantId: string, userId: string) =>
    this.client
      .delete<APIHTTPResponse<Tenant>>(
        `${TENANTS_ROUTE}/${tenantId}/users/${userId}/admin`
      )
      .then(parseResults)
      .catch(throwError)

  unassociateTenantLocation = (tenantId: string, spaceId: string) =>
    this.client
      .delete<APIHTTPResponse<any>>(
        `${TENANTS_ROUTE}/${tenantId}/locations/spaces/${spaceId}`,
      )
      .then(parseResults)
      .catch(throwError)

  updateTenant = ({
    id,
    ...args
  }: Pick<Tenant, 'id' | 'description' | 'profile_picture_url' | 'email_domains'> & LocalizationObject<Pick<Tenant, 'display_name'>>) =>
    this.client
      .put<APIHTTPResponse<Tenant>>(`${TENANTS_ROUTE}/${id}`, args)
      .then(parseResults)
      .catch(throwError)

  // User groups
  addUserToUserGroup = (userId: string, userGroupId: string) =>
    this.client
      .post<APIHTTPResponse<User>>(
        `${USER_GROUPS_ROUTE}/${userGroupId}/users/${userId}`,
      )
      .then(parseResults)
      .catch(throwError)

  dropUserFromUserGroup = (userId: string, userGroupId: string) =>
    this.client
      .delete<APIHTTPResponse<User>>(
        `${USER_GROUPS_ROUTE}/${userGroupId}/users/${userId}`,
      )
      .then(parseResults)
      .catch(throwError)

  getUserGroups = (userGroupId?: string) =>
    this.client
      .get<APIHTTPResponse<UserGroup>>(
        `${USER_GROUPS_ROUTE}${userGroupId ? `/${userGroupId}` : ''}`,
      )
      .then(parseResults)
      .catch(throwError)

  getUsersInUserGroup = (userGroupId: string, params?: ListRequest) =>
    this.client
      .get<PaginatedResponse<User>>(
        `${USER_GROUPS_ROUTE}/${userGroupId}/users`,
        { params: { ...params, mode: 'include_user_group' } },
      )
      .then(d => d.data)
      .catch(throwError)

  getUsersNotInUserGroup = (userGroupId: string, params?: ListRequest) =>
    this.client
      .get<PaginatedResponse<User>>(
        `${USER_GROUPS_ROUTE}/${userGroupId}/users`,
        { params: { ...params, mode: 'exclude_user_group' } },
      )
      .then(d => d.data)
      .catch(throwError)

  // Users
  // TO-DO: should be add by phone number
  addUser = (phone_number: string) =>
    this.client
      .post<APIHTTPResponse<User>>(`${USERS_ROUTE}`, {
        username: phone_number
      })
      .then(parseResults)
      .catch(throwError)

  dropUser = (userId: string) =>
    this.client
      .delete<APIHTTPResponse<User>>(`${USERS_ROUTE}/${userId}`)
      .then(parseResults)
      .catch(throwError)

  getUser = (userId: string) =>
    this.client
      .get<APIHTTPResponse<User>>(`${USERS_ROUTE}/${userId}`)
      .then(parseResults)
      .catch(throwError)

  getUsers = (params?: ListRequest) =>
    this.client
      .get<PaginatedResponse<User>>(USERS_ROUTE, { params })
      .then(d => d.data)
      .catch(throwError)

  uploadFile = (file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ url: string, id: string }>>(`${LEASING_MANAGEMENT_ROUTE}/pics/upload`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((d) => d.data.result)
      .catch(throwError)
}

export default LeasingManagementRestful
