import APIRestfulProvider from "../../restful-provider"
import HTMemberRestful from "./ht-member"
import ScanPointRestful from "./scan-point"
import HTAPPFunctionsServices from './ht-app-functions'
import SpendingTransactionRestful from './spending-transaction'
import HTNotification from './notification'



/**
 * Leasing Management Services
 */

class HendersonTouchEndpoints {
  htMember: HTMemberRestful
  scanPoint: ScanPointRestful
  appFuctions: HTAPPFunctionsServices
  spendingTransaction: SpendingTransactionRestful
  notification: HTNotification

  constructor(provider: APIRestfulProvider) {
    this.htMember = new HTMemberRestful(provider)
    this.scanPoint = new ScanPointRestful(provider)
    this.appFuctions = new HTAPPFunctionsServices(provider)
    this.spendingTransaction = new SpendingTransactionRestful(provider)
    this.notification = new HTNotification(provider)
  }
}

export default HendersonTouchEndpoints
