import { Action, ACTION_KEY_IOT_MGMT } from "../../actions";
import { Device, DeviceType, DataModel, Descriptor, CreatingDevice, DetailMode, DataModelRecordPair } from "../../../schema";

interface DeviceTypeState {
    success: boolean,
    loading: boolean,
    error: any,

    deviceTypes: DeviceType[],
    detailMode: DetailMode,
    detailDeviceType: DeviceType,

    providerList: string[]
}

const initialState: DeviceTypeState = {
    success: false,
    loading: false,
    error: null,

    deviceTypes: null,
    detailMode: "view",
    detailDeviceType: null,

    providerList: ['aws-iot']
}

const DeviceTypeReducer = function (state = initialState, action: Action): DeviceTypeState {
    switch (action.type) {

        case ACTION_KEY_IOT_MGMT.DEVICE_TYPE_LIST.SUCCESS: {
            console.warn('[DeviceReducer] ACTION_KEY_IOT_MGMT.DEVICE_LIST.SUCCESS')
            return {
                ...state,
                success: true,
                loading: false,
                deviceTypes: action.payload
            };
        }

        case ACTION_KEY_IOT_MGMT.DEVICE_TYPE_LIST.FAILURE: {
            console.warn('[DeviceReducer] ACTION_KEY_IOT_MGMT.DEVICE_LIST.FAILURE')
            return {
                ...state,
                success: false,
                loading: false,
                deviceTypes: null,
                error: action.payload
            };
        }


        // case ACTION_KEY_IOT_MGMT.DEVICE_TYPE_DETAIL.SUCCESS: {

        //     return {
        //         ...state,
        //         detailMode: 'create',
        //         detailDeviceType: action.payload
        //     }
        // }

        case ACTION_KEY_IOT_MGMT.DEVICE_TYPE_DETAIL.REQUEST: {

            return {
                ...state,
                success: false,
                loading: true,
                detailDeviceType: null,
            }
        }

        case ACTION_KEY_IOT_MGMT.DEVICE_TYPE_DETAIL.SUCCESS: {
            console.warn('[DeviceReducer] ACTION_KEY_IOT_MGMT.DEVICE_LIST.FAILURE')
            return {
                ...state,
                success: true,
                loading: false,
                detailMode: 'view',
                detailDeviceType: action.payload,

            }
        }

        case ACTION_KEY_IOT_MGMT.DEVICE_TYPE_DETAIL.FAILURE: {

            return {
                ...state,
                success: false,
                loading: false,
                detailMode: 'view',
                error: action.payload
            }
        }

        default: {
            return state;
        }
    }
};

export default DeviceTypeReducer;
