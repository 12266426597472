import { AxiosInstance } from 'axios'
import * as R from 'ramda'

import { Dashboard, SimpleCampus } from '../../../schema'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'

/**
 * Restful endpoints for AnalyticService module
 */
class AnalyticService {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/analytics'

  getCampuses = () =>
    this.client
      .get<APIHTTPResponse<SimpleCampus>>(`${this.subRoute}/utils/get-available-campuses`)
      .then((d) => d.data.result)
      .catch(throwError)

  getDashboard = (params: {id:string, number: number, campus_ids?: string[], start_time?: string, end_time?: string }) =>
    this.client
      .post<APIHTTPResponse<Dashboard>>(
        `${this.subRoute}/leasing-management/${params.id}/set-dashboard`,
          R.reject(
            R.either(R.isEmpty, R.isNil),
            R.pick(['number', 'to_time', 'campus_ids', 'start_time', 'end_time'], params),
          ),
        
      )
      .then((d) => d.data.result)
      .catch(throwError)

  getDashboards = (id: string) =>
    this.client
      .get<APIHTTPResponse<Dashboard>>(
        `${this.subRoute}/leasing-management/${id}/get-dashboards`,
      )
      .then((d) => d.data.result)
      .catch(throwError)
}

export default AnalyticService
