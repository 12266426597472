import { SimpleBase, LocalizationObject, LocalizationField } from './base'

export enum EntityType {
  GameCoupon = 'game.coupon',
  Coupon = 'comm.coupon',
  Ticket = 'comm.ticket',
}

export enum VoucherState {
  Claimed = 'claimed',
  Pending = 'pending',
  Used = 'used',
  Expired = 'expired',
  Voided = 'voided',
}

// export interface Asset extends SimpleBase, LocalizationObject {
//   asset_id: string,
//   quota: number,
//   description: string,
//   entity_code: string,
//   entity_type: EntityType,
//   expiry_date: string,
//   merchant_id: string,
//   merchant_url: string,
//   picture_id?: string,
//   picture_url?: string,
//   remarks: string,
//   terms: string,
//   amount?: number,
//   ref_locations: string[],
//   ref_location_type: string,
// }

export interface Asset extends SimpleBase, LocalizationObject {
  asset_id: string,
  amount: number,
  low_amount: number,
  show_by_threshold?: boolean,
  use_method?: string,
  void_code?: string,
  redeem_category?: string,
  redeem_method?: string,
  remain_amount?: number,

  // form field
  expiry_date?: string,
  start_time?: string,
  end_time?: string,
  picture_id?: string,
  picture_url?: string,
  ref_locations?: string[],
  ref_location_type?: string,
  entity_type?: EntityType,
  merchant_id?: string,
  remarks?: string,
  terms?: string,
  quota?: number,
}

export type AssetStock = {
  amount: number,
  asset_id: string,
  low_amount: number,
}

export type FormAsset = Asset & {
  localization: {
    description: LocalizationField,
    terms: LocalizationField,
    display_name: LocalizationField,
  },
  picture: {
    id: string,
    url: string,
  },
  low_amount?: number,
}

export interface Merchant extends LocalizationObject {
  id: string,
  display_name: string,
  introduction?: string,
  logo?: {
    url: string,
    id: string,
  },
}

export interface Voucher extends SimpleBase {
  asset_id: string,
  get_time?: string,
  claim_user_id?: string,
  claim_first_name?: string,
  claim_last_name?: string,
  claim_tenant_display_name: string,
  use_time: string,
  state: VoucherState,
  item_number: string,
}
