import APIRestfulProvider from "../../restful-provider"
import TenantVisitorServices from "./visitor"
import TenantUserManagement from "./user"
import TenantPermissionControlServices from "./permission"

/**
 * Tenant Services Endpoint
 */

class TenantServiceEndpoints {

    visitor: TenantVisitorServices
    user: TenantUserManagement
    permission: TenantPermissionControlServices

    constructor(provider: APIRestfulProvider) {
        this.visitor = new TenantVisitorServices(provider)
        this.user = new TenantUserManagement(provider)
        this.permission = new TenantPermissionControlServices(provider)
    }

}


export default TenantServiceEndpoints