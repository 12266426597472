import { action } from './index'
import { SimpleCampus, SimpleBuilding, SimpleFloor, SimpleSpace, SpaceType, HierarchySpace, LocationPermission, BaseUser, PermissionType } from '../../schema'


export const ACTION_KEY_LOCATION_SELECT = {
    LOCATION_SELECTION_START: 'LOCATION_SELECTION_START',
    LOCATION_SELECTION_READY: 'LOCATION_SELECTION_READY',
    CAMPUS_SELECT: 'CAMPUS_SELECT',

    BUILDING_READY: 'BUILDING_READY',
    BUILDING_SELECT: 'BUILDING_SELECT',

    FLOOR_READY: 'FLOOR_READY',
    FLOOR_SELECT: 'FLOOR_SELECT',

    SPACE_READY: 'SPACE_READY',
    SPACE_SELECT: 'SPACE_SELECT',
    SPACES_SELECT: 'SPACES_SELECT',

    CAMPUS_CLEAR_EXCLUDES: 'CAMPUS_CLEAR_EXCLUDES',
    CAMPUS_OPT_IN: 'CAMPUS_OPT_IN',
    BUILDING_OPT: 'BUILDING_OPT',
    FLOOR_OPT: 'FLOOR_OPT',
    SPACE_OPT: 'SPACE_OPT',

    LOCATION_EXCLUSIVE_SELECTION_START: 'LOCATION_EXCLUSIVE_SELECTION_START',
    LOCATION_EXCLUSIVE_SELECTION_READY: 'LOCATION_EXCLUSIVE_SELECTION_READY',

    LOCATION_EXCLUSIVE_SELECTION_SUBMIT: 'LOCATION_EXCLUSIVE_SELECTION_SUBMIT',
    LOCATION_EXCLUSIVE_SELECTION_SUBMIT_DONE: 'LOCATION_EXCLUSIVE_SELECTION_SUBMIT_DONE',



    // VIEW_CAMPUS_LOAD: createRequestActionStrings('CAMPUS_LOAD'),
    // VIEW_CAMPUS_SELECT: 'VIEW_CAMPUS_SELECT',
}

export const loadCampus = {}

const selectCampus = (campus: SimpleCampus) => action(ACTION_KEY_LOCATION_SELECT.CAMPUS_SELECT, campus)
const fetchedBuilding = (buildings: SimpleBuilding[], spaces: SimpleSpace[]) =>
    action(ACTION_KEY_LOCATION_SELECT.BUILDING_READY, { buildings, spaces })

const selectBuilding = (building: SimpleBuilding) => action(ACTION_KEY_LOCATION_SELECT.BUILDING_SELECT, building)
const fetchedFloors = (floors: SimpleFloor[], spaces: SimpleSpace[]) =>
    action(ACTION_KEY_LOCATION_SELECT.FLOOR_READY, { floors, spaces })


const optSpace = (space: SimpleSpace, floor: SimpleFloor, building: SimpleBuilding, campus: SimpleCampus) =>
    action(ACTION_KEY_LOCATION_SELECT.SPACE_OPT, { space, floor, campus, building })

const optFloor = (floor: SimpleFloor, building: SimpleBuilding, campus: SimpleCampus) => action(ACTION_KEY_LOCATION_SELECT.FLOOR_OPT, { floor, building, campus })
const optBuilding = (building: SimpleBuilding, campus: SimpleCampus) => action(ACTION_KEY_LOCATION_SELECT.BUILDING_OPT, { building, campus })


const optInCampus = (campus: SimpleCampus) => action(ACTION_KEY_LOCATION_SELECT.CAMPUS_OPT_IN, campus)

const resetCampusExcludes = (campus: SimpleCampus) => action(ACTION_KEY_LOCATION_SELECT.CAMPUS_CLEAR_EXCLUDES, campus)




const selectFloor = (floor: SimpleFloor) => action(ACTION_KEY_LOCATION_SELECT.FLOOR_SELECT, floor)
const fetchedSpaces = (spaces: SimpleSpace[]) => action(ACTION_KEY_LOCATION_SELECT.SPACE_READY, spaces)

const selectSpace = (space: SimpleSpace) => action(ACTION_KEY_LOCATION_SELECT.SPACE_SELECT, space)
const selectSpaces = (spaces: SimpleSpace[]) => action(ACTION_KEY_LOCATION_SELECT.SPACES_SELECT, spaces)

const showLocationSelection = {
    show: (filterTenant?: boolean, spaceTypes?: SpaceType[], selectingSpaces?: HierarchySpace[]) =>
        action(ACTION_KEY_LOCATION_SELECT.LOCATION_SELECTION_START, { filterTenant, spaceTypes, selectingSpaces }),
    loaded: (campuses: SimpleCampus[]) => action(ACTION_KEY_LOCATION_SELECT.LOCATION_SELECTION_READY, campuses),
}

const showLocationExclusiveSelection = {
    show: (allows: LocationPermission[], excludes: LocationPermission[], permissionType: PermissionType) =>
        action(ACTION_KEY_LOCATION_SELECT.LOCATION_EXCLUSIVE_SELECTION_START, { allows, excludes, permissionType }),
    loaded: (campuses: SimpleCampus[]) => action(ACTION_KEY_LOCATION_SELECT.LOCATION_EXCLUSIVE_SELECTION_READY, campuses),
}


const submitLocationExclusiveSelection = {
    request: (user: BaseUser) =>
        action(ACTION_KEY_LOCATION_SELECT.LOCATION_EXCLUSIVE_SELECTION_SUBMIT, user),
    loaded: (campuses: SimpleCampus[]) => action(ACTION_KEY_LOCATION_SELECT.LOCATION_EXCLUSIVE_SELECTION_SUBMIT_DONE, campuses),
}




// for location selection

// const loadViewCampuses = {
//     request: () => action(ACTION_KEY_LOCATION_SELECT.VIEW_CAMPUS_LOAD.REQUEST),
//     success: (campuses: any) => action(ACTION_KEY_LOCATION_SELECT.VIEW_CAMPUS_LOAD.SUCCESS, campuses),
//     failure: (error) => action(ACTION_KEY_LOCATION_SELECT.VIEW_CAMPUS_LOAD.FAILURE, error),
// }

// const selectViewCampus = (campus: SimpleCampus) => action(ACTION_KEY_LOCATION_SELECT.VIEW_CAMPUS_SELECT, campus)

export const LocationSelectActions = {
    showLocationSelection,
    showLocationExclusiveSelection,
    submitLocationExclusiveSelection,

    selectCampus,
    fetchedBuilding,
    selectBuilding,
    fetchedFloors,
    selectFloor,
    selectSpace,
    selectSpaces,
    fetchedSpaces,

    resetCampusExcludes,
    optSpace,
    optFloor,
    optBuilding,
    optInCampus

    // loadViewCampuses,
    // selectViewCampus
}
