import { LocalizationObject } from "./base"

export enum BannerCategory {
  Browser = 'outside_web_url',
  Game = 'game',
  InAppWebView = 'inside_web_url',
  Post = 'post',
}

export enum BannerUserAccessPermission {
  All = 'all_users',
  Tester = 'test_users',
}

export type Banner = LocalizationObject<{ title: string }> & {
  category: BannerCategory,
  entity_type: string,
  entity_id: string,
  index: number,
  picture_id: string,
  picture_url: string,
  state: string,
  title: string,
  user_access_permission: BannerUserAccessPermission,
  web_url: string,
}

export type UpdateBannersRequest = {
  category: BannerCategory,
  entity_id: string,
  index: number,
  picture_id: string,
  user_access_permission: BannerUserAccessPermission,
  web_url: string,
}
