import AsyncStorage from '@react-native-community/async-storage'
import { isReactNative } from '../utils/runtime'


class LocalStorageService {
  ls = isReactNative ?  AsyncStorage : window.localStorage 

  setItem(key, value) {
    value = JSON.stringify(value)
    this.ls.setItem(key, value)
    return true
  }

  getItem(key) {
    const value = this.ls.getItem(key) as string
    try {
      return JSON.parse(value)
    } catch (e) {
      return null
    }
  }

  removeItem(key) {
    this.ls.removeItem(key)
  }


  async setItemAsync(key, value) {
    value = JSON.stringify(value)
    this.ls.setItem(key, value)
    return true
  }

  async getItemAsync(key) {
    try {
      const value = await this.ls.getItem(key)
      return JSON.parse(value)
    } catch (e) {
      return null
    }
  }

  async removeItemAsync(key) {
    this.ls.removeItem(key)
  }

}

export default new LocalStorageService()