export const POINT_TRANSACTION_TYPES = [
  'READ_FEATURED_STORY_REWARD',
  'SHARE_FEATURED_STORY_REWARD',
  'TIER_UPGRADE_REWARD',
  'POINT_ADJUSTMENT',
  'INVITATION_INVITER_REWARD',
  'INVITATION_INVITEE_REWARD',
  'SPENDING_REWARD',
  'SPENDING_BONUS',
  'SCAN_TO_EARN_REWARD',
  'ENTER_BUILDING_REWARD',
  'VISITOR_VISIT_REWARD',
  'RESERVE_GIFT',
  'RESERVE_EVENT',
  'BASIC_TIER_REWARD',
  'SILVER_TIER_REWARD',
  'VIP_TIER_REWARD',
  'MEMBERSHIP_UPGRADE_DEDUCT_POINT',
  'REFUND_REMOVED_GIFT',
  'REFUND_CANCELLED_EVENT',
  'REFUND_DELAYED_EVENT',
  'SUBMIT_EVENT_SURVEY_FORM',
  'SPENDING_REFUND'
]

export const POINT_TRANSACTION_ADD_DEDUCT = ['ADD', 'DEDUCT']

export const MERCHANT_LOCATION_TYPE = ['IN_CAMPUS', 'OTHER_LOCATION']

// 'none' | 'inside_web_url' | 'outside_web_url' | 'merchant' | 'announcement' | 'event' | 'gift' | 'time_limited_offer' | 'article'

export const Ad_NONE = 'none'
export const Ad_INSIDE_WEB_URL = 'inside_web_url'
export const Ad_OUTSIDE_WEB_URL = 'outside_web_url'
export const Ad_MERCHANT = 'merchant'
export const Ad_ANNOUNCEMENT = 'announcement'
export const Ad_EVENT = 'event'
export const Ad_GIFT = 'gift'
export const Ad_TIME_LIMITED_OFFER = 'time_limited_offer'
export const Ad_ARTICLE = 'article'

export const AdCategoryCampusesList = [
  Ad_NONE,
  Ad_INSIDE_WEB_URL,
  Ad_OUTSIDE_WEB_URL,
  Ad_MERCHANT,
  Ad_ANNOUNCEMENT,
  Ad_EVENT,
  Ad_GIFT,
  Ad_TIME_LIMITED_OFFER,
  Ad_ARTICLE,
]

export const AdCategoryList = [Ad_NONE, Ad_INSIDE_WEB_URL, Ad_OUTSIDE_WEB_URL]
