import {
  // axios,
  AxiosInstance,
} from 'axios'
import {
  Device,
  DeviceType,
  StatusRecord,
  // TokenResult,
  // Descriptor,
  DeviceInterface,
} from '../../../schema'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'

/**
 * Restful endpoints for IOT module
 */
class IOTRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/platform-management/iot-management'

  ////////// Devices / Data / Aggerated

  getDevicesByBuilding = (building_id: string) =>
    this.client
      .get<APIHTTPResponse<Device>>(`${this.subRoute}/device/get-devices-by-building-id`, {
        params: { building_id },
      })
      .then((d) => d.data.result) 

  getDeviceStatus = (id: string) =>
    this.client
      .get<APIHTTPResponse<StatusRecord>>(`${this.subRoute}/device/get-devices-status`, {
        params: {
          data_from_days: 3,
          id,
        },
      })
      .then((d) => d.data.result)
      .catch(throwError)

  getDevicesStatuses = () =>
    this.client
      .get<APIHTTPResponse<StatusRecord>>(`${this.subRoute}/device/get-devices-status`, {
        params: {
          data_from_days: 3,
        },
      })
      .then((d) => d.data.result)
      .catch(throwError)

  sendDeviceAction = (params: any) =>
    this.client
      .post<APIHTTPResponse<any>>('/action', params)
      .then((d) => d.data.result)
      .catch(throwError)

  ////////// Device Type

  deviceTypeAddDataModel = (type: DeviceType) =>
    this.client
      .patch<APIHTTPResponse<DeviceType>>(`/deviceType/${type.id}`, type)
      .then((d) => d.data.result)
      .catch(throwError)
  deviceTypeUpdateDataModel = (type: DeviceType) =>
    this.client
      .patch<APIHTTPResponse<DeviceType>>(`/deviceType/${type.id}`, type)
      .then((d) => d.data.result)
      .catch(throwError)

  ////////// Interface

  getInterfaces = () =>
    this.client
      .get<APIHTTPResponse<DeviceInterface>>('/interface')
      .then((d) => d.data.result)
          .catch(throwError)
    
  getInterface = (id: string) =>
    this.client
      .get<APIHTTPResponse<DeviceInterface>>(`/interface/${id}`)
      .then((d) => d.data.result)
          .catch(throwError)
     // ???? 新接口
    // getInterface = (id: string) =>
    // this.client
    //   .get<APIHTTPResponse<DeviceInterface>>(`/device-data-interface/get-interfaces?id=${id}`)
    //   .then((d) => d.data.result)
     //     .catch(throwError)
    
  createInterface = () =>
    this.client
      .post<APIHTTPResponse<DeviceInterface>>('/interface')
      .then((d) => d.data.result)
          .catch(throwError)
    // ???? 新接口
    // createInterface = (type: DeviceInterface) =>
    // this.client
    //   .post<APIHTTPResponse<DeviceInterface>>('/device-data-interface/create-interface')
    //   .then((d) => d.data.result)
    //       .catch(throwError)
    
    // ???? 新接口
    editInterface = (id: string) =>
    this.client
      .put<APIHTTPResponse<DeviceInterface>>(`/device-data-interface/edit-interface?id=${id}`)
      .then((d) => d.data.result)
          .catch(throwError)

}

export default IOTRestful
