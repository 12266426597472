import { ACCESS_POLICY } from '@exaleap/common'

const tenantManagement = [ACCESS_POLICY.propertiesManagementService.tenantManagement.readOnly]

// TODO: ADMIN PREVIEW
const workOrderManagement = [
  // ACCESS_POLICY.platform.platformManagementManagement.fullAccess
  ACCESS_POLICY.propertiesManagementService.workOrderManagement.readOnly,
]

const userManagement = [
  ACCESS_POLICY.propertiesManagementService.userManagement.readOnly,
  ACCESS_POLICY.propertiesManagementService.userManagement.fullAccess,
]

const visitorPolicies = [
  ACCESS_POLICY.propertiesManagementService.visitorService.readOnly,
  ACCESS_POLICY.propertiesManagementService.visitorService.fullAccess,
]

const PropertyPortalRoutes = [
  {
    path: '/app/property-mgmt/tenants/:id',
    element: () => import('./TenantDetail'),
    auth: tenantManagement,
  },
  {
    path: '/app/property-mgmt/tenants',
    element: () => import('./TenantList'),
    auth: tenantManagement,
  },
  {
    path: '/app/property-mgmt/staff/*',
    element: () => import('./PropertyStaff'),
    auth: userManagement,
  },
  {
    path: '/app/property-mgmt/value-added/order/:id',
    element: () => import('./ValueAddedOrderDetail'),
    auth: [ACCESS_POLICY.valueAddedService.propertiesManagementAccess.fullAccess],
  },
  {
    path: '/app/property-mgmt/value-added/orders',
    element: () => import('./ValueAddedRecords'),
    auth: [ACCESS_POLICY.valueAddedService.propertiesManagementAccess.fullAccess],
  },
  {
    path: '/app/property-mgmt/value-added',
    element: () => import('./ValueAddedOverview'),
    auth: [ACCESS_POLICY.valueAddedService.propertiesManagementAccess.fullAccess],
  },
  {
    path: '/app/property-mgmt/visitor/:id',
    element: () => import('./VisitorRequestDetailPage'),
    auth: visitorPolicies,
  },
  {
    path: '/app/property-mgmt/visitor',
    element: () => import('./VisitorRequestList'),
    auth: visitorPolicies,
  },
  {
    path: '/app/property-mgmt/visitors/records',
    element: () => import('./VisitorRecords'),
    auth: [ACCESS_POLICY.visitorService.propertiesManagementAccess.fullAccess],
  },
  {
    path: '/app/property-mgmt/visitors',
    element: () => import('./VisitorRecordsOverview'),
    auth: [ACCESS_POLICY.visitorService.propertiesManagementAccess.fullAccess],
  },
  {
    path: '/app/property-mgmt/access-records',
    element: () => import('./AccessRecordsExport'),
    auth: [ACCESS_POLICY.propertiesManagementService.accessRecordManagement.fullAccess],
  },
  {
    path: '/app/property-mgmt/work-orders/list',
    element: () => import('./WorkOrderListing'),
    auth: workOrderManagement,
  },
  {
    path: '/app/property-mgmt/work-orders/:id/*',
    element: () => import('./WorkOrderDetailTabContainer'),
    auth: workOrderManagement,
  },
  {
    path: '/app/property-mgmt/work-orders',
    element: () => import('./WorkOrderOverview'),
    auth: workOrderManagement,
  },
  {
    path: '/app/property-mgmt/facility',
    exact: true,
    element: () => import('./FacilityDetailPage'),
    auth: [ACCESS_POLICY.propertiesManagementService.facilityService.fullAccess],
  },
  {
    path: '/app/property-mgmt/overview',
    element: () => import('./Overview'),
    auth: [ACCESS_POLICY.propertiesManagementService.generalService.basic],
  },
  {
    path: '/app/property-mgmt/transaction-records',
    element: () => import('./TransactionRecords'),
    auth: [ACCESS_POLICY.propertiesManagementService.facilityService.fullAccess],
  },
  {
    path: '/app/property-mgmt/shuttle-bus',
    element: () => import('./ShuttleBus'),
    auth: [ACCESS_POLICY.propertiesManagementService.shuttleBusService.fullAccess],
  },
  {
    path: '/app/property-mgmt/gamification',
    element: () => import('./Game/Management'),
    auth: [ACCESS_POLICY.propertiesManagementService.gameManagement.fullAccess],
  },
  {
    path: '/app/property-mgmt/content-mgmt/merchant',
    element: () => import('../leasing-portal/MerchantsV2'),
    auth: [ACCESS_POLICY.propertiesManagementCompany.merchant.merchantManagement],
  },
  {
    path: '/app/property-mgmt',
    exact: true,
    element: () => import('./DefaultPage'),
  },
]

export default PropertyPortalRoutes 
