import { SimpleFloor, SimpleSpace } from '../../../schema'
import { ACTION_KEY_BMS } from '../../actions/bms'
import { Action } from '../../actions'

interface FloorState {
  // reuqest login status
  success: boolean,
  loading: boolean,
  error: any,

  floors: SimpleFloor[],
  floorDetail: {
    floor: SimpleFloor,
    spaces: SimpleSpace[],
    addSpaceSuccess: SimpleSpace[],
  },
}

const initialState: FloorState = {
  success: false,
  loading: false,
  error: null,

  floors: null,
  floorDetail: {
    floor: null,
    spaces: null,
    addSpaceSuccess: null,
  },
}

const FloorReducer = function (state = initialState, action: Action): FloorState {
  switch (action.type) {
    case ACTION_KEY_BMS.FLOOR_LIST.SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        floors: action.payload,
      }
    }

    case ACTION_KEY_BMS.FLOOR_DETAIL.SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        floorDetail: action.payload,
      }
    }

    case ACTION_KEY_BMS.FLOOR_DETAIL_CREATE_SPACE.REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }

    case ACTION_KEY_BMS.FLOOR_DETAIL_CREATE_SPACE.FAILURE: {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      }
    }

    case ACTION_KEY_BMS.FLOOR_DETAIL_CREATE_SPACE.SUCCESS: {
      return {
        ...state,
        success: true,
        loading: false,
        floorDetail: {
          ...state.floorDetail,
          spaces: [...(state?.floorDetail?.spaces || []), action.payload],
          addSpaceSuccess: action.payload,
        },
      }
    }

    default: {
      return state
    }
  }
}

export default FloorReducer
