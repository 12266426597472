import {
  // axios,
  AxiosInstance,
} from 'axios'
import {
  Device,
  DeviceType,
  // StatusRecord,
  // TokenResult,
  // Descriptor,
  DataModel,
  CreatingDevice,
  // DeviceInterface,
  LatestDeviceData,
} from '../../../schema'
import APIRestfulProvider, { APIHTTPResponse, PaginatedResponse, throwError } from '../../restful-provider'
import { PLATFORM_ADMIN_ROUTE } from '../../config'
// import  from ''

class DevicesRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = `/device-management${PLATFORM_ADMIN_ROUTE}`

  //////// Device Type
  // ***** 只是路径变化
  getDeviceTypes = () =>
    this.client
      .get<APIHTTPResponse<DeviceType>>(`${this.subRoute}/device-types`)
      .then((d) => d.data.result)
      .catch(throwError)

  // ***** 只是路径变化
  getDeviceTypeById = (id: string) =>
    this.client
      .get<APIHTTPResponse<DeviceType>>(`${this.subRoute}/device-types`, { params: { id } })
      .then((d) => d.data.result)
      .catch(throwError)

  // ???? 好像没使用
  createDeviceType = () =>
    this.client
      .post<APIHTTPResponse<DeviceType>>(`${this.subRoute}/device-types`)
      .then((d) => d.data.result)
      .catch(throwError)

  // ???? 参数变化了，暂时没有使用
  updateDeviceType = (id: string) =>
    this.client
      .put<APIHTTPResponse<DeviceType>>(`${this.subRoute}/device-types/${id}`,)
      .then((d) => d.data.result)
      .catch(throwError)

  // ***** 只是路径变化
  getDevices = (
    searchText?: string,
    device_type_ids?: string[],
    locations?: string[],
    page_number = 1,
    page_size = 1000,
  ) => {
    const device_type_idsArr = (device_type_ids || []).map(i => `device_type_ids=${i}`)
    const locationsArr = (locations || []).map(i => `locations=${i}`)
    const paramsArr = [...device_type_idsArr, ...locationsArr].join('&')
    return this.client
      .get<PaginatedResponse<Device>>(`${this.subRoute}/devices${!!paramsArr?.length ? `?${paramsArr}` : ''}`, {
        params: {
          display_name: searchText,
          // device_type_ids: device_type_ids || [],
          // locations: locations || [],
          page_number,
          page_size,
        }
      })
      .then((d) => d.data)
  }

  // ***** 只是路径变化
  getDeviceById = (id: string) =>
    this.client
      .get<APIHTTPResponse<Device>>(`${this.subRoute}/devices/${id}`)
      .then((d) => d.data.result)
      .catch(throwError)

  // ***** 只是路径变化
  createDevice = (create: CreatingDevice) =>
    this.client
      .post<APIHTTPResponse<Device>>(`${this.subRoute}/devices`, create)
      .then((d) => d.data.result)
      .catch(throwError)

  // ***** 只是路径变化
  updateDevice = (id: string, device: Pick<CreatingDevice, 'display_name' | 'doc'>) =>
    this.client
      .put<APIHTTPResponse<Device>>(`${this.subRoute}/devices/${id}`, device)
      .then((d) => d.data.result)
      .catch(throwError)

  // ***** 只是路径变化
  deleteDevice = (id: string) =>
    this.client
      .delete<APIHTTPResponse<{ id: string, status: string }>>(`${this.subRoute}/devices/${id}`)
      .then((d) => d.data.result)
      .catch(throwError)

  // ***** 只是路径变化
  associateLocation = (device_id: string, space_id: string) =>
    this.client
      .patch<APIHTTPResponse<{ space_id: string, device_id: string }>>(
        `${this.subRoute}/devices/${device_id}/spaces/${space_id}`,
      )
      .then((d) => d.data.result)
      .catch(throwError)

  // ***** 只是路径变化
  unassociateLocation = (device_id: string, space_id: string) =>
    this.client
      .delete<APIHTTPResponse<{ space_id: string, device_id: string }>>(
        `${this.subRoute}/devices/${device_id}/spaces/${space_id}`,
      )
      .then((d) => d.data.result)
      .catch(throwError)

  // get-device-interface-data  唯一认证新增的接口，其他???? **** 待确认
  getDeviceInterfaceData = (device_id: string, interface_id: string) =>
    this.client
      .get<APIHTTPResponse<LatestDeviceData>>(`${this.subRoute}/devices/${device_id}/interfaces/${interface_id}/histories`)
      .then((d) => d.data.result)
      .catch(throwError)

  ////////// DataModels

  getDataModels = () =>
    this.client
      .get<APIHTTPResponse<DataModel>>(`${this.subRoute}/data-models`)
      .then((d) => d.data.result)
      .catch(throwError)

  getDataModel = (id: string) => {
    return this.client
      .get<APIHTTPResponse<DataModel>>(`${this.subRoute}/data-models`, {
        params: { id },
      })
      .then((d) => d.data.result)
      .catch(throwError)
  }

  // ???? 接口路径发生变化
  createDataModel = () =>
    this.client
      .post<APIHTTPResponse<DataModel>>(`${this.subRoute}/data-models`)
      .then((d) => d.data.result)
      .catch(throwError)

  // ????  新接口
  editDataModelById = (id: string) =>
    this.client
      .put<APIHTTPResponse<DataModel>>(`${this.subRoute}/data-models/${id}`)
      .then((d) => d.data.result)
      .catch(throwError)
}

export default DevicesRestful
