import {
  // all,
  // take,
  call,
  put,
  takeEvery,
  // select,
} from 'redux-saga/effects'
import { SimpleBuilding } from '../../schema'
import { ACTION_KEY_GENERAL_DATA, generalDataActions, NotificationActionFunctions } from '../actions'
import { apiService, hrdpActions } from '../../..'

function* buildingRequest() {
  console.group('[SAGA] MerchantTypeRequest')
  try {
    yield put(hrdpActions.fetchStart())
    const templates: SimpleBuilding[] = yield call(apiService.platform.buildingService.getBuildings) || []
    yield put(generalDataActions.buildings.success(templates))
    yield put(hrdpActions.fetchSuccess())
  } catch (error) {
    console.error('[SAGA] deviceDetailRequest', error)
    yield put(generalDataActions.buildings.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
    yield put(hrdpActions.fetchError(error))
  }
  console.groupEnd()
}

export const generalDataSagas = [
  takeEvery(ACTION_KEY_GENERAL_DATA.BUILDINGS.REQUEST, buildingRequest),
]
