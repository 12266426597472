import {
	RequestActionFunctions,
	action,
} from "./index"
import { SimpleCampus, SimpleBuilding } from "../../schema"
import { createRequestActionStrings } from "./helper"

// BMS Related
export const ACTION_KEY_OVERVIEW = {
	OVERVIEW_CAMPUS_DETAIL: createRequestActionStrings("OVERVIEW_CAMPUS_DETAIL"),
	FUNCTION_OVERVIEW: createRequestActionStrings("OVERVIEW_FUNCTION_DASHBOARD"),
}

// Campus Detail
export const campusOverview: RequestActionFunctions = {
	request: (id?: string) => action(ACTION_KEY_OVERVIEW.OVERVIEW_CAMPUS_DETAIL.REQUEST, id),
	success: (campuses: SimpleCampus[], detail: SimpleCampus, buildings: SimpleBuilding[]) =>
		action(ACTION_KEY_OVERVIEW.OVERVIEW_CAMPUS_DETAIL.SUCCESS, { campuses, detail, buildings }),
	failure: (error) =>
		action(ACTION_KEY_OVERVIEW.OVERVIEW_CAMPUS_DETAIL.FAILURE, error),
}

// Fuction Dashboard Overview
export const dashboardOverview: RequestActionFunctions = {
	request: (id?: string) => action(ACTION_KEY_OVERVIEW.FUNCTION_OVERVIEW.REQUEST, id),
	success: (campuses: SimpleCampus[], detail: SimpleCampus, buildings: SimpleBuilding[]) =>
		action(ACTION_KEY_OVERVIEW.FUNCTION_OVERVIEW.SUCCESS, { campuses, detail, buildings }),
	failure: (error) =>
		action(ACTION_KEY_OVERVIEW.FUNCTION_OVERVIEW.FAILURE, error),
}


export const OverviewActions = {
	campusOverview,
	dashboardOverview
}
