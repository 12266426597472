import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, PaginatedResponse, throwError } from '../../restful-provider'
import {
  Asset,
  LocalizationObject,
  ListRequest,
  NotificationRecord,
  Merchant,
  // PostListRequired,
  // PostType,
  PostSubType,
  SimpleMerchant,
  Voucher,
} from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for BMS module
 */
class CommunityServiceRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/community-service'

  // actionByEditor = (id: string, type: string) =>
  //   this.client
  //     .put<APIHTTPResponse<Post>>(`${this.subRoute}/posts/${id}/editor/action/${type}`)
  //     .then(parseResult)
  //     .catch(throwError)

  // actionByApprover = (id: string, type: string) =>
  //   this.client
  //     .put<APIHTTPResponse<Post>>(`${this.subRoute}/posts/${id}/approver/action/${type}`)
  //     .then(parseResult)
  //     .catch(throwError)

  assets = (type: string) =>
    this.client
      .get<APIHTTPResponse<Asset>>(`${this.subRoute}/assets`, { params: { selectable: 1, type } })
      .then(parseResult)
      .catch(throwError)

  createAsset = (params: Partial<Asset>) =>
    this.client.post<APIHTTPResponse<Asset>>(`${this.subRoute}/assets`, params).then(parseResult)

  createMerchant = (params: Partial<Merchant>) =>
    this.client.post<APIHTTPResponse<Merchant>>(`${this.subRoute}/merchants`, params).then(parseResult)

  // ****
  // createPost = (params: Partial<Post>) =>
  //   this.client.post<APIHTTPResponse<Post>>(`${this.subRoute}/posts`, params).then(parseResult).catch(throwError)

  // deletePost = (id: string) =>
  //   this.client.delete<APIHTTPResponse<Post>>(`${this.subRoute}/posts/${id}`).then(parseResult).catch(throwError)

  // duplicatePost = (id: string, params: Pick<Post, 'localization'>) =>
  //   this.client
  //     .put<APIHTTPResponse<Post>>(`${this.subRoute}/posts/${id}/duplicate`, params)
  //     .then(parseResult)
  //     .catch(throwError)

  getMerchants = () =>
    this.client
      .get<APIHTTPResponse<SimpleMerchant & LocalizationObject>>(`${this.subRoute}/merchants`)
      .then(parseResult)
      .catch(throwError)

  // getPostTypes = () =>
  //   this.client.get<APIHTTPResponse<PostType>>(`${this.subRoute}/posts/types`).then(parseResult).catch(throwError)

  getPostSubTypes = () =>
    this.client
      .get<APIHTTPResponse<PostSubType>>(`${this.subRoute}/posts/sub-types`)
      .then(parseResult)
      .catch(throwError)

  // getPostTemplates = () =>
  //   this.client
  //     .get<APIHTTPResponse<{ type: PostType, sub_type: PostSubType }>>(`${this.subRoute}/posts/templates`)
  //     .then(parseResult)
  //     .catch(throwError)

  merchant = (id: string) =>
    this.client.get<APIHTTPResponse<Merchant>>(`${this.subRoute}/merchants/${id}`).then(parseResult).catch(throwError)

  merchants = () =>
    this.client.get<APIHTTPResponse<Merchant>>(`${this.subRoute}/merchants`).then(parseResult).catch(throwError)

  notifications = (params?: ListRequest) =>
    this.client
      .get<PaginatedResponse<NotificationRecord>>(`${this.subRoute}/notifications/push`, { params })
      .then((d) => d.data)
      .catch(throwError)

  pushNotification = (params: { data?: { post_id: string }, topic: string } & LocalizationObject) =>
    this.client
      .put<APIHTTPResponse<{ post_id: string }>>(`${this.subRoute}/notifications/push/send`, params)
      .then(parseResult)
      .catch(throwError)

  // post = (id: string) =>
  //   this.client.get<APIHTTPResponse<Post>>(`${this.subRoute}/posts/${id}`).then(parseResult).catch(throwError)

  // posts = (params?: PostListRequired) =>
  //   this.client
  //     .get<PaginatedResponse<Post>>(`${this.subRoute}/posts`, {
  //       params: R.reject(
  //         R.isEmpty,
  //         R.pick(['order_by', 'page_number', 'page_size', 'search', 'sort_by', 'state', 'type'], params),
  //       ),
  //     })
  //     .then((d) => d.data)
  //     .catch(throwError)

  updateMerchant = ({ id, ...params }: Partial<Merchant>) =>
    this.client
      .put<APIHTTPResponse<Merchant>>(`${this.subRoute}/merchants/${id}`, params)
      .then(parseResult)
      .catch(throwError)

  // updatePost = (id: string, params: Partial<Post>) =>
  //   this.client.put<APIHTTPResponse<Post>>(`${this.subRoute}/posts/${id}`, params).then(parseResult).catch(throwError)

  uploadImage = (file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ url: string, id: string }>>(`${this.subRoute}/upload/single`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((d) => d.data.result)
      .catch(throwError)

  vouchers = (id: string) =>
    this.client
      .get<APIHTTPResponse<Voucher>>(`${this.subRoute}/sessions/${id}/vouchers`)
      .then(parseResult)
      .catch(throwError)
}

export default CommunityServiceRestful
