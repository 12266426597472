import {
	action,
} from "./index"
import { SimpleCampus, SimpleBuilding } from "../../schema"
import { createRequestActionStrings } from "./helper"

// BMS Related
export const ACTION_KEY_DASHBOARD = {
	CAMPUS_ANALYTICS: createRequestActionStrings("CAMPUS_ANALYTICS"),
}

// CAMPUS_ANALYTICS
export const campusAnalytics = {
	request: (id?: string) => action(ACTION_KEY_DASHBOARD.CAMPUS_ANALYTICS.REQUEST, id),
	success: (campuses: SimpleCampus[], detail?: SimpleCampus, buildings?: SimpleBuilding[]) =>
		action(ACTION_KEY_DASHBOARD.CAMPUS_ANALYTICS.SUCCESS, { campuses, detail, buildings }),
	failure: (error) =>
		action(ACTION_KEY_DASHBOARD.CAMPUS_ANALYTICS.FAILURE, error),
}


export const DashboardActions = {
	campusAnalytics,
}
