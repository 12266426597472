import { useReducer } from 'react'
import { SimpleCampus, PolicyCampusesResource } from '@exaleap/common'
import systemInfoReducer, { State } from '../reducers/systemInfo'
import systemInfoActions from '../actions/systemInfoActions'

const initialState: State = {
  campuses: [],
  campusesResource: []
}

export type SystemInfoState = {
  campuses: SimpleCampus[],
  setCampuses: (buildings: SimpleCampus[]) => void,
  campusesResource: PolicyCampusesResource[],
  setCampusesResource: (resource: PolicyCampusesResource[]) => void,
}

const useSystemInfo = (): SystemInfoState => {
  const [systemInfo, systemInfoDispatcher] = useReducer(systemInfoReducer, initialState)
  const { setCampuses, setCampusesResource } = systemInfoActions(systemInfoDispatcher)

  return {
    ...systemInfo,
    setCampuses,
    setCampusesResource
  }
}

export default useSystemInfo
