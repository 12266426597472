export const getThemeColorByColorTxt = (colorTxt: string, themeColor: any): string => {
  const colorLayer = colorTxt.split('.')

  return colorLayer.length > 1 ? themeColor[colorLayer[0]][colorLayer[1]] : ''
}


export const getHiddenConfig = (type: string) => {
  switch (type) {
    case 'smDown': {
      return { display: { xs: 'none', sm: 'block' } }
    }
    case 'mdDown': {
      return { display: { xs: 'none', md: 'block' } }
    }
    case 'lgDown': {
      return { display: { xs: 'none', language: 'block' } }
    }
    case 'xlDown': {
      return { display: { xs: 'none', lg: 'block' } }
    }
    case 'smUp': {
      return { display: { xs: 'block', sm: 'none' } }
    }
    case 'mdUp': {
      return { display: { xs: 'block', md: 'none' } }
    }
    case 'lgUp': {
      return { display: { xs: 'block', language: 'none' } }
    }
    case 'xlUp': {
      return { display: { xs: 'block', lg: 'none' } }
    }
    default: {
      return { display: { xs: 'block' } }
    }
  }
}
