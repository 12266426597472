import { AxiosInstance } from 'axios'
import { LocalizationObject } from '@exaleap/common'
import APIRestfulProvider, {
  APIHTTPResponse,
//  PaginatedResponse,
  throwError,
  parseResult,
  parseResults,
} from '../../restful-provider'
import { Facility } from '../../../schema'

class FacilityServiceEndpoints {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }
  subRoute = '/facility-service'

  getFacilities = (campusId: string) =>
    this.client
      .get<APIHTTPResponse<Facility>>(`${this.subRoute}/pmc/facilities`, {
        params: {
          campus_id: campusId,
        },
      })
      .then(parseResults)
      .catch(throwError)

  getFacility = (id: string) =>
    this.client.get<APIHTTPResponse<Facility>>(`${this.subRoute}/facility/${id}`).then(parseResult).catch(throwError)

  updateFacilityDisplay = (
    id: string,
    data: LocalizationObject & {
      enabled?: boolean,
      show_notice?: boolean,
    },
  ) =>
    this.client
      .put<APIHTTPResponse<any>>(`${this.subRoute}/pmc/facility/${id}`, { ...data })
      .then(parseResult)
      .catch(throwError)
}

export default FacilityServiceEndpoints
