import { AxiosInstance, AxiosResponse } from 'axios'
import { APIKey, APIKeyGroup } from '../../../schema'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for Visitor System module
 */
class LeasingAPIManagementRestful {
    client: AxiosInstance = null
    subRoute = '/leasing-management/api-key-management'

    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    create = (group_id: string, display_name: string) =>
        this.client
            .post<APIHTTPResponse<APIKey>>(`${this.subRoute}/create-api-key-in-api-key-group`, {
                api_key_group_id: group_id,
                display_name: display_name,
            })
            .then(parseResult)
            .catch(throwError)

    edit = (id: string, display_name: string) =>
        this.client
            .put<APIHTTPResponse<APIKey>>(
                `${this.subRoute}/edit-api-key`,
                {
                    display_name: display_name,
                },
                { params: { id } },
            )
            .then(parseResult)
            .catch(throwError)

    revoke = (id: string) =>
        this.client
            .put<APIHTTPResponse<APIKey>>(`${this.subRoute}/revoke-api-key`, null, { params: { id } })
            .then(parseResult)
            .catch(throwError)

    delete = (id: string) =>
        this.client
            .delete<APIHTTPResponse<any>>(
                `${this.subRoute}/delete-api-key`,

                { params: { id } },
            )
            .then(parseResult)
            .catch(throwError)

    apiGroups = () =>
        this.client
            .get<APIHTTPResponse<APIKeyGroup>>(`${this.subRoute}/get-api-key-groups`)
            .then(parseResult)
            .catch(throwError)

    apis = (group_id: string) =>
        this.client
            .get<APIHTTPResponse<APIKey>>(`${this.subRoute}/get-api-keys-by-api-key-group-id`, {
                params: { id: group_id },
            })
            .then(parseResult)
            .catch(throwError)
}

export default LeasingAPIManagementRestful
