import * as R from 'ramda'
import {
  // axios,
  AxiosInstance,
  AxiosResponse,
} from 'axios'
import { ScanPoint, ScanPointRequired, addScanPointRule } from '../../../schema'
import APIRestfulProvider, { PaginatedResponse, throwError, APIHTTPResponse } from '../../restful-provider'
import { LEASING_MANAGEMENT_ROUTE } from '../../config'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

// const LEASING_SUBROUTE = `/scan-point${LEASING_MANAGEMENT_ROUTE}`
// const ANONYMOUS_USER_SUBROUTE = `/scan-point${ANONYMOUS_USER_ROUTE}`

class ScanPointRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = `/scan-point${LEASING_MANAGEMENT_ROUTE}`

  // 扫码领分 列表
  getQrCodeList = (campus_ids: string, params: ScanPointRequired) => {
    const dateArr = ['start_to', 'start_from', 'end_from', 'end_to']
    const args = {
      campus_ids,
      ...R.reject(
        R.either(R.isEmpty, R.isNil),
        R.pick(['page_number', 'page_size', 'order_by', 'sort_by', 'search', ...dateArr], params),
      ),
    }
    dateArr.forEach((key) => {
      if (!!args[key]) {
        args[key] = new Date(args[key]).getTime()
      }
    })
    return this.client
      .get<PaginatedResponse<ScanPoint>>(`${this.subRoute}/rules`, { params: args })
      .then((d) => d.data)
  }
  // 扫码领分 查看规则
  getRuleById = (id: string) =>
    this.client
      .get<APIHTTPResponse<addScanPointRule>>(`${this.subRoute}/rules/:${id}`)
      .then(parseResult)
      .catch(throwError)

  // 扫码领分 新增规则
  addRule = (params: addScanPointRule) =>
    this.client
      .post<APIHTTPResponse<addScanPointRule>>(`${this.subRoute}/rules`, params)
      .then(parseResult)
      .catch(throwError)

  // 扫码领分 编辑规则
  editRule = (id: string, params: addScanPointRule) =>
    this.client
      .put<APIHTTPResponse<addScanPointRule>>(`${this.subRoute}/rules/${id}`, params)
      .then(parseResult)
      .catch(throwError)

}

export default ScanPointRestful
