import APIRestfulProvider from '../../restful-provider'
import PropertyVisitorServices from './visitor'
import PropertyAccessControlManagement from './access-control'
import WorkOrderRestful from './work-order'

/**
 * Platform Management Services
 */

class PropertyManagementEndpoints {
    visitor: PropertyVisitorServices
    accessControl: PropertyAccessControlManagement
    workOrder: WorkOrderRestful

    constructor(provider: APIRestfulProvider) {
        this.visitor = new PropertyVisitorServices(provider)
        this.accessControl = new PropertyAccessControlManagement(provider)
        this.workOrder = new WorkOrderRestful(provider)
    }
}

export default PropertyManagementEndpoints
