import { SimpleBuilding, SimpleCampus, SimpleFloor } from '@exaleap/common'
import { Action, ActionType } from '../actions/pmcActions'



export type State = {
  buildings: SimpleBuilding[],
  campuses: SimpleCampus[],
  floors: { [buildingId: string]: SimpleFloor[] },
}

const pmc = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.ACTION_SET_BUILDINGS:
      return {
        ...state,
        buildings: action.value,
      }
    case ActionType.ACTION_SET_CAMPUSES:
      return {
        ...state,
        campuses: action.value,
      }
    case ActionType.ACTION_SET_FLOORS_OF_BUILDING:
      return {
        ...state,
        floors: {
          ...state.floors,
          [action.value.id]: action.value.result,
        },
      }
    default:
      return state
  }
}

export default pmc
