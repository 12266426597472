import * as R from 'ramda'
import { AxiosInstance } from 'axios'
import APIRestfulProvider, { APIHTTPResponse2, throwError, PaginatedResponse } from '../../restful-provider'
import { Invitation, PointTransaction, HTMemberDetail, InvitationRules, HTMemberGrade } from '@exaleap/common'

/**
 * Restful endpoints for Visitor System module
 */
class HTMemberServices {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/member-service'

  // 会员详情
  getMembersDetail = (phoneNumber: number) => {
    return this.client
      .get<APIHTTPResponse2<HTMemberDetail>>(`${this.subRoute}/lm/members?phone_number=${phoneNumber}`)
      .then((d) => d.data.result)
      .catch(throwError)
  }

  // 会员等級
  getMemberGrades = () => {
    return this.client
      .get<APIHTTPResponse2<HTMemberGrade[]>>(`${this.subRoute}/a/grades`)
      .then((d) => d.data.result)
      .catch(throwError)
  }

  // 积分流水 列表
  getPointTransactionByPage = (params) => {
    const args = R.reject(
      R.isEmpty,
      R.pick(
        [
          'page_number',
          'page_size',
          'order_by',
          'sort_by',
          'end_time',
          'start_time',
          'tx_type',
          'tx_change_type',
        ],
        params,
      ),
    )
    const query = params?.search ? { query: params?.search } : {}
    return this.client
      .get<PaginatedResponse<PointTransaction>>(`${this.subRoute}/lm/points/transactions`, { params: { ...args, ...query } })
      .then((d) => d.data)
      .catch(throwError)
  }

  // 好友推荐 列表 /member-service/api/v1/invitation/cms/page
  getInvitationByPage = (params) => {
    const args = R.reject(
      R.isEmpty,
      R.pick(
        ['page_number', 'page_size', 'order_by', 'sort_by', 'end_time', 'start_time', 'time_field'],
        params,
      ),
    )
    const query = params?.search ? { query: params?.search } : {}
    return this.client
      .get<PaginatedResponse<Invitation>>(`${this.subRoute}/lm/invitations`, { params: { ...args, ...query } })
      .then((d) => d.data)
      .catch(throwError)
  }

  // 好友推荐 列表
  getInvitationRulesByPage = (params) => {
    const args = R.reject(R.isEmpty, R.pick(['page_number', 'page_size', 'order_by', 'sort_by'], params))
    return this.client
      .get<PaginatedResponse<InvitationRules>>(`${this.subRoute}/lm/invitations/rules`, { params: args })
      .then((d) => d.data)
      .catch(throwError)
  }
}

export default HTMemberServices
