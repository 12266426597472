import {
  // FETCH_ERROR,
  // FETCH_START,
  // FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SIGNUP_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
} from '../../../constants/ActionTypes'

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  }
}

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user,
  }
}
export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user,
  }
}
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  }
}
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser,
  }
}

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser,
  }
}
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
}

export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  }
}

export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  }
}
