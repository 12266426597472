import React from "react"
import { Navigate } from "react-router-dom"

import SignIn from "./SignIn"
import SignUp from "./SignUp"
import appRoute from '../app/routes'
import Error404 from '../app/routes/extraPages/routes/404'
import Error401 from '../app/routes/extraPages/routes/401'
import ErrorPage from '../app/routes/extraPages/routes/errorPage'
import FirebaseAnalytics from '../util/FirebaseComponent'
// Need to route based on role

const redirectRoute = [
  {
    path: "/",
    exact: true,
    element: <Navigate to="/app" />,
  }
]

const errorRoute = [
  {
    path: "error/401",
    element: <Error401 />
  },
  {
    path: "error/404",
    element: <Error404 />
  },
]


const routes = [
  {
    element: <FirebaseAnalytics />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/cn/signin',
        element: <SignIn />
      },
      {
        path: '/hk/signin',
        element: <SignIn />
      },
      {
        path: '/signup',
        element: <SignUp />
      },
      ...appRoute,
      ...redirectRoute,
      ...errorRoute,
      {
        path: "*",
        element: <Error404 />,
      },
    ]
  }
]

export default routes
