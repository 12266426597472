import APIRestfulProvider from "../../restful-provider"
import LeasingAPIManagementRestful from "./api-key"
import LeasingAnalyticsRestful from "./analytics"
import AppConfigServiceRestful from "./app-config-service"
import LeasingTenantVisitorRestful from "./tenant-visitor"
import CommunityServiceRestful from "./community-service"
import GameServiceEndpoints from './game'
import MerchantsEndpoints from './merchants'
import CMSRestful from './cms'


/**
 * Leasing Management Services
 */

class LeasingManagementEndpoints {
    analytics: LeasingAnalyticsRestful
    appConfigService: AppConfigServiceRestful
    tenantVisitorManagement: LeasingTenantVisitorRestful
    apiKeyManagement: LeasingAPIManagementRestful
    communityService: CommunityServiceRestful
    game: GameServiceEndpoints
    merchant: MerchantsEndpoints
    cms: CMSRestful

    constructor(provider: APIRestfulProvider) {
        this.analytics = new LeasingAnalyticsRestful(provider)
        this.appConfigService = new AppConfigServiceRestful(provider)
        this.tenantVisitorManagement = new LeasingTenantVisitorRestful(provider)
        this.apiKeyManagement = new LeasingAPIManagementRestful(provider)
        this.communityService = new CommunityServiceRestful(provider)
        this.game = new GameServiceEndpoints(provider)
        this.merchant = new MerchantsEndpoints(provider)
        this.cms = new CMSRestful(provider)
    }
}

export default LeasingManagementEndpoints
