import React, { createContext, useContext, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { MuiIconButton } from '@exaleap/react-component-lib'
import useLeasing, { LeasingState } from '../../state/useLeasing'
import usePMC, { PMCState } from '../../state/usePMC'
import useSystemInfo, { SystemInfoState } from '../../state/useSystemInfo'
import {
  fetchStart,
  fetchSuccess,
  showSuccessMsg,
  showErrorMsg,
  showMsg,
  notiClose,
  notiExit,
} from 'util/servicesHelper'

type CallApiProps = (
  type: 'apiFetch' | 'apiAction',
  api: Promise<any>,
  cb?: {
    catchAction?: (err?: any) => void,
    btnAction?: () => void,
    finallyAction?: () => void,
  },
) => Promise<any>

const StateContext = createContext<{
  leasing: LeasingState,
  pmc: PMCState,
  systemInfo: SystemInfoState,
  // fetchStart: () => void,
  // fetchSuccess: () => void,
  showSuccess: (msg: string, element?: (key: any) => JSX.Element | string) => boolean,
  showError: (err: string, element?: (key: any) => JSX.Element | string) => boolean,
  showMsg: (payload: { msg: string, type: "success" | "error" }) => void,
  dispatch: (payload: { type: string }) => void,
  notiClose: (key: string) => void,
  notiExit: (key: string | number) => void,
  callApi: CallApiProps
}>(undefined)

const StateProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const dispatch = useDispatch()
  const stateHooks = {
    leasing: useLeasing(),
    pmc: usePMC(),
    systemInfo: useSystemInfo(),
    fetchStart: fetchStart(dispatch),
    fetchSuccess: fetchSuccess(dispatch),
    showSuccess: showSuccessMsg(dispatch),
    showError: showErrorMsg(dispatch),
    showMsg: showMsg(dispatch),
    dispatch,
    notiClose: notiClose(dispatch),
    notiExit: notiExit(dispatch)
  }

  const callApi: CallApiProps = useCallback((type, apis, cb) => {
    if (type === 'apiFetch')
      stateHooks.fetchStart()

    return apis.catch((err) => {
      if (!!cb?.btnAction) {
        stateHooks.showError(
          err,
          key => <MuiIconButton
            iconName='Refresh'
            style={{ color: 'white' }}
            onClick={() => {
              cb.btnAction()
              stateHooks.notiClose(key)
            }}
          />)
      } else {
        if (cb?.catchAction)
          cb.catchAction(err)
        stateHooks.showError(err)
      }
      throw err
    }).finally(() => {
      if (cb?.finallyAction)
        cb.finallyAction()
      if (type === 'apiFetch')
        stateHooks.fetchSuccess()
    })
  }, [stateHooks?.fetchStart, stateHooks?.showError, stateHooks?.fetchSuccess, stateHooks?.notiClose])



  return <StateContext.Provider value={{ ...stateHooks, callApi }}>{children}</StateContext.Provider>
}

export const useStateHooks = () => {
  return useContext(StateContext)
}

export default StateProvider
