import { SimpleBase, ListRequest, LocalizationObject, LocalizationField } from './base'

export interface NewMerchant extends LocalizationObject {
  id: string,
  display_name: string,
  introduction?: string,
  logo?: {
    url: string,
    id: string,
  },
  merchant_configs?: MerchantConfig[],
}

export interface Merchants extends SimpleBase, LocalizationObject {
  easy_id: number,
  building_id: string,
  space_id: string,
  modified_by_id: string,
  merchant_type_id: string,
  merchant_subtype_id: string,
  merchant_subtype_name: string,
  modified: number,
  id: string,
  introduction: string,
  building_name?: string,
  need_notification: boolean,
  address: string,
  created: number,
  active: boolean,
  created_by: string,
  campus_id: string,
  is_show_qr_code: boolean,
  phone: string,
  modified_by: string,
  name: string,
  comment: string,
  issue_time: number,
  created_by_id: string,
  campus_name?: string,
  location_type: string,
  locations: MerchantSapce[],
}


export interface MerchantsRequired extends ListRequest {
  merchant_type_id: string,
  merchant_subtype_name?: string,
  name?: string,
  merchant_subtype_ids: string,
}

export interface MerchantsDetail extends SimpleBase, LocalizationObject {
  building_id: string,
  content_pictures: Pictures[],
  modified_by_id: string,
  merchant_subtype_id: string,
  easy_id: number,
  space_ids?: string[],
  modified: number,
  logo: Pictures,
  merchant_map: Pictures,
  introduction: string,
  need_notification: boolean,
  address: string,
  created: number,
  active: boolean,
  created_by: string,
  is_show_qr_code: boolean,
  is_in_campus: boolean,
  phone: string,
  modified_by: string,
  spaces?: LocalizationObject[],
  comment: string,
  issue_time: number,
  created_by_id: string,
  location_type: string,
  locations: MerchantSapce[],
  merchant_type_id?: string,
}

export interface Pictures {
  id: string,
  file_name?: string,
  url?: string,
  created?: number,
}

export interface MerchantsNew
  extends Pick<
    MerchantsDetail,
    | 'merchant_type_id'
    | 'merchant_subtype_id'
    | 'comment'
    | 'phone'
    | 'active'
    | 'location_type'
    | 'need_notification'
    | 'is_show_qr_code'
    | 'issue_time'
    | 'content_pictures'
    | 'logo'
    | 'locations'
    | 'merchant_map'
  > {
  id?: string,
  easy_id?: number,
  logo_id?: string,
  name?: LocalizationField,
  introduction?: LocalizationField,
  content_picture_ids?: string[],
  space_ids?: string[],
  address?: LocalizationField,
  opening_hours: LocalizationField,
  merchant_map_id?: string,
}
export interface MerchantSapce extends LocalizationObject, SimpleBase {
  campus: { localization: { campus_name: LocalizationField } },
  building: { localization: { building_name: LocalizationField } },
  floor: { localization: { floor_name: LocalizationField } },
  space: { localization: { space_name: LocalizationField }, space_id?: string },
}

export interface MerchantType extends LocalizationObject, SimpleBase { }

export interface MerchantTypeList extends LocalizationObject, SimpleBase {
  name: string,
  active: boolean,
  merchant_type_id: string,
}

export interface MerchantsSubTypeNew {
  type_id: string,
  name: LocalizationField,
  id?: string,
  active?: boolean,
}

export interface MerchantsSubType extends LocalizationObject {
  merchant_type_name: string,
  created?: number,
  modified_by?: string,
  name: string,
  active: boolean,
  modified?: number,
  modified_by_id: string,
  id: string,
  created_by_id?: string,
  created_by?: string,
  merchant_type_id: string,
  easy_id: number,
}

export interface Confirm extends LocalizationField {
  locale: string,
  name: string,
  nameKey?: string,
  languageId?: string,
  valueKey?: string,
  displayName?: string,
  key?: string,
  supportedLanguage?: boolean,
  render?: (params: any) => string | JSX.Element | Element,
}

export interface Confirm_V2 extends LocalizationField {
  key: string,
  name: string,
  nameKey?: string,
  languageId?: string,
  valueKey?: string,
  displayName?: string,
  supportedLanguage?: boolean,
  render?: (params: any) => string | JSX.Element | Element,
  renderData?: (params: any) => any,
}

export interface CodeConfigsType {
  key: string,
  render?: (params: any) => string | JSX.Element,
}

export interface MerchantConfig extends LocalizationObject {
  merchant_config_id?: string,
  active: boolean,
  id: string,
}

export interface MerchantConfigForm extends Pick<MerchantConfig, 'merchant_config_id' | 'active'> { }

export enum MerchantConfigKeys {
  GIFT_EXCHANGE = "GIFT_EXCHANGE",
  LIMITED_DISCOUNT_EXCHANGE = "LIMITED_DISCOUNT_EXCHANGE"
}