import * as R from 'ramda'
import {
  // axios,
  AxiosInstance,
  AxiosResponse,
} from 'axios'
import { SpendingTransaction, RewardRule, RewardRuleDetail, ReceiptChangeHistory } from '../../../schema'
import APIRestfulProvider, {
  PaginatedResponse,
  throwError,
  APIHTTPResponse,
  parseResult,
  parseResults,
} from '../../restful-provider'
import { ANONYMOUS_USER_ROUTE, LEASING_MANAGEMENT_ROUTE } from '../../config'
import { qureyString } from '../../../utils/helper'
import { isArray } from 'lodash'

const LEASING_MANAGEMENT_SUBROUTE = `/self-service-points${LEASING_MANAGEMENT_ROUTE}`
const ANONYMOUS_USER_SUBROUTE = `/self-service-points${ANONYMOUS_USER_ROUTE}`

class SpendingTransactionRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/self-service-points'

  getRepeciptsByPageLeasing = (params) => {
    const dateArr = ['receipt_date_to', 'receipt_date_from']
    const is_receipt_duplicated_Val = params?.is_receipt_duplicated || []
    const is_receipt_duplicated_finally_Val = isArray(is_receipt_duplicated_Val)
      ? is_receipt_duplicated_Val.join(',')
      : is_receipt_duplicated_Val
    const is_receipt_duplicated =
      !is_receipt_duplicated_finally_Val || is_receipt_duplicated_finally_Val.length > 6
        ? {}
        : { is_receipt_duplicated: is_receipt_duplicated_finally_Val === 'false' ? false : true }
    const args = {
      ...is_receipt_duplicated,
      ...R.reject(
        R.either(R.isEmpty, R.isNil),
        R.pick(
          ['campus_ids', 'page_number', 'page_size', 'order_by', 'sort_by', 'search', 'merchant_ids', ...dateArr],
          params,
        ),
      ),
    }
    dateArr.forEach((key) => {
      if (!!args[key]) {
        args[key] = new Date(args[key]).getTime()
      }
    })
    const status_arr = qureyString(params, 'status')
    return this.client
      .get<PaginatedResponse<SpendingTransaction>>(
        `${LEASING_MANAGEMENT_SUBROUTE}/receipts${status_arr.length ? `?${status_arr.join('&')}` : ''}`,
        { params: args },
      )
      .then((d) => d.data)
  }

  getRepeciptsByMember = (related_receipt_id: string) => {
    return this.client
      .get<APIHTTPResponse<SpendingTransaction>>(
        `${LEASING_MANAGEMENT_SUBROUTE}/receipts?related_receipt_id=${related_receipt_id}`,
      )
      .then((d) => d.data.result)
      .catch(throwError)
  }

  approvalRepecipts = (id: number, params) => {
    return this.client
      .patch<APIHTTPResponse<SpendingTransaction>>(
        `${LEASING_MANAGEMENT_SUBROUTE}/receipts/${id}/actions/approval`,
        params,
      )
      .then(parseResult)
      .catch(throwError)
  }

  getChangeHistoryData = (id: string) => {
    return this.client
      .get<APIHTTPResponse<ReceiptChangeHistory>>(`${LEASING_MANAGEMENT_SUBROUTE}/receipts/${id}/histories`)
      .then(parseResults)
      .catch(throwError)
  }

  repeciptsRequest = (id: string, actionName: string) => {
    return this.client
      .patch<APIHTTPResponse<ReceiptChangeHistory>>(`${LEASING_MANAGEMENT_SUBROUTE}/receipts/${id}/actions/${actionName}`)
      .then(parseResults)
      .catch(throwError)
  }


  getMerchantSummaryByPage = (params) => {
    const args = {
      ...R.reject(
        R.either(R.isEmpty, R.isNil),
        R.pick(
          ['page_number', 'page_size', 'order_by', 'sort_by', 'receipt_date_to', 'receipt_date_from', 'campus_ids'],
          params,
        ),
      ),
    }
    return this.client
      .get<PaginatedResponse<SpendingTransaction>>(`${LEASING_MANAGEMENT_SUBROUTE}/receipts/merchants/summaries`, {
        params: args,
      })
      .then((d) => d.data)
      .catch(throwError)
  }

  getMerchantSummaryTotal = (campusId: string, params) => {
    const args = {
      campus_ids: campusId,
      search: '',
      ...R.reject(R.either(R.isEmpty, R.isNil), R.pick(['receipt_date_to', 'receipt_date_from'], params)),
    }
    return this.client
      .get<APIHTTPResponse<{ amount: number }>>(
        `${LEASING_MANAGEMENT_SUBROUTE}/receipts/merchants/summaries/actions/count`,
        { params: args },
      )
      .then(parseResult)
      .catch(throwError)
  }

  downloadMerchantSummary = (campusId: string, params) => {
    const args = {
      campus_ids: campusId,
      search: '',
      ...R.reject(R.either(R.isEmpty, R.isNil), R.pick(['receipt_date_to', 'receipt_date_from'], params)),
    }
    return this.client
      .get<Blob>(`${LEASING_MANAGEMENT_SUBROUTE}/receipts/merchants/summaries/actions/export`, {
        params: args,
        responseType: 'blob',
      })
      .catch(throwError)
  }

  getDenyReasonByPage = (params) => {
    const args = {
      ...R.reject(R.either(R.isEmpty, R.isNil), R.pick(['order_by', 'sort_by', 'page_number', 'page_size'], params)),
    }
    return this.client
      .get<PaginatedResponse<SpendingTransaction>>(`${LEASING_MANAGEMENT_SUBROUTE}/deny-reasons`, {
        params: { ...args, list_all: false },
      })
      .then((d) => d.data)
      .catch(throwError)
  }

  getDenyReasonById = (id: string) => {
    return this.client
      .get<APIHTTPResponse<SpendingTransaction>>(`${LEASING_MANAGEMENT_SUBROUTE}/deny-reasons/${id}`)
      .then(parseResult)
      .catch(throwError)
  }

  cerateDenyReason = (params) => {
    return this.client
      .post<APIHTTPResponse<SpendingTransaction>>(`${LEASING_MANAGEMENT_SUBROUTE}/deny-reasons`, params)
      .then(parseResult)
      .catch(throwError)
  }

  updateDenyReason = (id: string, params) => {
    return this.client
      .put<APIHTTPResponse<SpendingTransaction>>(`${LEASING_MANAGEMENT_SUBROUTE}/deny-reasons/${id}`, params)
      .then(parseResult)
      .catch(throwError)
  }

  getDenyReasonlist = () => {
    return this.client
      .get<APIHTTPResponse<[]>>(`${LEASING_MANAGEMENT_SUBROUTE}/deny-reasons?list_all=true`)
      .then(parseResults)
      .catch(throwError)
  }

  // 積分獎賞規則
  getRewardRulesByPage = (campusId, params) => {
    const args = {
      campus_ids: campusId ?? '',
      ...R.reject(
        R.either(R.isEmpty, R.isNil),
        R.pick(['page_number', 'page_size', 'order_by', 'sort_by', 'search'], params),
      ),
    }
    return this.client
      .get<PaginatedResponse<RewardRule>>(`${LEASING_MANAGEMENT_SUBROUTE}/reward-rules`, { params: args })
      .then((d) => d.data)
      .catch(throwError)
  }

  getRewardRulesById = (id: string) => {
    return this.client
      .get<APIHTTPResponse<RewardRuleDetail>>(`${LEASING_MANAGEMENT_SUBROUTE}/reward-rules/${id}`)
      .then(parseResult)
      .catch(throwError)
  }

  // 獎賞規則
  getRewardRuleTypes = () => {
    return this.client
      .get<APIHTTPResponse<[]>>(`${ANONYMOUS_USER_SUBROUTE}/reward-rule-types`)
      .then(parseResults)
      .catch(throwError)
  }

  // 規則類型
  getRuleTypes = () => {
    return this.client
      .get<APIHTTPResponse<[]>>(`${ANONYMOUS_USER_SUBROUTE}/rule-types`)
      .then(parseResults)
      .catch(throwError)
  }

  // 獎賞類型
  getRewardTypes = () => {
    return this.client
      .get<APIHTTPResponse<[]>>(`${ANONYMOUS_USER_SUBROUTE}/reward-types`)
      .then(parseResults)
      .catch(throwError)
  }

  // 消費積分獎賞上限
  getRewardLimitTypes = () => {
    return this.client
      .get<APIHTTPResponse<[]>>(`${ANONYMOUS_USER_SUBROUTE}/reward-limit-types`)
      .then(parseResults)
      .catch(throwError)
  }

  cerateRewardRule = (params) => {
    return this.client
      .post<APIHTTPResponse<RewardRule>>(`${LEASING_MANAGEMENT_SUBROUTE}/reward-rules`, params)
      .then(parseResult)
      .catch(throwError)
  }

  updateRewardRule = (id: string, params) => {
    return this.client
      .put<APIHTTPResponse<RewardRule>>(`${LEASING_MANAGEMENT_SUBROUTE}/reward-rules/${id}`, params)
      .then(parseResult)
      .catch(throwError)
  }
}

export default SpendingTransactionRestful
