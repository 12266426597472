import { ACCESS_POLICY } from "@exaleap/common"

const iotPolicies = [ACCESS_POLICY.platformManagement.iotManagement.fullAccess]

const IOTRoutes = [
  {
    path: "/app/iot/devices/create",
    element: () => import("./CreateDevice"),
    auth: iotPolicies
  },
  {
    path: "/app/iot/devices/:id/:mode",
    element: () => import("./DeviceDetailTabContainer"),
    auth: iotPolicies
  },
  {
    path: "/app/iot/devices/:id",
    element: () => import("./DeviceDetailTabContainer"),
    auth: iotPolicies
  },
  {
    path: "/app/iot/devices",
    element: () => import("./DeviceList"),
    auth: iotPolicies
  },
  {
    path: "/app/iot/device_types/:id",
    element: () => import("./DeviceTypeDetail"),
    auth: iotPolicies
  },
  {
    path: "/app/iot/device_types",
    element: () => import("./DeviceTypeList"),
    auth: iotPolicies
  },
  {
    path: "/app/iot/interfaces",
    element: () => import("./InterfaceList"),
    auth: iotPolicies
  },
  {
    path: "/app/data_models/:id",
    element: () => import("./DataModelDetail"),
    auth: iotPolicies
  },
  {
    path: "/app/data_models",
    element: () => import("./DataModelList"),
    auth: iotPolicies
  },
]

export default IOTRoutes
