import { ACTION_KEY_USER_MGMT, Action } from "../../actions";
import { User } from "../../../schema";

interface UserManagementState {
  list: User[],

  detail: User
}

const initialState: UserManagementState = {
  list: null,
  detail: null
};

const UserReducer = function(state = initialState, action: Action) {
  switch (action.type) {

    case ACTION_KEY_USER_MGMT.USER_LIST.SUCCESS: {
      return {
        ...state,
        list: action.payload
      };
    }
    

    default: {
      return state;
    }
  }
};

export default UserReducer;
