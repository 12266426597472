import { AxiosInstance } from 'axios'
import {
  ListRequest,
  SimpleBuilding,
  SimpleCampus,
  SimpleFloor,
  SimpleSpace,
  SpaceType,
  User,
  SimpleDisplayObject,
  Entity,
  Policy,
  LocalizationObject,
  CreatingBuilding
} from '../../../schema'
import { PLATFORM_ADMIN_ROUTE } from '../../config'
import APIRestfulProvider, {
  APIHTTPResponse,
  PaginatedResponse,
  throwError,
  parseResults,
} from '../../restful-provider'

export type CreateBuildingResponse = { campus_id: string, building: SimpleBuilding }
export type CreateSpaceInCampusResponse = { campus_id: string, space: SimpleSpace }

export type CreateFloorResponse = { building_id: string, floor: SimpleFloor }
export type CreateSpaceInBuildingResponse = { building_id: string, space: SimpleSpace }

export type CreateSpaceInFloorResponse = { floor_id: string, space: SimpleSpace }
export type CreateSpaceInSpaceResponse = { space_id: string, space: SimpleSpace }

export type CreateFloorParam = {
  building_id: string,
  display_name: string,
  idx: number,
}

const LOCATIONS_ROUTE = `${PLATFORM_ADMIN_ROUTE}/locations`
const USER_GROUPS_ROUTE = `${PLATFORM_ADMIN_ROUTE}/user-groups/custom-groups`
const IAM_ROUTE = `${PLATFORM_ADMIN_ROUTE}/iam`



/**
 * Restful endpoints for the folloing
 * Accesses
 * Locations
 * Tenants
 * UserGroups
 * Users
 */
class PlatformManagementRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  /****************** user start **********************/
  getUserGroups = (params?: ListRequest) =>
    this.client
      .get<PaginatedResponse<User>>(`${USER_GROUPS_ROUTE}`, {
        params,
      })
      .then(parseResults)
      .catch(throwError)

  getUsersInUserGroup = (user_group_id: string, params?: ListRequest) =>
    this.client
      .get<PaginatedResponse<User>>(`${USER_GROUPS_ROUTE}/${user_group_id}/users`, {
        params: { user_group_id, ...params },
      })
      .then((d) => d.data)
      .catch(throwError)

  addUserToUserGroup = (area_code: string, user_group_id: string, phone_number: string) =>
    this.client
      .post<APIHTTPResponse<User>>(`${USER_GROUPS_ROUTE}/${user_group_id}/users`, {
        area_code,
        user_group_id,
        phone_number,
      })
      .then(parseResults)
      .catch(throwError)

  dropUserFromUserGroup = (user_id: string, user_group_id: string) =>
    this.client
      .delete<APIHTTPResponse<User>>(`${USER_GROUPS_ROUTE}/${user_group_id}/users/${user_id}`)
      .then(parseResults)
      .catch(throwError)

  /****************** user end **************************/

  /****************** entity-lookup start *************************/
  searchEntityLookup = (params?: ListRequest & { type: string }) =>
    this.client
      .get<PaginatedResponse<Entity>>(`${IAM_ROUTE}/lookup`, {
        params,
      })
      .then((d) => d.data)
      .catch(throwError)

  getPoliciesById = (id: string) =>
    this.client
      .get<APIHTTPResponse<Policy>>(`${IAM_ROUTE}/policies?group_id=${id}`, {
        params: { id },
      })
      .then(parseResults)
      .catch(throwError)

  getUserGroupsByTypeAndId = (params: { type: string, type_id?: string, user_id?: string }) =>
    this.client
      .get<APIHTTPResponse<Pick<Entity, 'category' | 'display_name' | 'id' | 'user_groups'>>>(
        `${PLATFORM_ADMIN_ROUTE}/user-groups/groups`,
        {
          params,
        },
      )
      .then(parseResults)
      .catch(throwError)
  /****************** entity-lookup end *******************/

  /****************** campus start ***********************/

  createCampus = (name: string) =>
    this.client.post<APIHTTPResponse<SimpleCampus>>(`${LOCATIONS_ROUTE}/campuses`, { name }).then(parseResults)

  getCampuses = (id?: string) =>
    this.client
      .get<APIHTTPResponse<SimpleCampus>>(`${LOCATIONS_ROUTE}/campuses`, {
        params: { id },
      })
      .then(parseResults)

  /////////////////////////////// Building

  createBuildingInCampus = (param: CreatingBuilding) =>
    this.client
      .post<APIHTTPResponse<CreateBuildingResponse>>(`${LOCATIONS_ROUTE}/campus/${param?.campus_id}/buildings`, param)
      .then(parseResults)

  getBuildings = (id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleBuilding>>(`${LOCATIONS_ROUTE}/buildings/${id}`, { params: { id } })
      .then(parseResults)

  getBuildingsInCampus = (campus_id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleBuilding>>(`${LOCATIONS_ROUTE}/campus/${campus_id}/buildings`, {
        // params: { id: campus_id },
      })
      .then(parseResults)

  /////////////////////////////// Floor

  createFloorInBuilding = (param: CreateFloorParam) =>
    this.client
      .post<APIHTTPResponse<CreateFloorResponse>>(`${LOCATIONS_ROUTE}/buildings/${param?.building_id}/floors`, param)
      .then(parseResults)

  getFloorsById = (id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleFloor>>(`${LOCATIONS_ROUTE}/floors/${id}`, {
        params: { id },
      })
      .then(parseResults)

  getFloorsInBuilding = (building_id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleFloor>>(`${LOCATIONS_ROUTE}/buildings/${building_id}/floors`, {
        params: { id: building_id },
      })
      .then(parseResults)

  /* remove  */
  removeFloorById = (building_id: string, floor_id: string) =>
    this.client
      .delete<APIHTTPResponse<SimpleFloor>>(`${LOCATIONS_ROUTE}/buildings/${building_id}/floors/${floor_id}`, { params: { id: floor_id } })
      .then(parseResults)

  /* edit */
  editFloorById = (
    building_id: string,
    floor_id: string,
    data: LocalizationObject & {
      idx?: string,
    },
  ) =>
    this.client.put<APIHTTPResponse<SimpleSpace>>(`${LOCATIONS_ROUTE}/buildings/${building_id}/floors/${floor_id}`, data).then(parseResults)

  /////////////////////////////// Get Spaces

  // TO-DO: withTenant will be removed.
  getSpacesById = (id?: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = {}

    if (id) {
      params = { id }
    }

    if (types) {
      params = { ...params, types: types.join(',') }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${LOCATIONS_ROUTE}/spaces${id ? `/${id}` : ''}`, {
        params
      })
      .then(parseResults)
  }

  // TO-DO: withTenant will be removed.
  getSpacesInCampus = (campus_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { id: campus_id }

    if (types) {
      params = { ...params, types }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${LOCATIONS_ROUTE}/campuses/${campus_id}/spaces`, {
        params,
      })
      .then(parseResults)
  }

  // TO-DO: withTenant will be removed.
  getSpacesInBuilding = (building_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { id: building_id }

    if (types) {
      params = { ...params, types }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${LOCATIONS_ROUTE}/buildings/${building_id}/spaces`, {
        params,
      })
      .then(parseResults)
  }

  // TO-DO: withTenant will be removed.
  getSpacesInFloor = (floor_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { id: floor_id }

    if (types) {
      params = { ...params, types }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${LOCATIONS_ROUTE}/floors/${floor_id}/spaces`, {
        params,
      })
      .then(parseResults)
  }

  // TO-DO: withTenant will be removed.
  getSpacesInSpace = (space_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { id: space_id }

    if (types) {
      params = { ...params, types }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${LOCATIONS_ROUTE}/spaces/${space_id}/spaces`, {
        params,
      })
      .then(parseResults)
  }

  getSpaceHierarchy = (ids: string[], types?: SpaceType[]) => {
    let params: any = { space_ids: ids }

    if (types) {
      params = { ...params, types }
    }

    return this.client
      .get<APIHTTPResponse<any>>(`${LOCATIONS_ROUTE}/spaces/${ids.join(',')}/hierarchy`, {
        params,
      })
      .then(parseResults)
  }

  getSpaceTypes = (filterType?: string) =>
    this.client
      .get<APIHTTPResponse<SimpleDisplayObject>>(
        `${LOCATIONS_ROUTE}/space-types${filterType ? `?filterFor=${filterType}` : ''}`,
      )
      .then(parseResults)
      .catch(throwError)

  /////////////////////////////// Create Space

  // no use
  createSpaceInCampus = (creating: { campus_id: string, space_name: string }) =>
    this.client
      .post<APIHTTPResponse<CreateSpaceInCampusResponse>>(`${LOCATIONS_ROUTE}/create-space-in-campus`, creating)
      .then(parseResults)

  // no use
  createSpaceInBuilding = (creating: { building_id: string, space_name: string }) =>
    this.client
      .post<APIHTTPResponse<CreateSpaceInBuildingResponse>>(`${LOCATIONS_ROUTE}/create-space-in-building`, creating)
      .then(parseResults)

  createSpaceInFloor = (creating: { floor_id: string, space_name: string }) =>
    this.client
      .post<APIHTTPResponse<CreateSpaceInFloorResponse>>(`${LOCATIONS_ROUTE}/floors/${creating?.floor_id}/space`, creating)
      .then(parseResults)

  createSpaceInSpace = (creating: { space_id: string, space_name: string }) =>
    this.client
      .post<APIHTTPResponse<CreateSpaceInSpaceResponse>>(`${LOCATIONS_ROUTE}/spaces/${creating?.space_id}/space`, creating)
      .then(parseResults)



  removeSpaceById = (space_id: string) =>
    this.client
      .delete<APIHTTPResponse<SimpleSpace>>(`${LOCATIONS_ROUTE}/spaces/${space_id}`, { params: { id: space_id } })
      .then(parseResults)


  editSpaceById = (
    space_id: string,
    data: LocalizationObject & {
      type_id?: string,
    },
  ) =>
    this.client.put<APIHTTPResponse<SimpleSpace>>(`${LOCATIONS_ROUTE}/spaces/${space_id}`, data).then(parseResults)

  /****************** campus end ******************/

}

export default PlatformManagementRestful
