import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import {
    VisitorRequest,
    VisitorEntity,
    CreateVisitorRequest,
    VisitorRequestEdit,
    VisitorRequestResponse,
    VisitorInput,
} from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for Visitor System module
 */

class TenantVisitorServices {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    subRoute = '/tenant-service/visitor-service'

    listMyRequests = (id: string = null, page_number = 1, page_size = 50) =>
        this.client
            .get<APIHTTPResponse<VisitorRequest>>(`${this.subRoute}/get-requests`, {
                params: { ids: id, page_number, page_size },
            })
            .then(parseResult)
            .catch(throwError)

    makeMyRequest = (request: CreateVisitorRequest) =>
        this.client
            .post<APIHTTPResponse<VisitorRequestResponse>>(`${this.subRoute}/make-my-request`, request)
            .then(parseResult)
            .catch(throwError)

    editMyRequest = (id: string, changes: VisitorRequestEdit) =>
        this.client
            .put<APIHTTPResponse<VisitorRequest>>(`${this.subRoute}/edit-my-request`, changes, {
                params: { id },
            })
            .then(parseResult)
            .catch(throwError)

    addGuestToMyRequest = (id: string, visitor: VisitorInput) =>
        this.client
            .put<APIHTTPResponse<VisitorRequest>>(
                `${this.subRoute}/add-visitor-to-my-request`,
                { visitors: [visitor] },
                {
                    params: { id },
                },
            )
            .then(parseResult)
            .catch(throwError)
    removeGuestToMyRequest = (id: string, visitor_ids: string[]) =>
        this.client
            .put<APIHTTPResponse<VisitorRequest>>(
                `${this.subRoute}/remove-visitor-from-my-request`,
                {
                    visitor_ids,
                },
                {
                    params: { id },
                },
            )
            .then(parseResult)
            .catch(throwError)
    cancelMyRequest = (id: string) =>
        this.client
            .delete<APIHTTPResponse<VisitorRequest>>(`${this.subRoute}/cancel-my-request`, {
                params: { id },
            })
            .then(parseResult)
            .catch(throwError)

    /// --------

    listApprovalRequests = (id?: string, page_number = 1, page_size = 50) =>
        this.client
            .get<APIHTTPResponse<VisitorRequest>>(`${this.subRoute}/get-my-approval-requests`, {
                params: { ids: id, page_number, page_size },
            })
            .then(parseResult)
            .catch(throwError)

    // makeTenantRequest = (request: CreateVisitorRequest) =>
    //     this.client
    //         .post<APIHTTPResponse<VisitorRequest>>(`${this.subRoute}/make-requests`, request)
    //         .then(parseResult)
    //         .catch(throwError)

    approveRequest = (
        id: string,
        param: {
            approve: boolean,
            reject_reason: string,
        },
    ) =>
        this.client
            .put<APIHTTPResponse<VisitorRequest>>(`${this.subRoute}/approval`, param, { params: { id } })
            .then(parseResult)
            .catch(throwError)

    // cancelTenantRequest = (id: string) =>
    //     this.client
    //         .delete<APIHTTPResponse<VisitorRequest>>(`${this.subRoute}/cancel-request/${id}`)
    //         .then(parseResult)
    //         .catch(throwError)
}

export default TenantVisitorServices
