import { createRequestActionStrings } from './helper'
import { RequestActionFunctions, action } from './index'
import { GET_AVAILABLE_CAMPUSES, SET_VENDING_ACCOUNT } from '../../constants/ActionTypes'

export const ACTION_KEY_CMS = {
  TEMPLATES: createRequestActionStrings('TEMPLATES'),
  CATEGORY_LIST: createRequestActionStrings('CATEGORY_LIST'),
  GET_AVAILABLE_CAMPUS: createRequestActionStrings('GET_AVAILABLE_CAMPUS')
}

const templates: RequestActionFunctions = {
  request: () => action(ACTION_KEY_CMS.TEMPLATES.REQUEST),
  success: (templates) => action(ACTION_KEY_CMS.TEMPLATES.SUCCESS, templates),
  failure: (error) => action(ACTION_KEY_CMS.TEMPLATES.FAILURE, error),
}

const categoryList: RequestActionFunctions = {
  request: () => action(ACTION_KEY_CMS.CATEGORY_LIST.REQUEST),
  success: (result) => action(ACTION_KEY_CMS.CATEGORY_LIST.SUCCESS, result),
  failure: (error) => action(ACTION_KEY_CMS.CATEGORY_LIST.FAILURE, error),
}

const getAvailableCampuses = (campuses) => {
  return {
    type: GET_AVAILABLE_CAMPUSES,
    payload: campuses,
  }
}

const setVedingAccount = (account) => {
  return {
    type: SET_VENDING_ACCOUNT,
    payload: account,
  }
}

export const CmsActions = {
  templates,
  categoryList,
  getAvailableCampuses,
  setVedingAccount
}


