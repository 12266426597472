import * as R from 'ramda'
import stringWidth from 'string-width'
import { REGEX_EMAIL, AREA_CODES } from '@exaleap/common'

export const hiddenEmail = (email: string): string => {
  if (!REGEX_EMAIL.test(email ?? '')) {
    return ''
  }
  const [name, domain] = email.split('@')
  return `${R.head(name)}${R.repeat<string>('*', Math.max(0, name.length - 2)).join('')}${R.last(name)}@${domain}`
}

export const hiddenPhoneNumber = (areaCode: string, phoneNumber: string): string => {
  const lastFour = phoneNumber.substr(phoneNumber.length - 4)
  const prefix = phoneNumber.substr(0, phoneNumber.length - 4).replace(/[0-9]/g, '*')
  return `+${areaCode} ${prefix}${lastFour}`
}

export const getCampusIdFromResource = (res: string) => res?.split(':')?.[4]

export const getStringLength = (str: string): number => {
  if (typeof str !== 'string' || str === '' || R.trim(str) === '') return 0
  return stringWidth(str)
}

export const getDisplayName = (aName: string, bName: string, joinBy = ' - '): string =>
  aName === bName || bName === '' ? aName : `${aName}${joinBy}${bName}`

export const getUserName = (lastName: string, firstName: string) =>
  /^[a-zA-Z]+$/.test(lastName) ? `${firstName ?? ''} ${lastName ?? ''}` : `${lastName ?? ''} ${firstName ?? ''}`

export const isValidUrl = (value: string) => {
  const pattern = new RegExp(
    '^([a-zA-Z]+:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
    'i',
  )
  return pattern.test(value)
}

export const getPhoneMaxLength = (areaCode: string) => {
  return AREA_CODES.find((e) => e.value === areaCode)?.length ?? 16
}

export const replaceCapitalLetterAfterUnderscore = (word: string) => word.replace(/(^|_)./g, s => s.slice(-1).toUpperCase())
