import React from 'react'
import { LANGUAGE_DATA_LIST } from '@exaleap/common'
import LanguageItem from './LanguageItem'
import CustomScrollbars from 'util/CustomScrollbars'
import {
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    makeStyles,
    TextField,
    ListItemSecondaryAction,
    IconButton,
    FormControlLabel,
    Checkbox,
    Menu,
    MenuItem,
} from '@material-ui/core'

const LanguageSwitcher = ({ anchorEl, switchLanguage, handleRequestClose }) => {

    return (
        <div className="messages-list language-list scrollbar" style={{ height: 70 }}>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                    handleRequestClose()
                }}
            >
                {LANGUAGE_DATA_LIST.map((language, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            handleRequestClose()
                            switchLanguage(language)
                        }}
                    >
                      <h4 className="mb-0 ml-2">{language.name}</h4>

                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}

export default LanguageSwitcher
