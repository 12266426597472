
export interface RequestActionNames {
    REQUEST: string,
    SUCCESS: string,
    FAILURE: string,
}

export function createRequestActionStrings(base: string): RequestActionNames {
    return {
        REQUEST: `${base}_REQUEST`,
        SUCCESS: `${base}_SUCCESS`,
        FAILURE: `${base}_FAILURE`,
    }
}