import { all, take, call, put, takeEvery, fork } from 'redux-saga/effects'
import { Device, DeviceType, DataModelRecordPair, CreatingDevice, Tenant, LocationHierarchy } from '../../schema'
import {
    Action,
    ACTION_KEY_IOT_MGMT,
    IOTActions,
    ACTION_KEY_TENANT,
    TenantActions,
    NotificationActionFunctions,
} from '../actions'
import { apiService } from '../../..'

// function* requestTenants(action: Action) {
//     console.warn('[SAGA] requestTenants')

//     try {
//         const [tenants] = yield all([call(apiService.tenant.list)])
//         yield put(TenantActions.tenantList.success(tenants))
//     } catch (error) {
//         console.warn('[SAGA] requestTenants error', error)
//         yield put(TenantActions.tenantList.failure(error))
//         yield put(
//             NotificationActionFunctions.enqueue({
//                 message: error,
//                 type: 'error',
//             }),
//         )
//     }
// }

// function* saveTenant(action: Action) {
//     console.warn('[SAGA] tenantDetailSave')

//     const currentTenant: Tenant = action.payload
//     try {
//         const [result] = yield all([
//             call(apiService.tenant.update, currentTenant.id, currentTenant.display_name, currentTenant.description),
//         ])
//         yield put(TenantActions.tenantDetailSave.success(result[0]))

//         yield put(
//             NotificationActionFunctions.enqueue({
//                 message: 'Saved successfully.',
//                 type: 'success',
//             }),
//         )
//     } catch (error) {
//         console.warn('[SAGA] tenantDetailSave error', error)
//         yield put(TenantActions.tenantDetailSave.failure(error))
//         yield put(
//             NotificationActionFunctions.enqueue({
//                 message: error,
//                 type: 'error',
//             }),
//         )
//     }
// }


// function* requestAllAvailableSpace() {
//     try {
//         const spaces = yield call(apiService.bms.getSpaces, false)
//         console.log(spaces)
//         return spaces
//     } catch (error) {
//         console.warn(error)
//         return []
//     }

// }

// function* requestTenant(action: Action) {
//     console.warn('[SAGA] requestTenant')
//     const id = action.payload

//     try {
//         const spacesCall = apiService.bms.getSpaces

//         const tenants = yield call(apiService.tenant.get, id)


//         if (tenants.length === 0) {
//             throw new Error('Tenant not found')
//         }
//         const tenant: Device = tenants[0]


//         yield put(TenantActions.tenantDetail.success(tenant, []))
//     } catch (error) {
//         console.warn('[SAGA] requestTenant', error)
//         yield put(TenantActions.tenantDetail.failure(error))
//         yield put(
//             NotificationActionFunctions.enqueue({
//                 message: error,
//                 type: 'error',
//             }),
//         )
//     }
// }

// function* assoicateSpace(action: Action) {
//     const { id, space_ids } = action.payload

//     try {
//         const result = yield call(apiService.tenant.associateLocation, id, space_ids)
//         if (result.length === 0) {
//             throw new Error('Failed ')
//         }

//         const locations = yield call(apiService.bms.getSpaceLocation, space_ids)
//         const tenantLocations: LocationHierarchy[] = locations.map(l => ({
//             campus: {
//                 id: l.campus_id,
//                 name: l.campus_name,
//             },
//             building: {
//                 id: l.building_id,
//                 name: l.building_name
//             },
//             floor: {
//                 id: l.floor_id,
//                 name: l.floor_name
//             },
//             space: {
//                 id: l.space_id,
//                 name: l.space_name
//             }
//         }))

//         if (tenantLocations.length > 0) {
//             yield put(TenantActions.tenantDetailAssociate.success(tenantLocations))
//         }
//     } catch (error) {
//         console.warn('[SAGA] tenantDetailAssociate', error)
//         yield put(TenantActions.tenantDetailAssociate.failure(error))
//         yield put(
//             NotificationActionFunctions.enqueue({
//                 message: error,
//                 type: 'error',
//             }),
//         )
//     }
// }

// function* assoicateUserSpace(action: Action) {
//     const { user, space_ids } = action.payload

//     try {
//         if (user.permissions && user.permissions.spaces.length > 0) {
//             if (space_ids.length === 0) {
//                 const result = yield call(apiService.tenant.deleteUserPermissionWithSpace, user.id)
//                 if (result.length === 0) {
//                     throw new Error('Failed ')
//                 }
//                 yield put(TenantActions.tenantUpdateUserLocation.success(user.id, []))
//             } else {
//                 const result = yield call(apiService.tenant.updateUserPermissionWithSpace, user.id, space_ids)
//                 if (result.length === 0) {
//                     throw new Error('Failed ')
//                 }
//                 yield put(TenantActions.tenantUpdateUserLocation.success(user.id, result[0].spaces))
//             }
           
//         } else {
//             const result = yield call(apiService.tenant.createUserPermissionWithSpace, user.id, space_ids)
//             if (result.length === 0) {
//                 throw new Error('Failed ')
//             }
//             yield put(TenantActions.tenantUpdateUserLocation.success(user.id, result[0].spaces))
//         }
//     } catch (error) {
//         console.warn('[SAGA] tenantDetailAssociate', error)
//         yield put(TenantActions.tenantUpdateUserLocation.failure(error))
//         yield put(
//             NotificationActionFunctions.enqueue({
//                 message: error,
//                 type: 'error',
//             }),
//         )
//     }
// }

// function* unassoicateSpace(action: Action) {
//     const { id, space_ids } = action.payload

//     try {
//         const result = yield call(apiService.tenant.unassociateLocation, id, space_ids)
//         if (result.length === 0) {
//             throw new Error('Failed ')
//         }
//         yield put(TenantActions.tenantDetailUnassociate.success(space_ids))
//     } catch (error) {
//         console.log('[SAGA] tenantDetailUnassociate', error)
//         yield put(TenantActions.tenantDetailUnassociate.failure(error))

//         yield put(
//             NotificationActionFunctions.enqueue({
//                 message: error,
//                 type: 'error',
//             }),
//         )
//     }
// }

export const tenantSagas = [
    // takeEvery(ACTION_KEY_TENANT.TENANT_LISTING.REQUEST, requestTenants),
    // takeEvery(ACTION_KEY_TENANT.TENANT_DETAIL.REQUEST, requestTenant),
    // takeEvery(ACTION_KEY_TENANT.TENANT_DETAIL_SAVE.REQUEST, saveTenant),
    // takeEvery(ACTION_KEY_TENANT.TENANT_DETAIL_ASSOCIATE_SPACE.REQUEST, assoicateSpace),
    // takeEvery(ACTION_KEY_TENANT.TENANT_STAFF_ASSOCIATE_SPACE.REQUEST, assoicateUserSpace),
    // takeEvery(ACTION_KEY_TENANT.TENANT_DETAIL_UNASSOCIATE_SPACE.REQUEST, unassoicateSpace),
]
