import { AxiosInstance, AxiosResponse } from 'axios'
import { SimpleDisplayObject } from '../../../schema'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import { PLATFORM_ADMIN_ROUTE } from '../../config'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for Supplier Management
 */
class SupplierRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = `/device-management${PLATFORM_ADMIN_ROUTE}`

  getSuppliers = () =>
    this.client
      .get<APIHTTPResponse<SimpleDisplayObject>>(`${this.subRoute}/suppliers`)
      .then(parseResult)
      .catch(throwError)
}

export default SupplierRestful
