const navigationMenus = [
  {
    name: 'sidebar.platform_management',
    type: 'section',
    icon: 'business',
    link: '/app/platform-mgmt',
    children: [
      {
        name: 'content.data_searching',
        type: 'item',
        icon: 'list',
        link: '/app/platform-mgmt/data',
      },
      {
        name: 'sidebar.iot.devices',
        type: 'item',
        icon: 'router',
        link: '/app/iot/devices',
      },
      {
        name: 'sidebar.iot.device_types',
        icon: 'developer_board',
        type: 'item',
        link: '/app/iot/device_types',
      },
      {
        name: 'sidebar.campus_management',
        icon: 'business',
        type: 'item',
        link: '/app/bms',
      },
      {
        name: 'pa.access_management.user_group_management',
        icon: 'people',
        type: 'item',
        link: '/app/platform-mgmt/user-group-management',
      },
      {
        name: 'sidebar.access_permission',
        icon: 'accessibility',
        type: 'item',
        link: '/app/platform-mgmt/access-permission',
      },
      {
        name: 'sidebar.access_permission_with_aps',
        icon: 'accessibility',
        type: 'item',
        link: '/app/platform-mgmt/access-permission/aps',
      },
    ],
  },

  {
    name: 'sidebar.leasing_management',
    type: 'section',
    icon: 'apartment',
    link: '/app/leasing-mgmt',
    children: [
      {
        name: 'leasing.overview',
        icon: 'home',
        type: 'item',
        link: '/app/leasing-mgmt/overview',
      },
      {
        name: 'content.leasing.tenant_management',
        icon: 'tenantIcon',
        type: 'item',
        link: '/app/leasing-mgmt/tenants',
      },
      {
        name: 'content.leasing.pmc_management',
        icon: 'assignment',
        type: 'item',
        link: '/app/leasing-mgmt/pmc',
      },
      {
        name: 'content.leasing_analytics',
        icon: 'show_chart',
        type: 'section',
        link: '/app/leasing-mgmt/analytics',
        children: [
          {
            name: 'content.leasing_analytics.member',
            type: 'item',
            link: '/app/leasing-mgmt/analytics/member',
          },
          {
            name: 'content.leasing_analytics.access-control',
            type: 'item',
            link: '/app/leasing-mgmt/analytics/access-control',
          },
          {
            name: 'content.leasing_analytics.work-order',
            type: 'item',
            link: '/app/leasing-mgmt/analytics/work-order',
          },
          {
            name: 'content.leasing_analytics.visitor',
            type: 'item',
            link: '/app/leasing-mgmt/analytics/visitor',
          },
          {
            name: 'content.leasing_analytics.spending-events-and-gift',
            type: 'item',
            link: '/app/leasing-mgmt/analytics/spending-events-and-gift',
          },
          {
            name: 'content.leasing_analytics.butler',
            type: 'item',
            link: '/app/leasing-mgmt/analytics/butler',
          },
          {
            name: 'content.leasing_analytics.ticketing',
            type: 'item',
            link: '/app/leasing-mgmt/analytics/ticketing',
          },
        ],
      },
      {
        name: 'leasing.staff.staff_management',
        icon: 'people',
        type: 'item',
        link: '/app/leasing-mgmt/staff',
      },
      {
        name: 'leasing.api_key.title',
        icon: 'vpn_key',
        type: 'item',
        link: '/app/leasing-mgmt/api-keys',
      },
      {
        name: 'leasing.app_config.title',
        icon: 'smartphone',
        type: 'item',
        link: '/app/leasing-mgmt/app-config/landing',
      },
      {
        name: 'leasing.merchants.title',
        icon: 'store',
        type: 'item',
        link: '/app/leasing-mgmt/merchants',
      },
      {
        name: 'leasing.gamification.title',
        icon: 'event_available',
        type: 'item',
        link: '/app/leasing-mgmt/gamification',
      },
      {
        name: 'leasing.notifications.title',
        icon: 'send',
        type: 'item',
        link: '/app/leasing-mgmt/notifications',
      },
      {
        name: 'leasing.merchant.v2.title',
        icon: 'store',
        type: 'section',
        link: '/app/leasing-mgmt/merchant',
        children: [
          {
            name: 'leasing.ht-spending-transaction.merchant',
            icon: 'store',
            type: 'item',
            link: '/app/leasing-mgmt/merchant/v2',
          },
          {
            name: 'leasing.content-mgmt.settings.v2.merchant-type.title',
            type: 'item',
            icon: 'apps',
            link: '/app/leasing-mgmt/merchant/type/v2',
          },
        ],
      },
      {
        name: 'leasing.content-mgmt.title',
        icon: 'format_list_bulleted',
        type: 'section',
        link: '/app/leasing-mgmt/content-mgmt',
        children: [
          {
            name: 'leasing.content-mgmt.cms.title',
            icon: 'format_list_bulleted',
            type: 'item',
            link: '/app/leasing-mgmt/content-mgmt/cms',
          },
          {
            name: 'leasing.ht.content-mgmt.settings.post-type.title',
            type: 'item',
            icon: 'design_services',
            link: '/app/leasing-mgmt/content-mgmt/post-type',
          },
        ],
      },
      {
        name: 'leasing.ht.content-mgmt.title',
        icon: 'format_list_bulleted',
        type: 'section',
        link: '/app/leasing-mgmt/content-mgmt',
        children: [
          {
            name: 'leasing.ht.content-mgmt.ad.title',
            icon: 'sms_rounded',
            type: 'item',
            link: '/app/leasing-mgmt/ht/content-mgmt/ad',
          },
          {
            name: 'leasing.ht-app-common-functions.scheduled-push-notification.title',
            icon: 'notifications_none',
            type: 'item',
            link: '/app/leasing-mgmt/ht/content-mgmt/scheduled-push-notification',
          },
          {
            name: 'leasing.ht-app-common-functions.feed.title',
            icon: 'description',
            type: 'item',
            link: '/app/leasing-mgmt/ht/content-mgmt/feed-campuses',
          },
          {
            name: 'leasing.ht-app-common-functions.shortcutKey.title',
            type: 'item',
            icon: 'add_to_home_screen',
            link: '/app/leasing-mgmt/ht/content-mgmt/shortcutKey',
          },
          {
            name: 'leasing.ht-app-common-functions.latest-news.title',
            type: 'item',
            icon: 'announcement',
            link: '/app/leasing-mgmt/ht/content-mgmt/latest-news',
          },
        ]
      },
      {
        name: 'leasing.ht-app-common-functions.title',
        icon: 'settings_cell',
        type: 'section',
        link: '/app/leasing-mgmt/ht/app-common-functions',
        children: [
          {
            name: 'leasing.ht.content-mgmt.ad.title',
            icon: 'sms_failed',
            type: 'item',
            link: '/app/leasing-mgmt/ht/app-common-functions/ad',
          },
          {
            name: 'leasing.ht-app-common-functions.scheduled-push-notification.title',
            icon: 'notifications_none',
            type: 'item',
            link: '/app/leasing-mgmt/ht/app-common-functions/scheduled-push-notification',
          },
          {
            name: 'leasing.ht-app-common-functions.feed.title',
            icon: 'description',
            type: 'item',
            link: '/app/leasing-mgmt/ht/app-common-functions/feed',
          },
          {
            name: 'leasing.content-mgmt.cms.announcement.title',
            icon: 'campaign',
            type: 'item',
            link: '/app/leasing-mgmt/ht/app-common-functions/announcement',
          },
          {
            name: 'leasing.ht-app-common-functions.campus-management.title',
            icon: 'apartment',
            type: 'item',
            link: '/app/leasing-mgmt/ht/app-common-functions/campus-management',
          },
          {
            name: 'leasing.ht-app-common-functions.greeting.title',
            icon: 'sentiment_satisfied_outlined',
            type: 'item',
            link: '/app/leasing-mgmt/ht/app-common-functions/greeting',
          },
          {
            name: 'leasing.ht-app-common-functions.privacy_policy.title',
            icon: 'lock',
            type: 'item',
            link: '/app/leasing-mgmt/ht/app-common-functions/privacy-policy',
          },
          {
            name: 'leasing.ht-app-common-functions.terms-condition.title',
            icon: 'insert_drive_file',
            type: 'item',
            link: '/app/leasing-mgmt/ht/app-common-functions/terms-condition',
          },
        ],
      },
      {
        name: 'leasing.ht-member-and-point.title',
        icon: 'account_box',
        type: 'section',
        link: '/app/leasing-mgmt/ht/member-and-point',
        children: [
          {
            name: 'leasing.ht-member.title',
            icon: 'badge',
            type: 'item',
            link: '/app/leasing-mgmt/ht/member-and-point/member',
          },
          {
            name: 'leasing.ht-spending-transaction.title',
            icon: 'receipt_long',
            type: 'item',
            link: '/app/leasing-mgmt/ht/member-and-point/spending-transaction',
          },
          {
            name: 'leasing.ht-point-transaction.title',
            pathD: "M8.58,17.25L9.5,13.36L6.5,10.78L10.45,10.41L12,6.8L13.55,10.45L17.5,10.78L14.5,13.36L15.42,17.25L12,15.19L8.58,17.25M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z",
            type: 'item',
            link: '/app/leasing-mgmt/ht/member-and-point/point-transaction',
          },
          {
            name: 'leasing.ht-member-points-qr-code',
            icon: 'qr_code',
            type: 'item',
            link: '/app/leasing-mgmt/ht/member-and-point/qr-code',
          },
          {
            name: 'leasing.ht-invitation.title',
            pathD:
              'M16.48 10.41c-.39.39-1.04.39-1.43 0l-4.47-4.46-7.05 7.04-.66-.63c-1.17-1.17-1.17-3.07 0-4.24l4.24-4.24c1.17-1.17 3.07-1.17 4.24 0L16.48 9c.39.39.39 1.02 0 1.41zm.7-2.12c.78.78.78 2.05 0 2.83-1.27 1.27-2.61.22-2.83 0l-3.76-3.76-5.57 5.57c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.42 0l4.62-4.62.71.71-4.62 4.62c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.42 0l4.62-4.62.71.71-4.62 4.62c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.62-4.62.71.71-4.62 4.62c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l8.32-8.34c1.17-1.17 1.17-3.07 0-4.24l-4.24-4.24c-1.15-1.15-3.01-1.17-4.18-.06l4.47 4.47z',
            type: 'item',
            link: '/app/leasing-mgmt/ht/member-and-point/invitation',
          },
          {
            name: 'leasing.ht-member-point.settings.title',
            icon: 'design_services',
            type: 'section',
            link: '/app/leasing-mgmt/ht/member-and-point/settings',
            children: [
              {
                name: 'leasing.ht-member-point.settings.invitation_rule.title',
                type: 'item',
                link: '/app/leasing-mgmt/ht/member-and-point/settings/invitation-rule',
              },
              {
                name: 'leasing.ht-spending-transaction.deny-reason.title',
                type: 'item',
                link: '/app/leasing-mgmt/ht/member-and-point/settings/spending-transaction-deny-reason',
              },
              {
                name: 'leasing.ht-member-point.settings.point_reward_rule.title',
                type: 'item',
                link: '/app/leasing-mgmt/ht/member-and-point/settings/point-reward-rule',
              },
            ],
          },
        ],
      },
    ],
  },

  {
    name: 'sidebar.property_management',
    type: 'section',
    icon: 'assignment',
    link: '/app/property-mgmt',
    children: [
      {
        name: 'pmc_management.overview',
        icon: 'home',
        type: 'item',
        link: '/app/property-mgmt/overview',
      },
      {
        name: 'content.pmc.tenant_management',
        icon: 'tenantIcon',
        type: 'item',
        link: '/app/property-mgmt/tenants',
      },
      {
        name: 'sidebar.property.facility_mgt',
        icon: 'fitness_center',
        type: 'item',
        link: '/app/property-mgmt/facility',
      },
      {
        name: 'pmc_management.shuttle-bus',
        icon: 'airport_shuttle',
        type: 'item',
        link: '/app/property-mgmt/shuttle-bus',
      },
      {
        name: 'sidebar.property.transaction_record',
        icon: 'receipt_sharp',
        type: 'item',
        link: '/app/property-mgmt/transaction-records',
      },
      {
        name: 'sidebar.property.work_order',
        icon: 'construction',
        type: 'item',
        link: '/app/property-mgmt/work-orders',
      },
      {
        name: 'sidebar.property.value_added',
        icon: 'room_service',
        type: 'item',
        link: '/app/property-mgmt/value-added',
      },
      {
        name: 'sidebar.property.visitor_records',
        icon: 'person',
        type: 'item',
        link: '/app/property-mgmt/visitors',
      },
      {
        name: 'sidebar.property.access_records',
        icon: 'assignment_ind',
        type: 'item',
        link: '/app/property-mgmt/access-records',
      },
      {
        name: 'pmc_management.staff.staff_management',
        icon: 'people',
        type: 'item',
        link: '/app/property-mgmt/staff',
      },
      {
        name: 'pmc_management.gamification.title',
        icon: 'event_available',
        type: 'item',
        link: '/app/property-mgmt/gamification',
      },
      {
        name: 'pmc_management.content-mgmt.title',
        icon: 'source',
        type: 'section',
        link: '/app/property-mgmt/content-mgmt',
        children: [
          {
            name: 'pmc_management.merchant.title',
            icon: 'store',
            type: 'item',
            link: '/app/property-mgmt/content-mgmt/merchant',
          },
        ],
      },

      // {
      //     name: 'sidebar.visitor_management',
      //     icon: 'supervised_user_circle',
      //     type: 'item',
      //     link: '/app/property-mgmt/visitor',
      // },
    ],
  },

  {
    name: 'sidebar.tenant_services',
    type: 'section',
    icon: 'store',
    link: '/app/tenant', //
    children: [
      {
        name: 'tenant.overview.header',
        type: 'item',
        icon: 'home',
        link: '/app/tenant/overview',
      },
      {
        name: 'tenant.staff.staff_management',
        icon: 'people',
        type: 'item',
        link: '/app/tenant/staff/',
      },

      // {
      //     name: 'sidebar.visitor_management',
      //     icon: 'supervised_user_circle',
      //     type: 'item',
      //     link: '/app/tenant/visitor', // !!! missing
      // },
    ],
  },
]

export default navigationMenus
