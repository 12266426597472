import { AuthUser, User, PolicyCampusesResource } from '../schema'
import localStorageService from '../services/localStorageService'
import * as R from 'ramda'

const UI_PLATFORM_ADMIN = 'ui_platform_admin'
const UI_ORG_ADMIN = 'ui_org_admin'
const UI_PROPERTY_USER = 'ui_property_user'
const UI_TENANT_USER = 'ui_tenant_user'

const UI_VISITOR = 'ui_visitor' // fallback
export const AuthRoles = {
  platform: [UI_PLATFORM_ADMIN], // Re-route to platform management portal
  admin: [UI_ORG_ADMIN, UI_PROPERTY_USER], // admin portal
  normal: [UI_ORG_ADMIN, UI_PROPERTY_USER, UI_TENANT_USER], // normal user
  all: [UI_ORG_ADMIN, UI_PLATFORM_ADMIN, UI_TENANT_USER, UI_VISITOR],

  tenant_admin: [],
  tenant_user: [],
  visitor: [],
}

// const UI_TENANT_USER = 'ui_tenant_user'
// const UI_PROPERTY_USER = 'ui_property_user'
// const UI_PLATFORM_ADMIN = 'ui_platform_admin'

export const USER_GROUPS = {
  INTERFACE_ADMIN_GROUP: 'interface_admin_group',
  DEVICE_AND_DEVICE_TYPE_ADMIN_GROUP: 'device_and_device_type_admin_group',
  BUILDING_ADMIN_GROUP: 'building_admin_group',
  IAM_ADMIN_GROUP: 'iam_admin_group',
  DATA_MODEL_ADMIN_GROUP: 'data_model_admin_group',
  BASIC_USER_GROUP: 'basic_user_group',
  TENANT_ADMIN_GROUP: 'tenant_admin_group',
  SUPPLIER_ADMIN_GROUP: 'supplier_admin_group',
  DATA_VIEWER_GROUP: 'data_viewer_group',
  ACCESS_CONTROL_AND_VISITOR_SYSTEM_ADMIN_GROUP: 'access_control_and_visitor_system_admin_group',
}

export const ACCESS_POLICY = {
  accessService: {
    propertiesManagementAccess: {
      fullAccess: 'policy.accessService.propertiesManagementAccess.fullAccess',
      readOnly: 'policy.accessService.propertiesManagementAccess.readOnly',
    },
  },
  analytics: {
    accessControl: {
      fullAccess: 'policy.leasing.accessAnalytics.readOnly',
    },
    butler: {
      fullAccess: 'policy.analytics.butler.fullAccess',
    },
    gamification: {
      fullAccess: 'policy.analytics.gamification.fullAccess',
    },
    member: {
      fullAccess: 'policy.leasing.memberAnalytics.readOnly',
    },
    spendingEventsAndGift: {
      fullAccess: 'policy.analytics.spendingEventsAndGift.fullAccess',
    },
    ticketing: {
      fullAccess: 'policy.analytics.ticketing.fullAccess',
    },
    visitor: {
      fullAccess: 'policy.analytics.visitor.fullAccess',
    },
    workOrder: {
      fullAccess: 'policy.leasing.visitorAnalytics.readOnly',
    }
  },
  platformManagement: {
    supplierManagement: {
      fullAccess: 'policy.system.iotManagement.fullAccess',
    },
    iotManagement: {
      fullAccess: 'policy.platformManagement.iotManagement.fullAccess',
    },
    campusManagement: {
      readOnly: 'policy.platformManagement.campusManagement.readOnly',
      fullAccess: 'policy.system.campusManagement.fullAccess',
    },
    customUserGroupManagement: {
      fullAccess: 'policy.platformManagement.customUserGroupManagement.fullAccess',
    },
    iam: {
      readOnly: 'policy.platformManagement.iam.readOnly',
      fullAccess: 'policy.platformManagement.iam.fullAccess',
      userManagement: {
        fullAccess: 'policy.platformManagement.iam.userManagement.fullAccess',
      },
    },
    accessControl: 'policy.general.myAccessPermissionManagement.readOnly',
  },
  propertiesManagementService: {
    accessRecordManagement: {
      fullAccess: 'policy.propertiesManagementService.accessRecordManagement.fullAccess'
      // new 'policy.pmc.pmcUserAccessPermissionManagement.fullAccess',
    },
    userManagement: {
      readOnly: 'policy.propertiesManagementService.userManagement.readOnly',
      fullAccess: 'policy.propertiesManagementService.userManagement.fullAccess',
    },
    tenantManagement: {
      readOnly: 'policy.pmc.tenantManagement.readOnly',
    },
    specialAccessManagement: {
      readOnly: 'policy.pmc.tenantUserAccessPermissionManagement.special.readOnly',
      fullAccess: 'policy.pmc.tenantUserAccessPermissionManagement.special.fullAccess',
    },
    visitorService: {
      readOnly: 'policy.propertiesManagementService.visitorService.readOnly',
      fullAccess: 'policy.propertiesManagementService.visitorService.fullAccess',
    },
    workOrderManagement: {
      readOnly: 'policy.pmc.workOrderServiceRequestManagement.readOnly',
    },
    generalService: {
      basic: 'policy.pmc.pmcUser.basicOnly',
    },
    facilityService: {
      fullAccess: 'policy.facilityService.propertiesManagementAccess.fullAccess',
      // new 'policy.pmc.facilityManagement.fullAccess',
    },
    shuttleBusService: {
      fullAccess: 'policy.shuttleBusService.propertiesManagementAccess.fullAccess',
    },
    gameManagement: {
      fullAccess: 'policy.propertiesManagementService.gameManagement.fullAccess',
    },
  },
  buildingService: {
    accessControlService: {
      genericQRCodeAccess: 'policy.general.identityQRCode.readOnly',
    },
  },
  communityService: {
    cmsService: {
      admin: 'policy.leasing.contentManagementApprover.fullAccess',
      editor: 'policy.leasing.contentManagementEditor.fullAccess',
    },
  },
  leasingManagement: {
    notificationService: {
      generalAccess: {
        fullAccess: "policy.leasingManagement.notificationService.generalAccess.fullAccess"
      },
      campusAccess: {
        fullAccess: "policy.leasingManagement.notificationService.campusAccess.fullAccess"
      }
    },
    tenantManagement: {
      fullAccess: 'policy.leasing.tenantManagement.fullAccess',
      readOnly: 'policy.leasing.tenantManagement.readOnly',
      userManagement: {
        fullAccess: 'policy.leasing.tenantUserManagement.fullAccess',
      },
      visitorManagement: {
        fullAccess: 'policy.leasingManagement.tenantManagement.visitorManagement.fullAccess',
      },
    },
    apiKeyManagement: {
      fullAccess: 'policy.leasing.apiKeyManagement.fullAccess',
    },
    propertiesManagementCompany: {
      fullAccess: 'policy.leasing.pmcManagement.fullAccess',
      readOnly: 'policy.leasing.pmcManagement.readOnly',
    },
    userManagement: {
      fullAccess: 'policy.leasing.leasingUserManagement.fullAccess',
    },

    generalService: {
      basic: 'policy.leasing.leasingUser.basicOnly',
    },
    gameManagement: {
      fullAccess: 'policy.leasingManagement.gameManagement.fullAccess',
    },
    memberService: {
      fullAccess: 'policy.leasingManagement.memberService.fullAccess',
      membership: 'policy.leasingManagement.memberService.membership',
      invitationRule: 'policy.leasingManagement.memberService.invitationRule',
      invitation: 'policy.leasingManagement.memberService.invitation',
      pointTransaction: 'policy.leasingManagement.memberService.pointTransaction',
    },
    scanPoint: {
      fullAccess: "policy.leasingManagement.scanPoint.fullAccess"
    },
    merchant: {
      merchantSubTypeManagement: 'policy.leasingManagement.merchant.merchantSubTypeManagement',
      merchantManagement: 'policy.leasingManagement.merchant.merchantManagement'
    },
    appConfigManagement: {
      fullAccess: 'policy.leasingManagement.appConfigManagement.fullAccess',
    },
    appConfigService: {
      privacyPolicy: {
        fullAccess: 'policy.leasingManagement.appConfigService.privacyPolicy.fullAccess'
      },
      termsAndConditions: {
        fullAccess: 'policy.leasingManagement.appConfigService.termsAndConditions.fullAccess'
      },
      greeting: {
        fullAccess: 'policy.leasingManagement.appConfigService.greeting.fullAccess'
      },
      campusConfig: {
        fullAccess: 'policy.leasingManagement.appConfigService.campusConfig.fullAccess'
      },
      shortcutConfig: {
        fullAccess: 'policy.leasingManagement.appConfigService.shortcutConfig.fullAccess'
      },
      campusesAdvertisements: 'policy.leasingManagement.appConfigService.campusesAdvertisements',
      generalAdvertisements: 'policy.leasingManagement.appConfigService.generalAdvertisements'

    },
    selfServicePoints: {
      selfServicePoints: {
        fullAccess: "policy.leasingManagement.selfServicePoints.selfServicePoints.fullAccess"
      },
      rewardRule: {
        fullAccess: "policy.leasingManagement.selfServicePoints.rewardRule.fullAccess"
      },
      adjustPoints: {
        fullAccess: 'policy.leasing.selfServicePointsManagement.adjustPoints.fullAccess'
      }
    },
    contentService: {
      fullAccess: 'policy.leasingManagement.contentService.fullAccess',
      editAccess: 'policy.leasingManagement.contentService.editAccess',
      globalEditAccess: 'policy.leasingManagement.contentService.globalEditAccess',
      globalFullAccess: 'policy.leasingManagement.contentService.globalFullAccess'
    }

  },
  tenantService: {
    visitorService: {
      approval: 'policy.tenantService.visitorService.approval',
      myRequest: 'policy.tenantService.visitorService.myRequest',
      request: 'policy.tenantService.visitorService.request',
      approvalManagement: 'policy.tenantService.visitorService.approvalManagement',
    },
    generalService: {
      tenantInfo: {
        readOnly: 'policy.tenant.tenantProfile.readOnly',
        fullAccess: 'policy.tenant.tenantProfile.fullAccess',
      },
    },
    userManagement: {
      readOnly: 'policy.tenant.tenantUserManagement.readOnly',
      fullAccess: 'policy.tenant.tenantUserManagement.fullAccess',
    },
  },
  valueAddedService: {
    propertiesManagementAccess: {
      fullAccess: 'policy.pmc.valueAddServiceRequestManagement.fullAccess',
    },
  },
  visitorService: {
    propertiesManagementAccess: {
      fullAccess: 'policy.pmc.visitorRegistration.fullAccess',
    },
  },
  supplierService: {
    accessControlService: ['addIccardInfoToUser', 'addFacialInfoToUser'],
  },
  propertiesManagementCompany: {
    merchant: {
      merchantManagement: 'policy.propertiesManagementCompany.merchant.merchantManagement'
    }
  },
  local: {
    development: 'policy.system.development.fullAccess',
  },
  generic: {
    selfServicePoints: {
      rewardRule: 'policy.generic.selfServicePoints.rewardRule'
    }
  }

}

const policyStringLookup = {}

export const ROLES_UI_GROUPS = [UI_PLATFORM_ADMIN, UI_ORG_ADMIN, UI_PROPERTY_USER, UI_TENANT_USER]

export const UIRole = (user: AuthUser) =>
  (user && user.policies && user.policies.find((s) => ROLES_UI_GROUPS.includes(s))) || null

export const AuthChecking = (user: User, policies: string[], campus_id?: string) => {
  // console.log('Authchecking:', user)
  if (!policies || policies.length === 0) return true
  // if (user?.policies?.includes(ACCESS_POLICY.local.development)) return true
  if (!!campus_id) {
    const campusesResource: PolicyCampusesResource[] = localStorageService.getItem('campusesResource') ?? []
    const corrCampusResource = [
      ...new Set(
        R.filter(item => R.includes(item?.ref, policies), campusesResource)
          .map(i => i.resources)
          .flat(Infinity),
      ),
    ]
    const corrCampusIds = corrCampusResource.map(val => {
      const campus_id = `${val}`.split(':')[4]
      return campus_id
    })
    return R.includes(campus_id, corrCampusIds)
  } else {
    return policies.filter((r) => user?.policies?.includes(r)).length > 0
  }
}

// Check out app/views/dashboard/DashboardRoutes.js
// Only SA & Admin has dashboard access

// const dashboardRoutes = [
//   {
//     path: "/dashboard/analytics",
//     component: Analytics,
//     auth: authRoles.admin <----------------
//   }
// ];
