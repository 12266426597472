import React from 'react'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'

import App from './containers/App'
import routes from './containers/RootRoutes'

import { HRDPStore } from 'state/root-store'


const history = createBrowserHistory()
const AppContext = React.createContext({ routes: [] })

const MainApp = () =>
  <AppContext.Provider value={{ routes }}>
    <Provider store={HRDPStore}>
      <App />
    </Provider>
  </AppContext.Provider>

export { MainApp, AppContext, history }