import { ListRequest, SimpleBase } from './base'
import type { FileWithPath } from 'react-dropzone'

export interface CommentFile extends File {
  isUploading?: boolean,
  cache?: FileWithPath,
}

export interface File {
  fileName: string,
  id: string,
  url: string,
}


export interface ValueAddedCampusSummary {
  building_id: string,
  campus_id: string,
  type_icon_url: string,
  type_id: string,
  type_name: string,
  value: number,
}

export interface ValueAddedOrderRequest extends ListRequest {
  apply_user_ids: string[] | string,
  building_id?: string,
  type_ids?: string[] | string,
  status_ids: string[] | string,
  tenant_ids: string[] | string,
}

export interface ValueAddedOrder {
  apply_user?: string,
  apply_time?: number,
  building_id: string,
  building_name: string,
  campus_id: string,
  confirm_files: File[],
  contract_area_code: string,
  contract_first_name: string,
  contract_last_name: string,
  contract_phone_num: string,
  created: number,
  description: string,
  floor_id: string,
  floor_name: string,
  id: string,
  rating?: number,
  rating_text?: string,
  space_id: string,
  space_name: string,
  status_id: ValueAddedOrderStatus,
  status_name: string,
  sub_type_id: string,
  sub_type_name: string,
  tenant_id: string,
  tenant_photos: File[],
  tenant_name: string,
  type_id: string,
  type_name: string,
  type_icon_url: string,
}

export interface ValueAddedOrderComment {
  comment: string,
  comment_id: string,
  comment_person: string,
  comment_person_id: string,
  comment_person_profile_picture_url?: string,
  comment_ts: number,
  files: File[],
  images: File[],
  status_id: ValueAddedOrderStatus,
  status_desc: string,
}

export interface ValueAddedOrderHistory {
  id: string,
  is_pmc: boolean,
  operator: string,
  operator_time: number,
  status_id: ValueAddedOrderStatus,
}

export enum ValueAddedOrderStatus {
  Pending = 'PENDING',
  Waiting = 'WAITING',
  Arranging = 'ARRANGING',
  Processing = 'PROCESSING',
  Done = 'DONE',
  Close = 'CLOSE',
  Cancel = 'CANCEL',
  NA = 'NA'
}

export interface ValueAddedType extends SimpleBase {
  icon_url: string,
}
