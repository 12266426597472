import { LANGUAGE_CODE_SC, LANGUAGE_CODE_TC, LANGUAGE_CODE_EN } from '@exaleap/common'
import enLang from './entries/en-US'
import zhCNLang from './entries/zh-CN'
import zhTWLang from './entries/zh-TW'

const AppLocale = {
  [LANGUAGE_CODE_EN]: enLang,
  [LANGUAGE_CODE_SC]: zhCNLang,
  [LANGUAGE_CODE_TC]: zhTWLang,
}

export default AppLocale
