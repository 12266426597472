import { RequestActionFunctions, action } from './index'

import {
    // AuthUser,
    Device,
    DeviceType,
    DataModelRecordPair,
    CreatingDevice,
    DataModel,
    SimpleSpace,
    DataModelType,
    PaginationResponse,
} from '../../schema'
import { createRequestActionStrings } from './helper'

// Devices Related
export const ACTION_KEY_IOT_MGMT = {
    DEVICE_LIST: createRequestActionStrings('DEVICE_LIST'),
    DEVICE_DETAIL: createRequestActionStrings('DEVICE_DETAIL'),
    DEVICE_DETAIL_ASSOCIATE_LOCATION: createRequestActionStrings('DEVICE_DETAIL_ASSOCIATE_LOCATION'),
    DEVICE_DETAIL_UNASSOCIATE_LOCATION: createRequestActionStrings('DEVICE_DETAIL_UNASSOCIATE_LOCATION'),
    DEVICE_DELETE: createRequestActionStrings('DEVICE_DELETE'),
    DEVICE_CREATE: createRequestActionStrings('DEVICE_CREATE'),
    DEVICE_CREATE_BEGIN: createRequestActionStrings('DEVICE_CREATE_BEGIN'),
    DEVICE_TYPE_LIST: createRequestActionStrings('DEVICE_TYPE_LIST'),
    DEVICE_TYPE_DETAIL: createRequestActionStrings('DEVICE_TYPE_DETAIL'),

    DATA_MODEL_LIST: createRequestActionStrings('DATA_MODEL_LIST'),
    DATA_MODEL_DETAIL: createRequestActionStrings('DATA_MODEL_DETAIL'),

    UPDATE_FILTER: 'UPDATE_FILTER',
    UPDATED_FILTER: 'UPDATED_FILTER',
    UPDATE_SEARCH_STRING: 'UPDATE_SEARCH_STRING',
    UPDATE_DEVICE_DETAIL: 'UPDATE_DEVICE_DETAIL',
}

const changeFilterResult = (filter: any, devices: Device[], pagination: PaginationResponse) => action(ACTION_KEY_IOT_MGMT.UPDATED_FILTER, { filter, devices, pagination })


const changeFilter = (filter: any) => action(ACTION_KEY_IOT_MGMT.UPDATE_FILTER, filter)
const changeSearchString = (search: string) => action(ACTION_KEY_IOT_MGMT.UPDATE_SEARCH_STRING, search)

const updateDeviceDetail = (data: Partial<Device>) => action(ACTION_KEY_IOT_MGMT.UPDATE_DEVICE_DETAIL, data)

export const deviceList: RequestActionFunctions = {
    request: () => action(ACTION_KEY_IOT_MGMT.DEVICE_LIST.REQUEST),
    success: (devices, types, powers, connections, campuses, pagination) =>
        action(ACTION_KEY_IOT_MGMT.DEVICE_LIST.SUCCESS, { devices, types, powers, connections, campuses, pagination }),
    failure: (error) => action(ACTION_KEY_IOT_MGMT.DEVICE_LIST.FAILURE, error),
}

export const deviceDetail: RequestActionFunctions = {
    request: (id: string) => action(ACTION_KEY_IOT_MGMT.DEVICE_DETAIL.REQUEST, id),
    success: (
        device: Device,
        type: DataModelType,
        records: DataModelRecordPair[],
        spaces: SimpleSpace[],
    ) => action(ACTION_KEY_IOT_MGMT.DEVICE_DETAIL.SUCCESS, { device, type, records, spaces }),
    failure: (error) => action(ACTION_KEY_IOT_MGMT.DEVICE_DETAIL.FAILURE, error),
}

export const associateLocation: RequestActionFunctions = {
    request: (device_id: string, space: SimpleSpace) =>
        action(ACTION_KEY_IOT_MGMT.DEVICE_DETAIL_ASSOCIATE_LOCATION.REQUEST, { device_id, space }),
    success: (space: SimpleSpace) =>
        action(ACTION_KEY_IOT_MGMT.DEVICE_DETAIL_ASSOCIATE_LOCATION.SUCCESS, space),
    failure: (error) => action(ACTION_KEY_IOT_MGMT.DEVICE_DETAIL_ASSOCIATE_LOCATION.FAILURE, error),
}

export const unassociateLocation: RequestActionFunctions = {
    request: (device_id: string, space_id: string) =>
        action(ACTION_KEY_IOT_MGMT.DEVICE_DETAIL_UNASSOCIATE_LOCATION.REQUEST, { device_id, space_id }),
    success: () =>
        action(ACTION_KEY_IOT_MGMT.DEVICE_DETAIL_UNASSOCIATE_LOCATION.SUCCESS),
    failure: (error) => action(ACTION_KEY_IOT_MGMT.DEVICE_DETAIL_UNASSOCIATE_LOCATION.FAILURE, error),
}

export const deleteDevice: RequestActionFunctions = {
    request: (id: string) => action(ACTION_KEY_IOT_MGMT.DEVICE_DELETE.REQUEST, id),
    success: (id: string) => action(ACTION_KEY_IOT_MGMT.DEVICE_DELETE.SUCCESS, id),
    failure: (error) => action(ACTION_KEY_IOT_MGMT.DEVICE_DELETE.FAILURE, error),
}

export const createDevice: RequestActionFunctions = {
    request: (newDevice: CreatingDevice) => action(ACTION_KEY_IOT_MGMT.DEVICE_CREATE.REQUEST, newDevice),
    success: (device: Device) => action(ACTION_KEY_IOT_MGMT.DEVICE_CREATE.SUCCESS, device),
    failure: (error) => action(ACTION_KEY_IOT_MGMT.DEVICE_CREATE.FAILURE, error),
}

export const createDeviceBegin: RequestActionFunctions = {
    request: () => action(ACTION_KEY_IOT_MGMT.DEVICE_CREATE_BEGIN.REQUEST),
    success: (types) => action(ACTION_KEY_IOT_MGMT.DEVICE_CREATE_BEGIN.SUCCESS, types),
    failure: (error) => action(ACTION_KEY_IOT_MGMT.DEVICE_CREATE_BEGIN.FAILURE, error),
}

// Device type

export const deviceTypeList: RequestActionFunctions = {
    request: () => action(ACTION_KEY_IOT_MGMT.DEVICE_TYPE_LIST.REQUEST),
    success: (types: DeviceType[]) => action(ACTION_KEY_IOT_MGMT.DEVICE_TYPE_LIST.SUCCESS, types),
    failure: (error) => action(ACTION_KEY_IOT_MGMT.DEVICE_TYPE_LIST.FAILURE, error),
}

export const deviceTypeDetail: RequestActionFunctions = {
    request: (id: string) => action(ACTION_KEY_IOT_MGMT.DEVICE_TYPE_DETAIL.REQUEST, id),
    success: (type: DeviceType) => action(ACTION_KEY_IOT_MGMT.DEVICE_TYPE_DETAIL.SUCCESS, type),
    failure: (error) => action(ACTION_KEY_IOT_MGMT.DEVICE_TYPE_DETAIL.FAILURE, error),
}

// Data Models

export const dataModelList: RequestActionFunctions = {
    request: () => action(ACTION_KEY_IOT_MGMT.DATA_MODEL_LIST.REQUEST),
    success: (models: DataModel[]) => action(ACTION_KEY_IOT_MGMT.DATA_MODEL_LIST.SUCCESS, models),
    failure: (error) => action(ACTION_KEY_IOT_MGMT.DATA_MODEL_LIST.FAILURE, error),
}

export const dataModelDetail: RequestActionFunctions = {
    request: (id: string) => action(ACTION_KEY_IOT_MGMT.DATA_MODEL_DETAIL.REQUEST, id),
    success: (model: DataModel) => action(ACTION_KEY_IOT_MGMT.DATA_MODEL_DETAIL.SUCCESS, model),
    failure: (error) => action(ACTION_KEY_IOT_MGMT.DATA_MODEL_DETAIL.FAILURE, error),
}

export const IOTActions = {
    changeFilter,
    changeSearchString,
    changeFilterResult,
    deviceList,
    deviceDetail,
    associateLocation,
    unassociateLocation,
    deleteDevice,
    createDevice,
    createDeviceBegin,
    deviceTypeList,
    deviceTypeDetail,
    dataModelList,
    dataModelDetail,
    updateDeviceDetail,
}
