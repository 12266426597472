import * as R from 'ramda'
import { Action, ACTION_KEY_WORK_ORDER } from '../../actions'
import {
    SimpleBase,
    SimpleCampus,
    WorkOrderStatusEnum,
    WorkOrderListItem,
    WorkOrderType,
    WorkOrderStatusObject,
    SimpleBuilding,
    CampusWorkOrderOverview,
    WorkOrderDetailItem,
    PaginationResponse,
} from '../../../schema'

export interface WorkOrderFilter {
    display_name?: string,

    type_ids: string[],
    repair_area_type_ids: string[],

    page_number?: number,
    page_size?: number,
    campus_id?: string,
    building_id?: string,
    status_id?: WorkOrderStatusEnum,
}


interface WorkOrderState {
    // general
    success: boolean,
    loading: boolean,
    error: any,

    // data
    overview: CampusWorkOrderOverview[],
    orderList: WorkOrderListItem[],
    orderDetail: WorkOrderDetailItem,

    // configs
    orderTypes?: WorkOrderType[],
    orderAreaTypes?: SimpleBase[],
    allStatuses?: WorkOrderStatusObject[],
    campuses?: SimpleCampus[],
    buildings?: SimpleBuilding[],

    // filter & pagination
    filter: WorkOrderFilter,
    paginationResult: PaginationResponse,
}

const initialState: WorkOrderState = {
    success: false,
    loading: false,
    error: null,

    overview: null,
    orderList: [],
    orderDetail: null,

    orderTypes: null,
    orderAreaTypes: null,
    allStatuses: null,
    campuses: null,
    buildings: null,

    filter: {
        display_name: '',
        repair_area_type_ids: [],
        type_ids: [],
        page_number: 1,
        page_size: 50,
    },

    paginationResult: {
        current_page: 1,
        page_size: 50,
        total_pages: 0,
        total_records: 0,
    },
}

const WorkOrderReducer = function (state = initialState, action: Action): WorkOrderState {
    switch (action.type) {
        case ACTION_KEY_WORK_ORDER.RESET_FILTER: {
            return { ...state, filter: R.omit(['campus_id', 'building_id'], state.filter) }
        }

        case ACTION_KEY_WORK_ORDER.UPDATE_FILTER: {
            const page_number = action.payload?.page_number ?? 1
            return { ...state, filter: { ...state.filter, ...action.payload, page_number } }
        }

        case ACTION_KEY_WORK_ORDER.UPDATE_SEARCH_STRING: {

            return { ...state, filter: { ...state.filter, display_name: action.payload, page_number: 1 } }
        }

        case ACTION_KEY_WORK_ORDER.UPDATED_FILTER: {

            return {
                ...state,
                success: true,
                loading: false,
                orderList: action.payload.orders,
                paginationResult: action.payload.pagination,

                allStatuses: action.payload.statuses,
                campuses: action.payload.campuses,
                orderTypes: action.payload.types,
                orderAreaTypes: action.payload.areas,

                overview: action.payload.overview
            }
        }

        case ACTION_KEY_WORK_ORDER.UPDATED_FILTER_FAIL: {
            return {
                ...state,
                success: false,
                loading: false,
                error: action.payload,
            }
        }


        case ACTION_KEY_WORK_ORDER.WORK_ORDER_OVERVIEW.SUCCESS: {

            return {
                ...state,
                success: true,
                loading: false,
                campuses: action.payload.campuses,
                overview: action.payload.overview,
                allStatuses: action.payload.statuses
            }
        }


        case ACTION_KEY_WORK_ORDER.WORK_ORDER_DETAIL.REQUEST: {
            return {
                ...state,
                success: false,
                loading: true,
                orderDetail: null,
            }
        }


        case ACTION_KEY_WORK_ORDER.WORK_ORDER_DETAIL.SUCCESS: {

            return {
                ...state,
                success: true,
                loading: false,
                orderDetail: action.payload.order
            }
        }

        case ACTION_KEY_WORK_ORDER.WORK_ORDER_DETAIL.FAILURE: {
            return {
                ...state,
                success: false,
                loading: false,

                error: action.payload,
            }
        }

        default: {
            return state
        }
    }
}

export default WorkOrderReducer
