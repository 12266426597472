import { AxiosInstance } from 'axios'
import {
  AuthUser,
  TokenResult,
  UserProfile,
  VerifyPolicyResponse,
  PolicyCampusesResource,
  AuthorizeAltchaChallenge,
  AltchaChallengeResponse,
} from '../../../schema'
import { ANONYMOUS_USER_ROUTE, REGISTER_USER_ROUTE } from '../../config'
import APIRestfulProvider, {
  APIHTTPResponse, parseResult, parseResults, throwError
} from '../../restful-provider'

const ACCOUNTS_PATH = '/accounts'

/**
 * Restful endpoints for Accounts related
 */
class AccountRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  getProfile = () =>
    this.client
      .get<APIHTTPResponse<UserProfile>>(`${REGISTER_USER_ROUTE}${ACCOUNTS_PATH}/profile`)
      .then(parseResults)

  updateProfile = (object: any) =>
    this.client
      .put<APIHTTPResponse<{ id: string }>>(
        `${REGISTER_USER_ROUTE}${ACCOUNTS_PATH}/profile`,
        object,
      )
      .then(parseResults)

  uploadProfile = (file: FormData) =>
    this.client
      .put<APIHTTPResponse<{ id: string, profile_picture_url: string }>>(
        `${REGISTER_USER_ROUTE}${ACCOUNTS_PATH}/profile/pic/upload`,
        file,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      )
      .then(parseResults)

  refreshToken = (refreshToken: string) =>
    this.client
      .put<APIHTTPResponse<TokenResult>>(`${ANONYMOUS_USER_ROUTE}${ACCOUNTS_PATH}/token/${refreshToken}/refresh`)
      .then(parseResults)

  authorize = async (username: string, password: string, organization_identifier: string) =>
    this.client
      .post<APIHTTPResponse<AuthUser>>(`${ANONYMOUS_USER_ROUTE}${ACCOUNTS_PATH}/authorize`, {
        username,
        password,
        organization_identifier,
      })
      .then(parseResults)
      .catch(throwError)

  getCaptchaChallenge = async () =>
    this.client
      .post<APIHTTPResponse<AltchaChallengeResponse>>(`${ANONYMOUS_USER_ROUTE}${ACCOUNTS_PATH}/altcha-challenge`)
      .then(parseResult)
      .catch(throwError)

  requestSMS = async (area_code: string, phone_number: string, organization_identifier: string, challenge?: AuthorizeAltchaChallenge) =>
    this.client
      .post<APIHTTPResponse<AuthUser>>(`${ANONYMOUS_USER_ROUTE}${ACCOUNTS_PATH}/authorize`, {
        altcha_solution: challenge,
        area_code: area_code,
        phone_number: phone_number,
        organization_identifier,
      })
      .then(parseResults)
      .catch(throwError)

  verifySMS = async (area_code: string, phone_number: string, verify_code: string) =>
    this.client
      .post<APIHTTPResponse<AuthUser>>(`${ANONYMOUS_USER_ROUTE}${ACCOUNTS_PATH}/verify-code`, {
        area_code: area_code,
        phone_number: phone_number,
        verify_code: verify_code,
      })
      .then(parseResults)
      .catch(throwError)

  verifyPolicyCampusResource = (campusId: string, policies: string[]) =>
    this.client
      .post<APIHTTPResponse<VerifyPolicyResponse>>(`account/verify-policy-campus-resource`, {
        campus_id: campusId,
        policies,
      })
      .then(parseResult)
      .catch(throwError)


  getPolicies = () =>
    this.client
      .get<APIHTTPResponse<{ id: string, policies: PolicyCampusesResource[] }>>(`${REGISTER_USER_ROUTE}${ACCOUNTS_PATH}/policies`)
      .then(parseResult)
      .catch(throwError)

  getCampusesByPolicy = (policy: string) =>
    this.client
      .get<APIHTTPResponse<VerifyPolicyResponse>>(`${REGISTER_USER_ROUTE}${ACCOUNTS_PATH}/policies/${policy}`)
      .then(parseResult)
      .catch(throwError)

  logout = async () =>
    this.client
      .post(`${ANONYMOUS_USER_ROUTE}${ACCOUNTS_PATH}/logout`)
      .then(parseResults)
}

export default AccountRestful
