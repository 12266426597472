import {
  INIT_URL,
  USER_DATA,
  USER_TOKEN_SET,
} from "../../constants/ActionTypes"
import { REGION_CODES, HK_APP_ID, CHINA_APP_ID } from '../../constants/Configs'
import localStorageService from "../../services/localStorageService"
import { TokenResult, AuthUser, UserProfile } from "../../schema"
import { ACTION_KEY_AUTH } from "../actions"
import { isReactNative } from "../../utils/runtime"


interface AuthStore {
  token: TokenResult,
  initURL: string,
  authUser: AuthUser,
  profile: UserProfile,
  loader: boolean,
  requestStatus: "requested" | "failed" | "none",
  regionCd: string,
  smsTimer: number,
  validCodeFailure: boolean,
  validCodeFailureCode: string,
  validCodeFailureTimer: number,
  failedError: Record<string, string>,
  applicationId: string,
  isCNApp: boolean,
}

export const loadAsyncInitState = async () => {
  const token = await localStorageService.getItemAsync('token')
  const LocalAuthUser = await localStorageService.getItemAsync('auth_user')

  return {
    token: token,
    initURL: '',
    authUser: LocalAuthUser,
    loader: true,
    profile: null,
    requestStatus: "none",
    regionCd: REGION_CODES[0],
    smsTimer: 0,
    validCodeFailure: false,
    validCodeFailureCode: null,
    validCodeFailureTimer: 0,
    failedError: null,
    applicationId: REGION_CODES[0] === 'CN' ? CHINA_APP_ID : HK_APP_ID,
    isCNApp: REGION_CODES[0] === 'CN',
  }
}

const INIT_STATE: AuthStore = isReactNative ? {
  token: null,
  initURL: '',
  authUser: null,
  profile: null,
  loader: true,
  requestStatus: "none",
  regionCd: REGION_CODES[0],
  smsTimer: 0,
  validCodeFailure: false,
  validCodeFailureCode: null,
  validCodeFailureTimer: 0,
  failedError: null,
  applicationId: REGION_CODES[0] === 'CN' ? CHINA_APP_ID : HK_APP_ID,
  isCNApp: REGION_CODES[0] === 'CN',
} : {
  token: localStorageService.getItem('token'),
  initURL: '',
  authUser: localStorageService.getItem('auth_user'),
  loader: false,
  profile: null,
  requestStatus: "none",
  regionCd: localStorageService.getItem('regionCd') ?? REGION_CODES[0],
  smsTimer: 0,
  validCodeFailure: false,
  validCodeFailureCode: null,
  validCodeFailureTimer: 0,
  failedError: null,
  applicationId: localStorageService.getItem('regionCd') === 'CN' ? CHINA_APP_ID : HK_APP_ID,
  isCNApp: localStorageService.getItem('regionCd') === 'CN',
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      return { ...state, initURL: action.payload }
    }

    case ACTION_KEY_AUTH.SET_SMS_TIMER: {
      return { ...state, smsTimer: action.payload }
    }

    case ACTION_KEY_AUTH.REMOVE_CURRENT_AUTH_USER: {
      localStorageService.removeItemAsync('token')
      localStorageService.removeItemAsync('auth_user')
      localStorageService.removeItemAsync('isGetAuthRoute')
      localStorageService.removeItemAsync('regionCd')
      localStorageService.removeItemAsync('campusesResource')
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: '',
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      }
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      }
    }

    case ACTION_KEY_AUTH.SET_CURRENT_AUTH_USER: {
      localStorage.setItem('language', action.payload.preferred_language)
      return {
        ...state,
        authUser: action.payload
      }
    }

    case ACTION_KEY_AUTH.VERIFY_SMS.REQUEST: {
      return {
        ...state,
        loader: true,
      }
    }

    case ACTION_KEY_AUTH.VERIFY_SMS.SUCCESS: {
      return {
        ...state,
        loader: false,
      }
    }

    case ACTION_KEY_AUTH.VERIFY_SMS.FAILURE: {
      const errorCode = action.payload
      return {
        ...state,
        loader: false,
        validCodeFailure: true,
        validCodeFailureCode: errorCode,
        validCodeFailureTimer: state.validCodeFailureTimer + 1
      }
    }


    case ACTION_KEY_AUTH.GET_PROFILE.SUCCESS: {
      return {
        ...state,
        loader: false,
        profile: action.payload,
        authUser: { ...(state?.authUser || {}), policies: action.payload?.policies }
      }
    }

    case ACTION_KEY_AUTH.GET_PROFILE.FAILURE: {
      return {
        ...state,
        loader: false,
        requestStatus: "failed",
      }
    }

    case ACTION_KEY_AUTH.REQUEST_SMS.REQUEST: {
      const { regionCd } = action.payload
      const applicationId = regionCd === 'CN' ? CHINA_APP_ID : HK_APP_ID
      localStorageService.setItem('regionCd', regionCd)
      return {
        ...state,
        loader: true,
        smsTimer: 0,
        regionCd,
        applicationId,
        isCNApp: regionCd === 'CN',

      }
    }

    case ACTION_KEY_AUTH.REQUEST_SMS.SUCCESS: {
      return {
        ...state,
        loader: false,
        requestStatus: "requested",
        validCodeFailure: false,
        validCodeFailureTimer: 0,
        failedError: null
      }
    }

    case ACTION_KEY_AUTH.REQUEST_SMS.FAILURE: {
      return {
        ...state,
        token: null,
        authUser: null,
        loader: false,
        requestStatus: "failed",
        failedError: action.payload,
        validCodeFailure: false,
        validCodeFailureTimer: 0,
      }
    }


    case ACTION_KEY_AUTH.REFRESH_TOKEN.SUCCESS: {
      return {
        ...state,
        token: action.payload
      }
    }

    case ACTION_KEY_AUTH.REFRESH_TOKEN.FAILURE: {
      return {
        ...state,
        token: null,
        authUser: null
      }
    }

    default:
      return state
  }
}
