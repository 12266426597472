import { useReducer } from 'react'
import { SimpleBuilding, SimpleMerchant, SimpleFloor } from '@exaleap/common'
import leasingReducer, { State } from '../reducers/leasing'
import leasingActions from '../actions/leasingActions'

const initialState: State = {
  buildings: [],
  merchants: [],
  merchantsHt: [],
  floors: {}
}

export type LeasingState = {
  buildings: SimpleBuilding[],
  merchants: SimpleMerchant[],
  merchantsHt: SimpleMerchant[],
  floors: Record<string, SimpleFloor[]>,
  setBuildings: (buildings: SimpleBuilding[]) => void,
  setMerchants: (buildings: SimpleMerchant[]) => void,
  setFloors: (buildingId: string, floors: SimpleFloor[]) => void,
}

const useLeasing = (): LeasingState => {
  const [leasing, leasingDispatcher] = useReducer(leasingReducer, initialState)
  const { setBuildings, setMerchants, setFloors } = leasingActions(leasingDispatcher)

  return {
    ...leasing,
    setBuildings,
    setMerchants,
    setFloors,
  }
}

export default useLeasing
