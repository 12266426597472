import { Action, ACTION_KEY_IOT_MGMT } from '../../actions'
import {
    Device,
    DeviceType,
    DataModel,
    // Descriptor,
    // CreatingDevice,
    DetailMode,
    DataModelRecordPair,
    LatestDeviceData,
    // SimpleBuilding,
    // SimpleCampus,
    // SimpleFloor,
    SimpleBase,
    SimpleSpace,
    SimpleCampus,
    PaginationResponse,
} from '../../../schema'

export interface DeviceFilter {
    display_name?: string,
    device_type_ids: string[],
    locations: string[],
    page_number?: number,
    page_size?: number,
}


interface DeviceState {
    // reuqest login status
    success: boolean,
    loading: boolean,
    error: any,

    deviceList: Device[],
    deviceTypes: DeviceType[],
    devicePowers: LatestDeviceData[],
    deviceConnections: LatestDeviceData[],

    detailMode: DetailMode,
    detailDevice: Device,
    detailDeviceType: DeviceType,
    detailDataRecords: DataModelRecordPair[],
    detailAvailableSpaces: SimpleSpace[],
    createdDevice: Device,

    dataModelList: DataModel[],
    detailDataModel: DataModel,

    campuses: SimpleCampus[],

    filter: DeviceFilter,

    paginationResult: PaginationResponse,
}

const initialState: DeviceState = {
    success: false,
    loading: false,
    error: null,
    deviceList: null,
    deviceTypes: null,
    devicePowers: null,
    deviceConnections: null,
    detailMode: 'view',
    detailDevice: null,
    detailDeviceType: null,
    detailDataRecords: null,
    detailAvailableSpaces: [],

    createdDevice: null,

    detailDataModel: null,
    dataModelList: null,

    campuses: null,

    filter: {
        display_name: '',
        device_type_ids: [],
        locations: [],
        page_number: 1,
        page_size: 50,
    },
    paginationResult: {
        current_page: 1,
        page_size: 50,
        total_pages: 0,
        total_records: 0,
    },
}

const DeviceReducer = function (state = initialState, action: Action): DeviceState {
    switch (action.type) {
        case ACTION_KEY_IOT_MGMT.UPDATE_FILTER: {
            console.warn('[DeviceReducer] ACTION_KEY_IOT_MGMT.UPDATE_FILTER')
            const page_number = action.payload?.page_number ?? 1
            return { ...state, filter: {
                ...state.filter,
                ...action.payload,
                page_number
            } }
        }

        case ACTION_KEY_IOT_MGMT.UPDATE_SEARCH_STRING: {
            console.warn('[DeviceReducer] ACTION_KEY_IOT_MGMT.UPDATE_SEARCH_STRING')

            return { ...state, filter: {
                ...state.filter,
                display_name: action.payload,
                page_number: 1
            } }
        }

        case ACTION_KEY_IOT_MGMT.UPDATED_FILTER: {
            console.warn('[DeviceReducer] ACTION_KEY_IOT_MGMT.DEVICE_LIST.SUCCESS')
            return {
                ...state,
                success: true,
                loading: false,
                deviceList: action.payload.devices,
                paginationResult: action.payload.pagination
            }
        }

        case ACTION_KEY_IOT_MGMT.DEVICE_LIST.SUCCESS: {
            console.warn('[DeviceReducer] ACTION_KEY_IOT_MGMT.DEVICE_LIST.SUCCESS')
            return {
                ...state,
                success: true,
                loading: false,
                campuses: action.payload.campuses,
                deviceList: action.payload.devices,
                deviceTypes: action.payload.types,
                devicePowers: action.payload.powers,
                deviceConnections: action.payload.connections,
                paginationResult: action.payload.pagination
            }
        }

        case ACTION_KEY_IOT_MGMT.DEVICE_LIST.FAILURE: {
            console.warn('[DeviceReducer] ACTION_KEY_IOT_MGMT.DEVICE_LIST.FAILURE')
            return {
                ...state,
                success: false,
                loading: false,
                deviceList: null,
                deviceTypes: null,
                error: action.payload,
            }
        }

        case ACTION_KEY_IOT_MGMT.DEVICE_CREATE_BEGIN.SUCCESS: {
            return {
                ...state,
                detailDevice: null,
                createdDevice: null,
                detailMode: 'create',
                deviceTypes: action.payload,
            }
        }

        case ACTION_KEY_IOT_MGMT.DEVICE_CREATE.SUCCESS: {
            return {
                ...state,
                createdDevice: action.payload,
            }
        }

        case ACTION_KEY_IOT_MGMT.DEVICE_DELETE.SUCCESS: {
            return {
                ...state,
                createdDevice: action.payload,
            }
        }

        case ACTION_KEY_IOT_MGMT.DEVICE_DETAIL.REQUEST: {
            return {
                ...state,
                success: false,
                loading: true,
                detailDevice: null,
                detailDeviceType: null,
                createdDevice: null,
            }
        }

        case ACTION_KEY_IOT_MGMT.DEVICE_DETAIL_ASSOCIATE_LOCATION.SUCCESS: {
            const { hierarchy, ...args } = action.payload
            const { building, campus, floor } = hierarchy
            const location = {
                space: args,
                building,
                campus,
                floor,
            }
            return {
                ...state,
                success: true,
                loading: false,
                detailDevice: {
                    ...state.detailDevice,
                    location,
                },
            }
        }

        case ACTION_KEY_IOT_MGMT.DEVICE_DETAIL_UNASSOCIATE_LOCATION.SUCCESS: {

            return {
                ...state,
                success: true,
                loading: false,
                detailDevice: {
                    ...state.detailDevice,
                    location: null,
                },
            }
        }

        case ACTION_KEY_IOT_MGMT.DEVICE_DETAIL.SUCCESS: {
            console.warn('[DeviceReducer] ACTION_KEY_IOT_MGMT.DEVICE_LIST.FAILURE')

            return {
                ...state,
                success: true,
                loading: false,
                detailMode: 'view',
                detailDevice: action.payload.device,
                detailDeviceType: action.payload.type,
                detailDataRecords: action.payload.records,
                detailAvailableSpaces: action.payload.spaces,
            }
        }

        case ACTION_KEY_IOT_MGMT.DEVICE_DETAIL.FAILURE: {
            return {
                ...state,
                success: false,
                loading: false,
                detailMode: 'view',
                error: action.payload,
            }
        }

        case ACTION_KEY_IOT_MGMT.UPDATE_DEVICE_DETAIL: {
            return {
                ...state,
                detailDevice: {
                    ...state.detailDevice,
                    ...action.payload,
                },
            }
        }
        case ACTION_KEY_IOT_MGMT.DATA_MODEL_LIST.SUCCESS: {
            console.warn('[DeviceReducer] ACTION_KEY_IOT_MGMT.DEVICE_LIST.FAILURE')
            return {
                ...state,
                dataModelList: action.payload,
            }
        }

        case ACTION_KEY_IOT_MGMT.DATA_MODEL_DETAIL.SUCCESS: {
            return {
                ...state,
                detailDataModel: action.payload,
            }
        }

        default: {
            return state
        }
    }
}

export default DeviceReducer
