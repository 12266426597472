import { LANGUAGE_DATA_LIST, LocalizationObject } from '@exaleap/common'
import { intl } from './IntlHelper'

export const getLanguageData = (langCd: string) => LANGUAGE_DATA_LIST.find(({ locale }) => locale === langCd)

export const getLocaleByIntl = () => {
    const intlLocale = intl.locale
    return LANGUAGE_DATA_LIST.find(({ languageId }) => intlLocale === languageId).locale
}

export const getValueWithLocale = <T>(key: string, data?: T & LocalizationObject, landCd?: string): string =>
    data?.localization?.[key]?.[landCd || getLocaleByIntl()] ?? data?.[key] ?? ''
