
import { AxiosInstance } from 'axios'
import APIRestfulProvider, {
  APIHTTPResponse,
  throwError,
  parseResult,
  parseResults,
} from '../../restful-provider'
import { Package, } from '../../../schema'
import { PMC_MANAGEMENT_ROUTE, REGISTER_USER_ROUTE } from '../../config'

class MarketServiceEndpoints {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }
  subRoute = `/asset`

  getPackages = (facility_id: string) =>
    this.client
      .get<APIHTTPResponse<Package>>(`${this.subRoute}${REGISTER_USER_ROUTE}/products`, { params: { facility_id } })
      .then(parseResults)
      .catch(throwError)

  updateProductDisplay = (id: string, display: boolean) =>
    this.client
      .patch<APIHTTPResponse<any>>(
        `${this.subRoute}${PMC_MANAGEMENT_ROUTE}/products/${id}`,
        { display },
      )
      .then(parseResult)
      .catch(throwError)

}

export default MarketServiceEndpoints
