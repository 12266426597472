import { ACCESS_POLICY } from "@exaleap/common"


const TenantVisitor = ACCESS_POLICY.tenantService.visitorService

const AccessControlRoutes = [
  {
    path: "/app/tenant/visitor/:id",
    element: () => import("./VisitorRequestDetailPage"),
    auth: [TenantVisitor.approvalManagement, TenantVisitor.approval, TenantVisitor.myRequest, TenantVisitor.request],
  },
  {
    path: "/app/tenant/visitor",
    element: () => import("./VisitorRequestList"),
    auth: [TenantVisitor.approvalManagement, TenantVisitor.approval, TenantVisitor.myRequest, TenantVisitor.request],
  },
  {
    path: "/app/access-control",
    element: () => import("./AccessControlList"),
    auth: []
  },

]

export default AccessControlRoutes
