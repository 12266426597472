import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Navigate, matchRoutes, useLocation, Outlet } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { jwtAuthService, AuthUser, AuthActions, AuthChecking, generalDataActions } from '@exaleap/common'
import { useDispatch } from 'react-redux'
import { useSelector } from 'state/root-store'
import * as R from 'ramda'

import getNavigationByUser, { SET_USER_NAVIGATION } from './NavigationHelper'

const LoadingComponent = () => {
  return (
    <div className="loader-view" style={{ height: 'calc(100vh - 200px)' }}>
      <CircularProgress />
    </div>
  )
}

const isPermissionAllowed = (pathname: string, routes: any[], user: AuthUser) => {
  const matched = matchRoutes(routes, pathname)[0]
  const authenticated = matched ? AuthChecking(user, matched.route.auth) : false
  // console.warn('[AUTHORIZATION]', user.policies, matched && matched.route.auth, pathname)
  return authenticated
}

const AppAuthContent = props => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { authUser, token, profile, regionCd } = useSelector(({ auth }) => auth)
  const { routes } = props
  const [loading, setLoading] = useState(true)
  const regionVersion = useRef()
  regionVersion.current = useMemo(() => !!regionCd ? regionCd.toLocaleLowerCase() : (R.includes('henderson', window?.location?.hostname ?? '') ? 'cn' : 'hk'), [])

  if (token) {
    jwtAuthService.setSession(token)
  }

  const access_token = token ? token.access_token : null
  const refresh_token = token ? token.refresh_token : null

  const authHandler = async () => {
    console.warn('JWT checking: ', token)
    if (!jwtAuthService.assertJWT(access_token)) {
      console.warn('JWT expired. perform refresh token now: ', refresh_token)
      dispatch(AuthActions.refreshToken.request(refresh_token))
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (authUser) {
      // get profile to sync system language
      dispatch(AuthActions.requestProfile.request())
      dispatch(generalDataActions.buildings.request())
    }
  }, [])

  useEffect(() => {
    if (authUser || profile) {
      const nav = getNavigationByUser(routes, profile || authUser, regionVersion.current)
      dispatch({ type: SET_USER_NAVIGATION, payload: nav })
      if (!!authUser) {
        localStorage.setItem('isGetAuthRoute', `${!!authUser}`)
      } else {
        localStorage.removeItem('isGetAuthRoute')
      }
    }
  }, [authUser, profile])

  useEffect(() => {
    //  console.log('[AUTHORIZATION] useEffect monitoring pathname & access token', location.pathname, access_token)
    if (access_token) {
      authHandler()
    } else {
      setLoading(false)
    }
  }, [location.pathname, access_token])

  if (loading) return <LoadingComponent />

  if (!authUser) {
    return (
      <Navigate
        to={{
          pathname: `/${regionVersion.current}/signin`,
        }}
        state={{ from: location }}
      />
    )
  }

  if (!isPermissionAllowed(location.pathname, routes, profile ?? authUser)) {
    // console.log('[AUTHORIZATION] Permission not allowed', location.pathname, route.routes, authUser)
    return <Navigate to="/error/401" />
  }

  return (<Outlet />)
  // return (
  // routesElement
  // <Suspense fallback={<LoadingComponent />}>
  {/* sub routes */ }
  // {routesElement}
  // </Suspense>
  // )
}

export default AppAuthContent
