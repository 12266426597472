import { ACCESS_POLICY } from '@exaleap/common'
import HTMemberAndPointRoutes from './HTMemberAndPoints/index'
import HTAppFunctionsRoutes from './HTAppFuntions/index'
import CmsRoutes from './Cms/index'
import HTContentMgmtRoutes from './HTContentManagement/index'

const LeasingPortalRoutes = [
  {
    path: '/app/leasing-mgmt/tenants/:id',
    element: () => import('./TenantDetail'),
    auth: [
      ACCESS_POLICY.leasingManagement.tenantManagement.fullAccess,
      ACCESS_POLICY.leasingManagement.tenantManagement.readOnly,
    ],
  },
  {
    path: '/app/leasing-mgmt/tenants',
    element: () => import('./TenantList'),
    auth: [
      ACCESS_POLICY.leasingManagement.tenantManagement.fullAccess,
      ACCESS_POLICY.leasingManagement.tenantManagement.readOnly,
    ],
  },
  {
    path: '/app/leasing-mgmt/pmc/:id',
    element: () => import('./PropertyMgmtCompanyDetail'),
    auth: [
      ACCESS_POLICY.leasingManagement.propertiesManagementCompany.fullAccess,
      ACCESS_POLICY.leasingManagement.propertiesManagementCompany.readOnly,
    ],
  },
  {
    path: '/app/leasing-mgmt/pmc',
    element: () => import('./PropertyMgmtCompanyList'),
    auth: [
      ACCESS_POLICY.leasingManagement.propertiesManagementCompany.fullAccess,
      ACCESS_POLICY.leasingManagement.propertiesManagementCompany.readOnly,
    ],
  },
  {
    path: '/app/leasing-mgmt/staff/*',
    element: () => import('./LeasingStaff'),
    auth: [ACCESS_POLICY.leasingManagement.userManagement.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/api-keys',
    element: () => import('./APIKeyList'),
    auth: [ACCESS_POLICY.leasingManagement.apiKeyManagement.fullAccess],
  },
  // {
  //    // path: '/app/leasing-mgmt/analytics',
  //    // element: () => import('./LeasingAnalytics'),
  // },
  {
    path: '/app/leasing-mgmt/analytics/member',
    element: () => import('./LeasingAnalytics'),
    auth: [ACCESS_POLICY.analytics.member.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/analytics/work-order',
    element: () => import('./LeasingAnalytics'),
    auth: [ACCESS_POLICY.analytics.workOrder.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/analytics/visitor',
    element: () => import('./LeasingAnalytics'),
    auth: [ACCESS_POLICY.analytics.visitor.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/analytics/access-control',
    element: () => import('./LeasingAnalytics'),
    auth: [ACCESS_POLICY.analytics.accessControl.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/analytics/spending-events-and-gift',
    element: () => import('./LeasingAnalytics'),
    auth: [ACCESS_POLICY.analytics.spendingEventsAndGift.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/analytics/butler',
    element: () => import('./LeasingAnalytics'),
    auth: [ACCESS_POLICY.analytics.butler.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/analytics/ticketing',
    element: () => import('./LeasingAnalytics'),
    auth: [ACCESS_POLICY.analytics.ticketing.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/merchants',
    element: () => import('./MerchantsManagement'),
    auth: [ACCESS_POLICY.communityService.cmsService.admin],
  },
  {
    path: '/app/leasing-mgmt/app-config/landing',
    element: () => import('./AppConfig'),
    auth: [ACCESS_POLICY.leasingManagement.appConfigManagement.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/notifications',
    element: () => import('./Notifications'),
    auth: [ACCESS_POLICY.communityService.cmsService.admin],
  },
  {
    path: '/app/leasing-mgmt/gamification/:gameId',
    element: () => import('./Game/DetailPage'),
    auth: [ACCESS_POLICY.leasingManagement.gameManagement.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/gamification',
    element: () => import('./Game/Management'),
    auth: [ACCESS_POLICY.leasingManagement.gameManagement.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/overview',
    element: () => import('./Overview'),
    auth: [ACCESS_POLICY.leasingManagement.generalService.basic],
  },
  {
    path: '/app/leasing-mgmt/merchant/v2',
    element: () => import('./MerchantsV2'),
    auth: [ACCESS_POLICY.leasingManagement.merchant.merchantManagement],
  },
  {
    path: '/app/leasing-mgmt/merchant/type/v2',
    element: () => import('./MerchantTypesV2'),
    auth: [ACCESS_POLICY.leasingManagement.merchant.merchantSubTypeManagement],
  },
  ...HTMemberAndPointRoutes,
  ...HTAppFunctionsRoutes,
  ...CmsRoutes,
  ...HTContentMgmtRoutes,
  {
    path: '/app/leasing-mgmt',
    element: () => import('./DefaultPage'),
  },
]

export default LeasingPortalRoutes
