import { combineReducers } from "redux"
import SpaceReducer from "./space"
import FloorReducer from "./floor"
import BuildingReducer from "./building"
import CampusReducer from "./campus"

export default combineReducers({
    space: SpaceReducer,
    floor: FloorReducer,
    building: BuildingReducer,
    campus: CampusReducer
})
