import { AxiosInstance } from 'axios'
import {
  ListRequest,
  Tenant,
  User,
  UserGroup,
  TenantContact,
} from '../../../schema'
import { TENANT_USER_ROUTE, ACCESS_ROUTE } from '../../config'
import APIRestfulProvider, {
  APIHTTPResponse,
  PaginatedResponse,
  parseResults,
  throwError,
} from '../../restful-provider'

export interface EditingTenant {
  profile_picture_id?: string,
  contacts: {
    corporate?: TenantContact[],
    emergency?: TenantContact[],
  },
}

const PERMISSIONS_ROUTE = `${ACCESS_ROUTE}${TENANT_USER_ROUTE}/accesses/permissions`
const USER_GROUPS_ROUTE = `${TENANT_USER_ROUTE}/user-groups`
const USERS_ROUTE = `${TENANT_USER_ROUTE}/users`

/**
 * Restful endpoints for the folloing
 * Accesses
 * Locations
 * Managed-companies
 * Tenants
 * UserGroups
 * Users
 */
class TenantServiceRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  /***********************  general start *************************/
  getTenantInfo = () =>
    this.client.get<APIHTTPResponse<Tenant>>(`${TENANT_USER_ROUTE}/info`).then(parseResults).catch(throwError)

  editTenantInfo = (editingTenant: EditingTenant) =>
    this.client
      .put<APIHTTPResponse<Tenant>>(`${TENANT_USER_ROUTE}/info`, editingTenant)
      .then(parseResults)
      .catch(throwError)
  /***********************  general end *************************/

  /***********************  user start *************************/
  getUserById = (id: string) =>
    this.client
      .get<APIHTTPResponse<User>>(`${USERS_ROUTE}`, {
        params: { user_id: id },
      })
      .then((d) => d.data)
      .catch(throwError)

  admins = () =>
    this.client.get<APIHTTPResponse<User>>(`${USERS_ROUTE}/get-user-admins`).then(parseResults).catch(throwError)

  getUsers = (params?: ListRequest) =>
    this.client
      .get<PaginatedResponse<User>>(`${USERS_ROUTE}`, {
        params,
      })
      .then((d) => d.data)
      .catch(throwError)

  //  Webportal no use
  addUser = (phone_number: string) =>
    this.client
      .post<APIHTTPResponse<any>>(`${USERS_ROUTE}/${phone_number}`, {
        type: 'phone_number',
        value: phone_number,
      })
      .then(parseResults)
      .catch(throwError)

  dropUser = (user_id: string) =>
    this.client
      .delete<APIHTTPResponse<any>>(`${USERS_ROUTE}/${user_id}`, {
        params: { id: user_id },
      })
      .then(parseResults)
      .catch(throwError)

  deleteUserAccessPermission = (params: { user_id: string, allow: string }) =>
    this.client
      .delete<APIHTTPResponse<any>>(`${PERMISSIONS_ROUTE}/generic/users/${params?.user_id}`, {
        params,
      })
      .then(parseResults)
      .catch(throwError)

  deleteAllUserAccessPermissionsInSpace = (params: { id: string }) =>
    this.client
      .delete<APIHTTPResponse<any>>(`${PERMISSIONS_ROUTE}/generic/spaces/${params?.id}`, {
        params,
      })
      .then(parseResults)
      .catch(throwError)

  getUsersByUserAccessPermission = (
    params: ListRequest & {
      allow: string,
      with_allow: boolean,
    },
  ) =>
    this.client
      .get<PaginatedResponse<User>>(`${PERMISSIONS_ROUTE}/generic/users`, { params })
      .then((d) => d.data)
      .catch(throwError)

  copyUserAccessPermissions = (params: { from_space_id: string, to_space_ids: string[] }) =>
    this.client
      .patch<APIHTTPResponse<any>>(`${PERMISSIONS_ROUTE}/generic/users/spaces/${params?.from_space_id}/copy`, {
        ...params,
      })
      .then(parseResults)
      .catch(throwError)

  moveUserAccessPermissions = (params: { from_space_id: string, to_space_ids: string[] }) =>
    this.client
      .patch<APIHTTPResponse<any>>(`${PERMISSIONS_ROUTE}/generic/users/spaces/${params?.from_space_id}/move`, {
        ...params,
      })
      .then(parseResults)
      .catch(throwError)

  setAccessPermissionForUsers = (allow: string, user_ids: string[]) =>
    this.client
      .post<APIHTTPResponse<any>>(`${PERMISSIONS_ROUTE}/generic/multiple-users`, {
        user_ids,
        allow,
      })
      .then(parseResults)
      .catch(throwError)

  setAccessPermission = (user_id: string, allow: string[], exclude: string[] = []) =>
    this.client
      .post<APIHTTPResponse<any>>(`${PERMISSIONS_ROUTE}/generic/users/${user_id}`, {
        user_id,
        allow,
        exclude,
      })
      .then(parseResults)
      .catch(throwError)

  // not calling atm
  getAccessPermission = (user_id: string, permission_id: string) =>
    this.client
      .get<APIHTTPResponse<User>>(`${PERMISSIONS_ROUTE}/generic/users`, {
        params: { user_id, permission_id },
      })
      .then(parseResults)
      .catch(throwError)


  getUserGroups = (id?: string) =>
    this.client
      .get<APIHTTPResponse<UserGroup>>(`${USER_GROUPS_ROUTE}${id ? `/${id}` : ''}`, { params: id ? { id } : null })
      .then(parseResults)
      .catch(throwError)

  getUsersInUserGroup = (user_group_id: string, params?: ListRequest) =>
    this.client
      .get<PaginatedResponse<User>>(`${USER_GROUPS_ROUTE}/${user_group_id}/users`, {
        params: { mode: 'include_user_group', ...params },
      })
      .then((d) => d.data)
      .catch(throwError)

  getUsersNotInUserGroup = (user_group_id: string, params?: ListRequest) =>
    this.client
      .get<PaginatedResponse<User>>(`${USER_GROUPS_ROUTE}/${user_group_id}/users`, {
        params: { mode: 'exclude_user_group', ...params },
      })
      .then((d) => d.data)
      .catch(throwError)

  addUserToUserGroup = (user_id: string, user_group_id: string) =>
    this.client
      .post<APIHTTPResponse<User>>(`${USER_GROUPS_ROUTE}/${user_group_id}/users/${user_id}`)
      .then(parseResults)
      .catch(throwError)

  dropUserFromUserGroup = (user_id: string, user_group_id: string) =>
    this.client
      .delete<APIHTTPResponse<User>>(`${USER_GROUPS_ROUTE}/${user_group_id}/users/${user_id}`)
      .then(parseResults)
      .catch(throwError)

  /***********************  user end *************************/
}

export default TenantServiceRestful
