import { all, call, put, takeEvery } from 'redux-saga/effects'
import { Device } from '../../schema'
import {
    Action,
    ACTION_KEY_PROPERTY_MGMT_TENANT,
    PropertyManagementActions,
    NotificationActionFunctions,
} from '../actions'
import { apiService } from '../../..'

function* requestTenants() {
    console.warn('[SAGA] requestTenants')

    try {
        const [tenants] = yield all([call(apiService.platform.propertiesManagement.getTenantList)])
        yield put(PropertyManagementActions.tenant.tenantList.success(tenants))
    } catch (error) {
        console.warn('[SAGA] requestTenants error', error)
        yield put(PropertyManagementActions.tenant.tenantList.failure(error))
        yield put(
            NotificationActionFunctions.enqueue({
                message: error,
                type: 'error',
            }),
        )
    }
}

// function* saveTenant(action: Action) {
//     console.warn('[SAGA] tenantDetailSave deprecated')

// const currentTenant: Tenant = action.payload
// try {
//     const [result] = yield all([
//         call(apiService.platform.propertiesManagement.edit, currentTenant.id, currentTenant.display_name, currentTenant.description),
//     ])
//     yield put(PropertyManagementActions.tenant.tenantDetailSave.success(result[0]))

//     yield put(
//         NotificationActionFunctions.enqueue({
//             message: 'Saved successfully.',
//             type: 'success',
//         }),
//     )
// } catch (error) {
//     console.warn('[SAGA] tenantDetailSave error', error)
//     yield put(PropertyManagementActions.tenant.tenantDetailSave.failure(error))
//     yield put(
//         NotificationActionFunctions.enqueue({
//             message: error,
//             type: 'error',
//         }),
//     )
// }
// }



function* requestTenant(action: Action) {
    console.warn('[SAGA] property -> requestTenant')
    const id = action.payload

    try {
        // const spacesCall = apiService.apiService.platform.propertiesManagement.getSpaces

        const tenants = yield call(apiService.platform.propertiesManagement.getTenantById, id)


        if (tenants.length === 0) {
            throw new Error('Tenant not found')
        }
        const tenant: Device = tenants[0]


        yield put(PropertyManagementActions.tenant.tenantDetail.success(tenant, []))
    } catch (error) {
        console.warn('[SAGA] property -> requestTenant', error)
        yield put(PropertyManagementActions.tenant.tenantDetail.failure(error))
        yield put(
            NotificationActionFunctions.enqueue({
                message: error,
                type: 'error',
            }),
        )
    }
}

// function* assoicateSpace(action: Action) {
// const { id, space_id } = action.payload

// try {
//     const result = yield call(apiService.platform.propertiesManagement.associateLocation, id, space_id)
//     if (result.length === 0) {
//         throw new Error('Failed ')
//     }

//     const locations = yield call(apiService.platform.buildingService.getSpaceHierarchy, [space_id])
//     const tenantLocations: LocationHierarchy[] = locations.map(l => ({
//         campus: {
//             id: l.campus_id,
//             name: l.campus_name,
//         },
//         building: {
//             id: l.building_id,
//             name: l.building_name
//         },
//         floor: {
//             id: l.floor_id,
//             name: l.floor_name
//         },
//         space: {
//             id: l.space_id,
//             name: l.space_name
//         }
//     }))

//     if (tenantLocations.length > 0) {
//         yield put(PropertyManagementActions.tenant.tenantDetailAssociate.success(tenantLocations))
//     }
// } catch (error) {
//     console.warn('[SAGA] tenantDetailAssociate', error)
//     yield put(PropertyManagementActions.tenant.tenantDetailAssociate.failure(error))
//     yield put(
//         NotificationActionFunctions.enqueue({
//             message: error,
//             type: 'error',
//         }),
//     )

// }


// }


// function* unassoicateSpace(action: Action) {
//     const { id, space_ids } = action.payload
//     try {
//         const result = yield call(apiService.platform.propertiesManagement., id, space_ids)
//         if (result.length === 0) {
//             throw new Error('Failed ')
//         }
//         yield put(PropertyManagementActions.tenant.tenantDetailUnassociate.success(space_ids))
//     } catch (error) {
//         console.log('[SAGA] tenantDetailUnassociate', error)
//         yield put(PropertyManagementActions.tenant.tenantDetailUnassociate.failure(error))

//         yield put(
//             NotificationActionFunctions.enqueue({
//                 message: error,
//                 type: 'error',
//             }),
//         )
//     }
// }

export const propertyManagementSagas = [
    takeEvery(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_LISTING.REQUEST, requestTenants),
    takeEvery(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL.REQUEST, requestTenant),
    // takeEvery(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL_SAVE.REQUEST, saveTenant),
    // takeEvery(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL_ASSOCIATE_SPACE.REQUEST, assoicateSpace),
    // takeEvery(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL_UNASSOCIATE_SPACE.REQUEST, unassoicateSpace),
]
