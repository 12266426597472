import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import { Game, GameForm, GameState, SearchForm, StampLookupRes } from '@exaleap/common'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for Visitor System module
 */
class GameServices {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/game-service'

  getGames = (params: { state?: GameState } = {}) =>
    this.client.get<APIHTTPResponse<Game>>(`${this.subRoute}/games`, { params }).then(parseResult).catch(throwError)

  getGameById = (id: string) =>
    this.client.get<APIHTTPResponse<Game>>(`${this.subRoute}/games/${id}`).then(parseResult).catch(throwError)

  createGame = (params: GameForm) => {
    return this.client.post<APIHTTPResponse<Game>>(`${this.subRoute}/games`, params).then(parseResult).catch(throwError)
  }

  updateGameById = (id: string, data: GameForm) =>
    this.client
      .put<APIHTTPResponse<Game>>(`${this.subRoute}/games/${id}`, data, {
        params: { id },
      })
      .then(parseResult)
      .catch(throwError)

  uploadImage = (file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ url: string; id: string }>>(`${this.subRoute}/upload/single`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(parseResult)
      .catch(throwError)

  getUserStamp = (gameId: string, params: Pick<SearchForm, 'area_code' | 'phone_number' | 'filter_date'>) =>
    this.client
      .get<APIHTTPResponse<StampLookupRes>>(`${this.subRoute}/games/${gameId}/users/stamps`, { params })
      .then(parseResult)
      .catch(throwError)
}

export default GameServices
