import { ACCESS_POLICY } from '@exaleap/common'

const HTContentMgmtRoutes = [
  {
    path: '/app/leasing-mgmt/content-mgmt/post-type',
    element: () => import('./CMSSubCatePage'),
    auth: [
      ACCESS_POLICY.leasingManagement.contentService.fullAccess,
      ACCESS_POLICY.leasingManagement.contentService.editAccess,
    ],
  },
  {
    path: '/app/leasing-mgmt/ht/content-mgmt/ad',
    element: () => import('./Ad'),
    auth: [ACCESS_POLICY.leasingManagement.appConfigService.campusesAdvertisements],
  },
  {
    path: '/app/leasing-mgmt/ht/content-mgmt/scheduled-push-notification',
    element: () => import('./ScheduledPushNotification'),
    auth: [ACCESS_POLICY.leasingManagement.notificationService.campusAccess.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/content-mgmt/feed-campuses',
    element: () => import('./Feed'),
    auth: [ACCESS_POLICY.leasingManagement.notificationService.campusAccess.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/content-mgmt/shortcutKey',
    element: () => import('./Hotkey'),
    auth: [ACCESS_POLICY.leasingManagement.appConfigService.shortcutConfig.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/content-mgmt/latest-news',
    element: () => import('./LatestNews'),
    auth: [
      ACCESS_POLICY.leasingManagement.contentService.fullAccess,
      ACCESS_POLICY.leasingManagement.contentService.editAccess,
    ],
  },
]

export default HTContentMgmtRoutes
