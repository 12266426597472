import {
  all,
  // take,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import { MerchantType } from '../../schema'
import { ACTION_KEY_HT, HTActions, NotificationActionFunctions } from '../actions'
import { apiService, hrdpActions } from '../../..'

function* MerchantTypeRequest() {
  console.group('[SAGA] MerchantTypeRequest')

  try {
    yield put(hrdpActions.fetchStart())
    const merchantType: MerchantType[] = yield call(apiService.leasing.merchant.getMerchantTypes) || []

    yield put(HTActions.merchantType.success(merchantType))
    yield put(hrdpActions.fetchSuccess())
  } catch (error) {
    console.error('[SAGA] deviceDetailRequest', error)
    yield put(HTActions.merchantType.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
    yield put(hrdpActions.fetchError(error))
  }
  console.groupEnd()
}

function* RewardRuleTypesRequest() {
  console.group('[SAGA] RewardRuleTypesRequest')

  try {
    yield put(hrdpActions.fetchStart())
    const [rewardRuleTypes, ruleTypes, rewardTypes, rewardLimitTypes] = yield all([
      call(apiService.ht.spendingTransaction.getRewardRuleTypes),
      call(apiService.ht.spendingTransaction.getRuleTypes),
      call(apiService.ht.spendingTransaction.getRewardTypes),
      call(apiService.ht.spendingTransaction.getRewardLimitTypes),
    ])

    yield put(
      HTActions.rewardRuleAllTypes.success({
        rewardRuleTypes,
        ruleTypes,
        rewardTypes,
        rewardLimitTypes,
      }),
    )
    yield put(hrdpActions.fetchSuccess())
  } catch (error) {
    console.error('[SAGA] deviceDetailRequest', error)
    yield put(HTActions.rewardRuleAllTypes.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
    yield put(hrdpActions.fetchError(error))
  }
  console.groupEnd()
}

export const htSagas = [
  takeEvery(ACTION_KEY_HT.MERCHANT_TYPE.REQUEST, MerchantTypeRequest),
  takeEvery(ACTION_KEY_HT.REWARD_RULE_TYPES.REQUEST, RewardRuleTypesRequest),
]
