import React from 'react'
import { ACCESS_POLICY } from '@exaleap/common'
import { Navigate } from 'react-router-dom'

const PlatformPortalRoutes = [
  {
    path: '/app/platform-mgmt/access-permission/*',
    element: () => import('./AccessPermission'),
    auth: [ACCESS_POLICY.platformManagement.accessControl],
    ignoreRegionCds: ['hk']
  },
  {
    path: '/app/platform-mgmt/access-permission/aps/*',
    element: () => import('./AccessPermissionV2'),
    auth: [ACCESS_POLICY.platformManagement.accessControl],
    ignoreRegionCds: ['cn']
  },
  {
    path: '/app/platform-mgmt/data',
    element: () => import('./DataSearching'),
    auth: [ACCESS_POLICY.platformManagement.accessControl],
  },
  {
    path: '/app/platform-mgmt/user-group-management',
    element: () => import('./UserGroupManagement'),
    auth: [ACCESS_POLICY.platformManagement.customUserGroupManagement.fullAccess],
  },
  {
    path: '/app/platform-mgmt',
    exact: true,
    element: <Navigate to="/app/bms" />,
  },
]

export default PlatformPortalRoutes
