import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import { AccessibleSpaceType, PublicAccesses, SimpleDisplayObject } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

class PermissionService {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }
    subRoute = '/access-service'

    createPublicAccess = (params: {
        building_id: string,
        user_category_id: string,
        permission: {
            permission_type_id: string,
            permission_level: number,
            timezone: string,
            allow_locations: string[],
            exclude_locations: string[],
            mode: string,
            date_range: string[],
            exact_dates: string[],
            exclude_dates: string[],
            time_range: string[],
        },
    }) => this.client.post<APIHTTPResponse<PublicAccesses>>(`${this.subRoute}/pa/permission-control/public-accesses`, params)
        .then(parseResult)
        .catch(throwError)

    createUserAccessibleSpaceType = (params: {
        building_id: string,
        floor_id: string,
        space_type_id: string,
        user_category_id: string,
    }) => this.client.post<APIHTTPResponse<AccessibleSpaceType>>(`${this.subRoute}/pa/permission-control/accessible-space-types`, params)
        .then(parseResult)
        .catch(throwError)

    deletePublicAccess = (params: { id: string }) =>
        this.client.delete<APIHTTPResponse<any>>(`${this.subRoute}/pa/permission-control/public-accesses/${params?.id}`)
            .then(parseResult)
            .catch(throwError)

    deleteUserAccessibleSpaceType = (params: { id: string }) =>
        this.client.delete<APIHTTPResponse<any>>(`${this.subRoute}/pa/permission-control/accessible-space-types/${params?.id}`)
            .then(parseResult)
            .catch(throwError)

    getSpaceTypesByPermissionType = (params: { id: string }) =>
        this.client.get<APIHTTPResponse<SimpleDisplayObject>>(`${this.subRoute}/pa/permission-control/permission-types/${params?.id}/space-types`)
            .then(parseResult)
            .catch(throwError)

    getUserAccessibleSpaceTypes = (params: { building_id: string, user_category_id: string }) =>
        this.client.get<APIHTTPResponse<AccessibleSpaceType>>(`${this.subRoute}/pa/permission-control/accessible-space-types`, { params })
            .then(parseResult)
            .catch(throwError)

    getPublicAccesses = (params: { building_id: string, user_category_id: string }) =>
        this.client.get<APIHTTPResponse<PublicAccesses>>(`${this.subRoute}/pa/permission-control/public-accesses`, { params })
            .then(parseResult)
            .catch(throwError)
}

export default PermissionService