import { AxiosInstance } from 'axios'
import * as R from 'ramda'
import {
  ListRequest,
  LocationPermission,
  SimpleBuilding,
  SimpleCampus,
  SimpleFloor,
  SimpleSpace,
  SpaceType,
  Tenant,
  User,
  UserGroup,
  PropertyMgmtCompany,
  SimpleDisplayObject,
  SpecialAccessRecord,
  TransactionRequired,
  TransactionRequest
} from '../../../schema'
import { PMC_MANAGEMENT_ROUTE, ACCESS_ROUTE } from '../../config'
import APIRestfulProvider, {
  APIHTTPResponse,
  PaginatedResponse,
  throwError,
  parseResults,
  convertToPaginatedResult
} from '../../restful-provider'


const PERMISSIONS_ROUTE = `${ACCESS_ROUTE}${PMC_MANAGEMENT_ROUTE}/accesses/permissions`
const LOCATIONS_ROUTE = `${PMC_MANAGEMENT_ROUTE}/locations`
const TENANTS_ROUTE = `${PMC_MANAGEMENT_ROUTE}/tenants`
const USER_GROUPS_ROUTE = `${PMC_MANAGEMENT_ROUTE}/user-groups`
const USERS_ROUTE = `${PMC_MANAGEMENT_ROUTE}/users`
const ORDER_ROUTE = `/asset${PMC_MANAGEMENT_ROUTE}/orders`

/**
 * Restful endpoints for the folloing
 * Accesses
 * Locations
 * Tenants
 * UserGroups
 * Users
 */
class PropertiesManagementRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  // general
  getInfo = () =>
    this.client
      .get<APIHTTPResponse<PropertyMgmtCompany>>(`${PMC_MANAGEMENT_ROUTE}/info`)
      .then(parseResults)
      .catch(throwError)

  /** campusInfo start **/

  /////////////////////////////// Campus
  getCampuses = (id?: string) =>
    this.client
      .get<APIHTTPResponse<SimpleCampus>>(`${LOCATIONS_ROUTE}/campuses`, {
        params: { id },
      })
      .then(parseResults)

  /////////////////////////////// Building
  getBuildings = (id?: string) =>
    this.client
      .get<APIHTTPResponse<SimpleBuilding>>(`${LOCATIONS_ROUTE}/buildings`, { params: { id } })
      .then(parseResults)

  getBuildingsInCampus = (campus_id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleBuilding>>(`${LOCATIONS_ROUTE}/campuses/${campus_id}/buildings`)
      .then(parseResults)

  /////////////////////////////// Floor
  getFloorsInBuilding = (building_id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleFloor>>(`${LOCATIONS_ROUTE}/buildings/${building_id}/floors`)
      .then(parseResults)

  /////////////////////////////// Get Spaces 
  // TO-DO: withTenant will be removed.
  getSpacesInCampus = (campus_id: string, withTenant?: boolean, types?: SpaceType[], filters?: string) => {
    let params: any = { id: campus_id }

    if (types) {
      params = { ...params, types: types.join(',') }
    }
    if (!!filters) {
      params = { ...params, filters }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${LOCATIONS_ROUTE}/campuses/${campus_id}/spaces`, {
        params,
      })
      .then(parseResults)
  }

  // TO-DO: withTenant will be removed.
  getSpacesInBuilding = (building_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { id: building_id }

    if (types) {
      params = { ...params, types: types.join(',') }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${LOCATIONS_ROUTE}/buildings/${building_id}/spaces`, {
        params,
      })
      .then(parseResults)
  }

  // TO-DO: withTenant will be removed.
  getSpacesInFloor = (floor_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { id: floor_id }

    if (types) {
      params = { ...params, types: types.join(',') }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${LOCATIONS_ROUTE}/floors/${floor_id}/spaces`, {
        params,
      })
      .then(parseResults)
  }

  // TO-DO: withTenant will be removed.
  getSpacesInSpace = (space_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { space_id }

    if (types) {
      params = { ...params, types: types.join(',') }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${LOCATIONS_ROUTE}/spaces/${space_id}/spaces`, {
        params,
      })
      .then(parseResults)
  }

  getSpaceHierarchy = (ids: string[], types?: SpaceType[]) => {
    let params: any = { space_id: ids.join(',') }

    if (types) {
      params = { ...params, types: types.join(',') }
    }
    return this.client
      .get<APIHTTPResponse<any>>(`${LOCATIONS_ROUTE}/spaces/${ids.join(',')}/hierarchy`, {
        params,
      })
      .then(parseResults)
  }

  getSpaceTypes = (filterType?: string) =>
    this.client
      .get<APIHTTPResponse<SimpleDisplayObject>>(
        `${LOCATIONS_ROUTE}/space-types${filterType ? `?filterFor=${filterType}` : ''}`,
      )
      .then(parseResults)
      .catch(throwError)

  /** campusInfo end **/

  /** tenants start **/
  getTenantById = (id: string) =>
    this.client
      .get<APIHTTPResponse<Tenant>>(`${TENANTS_ROUTE}/${id}`)
      .then(parseResults)
      .catch(throwError)

  getTenantList = (occupy?: boolean) =>
    this.client
      .get<APIHTTPResponse<Tenant>>(`${TENANTS_ROUTE}`, {
        params: occupy !== undefined ? { occupy } : {},
      })
      .then(parseResults)
      .catch(throwError)

  getTenantListByCampus = (campus_id: string) =>
    this.client
      .get<APIHTTPResponse<Tenant>>(`${TENANTS_ROUTE}`, {
        params: { campus_id },
      })
      .then((e) => {
        return convertToPaginatedResult(e)
      })
      .catch(throwError)

  getTenantListByBuilding = (building_id: string) =>
    this.client
      .get<APIHTTPResponse<Tenant>>(`${TENANTS_ROUTE}`, {
        params: { building_id },
      })
      .then(parseResults)
      .catch(throwError)

  getAdminsInTenant = (tenant_id: string) =>
    this.client
      .get<APIHTTPResponse<Tenant>>(`${TENANTS_ROUTE}/${tenant_id}/users`, {
        params: { tenant_id, type: 'admin' },
      })
      .then(parseResults)
      .catch(throwError)

  getNumberOfStaff = (id: string) =>
    this.client
      .get<APIHTTPResponse<{ number_of_staff: number }>>(`${TENANTS_ROUTE}/${id}/users`, {
        params: { id, mode: 'count' },
      })
      .then(parseResults)
      .catch(throwError)

  getUsersInTenant = (tenant_id: string) =>
    this.client
      .get<APIHTTPResponse<Tenant>>(`${TENANTS_ROUTE}/${tenant_id}/users`, {
        params: { tenant_id },
      })
      .then(parseResults)
      .catch(throwError)

  /** tenants end **/

  /** user start**/

  getUserById = (id: string) =>
    this.client
      .get<APIHTTPResponse<User>>(`${USERS_ROUTE}/${id}`, { params: { user_id: id } })
      .then(parseResults)
      .catch(throwError)

  getUsersList = (params?: ListRequest) =>
    this.client
      .get<PaginatedResponse<User>>(`${USERS_ROUTE}`, {
        params,
      })
      .then((d) => d.data)
      .catch(throwError)

  addUser = (phone_number: string) =>
    this.client
      .post<APIHTTPResponse<any>>(`${USERS_ROUTE}`, {
        username: phone_number,
      })
      .then(parseResults)
      .catch(throwError)

  dropUser = (user_id: string) =>
    this.client.delete<APIHTTPResponse<any>>(`${USERS_ROUTE}/${user_id}`).then(parseResults).catch(throwError)

  getUserGroups = (id?: string) =>
    this.client
      .get<APIHTTPResponse<UserGroup>>(`${USER_GROUPS_ROUTE}`, { params: id ? { id } : null })
      .then(parseResults)
      .catch(throwError)

  getUsersInUserGroup = (user_group_id: string, params?: ListRequest) =>
    this.client
      .get<PaginatedResponse<User>>(`${USER_GROUPS_ROUTE}/${user_group_id}/users`, {
        params: { mode: 'include_user_group', ...params },
      })
      .then((d) => d.data)
      .catch(throwError)

  getUsersNotInUserGroup = (user_group_id: string, params?: ListRequest) =>
    this.client
      .get<PaginatedResponse<User>>(`${USER_GROUPS_ROUTE}/${user_group_id}/users`, {
        params: { mode: 'exclude_user_group', ...params },
      })
      .then((d) => d.data)
      .catch(throwError)

  addUserToUserGroup = (user_id: string, user_group_id: string) =>
    this.client
      .post<APIHTTPResponse<User>>(`${USER_GROUPS_ROUTE}/${user_group_id}/users`, { user_id, user_group_id })
      .then(parseResults)
      .catch(throwError)

  dropUserFromUserGroup = (user_id: string, user_group_id: string) =>
    this.client
      .delete<APIHTTPResponse<User>>(`${USER_GROUPS_ROUTE}/${user_group_id}/users/${user_id}`)
      .then(parseResults)
      .catch(throwError)

  /** user end **/

  /** accesses start **/

  setAccessPermission = (user_id: string, allow: string[], exclude: string[] = []) =>
    this.client
      .post<
        APIHTTPResponse<{
          allow: LocationPermission[],
          exclude: LocationPermission[],
          user_id: string,
        }>
      >(`${PERMISSIONS_ROUTE}/generic/users/${user_id}`, {
        allow,
        exclude,
      })
      .then(parseResults)
      .catch(throwError)

  createUserSpecialAccess = (user_id: string, accesses: string[]) =>
    this.client
      .post<APIHTTPResponse<Tenant>>(`${PERMISSIONS_ROUTE}/special/users/${user_id}`, {
        accesses,
      })
      .then(parseResults)
      .catch(throwError)

  editUserSpecialAccess = (user_id: string, accesses: string[]) =>
    this.client
      .put<APIHTTPResponse<Tenant>>(
        `${PERMISSIONS_ROUTE}/special/users/${user_id}`,
        { accesses },
        { params: { user_id } },
      )
      .then(parseResults)
      .catch(throwError)

  deleteUserSpecialAccess = (user_id: string) =>
    this.client
      .delete<APIHTTPResponse<Tenant>>(`${PERMISSIONS_ROUTE}/special/users/${user_id}`, {
        params: { user_id },
      })
      .then(parseResults)
      .catch(throwError)

  verifyUser = (tenant_id: string, area_code: string, phone_number: string) =>
    this.client
      .get<APIHTTPResponse<Tenant>>(`${TENANTS_ROUTE}/${tenant_id}/users`, {
        params: { tenant_id, area_code, phone_number },
      })
      .then(parseResults)
      .catch(throwError)

  getTenantSpecialAccess = (tenant_id: string) =>
    this.client
      .get<APIHTTPResponse<SpecialAccessRecord>>(
        `${PERMISSIONS_ROUTE}/special/tenants/${tenant_id}/users`,
        {
          params: { tenant_id },
        },
      )
      .then(parseResults)
      .catch(throwError)

  getUserSpecialAccess = (user_id: string) =>
    this.client
      .get<APIHTTPResponse<SpecialAccessRecord>>(
        `${PERMISSIONS_ROUTE}/special/users/${user_id}`,
        {
          params: { user_id },
        },
      )
      .then(parseResults)
      .catch(throwError)

  /** accesses end **/

  getTransactionList = (campusId: string, params: TransactionRequired) =>
    this.client
      .get<PaginatedResponse<TransactionRequest>>(`${ORDER_ROUTE}`, {
        params: {
          campus_id: campusId,
          ...R.reject(
            R.either(R.isEmpty, R.isNil),
            R.pick(
              ['from_time', 'to_time', 'page_number', 'page_size', 'order_by', 'sort_by', 'order_no', 'state'],
              params,
            ),
          ),
        },
      })
      .then((d) => d.data)
      .catch(throwError)

}

export default PropertiesManagementRestful
