import * as R from 'ramda'
import { AxiosInstance } from 'axios'
import APIRestfulProvider, {
  APIHTTPResponse,
  throwError,
  PaginatedResponse,
  parseResults,
  parseResult,
} from '../../restful-provider'
import {
  MerchantsRequired,
  Merchants,
  MerchantsNew,
  MerchantTypeList,
  MerchantsSubTypeNew,
  MerchantsDetail,
  MerchantConfig,
  Merchant,
  NewMerchant
} from '@exaleap/common'

import { LEASING_MANAGEMENT_ROUTE, PMC_MANAGEMENT_ROUTE, ANONYMOUS_USER_ROUTE } from '../../config'


/**
 * Restful endpoints for Visitor System module
 */
class HTMemberServices {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }


  subRoute = `/merchant-management${LEASING_MANAGEMENT_ROUTE}`
  subRoutePM = `/merchant-management${PMC_MANAGEMENT_ROUTE}`
  subRouteA = `/merchant-management${ANONYMOUS_USER_ROUTE}`

  // with-simple-mode: false 列表，true 用于其他模块的下拉选择项 等同于 之前的saerch 接口
  getMerchantsByPage = (campus_ids: string, params: MerchantsRequired) => {
    const args = R.reject(R.isEmpty, R.pick(['page_number', 'page_size', 'order_by', 'sort_by', 'search', 'merchant_subtype_ids'], params))
    const location_types = campus_ids !== 'unoccupied' ? 'IN_CAMPUS' : 'OTHER_LOCATION'
    const campus_id = campus_ids !== 'unoccupied' ? { campus_ids } : {}
    return this.client
      .get<PaginatedResponse<Merchants>>(`${this.subRoute}/merchants`, {
        params: {
          ['with-simple-mode']: false,
          ...campus_id,
          ...args,
          merchant_type_ids:
            params?.merchant_type_id && params?.merchant_type_id !== 'all' ? params?.merchant_type_id : null,
          location_types,
        }
      })
      .then((d) => d.data)
      .catch(throwError)
  }

  getMerchantsByPagePmc = (campus_ids: string, params: MerchantsRequired) => {
    const args = R.reject(R.isEmpty, R.pick(['page_number', 'page_size', 'order_by', 'sort_by', 'search', 'merchant_subtype_ids'], params))
    const location_types = campus_ids !== 'unoccupied' ? 'IN_CAMPUS' : 'OTHER_LOCATION'
    const campus_id = campus_ids !== 'unoccupied' ? { campus_ids } : {}
    return this.client
      .get<PaginatedResponse<Merchants>>(`${this.subRoutePM}/merchants`, {
        params: {
          ['with-simple-mode']: false,
          ...campus_id,
          ...args,
          merchant_type_ids:
            params?.merchant_type_id && params?.merchant_type_id !== 'all' ? params?.merchant_type_id : null,
          location_types,
        }
      })
      .then((d) => d.data)
      .catch(throwError)
      .catch(throwError)
  }

  getMerchantDetailById = (id: string) => {
    return this.client
      .get<APIHTTPResponse<MerchantsDetail>>(`${this.subRoute}/merchants/${id}`)
      .then(parseResult)
      .catch(throwError)
  }

  getMerchantDetailByIdPcm = (id: string) => {
    return this.client
      .get<APIHTTPResponse<MerchantsDetail>>(`${this.subRoutePM}/merchants/${id}`)
      .then(parseResult)
      .catch(throwError)
  }

  getMerchantTypes = () => {
    return this.client
      .get<PaginatedResponse<Merchants>>(`${this.subRouteA}/merchant-types`)
      .then(parseResults)
      .catch(throwError)
  }

  getMerchantSubTypes = (type_id: string) => {
    return this.client
      .get<PaginatedResponse<Merchants>>(`${this.subRouteA}/merchant-types/${type_id}/subtypes`)
      .then(parseResults)
      .catch(throwError)
  }

  addMerchant = (params: MerchantsNew) => {
    return this.client
      .post<APIHTTPResponse<MerchantsSubTypeNew>>(`${this.subRoute}/merchants`, params)
      .then(parseResult)
      .catch(throwError)
  }

  updateMerchant = (id: string, params: MerchantsNew, need_notification?: boolean) => {
    return this.client
      .put<APIHTTPResponse<MerchantsSubTypeNew>>(`${this.subRoute}/merchants/${id}?need_notification=${need_notification}`, params)
      .then(parseResult)
      .catch(throwError)
  }

  getMerchantSubTypesList = (params) => {
    const args = R.reject(R.isEmpty, R.pick(['page_number', 'page_size', 'order_by', 'sort_by', 'active'], params))
    return this.client
      .get<PaginatedResponse<MerchantTypeList>>(`${this.subRoute}/merchant-subtypes`, {
        params: args
      })
      .then((d) => d.data)
      .catch(throwError)
  }

  addMerchantSubType = (params) => {
    return this.client
      .post<APIHTTPResponse<MerchantsSubTypeNew>>(`${this.subRoute}/merchant-subtypes`, params)
      .then(parseResult)
      .catch(throwError)
  }

  updateMerchantSubType = (id, params) => {
    return this.client
      .put<APIHTTPResponse<MerchantsSubTypeNew>>(`${this.subRoute}/merchant-subtypes/${id}`, params)
      .then(parseResult)
      .catch(throwError)
  }

  uploadImage = (file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ url: string, id: string }>>(`${this.subRoute}/files`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((d) => d.data.result)
      .catch(throwError)

  // 组务管理>帖子管理>新增资产 中的发行单位
  /****
   * is_build_in: true:虚拟商户  ,false: 实体商户, 默认不传是false
   * issued_only: true 已发布 
   */
  getMerchantsLeasing = (campus_ids?: string, is_build_in?: boolean) => {
    const is_build_in_obj = is_build_in === undefined ? {} : { is_build_in }
    const params = {
      campus_ids,
      issued_only: true,
      ...is_build_in_obj,
      ['with-simple-mode']: true,
    }
    return this.client
      .get<APIHTTPResponse<NewMerchant>>(`${this.subRoute}/merchants`, { params })
      .then(parseResults)
      .catch(throwError)
  }

  // merchants/leasing/search
  getMerchants = (campus_ids: string, is_build_in?: boolean) => {
    const args = {
      campus_ids,
      is_build_in: is_build_in || false,
      ['with-simple-mode']: true,
    }
    return this.client
      .post<APIHTTPResponse<Merchant>>(`${this.subRoute}/merchants`, args)
      .then(parseResults)
      .catch(throwError)
  }

  getMerchantConfigById = (merchant_id: string) => {
    return this.client
      .get<APIHTTPResponse<MerchantConfig>>(`${this.subRoute}/merchants/${merchant_id}/configs`)
      .then(parseResults)
      .catch(throwError)
  }

  getMerchantConfigByIdPmc = (merchant_id: string) => {
    return this.client
      .get<APIHTTPResponse<MerchantConfig>>(`${this.subRoutePM}/merchants/${merchant_id}/configs`)
      .then(parseResults)
      .catch(throwError)
  }

  updateMerchantConfigById = (merchant_id: string, params: Record<string, Record<string, string | boolean>[]>) => {
    return this.client
      .post<APIHTTPResponse<MerchantConfig>>(`${this.subRoute}/merchants/${merchant_id}/configs`, params)
      .then(parseResult)
      .catch(throwError)
  }
}

export default HTMemberServices
