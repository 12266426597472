import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import { User, PropertyMgmtCompany, SpaceType, AccessRecordData } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for Visitor System module
 */

export interface CreateCompanyParams {
    name: string,
    display_name: string,
    description: string,
}

class AccessRecordService {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    subRoute = '/access-control'

    recordsByUser = (user_id: string, start_time: number, end_time: number, limit: number) =>
        this.client
            .get<APIHTTPResponse<AccessRecordData>>(`${this.subRoute}/access-records-by-user-id`, {
                params: {
                    id: user_id,
                    start_time,
                    end_time,
                    limit,
                },
            })
            .then(parseResult)
            .catch(throwError)

    recordsBySpace = (space_id: string, start_time: number, end_time: number, limit: number) =>
        this.client
            .get<APIHTTPResponse<AccessRecordData>>(`${this.subRoute}/access-records-by-space-id`, {
                params: {
                    id: space_id,
                    start_time,
                    end_time,
                    limit,
                },
            })
            .then(parseResult)
            .catch(throwError)

    recordsCountBySpace = (space_id: string, start_time: number, end_time: number, limit: number) =>
        this.client
            .get<APIHTTPResponse<{ count: number }>>(`${this.subRoute}/access-record-count-by-space-id`, {
                params: {
                    id: space_id,
                    start_time,
                    end_time,
                    limit,
                },
            })
            .then(parseResult)
            .catch(throwError)

    recordsByBuilding = (
        building_id: string,
        start_time: number,
        end_time: number,
        limit: number,
        space_types?: SpaceType[],
    ) => {
        const params: any = {
            id: building_id,
            start_time,
            end_time,
            limit,
        }

        if (space_types) {
            params.space_types = space_types?.join(',')
        }
        
        return this.client
            .get<APIHTTPResponse<AccessRecordData>>(`${this.subRoute}/access-records-by-building-id`, {
                params
            })
            .then(parseResult)
            .catch(throwError)
    }

    recordsCountByBuilding = (
        building_id: string,
        start_time: number,
        end_time: number,
        limit: number,
        space_types: SpaceType[],
    ) =>
        this.client
            .get<APIHTTPResponse<{ count: number }>>(`${this.subRoute}/access-record-count-by-building-id`, {
                params: {
                    id: building_id,
                    start_time,
                    end_time,
                    limit,
                    space_types: space_types.join(','),
                },
            })
            .then(parseResult)
            .catch(throwError)

    recordsByCampus = (
        campus_id: string,
        start_time: number,
        end_time: number,
        limit: number,
        space_types: SpaceType[],
    ) =>
        this.client
            .get<APIHTTPResponse<AccessRecordData>>(`${this.subRoute}/access-records-by-campus-id`, {
                params: {
                    id: campus_id,
                    start_time,
                    end_time,
                    limit,
                    space_types: space_types.join(','),
                },
            })
            .then(parseResult)
            .catch(throwError)

    recordsCountByCampus = (
        campus_id: string,
        start_time: number,
        end_time: number,
        limit: number,
        space_types: SpaceType[],
    ) =>
        this.client
            .get<APIHTTPResponse<{ count: number }>>(`${this.subRoute}/access-record-count-by-campus-id`, {
                params: {
                    id: campus_id,
                    start_time,
                    end_time,
                    limit,
                    space_types: space_types.join(','),
                },
            })
            .then(parseResult)
            .catch(throwError)
}

export default AccessRecordService
