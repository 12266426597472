import { ACCESS_POLICY } from '@exaleap/common'

const CmsRoutes = [
  {
    path: '/app/leasing-mgmt/content-mgmt/cms/:contentType/:id',
    element: () => import('./Content'),
    auth: [ACCESS_POLICY.leasingManagement.contentService.fullAccess, ACCESS_POLICY.leasingManagement.contentService.editAccess],
  },
  {
    path: '/app/leasing-mgmt/content-mgmt/cms',
    element: () => import('./ContentManagement'),
    auth: [ACCESS_POLICY.leasingManagement.contentService.fullAccess, ACCESS_POLICY.leasingManagement.contentService.editAccess],
  },

]

export default CmsRoutes
