import { MerchantType, Types } from '../../schema'
import { ACTION_KEY_HT, Action } from '../actions'

interface htState {
  merchantType: MerchantType[],
  rewardRuleAllTypes: {
    rewardRuleTypes: Types[],
    ruleTypes: Types[],
    rewardTypes: Types[],
    rewardLimitTypes: Types[],
  },
}

const initialState: htState = {
  merchantType: [],
  rewardRuleAllTypes: {
    rewardRuleTypes: [],
    ruleTypes: [],
    rewardTypes: [],
    rewardLimitTypes: [],
  },
}

const HTReducer = function (state = initialState, action: Action): htState {
  if (!action.payload) return state

  switch (action.type) {
    case ACTION_KEY_HT.MERCHANT_TYPE.SUCCESS: {
      const merchantType = action.payload
      return {
        ...state,
        merchantType,
      }
    }
    case ACTION_KEY_HT.REWARD_RULE_TYPES.SUCCESS: {
      const rewardRuleAllTypes = action.payload
      return {
        ...state,
        rewardRuleAllTypes,
      }
    }
    default: {
      return state
    }
  }
}

export default HTReducer
