import React from 'react'
import { useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'

import { useStateHooks } from 'contexts/provider/StateProvider'

import { NotificationObject } from '@exaleap/common/src/redux/reducers/NotificationReducer'
import { intl } from '../util/IntlHelper'

let displayed = []

const Notifier = () => {
    const { notiExit } = useStateHooks()
    const notifications = useSelector((store: any) =>
        store.notification ? store.notification.notifications || [] : [],
    )
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const storeDisplayed = id => {
        displayed = [...displayed, id]
    }

    const removeDisplayed = id => {
        displayed = [...displayed.filter(key => id !== key)]
    }

    React.useEffect(() => {
        notifications.forEach((notificaiton: NotificationObject) => {
            const { key, action, message, variant, dismissed = false } = notificaiton
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key)
                return
            }

            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) return
            const defaultMessage = intl.formatMessage({ id: 'api.error.generic' }, { code: message.code + ' - ' + message.message || '' })

            let finalMessage = message

            if (variant === 'error') {
                finalMessage = message?.code
                    ? intl.formatMessage({
                        id: `api.error.${message.code}`,
                        defaultMessage,
                    })
                    : message.message || String(message)
            }
            // display snackbar using notistack
            enqueueSnackbar(finalMessage, {
                key,
                variant: variant,
                onClose: () => null,
                onExited: (event, myKey) => {
                    // removen this snackbar from redux store
                    notiExit(myKey)
                    removeDisplayed(myKey)
                },
                action
            })

            // keep track of snackbars that we've displayed
            storeDisplayed(key)
        })
    }, [notifications, closeSnackbar, enqueueSnackbar])

    return null
}

export default Notifier
