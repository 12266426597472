import { ACTION_KEY_DASHBOARD, Action } from './../actions'
import { SimpleCampus } from '../../schema'

export interface DashboardState {
    campuses: SimpleCampus[],
    campusDetail?: SimpleCampus,
}

const defaultState: DashboardState = {
    campuses: [],
    campusDetail: null
}

const DashboardReducer = (state = defaultState, action: Action) => {
    switch (action.type) {
        case ACTION_KEY_DASHBOARD.CAMPUS_ANALYTICS.SUCCESS:
            return {
                ...state,
                campuses: action.payload.campuses,
                campusDetail: action.payload.detail
            }


        default: {
            return state
        }
    }
} 

export default DashboardReducer
