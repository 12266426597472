import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/functions'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyAgZAsgqzYmux8bdTyS9RHT0Y85seSROEs',
    authDomain: 'henderson-smart-platform.firebaseapp.com',
    projectId: 'henderson-smart-platform',
    storageBucket: 'henderson-smart-platform.appspot.com',
    messagingSenderId: '796604948908',
    appId: '1:796604948908:web:938d19716013d47d405a09',
    measurementId: 'G-SK8XZ0CPTW',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export default firebase
