import * as R from 'ramda'
import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, PaginatedResponse } from '../../restful-provider'
import {
  SimpleBuilding,
  SimpleFloor,
  SimpleSpace,
  WorkOrderListItem,
  WorkOrderStatusObject,
  WorkOrderType,
  SimpleBase,
  CampusWorkOrderOverview,
  WorkOrderFilterStatusEnum,
  WorkOrderDetailItem,
} from '../../../schema'

import { REGISTER_USER_ROUTE, PMC_MANAGEMENT_ROUTE } from '../../config'

export type CreateBuildingResponse = { campus_id: string, building: SimpleBuilding }
export type CreateSpaceInCampusResponse = { campus_id: string, space: SimpleSpace }

export type CreateFloorResponse = { building_id: string, floor: SimpleFloor }
export type CreateSpaceInBuildingResponse = { building_id: string, space: SimpleSpace }

export type CreateSpaceInFloorResponse = { floor_id: string, space: SimpleSpace }
export type CreateSpaceInSpaceResponse = { space_id: string, space: SimpleSpace }

export type CreateFloorParam = {
  building_id: string,
  display_name: string,
  idx: number,
}

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

const route = '/work-order'
const REGISTER_USER_SUBROUTE = `${route}${REGISTER_USER_ROUTE}`
const PMC_MANAGEMENT_SUBROUTE = `${route}${PMC_MANAGEMENT_ROUTE}`

/**
 * Restful endpoints for BMS module
 */
class WorkOrderRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }
  // subRoute = '/work-order/properties-management-service/work-order-management'

  getStatuses = () =>
    this.client.get<APIHTTPResponse<WorkOrderStatusObject>>(`${REGISTER_USER_SUBROUTE}/statuses`).then(parseResult)

  getTypes = () => this.client.get<APIHTTPResponse<WorkOrderType>>(`${REGISTER_USER_SUBROUTE}/types`).then(parseResult)

  getRepairAreaTypes = () =>
    this.client.get<APIHTTPResponse<SimpleBase>>(`${REGISTER_USER_SUBROUTE}/repair-area-types`).then(parseResult)

  getOrder = (order_id: string) =>
    this.client
      .get<PaginatedResponse<WorkOrderDetailItem>>(`${PMC_MANAGEMENT_SUBROUTE}/orders/${order_id}`)
      .then(parseResult)

  getOrders = (params) => {
    const args = R.reject(
      R.isEmpty,
      R.pick(
        [
          'display_name',
          'page_number',
          'page_size',
          'type_ids',
          'campus_id',
          'repair_area_type_ids',
          'status_id',
          'building_id',
        ],
        params,
      ))
    return this.client
      .get<PaginatedResponse<WorkOrderListItem>>(`${PMC_MANAGEMENT_SUBROUTE}/orders`, {
        params: args,
      })
      .then((d) => d.data)
  }

  getOrderStatusCounts = () =>
    this.client
      .get<APIHTTPResponse<CampusWorkOrderOverview>>(`${PMC_MANAGEMENT_SUBROUTE}/orders?type=status_summary`)
      .then(parseResult)
}

export default WorkOrderRestful
