import { RequestActionFunctions, action } from './index'
import { SimpleCampus, Tenant, SimpleSpace, User } from '../../schema'
import { createRequestActionStrings } from './helper'

// Tenant Related
export const ACTION_KEY_PROPERTY_MGMT_TENANT = {
    TENANT_LISTING: createRequestActionStrings('PROPERTY_MGMT_TENANT_LISTING'),
    TENANT_DETAIL: createRequestActionStrings('PROPERTY_MGMT_TENANT_DETAIL'),
    TENANT_DETAIL_SAVE: createRequestActionStrings('PROPERTY_MGMT_TENANT_DETAIL_SAVE'),
    TENANT_DETAIL_ASSOCIATE_SPACE: createRequestActionStrings('PROPERTY_MGMT_TENANT_DETAIL_ASSOCIATE_SPACE'),
    TENANT_DETAIL_UNASSOCIATE_SPACE: createRequestActionStrings('PROPERTY_MGMT_TENANT_DETAIL_UNASSOCIATE_SPACE'),


}

// Tenant List
const tenantList: RequestActionFunctions = {
    request: () => action(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_LISTING.REQUEST),
    success: (tenants: Tenant[]) => action(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_LISTING.SUCCESS, tenants),
    failure: (error) => action(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_LISTING.FAILURE, error),
}

// Tenant Detail
const tenantDetail: RequestActionFunctions = {
    request: (id) => action(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL.REQUEST, id),
    success: (tenant: Tenant, spaces: SimpleSpace[]) => action(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL.SUCCESS, { tenant, spaces }),
    failure: (error) => action(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL.FAILURE, error),
}


// Tenant Detail
const tenantDetailSave: RequestActionFunctions = {
    request: (tenant: Tenant) => action(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL_SAVE.REQUEST, tenant),
    success: (tenant: Tenant) => action(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL_SAVE.SUCCESS, tenant),
    failure: (error) => action(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL_SAVE.FAILURE, error),
}

// Tenant Detail Associate Space
const tenantDetailAssociate: RequestActionFunctions = {
    request: (id: string, space_ids: string[]) => action(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL_ASSOCIATE_SPACE.REQUEST, { id, space_ids }),
    success: (spaces: SimpleSpace[]) => action(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL_ASSOCIATE_SPACE.SUCCESS, spaces),
    failure: (error) => action(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL_ASSOCIATE_SPACE.FAILURE, error),
}

// Tenant Detail Unassociate Space
const tenantDetailUnassociate: RequestActionFunctions = {
    request: (id: string, space_ids: string[]) => action(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL_UNASSOCIATE_SPACE.REQUEST, { id, space_ids }),
    success: (space_ids: string[]) => action(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL_UNASSOCIATE_SPACE.SUCCESS, space_ids),
    failure: (error) => action(ACTION_KEY_PROPERTY_MGMT_TENANT.TENANT_DETAIL_UNASSOCIATE_SPACE.FAILURE, error),
}


export const PropertyManagementActions = {
    tenant: {
        tenantList,
        tenantDetail,
        tenantDetailSave,
        tenantDetailAssociate,
        tenantDetailUnassociate,
    },

}
