import {
    SimpleSpace,
    SimpleBase,
} from '../../../schema'
import { ACTION_KEY_BMS } from '../../actions/bms'
import { Action } from '../../actions'

interface SpaceState {
    // reuqest login status
    success: boolean,
    loading: boolean,
    error: any,

    spaces: SimpleSpace[],
    spaceDetail: {
        space: SimpleSpace,
        spaces: SimpleSpace[],
    },
    detailLocation: {
        building?: SimpleBase,
        floor?: SimpleBase,
        campus?: SimpleBase,
    },
}

const initialState: SpaceState = {
    success: false,
    loading: false,
    error: null,

    spaces: null,
    spaceDetail: {
        space: null,
        spaces: null
    },
    detailLocation: {},
}

const SpaceReducer = function(state = initialState, action: Action): SpaceState {
    switch (action.type) {
        case ACTION_KEY_BMS.SPACE_LIST.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                spaces: action.payload,
            }
        }

        case ACTION_KEY_BMS.SPACE_DETAIL.SUCCESS: {
            // const payLoadLocation = action.payload.location

            // const locationInState = payLoadLocation
            //     ? {
            //           building: payLoadLocation.building_id
            //               ? {
            //                     id: payLoadLocation.building_id,
            //                     name: payLoadLocation.building_name,
            //                 }
            //               : null,
            //           floor: payLoadLocation.floor_id
            //               ? {
            //                     id: payLoadLocation.floor_id,
            //                     name: payLoadLocation.floor_name,
            //                 }
            //               : null,
            //           campus: payLoadLocation.campus_id
            //               ? {
            //                     id: payLoadLocation.campus_id,
            //                     name: payLoadLocation.campus_name,
            //                 }
            //               : null,
            //       }
            //     : {}

            return {
                ...state,
                success: true,
                loading: false,
                spaceDetail: action.payload,
                // detailLocation: locationInState,
            }
        }

        case ACTION_KEY_BMS.SPACE_DETAIL.REQUEST: {
            return {
                ...state,
                success: false,
                loading: true,
                spaceDetail: {
                    space: null,
                    spaces: null
                },
                detailLocation: {}
            }
        }

        case ACTION_KEY_BMS.SPACE_DETAIL_CREATE_SPACE.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                spaceDetail: {
                    ...state.spaceDetail,
                    spaces: [...state.spaceDetail.spaces, action.payload],
                },
            }
        }

        default: {
            return state
        }
    }
}

export default SpaceReducer
