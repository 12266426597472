import { Dispatch } from 'react'
import { SimpleBuilding, SimpleMerchant, SimpleFloor } from '@exaleap/common'

export enum ActionType {
  ACTION_SET_BUILDINGS = 'ACTION_SET_BUILDINGS',
  ACTION_SET_MERCHANTS = 'ACTION_SET_MERCHANTS',
  ACTION_SET_MERCHANTS_HT = 'ACTION_SET_MERCHANTS_HT',
  ACTION_SET_FLOORS = 'ACTION_SET_FLOORS'
}

export type Action =
  | { type: ActionType.ACTION_SET_BUILDINGS, value: SimpleBuilding[] }
  | { type: ActionType.ACTION_SET_MERCHANTS, value: SimpleMerchant[] }
  | { type: ActionType.ACTION_SET_MERCHANTS_HT, value: SimpleMerchant[] }
  | { type: ActionType.ACTION_SET_FLOORS, value: Record<string, SimpleFloor[]> }

const leasingAction = (dispatch: Dispatch<Action>) => {
  const setBuildings = (value: SimpleBuilding[]) =>
    dispatch({
      type: ActionType.ACTION_SET_BUILDINGS,
      value,
    })

  const setMerchants = (value: SimpleMerchant[]) =>
    dispatch({
      type: ActionType.ACTION_SET_MERCHANTS,
      value,
    })

  const setFloors = (buildingId: string, value: SimpleFloor[]) => {
    const floorsObj = { [buildingId]: value }
    return dispatch({
      type: ActionType.ACTION_SET_FLOORS,
      value: floorsObj,
    })
  }


  return { setBuildings, setMerchants, setFloors }
}

export default leasingAction
