import { AxiosInstance } from 'axios'
import { ANONYMOUS_USER_ROUTE } from '../../config'
import APIRestfulProvider, { APIHTTPResponse, parseResult } from '../../restful-provider'

const SYSTEM_ROUTE = '/system'

/**
 * Restful endpoints for System related
 */
class SystemRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  info = () =>
    this.client
      .get<APIHTTPResponse<{ version: string }>>(`${ANONYMOUS_USER_ROUTE}${SYSTEM_ROUTE}/info`)
      .then(parseResult)
}

export default SystemRestful
