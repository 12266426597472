import {
    User,
    Tenant
} from '../../../schema'

import { Action, ACTION_KEY_TENANT } from '../../actions'

export interface TenantUserState {
    // reuqest login status
    success: boolean,
    loading: boolean,
    error: any,
    userList: User[],
    tenant: Tenant,
}

const initialState: TenantUserState = {
    success: false,
    loading: false,
    error: null,

    userList: null,
    tenant: null
}

const TenantUserReducer = function(state = initialState, action: Action): TenantUserState {
    switch (action.type) {
        case ACTION_KEY_TENANT.TENANT_USER_LISTING.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                userList: action.payload.users,
                tenant: action.payload.tenant
            }
        }

        case ACTION_KEY_TENANT.TENANT_ADD_USER.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                userList: [...state.userList, action.payload]
            }
        }

        case ACTION_KEY_TENANT.TENANT_REMOVE_USER.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                userList: state.userList.filter(u => u.id !== action.payload),
            }
        }

        case ACTION_KEY_TENANT.TENANT_STAFF_ASSOCIATE_SPACE.SUCCESS: {
            return {
                ...state,
                success: true,
                loading: false,
                userList: state.userList.map(u => {
                    // if (u.id === action.payload.user_id) {
                    //     u.allow.spaces = action.payload.spaces
                    // }
                    return u
                })
            }
        }

        default: {
            return state
        }
    }
}

export default TenantUserReducer
