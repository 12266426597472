import React, { useState, useEffect, Fragment, useMemo } from 'react'
import * as R from 'ramda'
import { NavLink, useNavigate, useLocation, matchPath } from 'react-router-dom'
import { formatTenantCode } from 'utils'
import { styled, Theme, useTheme } from '@mui/material/styles'
import {
  Collapse,
  List,
  ListItem,
  Icon,
  ListItemIcon,
  ListItemText,
  Select,
  MenuItem,
  IconButton,
  Menu,
  SvgIcon,
} from '@mui/material'

import {
  MuiIcon,
  MuiIconButton
} from '@exaleap/react-component-lib'

import { useSelector } from 'state/root-store'
import { useStateHooks } from 'contexts/provider/StateProvider'

import { ACCESS_POLICY, apiService, AuthChecking, AuthUser, hrdpActions, PropertyMgmtCompany, Tenant } from '@exaleap/common'

import { intl } from 'util/IntlHelper'
import { getValueWithLocale } from 'util/localeHelper'
import { replaceCapitalLetterAfterUnderscore } from 'util/stringHelper'

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme: { spacing, palette }, isActive }: { theme: Theme, isActive?: boolean }) => ({
  paddingLeft: spacing(5),
  paddingTop: spacing(0.5),
  paddingBottom: spacing(0.75),
  color: palette?.grey?.[300],
  fontSize: '22px',
  backgroundColor: palette?.background.paper,
  cursor: 'pointer',
  '&:hover,&:hover .material-icons, &.active, &.active .material-icons': {
    color: palette?.primary.main,
  },
  '& >svg': {
    fontSize: '20px',
  },
  ...isActive && {
    color: palette?.primary.main,
  }
}))

const StyledCollapse = styled(Collapse)(({ theme: { spacing } }: { theme: Theme }) => ({
  paddingLeft: spacing(8.5),
  '& .MuiList-padding': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))

const StyledSelect = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'longWidth',
})<{ longWidth?: boolean }>(({ theme: { spacing, palette }, longWidth }: { theme: Theme, longWidth?: boolean }) => ({
  paddingLeft: spacing(5),
  height: spacing(12),
  borderBottom: `2px solid ${palette?.primary?.main}`,
  width: longWidth ? 212 : 120,
  '& .MuiSvgIcon-root': {
    color: palette.primary.main,
  },
  '& .MuiSvgIcon-root:hover, &.MuiInput-underline:hover:not(.Mui-disabled):before, &.MuiInput-underline:before, &.MuiInput-underline:after': {
    borderBottom: 'none',
  },
}))

const StyledNavLink = styled(NavLink)(({ theme: { spacing, palette } }: { theme: Theme }) => ({
  color: `${palette?.grey?.[300]} !important`,
  fontSize: 14,
  padding: `${spacing(1.25)} ${spacing(0)} ${spacing(1.25)} ${spacing(0)}`,
  letterSpacing: '0.02857em',
  lineHeight: 1.5,
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  '&.active': {
    color: `${palette?.primary?.main} !important`,
  }
}))

const MenuItemList = ({ items }) => {
  const { spacing } = useTheme()
  const location = useLocation()
  const navigate = useNavigate()
  const [openKeys, setOpenKeys] = React.useState([])
  const handleClickMenu = key => {
    setOpenKeys(currKeys => {
      const isOpened = currKeys.indexOf(key) > -1
      return isOpened ? currKeys.filter(i => i !== key) || [] : [...currKeys, key]
    })
  }
  const isParentItemHighlight = item => {
    const link = item.link
    return location?.pathname.indexOf(link) > -1
  }

  // ---- fix collapse is not open by default, keep the below code once the api result is revised
  // useEffect(() => {
  //   if (!!location?.pathname && items?.length)
  //     setOpenKeys(
  //       items?.length ?
  //         items?.filter(e => !!matchPath({ path: `${e.link}/*` }, location?.pathname))
  //           .map((e) => e.name)
  //         : []
  //     )
  // }, [items?.length, location?.pathname])

  return (
    <List>
      {items?.map((c, i) => {
        const isActive = openKeys.indexOf(c.name) > -1
        const itemChildren = c?.children || []
        if (itemChildren.length > 0) {
          return (
            <Fragment key={c.name}>
              <StyledListItem
                onClick={() => handleClickMenu(c.name)}
                isActive={isParentItemHighlight(c)}
              >
                {!!c?.icon && (
                  <ListItemIcon style={{ minWidth: 30 }}>
                    <MuiIcon
                      iconName={replaceCapitalLetterAfterUnderscore(c.icon) as any}
                      style={{
                        fontSize: 22,
                        marginRight: spacing(3),
                      }}
                    />
                  </ListItemIcon>
                )}
                {!!c?.pathD && (
                  <ListItemIcon style={{ minWidth: 30 }}>
                    <SvgIcon style={{ fontSize: 22, marginRight: spacing(3) }}>
                      <path d={c.pathD} />
                    </SvgIcon>
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={
                    <span
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {intl.formatMessage({ id: c?.name })}
                    </span>
                  }
                />
                {isActive ? <MuiIcon iconName="ExpandLess" /> : <MuiIcon iconName="ExpandMore" />}
              </StyledListItem>
              <StyledCollapse
                in={isActive}
                timeout="auto"
                unmountOnExit
              >
                <MenuItemList items={itemChildren} />
              </StyledCollapse>
            </Fragment>
          )
        }
        return (
          <StyledListItem key={`menuItem_${i}`}
            onClick={() => { navigate(c?.link) }}
            isActive={isParentItemHighlight(c)}
          >
            {!!c?.icon && (
              <MuiIcon iconName={replaceCapitalLetterAfterUnderscore(c.icon) as any}
                style={{
                  fontSize: 22,
                  marginRight: spacing(3),
                }} />
            )}
            {!!c?.pathD && (
              <SvgIcon style={{ fontSize: 22, marginRight: spacing(3) }}>
                <path d={c.pathD} />
              </SvgIcon>
            )}
            <ListItemText
              primary={
                <span
                  style={{
                    fontSize: 14,
                  }}
                >
                  {intl.formatMessage({ id: c?.name })}
                </span>
              }
            />
          </StyledListItem>
        )
      })}
    </List>
  )
}

type CompanyInformation = {
  image: string,
  title: string | JSX.Element,
  desc: string,
}

const FunctionSelection = ({
  handleMouseLeave,
  onToggleCollapsedNav,
  navCollapsed,
  width,
}: {
  handleMouseLeave: () => void,
  onToggleCollapsedNav: () => void,
  navCollapsed: boolean,
  width: number,
}) => {
  const { dispatch } = useStateHooks()
  const { spacing, palette, breakpoints: { values: { lg } } } = useTheme()
  // const sections = createSelector([(s: any) => s.navigation], s => s)
  const isGetAuthRoute = localStorage.getItem('isGetAuthRoute')
  const sections: any[] = useSelector(s => s.navigation) as any[]
  const user: AuthUser = useSelector(s => s.auth.authUser)
  // const [sections,_] = useState([])
  const leasingTitle = useMemo(
    () =>
      R.includes('hendersonsmartplatform', window?.location?.hostname) ? (
        '恒基（中国）租务部'
      ) : (
        intl.formatMessage({ id: "leasing.title" })
      ),
    [],
  )

  const navigate = useNavigate()
  const location = useLocation()

  const [index, setIndex] = useState<number>(-1)

  const [tenant, setTenant] = useState<CompanyInformation>(null)
  const [leasing] = useState<CompanyInformation>({
    title: leasingTitle,
    desc: '',
    image: require('assets/images/leasing-logo-hld.jpg'),
  })
  const [apiv, setAPIV] = useState<string>(null)
  const [property, setProperty] = useState<CompanyInformation>(null)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const routePath = location?.pathname //useSelector(s => s.router.location.pathname)

  // default for selected main menu item
  useEffect(() => {
    // let pointIndex = -1
    // sections.forEach((section, ind) => {
    // const result = section.children.reduce((current, child) => {
    //   const res = current || !!matchPath(child.link routePath)
    //   return res
    // }, false)
    // if (result) {
    //   pointIndex = ind
    //   setIndex(ind)
    // } else {
    //   if (!!matchPath(section.link, routePath)) {
    //     pointIndex = ind
    //     setIndex(ind)
    //   }
    // }
    // })
    // if (pointIndex === -1) {
    //   // console.log('setIndex @ React.useEffect cannot find path', routePath)
    //   if (index === -1) {
    //     setIndex(0)
    //   }
    // }

    const matchedIndex = sections.findIndex(section => !!matchPath({ path: `${section.link}/*` }, routePath))
    if (matchedIndex !== -1) {
      setIndex(matchedIndex)
    } else {
      if (index === -1) {
        setIndex(0)
      }
    }
  }, [routePath, sections, index])

  useEffect(() => {
    // console.log('FunctionSelection:: LocationSelectActions.loadViewCampuses.request')

    apiService.platform.system.info().then(apiver => {
      setAPIV(apiver?.version)
    })
    // dispatch(LocationSelectActions.loadViewCampuses.request())
  }, [])

  // fetch leasing / property management company / tenant company information
  useEffect(() => {
    sections.forEach(section => {
      if (!!isGetAuthRoute && !!user) {
        if (section.name === 'sidebar.property_management' && !property) {
          apiService.platform.propertiesManagement.getInfo().then(cs => {
            if (cs?.length > 0) {
              const p: PropertyMgmtCompany = cs[0]
              setProperty({
                image: p.profile_picture_url,
                title: getValueWithLocale('display_name', section),
                desc: p.description,
              })
            }
          })
        }

        if (section.name === 'sidebar.leasing_management') {
          // apiService.leasing.
        }
        if (section.name === 'sidebar.tenant_services' && !tenant) {
          apiService.platform.tenantService.getTenantInfo().then(tenants => {
            if (tenants?.length > 0) {
              const t: Tenant = tenants?.[0]

              if (t) {
                setTenant({
                  image: t.profile_picture_url,
                  title: getValueWithLocale('display_name', t),
                  desc: `租户编号：${formatTenantCode(t.tenant_code)}`,
                })
              }
            }
          })
        }
      }
    })
  }, [sections, isGetAuthRoute, property, tenant, user])

  useEffect(() => {
    if (!!sections[index]?.children?.length)
      dispatch(hrdpActions.setCurrentMenuItems(sections[index]?.children))
  }, [sections[index]?.children])

  const companyInfo = i => {
    const section = sections[i]?.name
    if (section === 'sidebar.property_management') {
      return property
    }

    if (section === 'sidebar.leasing_management') {
      return leasing
    }
    if (section === 'sidebar.tenant_services') {
      return tenant
    }
  }

  const placeholderPath = i => {
    const section = sections[i]?.name
    if (section === 'sidebar.property_management') {
      return require('assets/images/placeholder-pmc-light.jpg')
    }

    if (section === 'sidebar.leasing_management') {
      return require('assets/images/leasing-logo-hld.jpg')
    }
    if (section === 'sidebar.tenant_services') {
      return require('assets/images/placeholder-tenant-light.jpg')
    }

    return require('assets/images/logo-exaleap.jpg')
  }
  const landCd = intl?.locale
  return (
    <div
      onMouseLeave={handleMouseLeave}
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      {sections.length > 1 && (
        <StyledSelect
          value={index === -1 ? '' : index}
          disabled={sections.length === 1}
          onChange={e => {
            const selectIndex = e.target.value as number
            setIndex(selectIndex)
            navigate(sections[selectIndex].link)
            // dispatch(LocationSelectActions.selectViewCampus(campuses.find(c => c.id ===e.target.value)))
          }}
          variant="standard"
          longWidth={landCd === 'en-US'}

        >
          {sections?.map((s, i) => (
            <MenuItem key={s.name} value={i}>
              {intl.formatMessage({ id: s.name })}
            </MenuItem>
          ))}
        </StyledSelect>
      )}

      {sections.length === 1 && (
        <ListItem style={{ fontSize: 16, color: '#000000', height: 48, borderBottom: `2px solid ${palette?.primary?.main}` }}>
          <Icon
            color="primary"
            style={{
              fontSize: 16,
              marginRight: spacing(3),
            }}
          >
            {sections[0].icon}
          </Icon>
          <span>{intl.formatMessage({ id: sections[0].name })}</span>
        </ListItem>
      )}

      {sections[index]?.name === 'sidebar.tenant_services' && companyInfo(index)?.image && (
        <img
          alt=""
          src={companyInfo(index)?.image || placeholderPath(index)}
          style={{ height: 260, width: '100%', objectFit: 'cover' }}
        />
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {companyInfo(index)?.title && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', padding: spacing(3.75) }}>
            <span style={{ fontSize: 14, fontWeight: 700, color: palette?.grey?.[100], textIndent: '6px' }}>
              {companyInfo(index)?.title}
            </span>
            <span style={{ fontSize: 10 }}>{companyInfo(index)?.desc}</span>
          </div>
        )}

        {sections[index]?.name === 'sidebar.tenant_services' &&
          companyInfo(index)?.title &&
          AuthChecking(user, [ACCESS_POLICY.tenantService.generalService.tenantInfo.fullAccess]) ? (
          <MuiIconButton
            iconName="MoreVert"
            onClick={event => {
              setAnchorEl(event.currentTarget)
            }}
          />
        ) : null}

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null)
          }}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null)
              navigate('/app/tenant/edit')
            }}
          >
            {intl.formatMessage({ id: "tenant.overview.edit" })}
          </MenuItem>
        </Menu>
        <div
          style={{
            zIndex: 1500,
            position: 'absolute',
            top: 0,
            right: 0,
            height: '48px',
            width: landCd === 'en-US' ? '48px' : '140px',
            borderBottom: `2px solid ${palette?.primary?.main}`,
          }}
        >
          {width > lg && (
            <IconButton onClick={() => onToggleCollapsedNav()} style={{ float: 'right', padding: spacing(3) }}>
              {!navCollapsed ? (
                <SvgIcon style={{ fontSize: 24 }}>
                  <path d="M18.41,7.41L17,6L11,12L17,18L18.41,16.59L13.83,12L18.41,7.41M12.41,7.41L11,6L5,12L11,18L12.41,16.59L7.83,12L12.41,7.41Z" />
                </SvgIcon>
              ) : (
                <SvgIcon style={{ fontSize: 24 }}>
                  <path d="M5.59,7.41L7,6L13,12L7,18L5.59,16.59L10.17,12L5.59,7.41M11.59,7.41L13,6L19,12L13,18L11.59,16.59L16.17,12L11.59,7.41Z" />
                </SvgIcon>
              )}
            </IconButton>
          )}
        </div>
      </div>

      <MenuItemList items={sections[index]?.children} />

      <p style={{ position: 'absolute', bottom: spacing(5), left: spacing(2.5), fontSize: 10, color: 'white' }}>API ver. {apiv}</p>
      <p style={{ position: 'absolute', bottom: 0, left: spacing(2.5), fontSize: 10 }}>v1.1.15</p>
    </div>
  )
}

const SideBarContent = props => {
  return <FunctionSelection {...props} />
}

export default SideBarContent
