import React from "react"
import { ACCESS_POLICY } from "@exaleap/common"

import { Navigate } from "react-router-dom"


const campusPolicies = [
  ACCESS_POLICY.platformManagement.campusManagement.fullAccess,
  ACCESS_POLICY.platformManagement.campusManagement.readOnly
]

const BMSRoutes = [
  {
    path: "/app/bms/campuses/:id",
    element: () => import("./CampusDetail"),
    auth: campusPolicies
  },
  {
    path: "/app/bms/floors/:id",
    element: () => import("./FloorDetail"),
    auth: campusPolicies
  },
  {
    path: "/app/bms/spaces/:id",
    element: () => import("./SpaceDetail"),
    auth: campusPolicies
  },
  {
    path: "/app/bms/campuses",
    element: () => import("./CampusList"),
    auth: campusPolicies
  },
  {
    path: "/app/bms/floors",
    element: () => import("./FloorList"),
    auth: campusPolicies
  },
  {
    path: "/app/bms/spaces",
    element: () => import("./SpaceList"),
    auth: campusPolicies
  },

  {
    path: "/app/bms",
    element: <Navigate to="/app/bms/campuses" />,
    auth: campusPolicies
  },
]

export default BMSRoutes
