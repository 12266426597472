import { AxiosInstance } from 'axios'
import * as R from 'ramda'
import {
  SimpleDisplayObject,
  HistoryRequired,
  Register,
  Statistics,
  Visitor
} from '../../../schema'
import { PMC_MANAGEMENT_ROUTE } from '../../config'
import APIRestfulProvider, {
  APIHTTPResponse,
  PaginatedResponse,
  parseResults,
  throwError,
} from '../../restful-provider'

class VisitorServiceEndpoints {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = `/visitor-service${PMC_MANAGEMENT_ROUTE}`

  getTenantsByFloor = (buildingId: string, floorIdx: number) =>
    this.client
      .get<APIHTTPResponse<SimpleDisplayObject>>(`${this.subRoute}/buildings/${buildingId}/floor/${floorIdx}`)
      .then(parseResults)
      .catch(throwError)

  history = (id: string, params: HistoryRequired) =>
    this.client
      .get<PaginatedResponse<Visitor>>(`${this.subRoute}/buildings/${id}/visitors`, {
        params: R.reject(
          R.either(R.isEmpty, R.isNil),
          R.pick([
            'end_time',
            'filter_by_time',
            'floor_index',
            'order_by',
            'page_number',
            'page_size',
            'search',
            'sort_by',
            'start_time',
            'status',
            'tenant_id',
          ], params),
        ),
      })
      .then(d => d.data)
      .catch(throwError)

  overview = () =>
    this.client
      .get<APIHTTPResponse<Statistics>>(`${this.subRoute}/overview`)
      .then(parseResults)
      .catch(throwError)

  register = (params: Register) =>
    this.client
      .post<APIHTTPResponse<{ id: string, visitor_id: string }>>(`${this.subRoute}/invitations`, params)
      .then(parseResults)
      .catch(throwError)

  remove = ({ invitation_id, id }: Visitor) =>
    this.client
      .delete<APIHTTPResponse<any>>(`${this.subRoute}/invitations/${invitation_id}/visitor/${id}`)
      .then(parseResults)
      .catch(throwError)

}

export default VisitorServiceEndpoints
