import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Drawer } from '@mui/material'
import { styled, Theme, useTheme } from '@mui/material/styles'

import { COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION } from '@exaleap/common'

import SideBarContent from './SideBarContent'
import { hrdpActions } from '@exaleap/common'

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'temporaryType',
})<{ temporaryType?: boolean }>(
  ({ temporaryType }: { temporaryType?: boolean }) => ({
    '.MuiDrawer-paper': {
      width: 260,
      borderRight: '0 none !important',
      backgroundColor: '#fff',
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.15)'
    },
    ...(temporaryType && {
      '& .MuiBackdrop-root': {
        background: 'none',
      },
    }),
  }),
)

const WrapperDrawer = styled(Box, {
  shouldForwardProp: prop => prop !== 'drawerStyle',
})<{ drawerStyle?: string }>
  (({ theme: { breakpoints }, drawerStyle }: { theme: Theme, drawerStyle: string }) => ({
    display: 'none',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    width: 260,
    minWidth: 260,
    maxWidth: 260,
    ...drawerStyle === FIXED_DRAWER ? {
      [breakpoints.up("lg")]: {
        display: 'flex',
      }
    } : drawerStyle === COLLAPSED_DRAWER ? {} : { display: 'flex' }
  }))

const SideBar = () => {
  const { breakpoints: { values: { lg } } } = useTheme()
  const dispatch = useDispatch()
  const { navCollapsed, drawerType, width, navigationStyle } = useSelector(({ settings }) => settings)

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(hrdpActions.updateWindowWidth(window.innerWidth))
      if (window.innerWidth < lg && drawerType.includes(FIXED_DRAWER)) {
        dispatch(hrdpActions.toggleCollapsedNav(false))
      }
    })
  }, [dispatch])

  useEffect(() => {
    if (width < lg) {
      dispatch(hrdpActions.toggleCollapsedNav(false))
    }
  }, [])

  const onToggleCollapsedNav = e => {
    const val = !navCollapsed
    if (!val && drawerType.includes(COLLAPSED_DRAWER)) {
      dispatch(hrdpActions.setDrawerType(FIXED_DRAWER))
      dispatch(hrdpActions.toggleCollapsedNav(val))
    }
    if (drawerType.includes(FIXED_DRAWER)) {
      if (width > lg) {
        dispatch(hrdpActions.setDrawerType(COLLAPSED_DRAWER))
      } else {
        dispatch(hrdpActions.toggleCollapsedNav(val))
      }
    }
  }

  let type: "persistent" | "temporary" | "permanent" = 'persistent'
  if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < lg)) {
    type = 'temporary'
  }

  if (navigationStyle === HORIZONTAL_NAVIGATION) {
    type = 'temporary'
  }

  const handleMouseLeave = (e: MouseEvent) => {
    // const e = window.event
    if (navCollapsed && (e.clientX >= 260 || e.clientX < 0 || e.clientY >= window.innerHeight || e.clientY < 0)) {
      dispatch(hrdpActions.toggleCollapsedNav(false))
    }
  }

  return (
    <WrapperDrawer drawerStyle={navigationStyle === HORIZONTAL_NAVIGATION ? '' : drawerType}>
      <StyledDrawer
        temporaryType={type === 'temporary'}
        variant={type}
        open={type === 'temporary' ? navCollapsed : !navCollapsed}
        onClose={onToggleCollapsedNav}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {/* <Toolbar variant="dense" style={{ backgroundColor: '#27AE60', color: 'white' }}>
                    恒基智能平台
                </Toolbar> */}
        {/* <CampusSelection /> */}
        {/* <UserInfo/> */}
        <SideBarContent
          handleMouseLeave={handleMouseLeave}
          navCollapsed={navCollapsed}
          onToggleCollapsedNav={onToggleCollapsedNav}
          width={width}
        />
      </StyledDrawer>
    </WrapperDrawer >
  )
}

export default SideBar

