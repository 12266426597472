export const POST_TYPE_ACTIVITY = 'activity'
export const POST_TYPE_PERK = 'perk'
export const POST_TYPE_NEWS = 'news'

export const POST_STATE_DRAFT = 'draft'
export const POST_STATE_WAIT_FOR_APPROVE = 'wait_for_approve'
export const POST_STATE_APPROVED = 'approved'
export const POST_STATE_LAUNCHED = 'launched'
export const POST_STATE_ENDED = 'ended'
export const POST_STATE_LOCKED = 'locked'

export const POST_ACTION_APPROVE = 'approve'
export const POST_ACTION_COMMIT = 'commit'
export const POST_ACTION_EDIT = 'edit'
export const POST_ACTION_END = 'end'
export const POST_ACTION_LAUNCH = 'launch'
export const POST_ACTION_LEAVE = 'leave'
export const POST_ACTION_PUSH_NOTIFICATION = 'push_notification'
export const POST_ACTION_REVOKE = 'revoke'
export const POST_ACTION_REJECT = 'reject'
export const POST_ACTION_DELAY = 'delay'
export const POST_ACTION_RESTART = 'restart'
export const POST_ACTION_CANCEL = 'cancel'
export const POST_ACTION_PREVIEW_REQUEST = 'preview_request'
export const POST_ACTION_VIEW_REDEEM = 'view_redeem'

export const SESSION_STATE_CONTINUED = 'continued'
export const SESSION_STATE_DELAYED = 'delayed'
export const SESSION_STATE_CANCELLED = 'cancelled'

// export const POST_CATEGORIES_CONFIGS = {
//   relax: {
//     formRequiredFields: [
//       'post.localization.title',
//       'post.localization.description',
//       'post.schedule', // schedule_end_time, schedule_start_time
//       'post.who_can_view',
//       'post.auto_push_enabled',
//       'sub_category',
//     ],
//     specialComponents: null,
    
//   },
// }
