
interface Solution {
  number: number;
  took: number;
  worker?: boolean;
}

const encoder = new TextEncoder();

const ab2hex = (ab: ArrayBuffer) => {
  return [...new Uint8Array(ab)].map(x => x.toString(16).padStart(2, '0')).join('');
}

const hashChallenge = async (salt: string, num: number, algorithm: string) => {
  return ab2hex(await crypto.subtle.digest(algorithm.toUpperCase(), encoder.encode(salt + num)));
}

export const solveChallenge = (
  challenge: string,
  salt: string,
  algorithm: string = 'SHA-256',
  max: number = 1e6,
  start: number = 0
): {
  promise: Promise<Solution | null>; controller: AbortController
} => {
  const controller = new AbortController();
  const promise = new Promise((resolve, reject) => {
    const startTime = Date.now();
    const next = (n: number) => {
      if (controller.signal.aborted || n > max) {
        resolve(null);
      } else {
        hashChallenge(salt, n, algorithm).then((t) => {
          if (t === challenge) {
            resolve({
              number: n,
              took: Date.now() - startTime,
            });
          } else {
            next(n + 1);
          }
        }).catch(reject);
      }
    };
    next(start);
  }) as Promise<Solution | null>;
  return {
    promise,
    controller,
  };
}