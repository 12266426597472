import { useReducer } from 'react'
import { SimpleBuilding, SimpleCampus, SimpleFloor } from '@exaleap/common'
import pmcReducer, { State } from '../reducers/pmc'
import pmcActions from '../actions/pmcActions'

const initialState: State = {
  buildings: [],
  campuses: [],
  floors: {},
}

export type PMCState = {
  buildings: SimpleBuilding[],
  campuses: SimpleCampus[],
  floors: { [buildingId: string]: SimpleFloor[] },
  setBuildings: (buildings: SimpleBuilding[]) => void,
  setCampuses: (buildings: SimpleCampus[]) => void,
  setFloorsOfBuilding: (value: { id: string, result: SimpleFloor[] }) => void,
}

const usePMC = (): PMCState => {
  const [pmc, pmcDispatcher] = useReducer(pmcReducer, initialState)
  const { setBuildings, setCampuses, setFloorsOfBuilding } = pmcActions(pmcDispatcher)

  return {
    ...pmc,
    setBuildings,
    setCampuses,
    setFloorsOfBuilding,
  }
}

export default usePMC
