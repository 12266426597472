import { Dispatch } from 'react'
import { SimpleCampus, PolicyCampusesResource } from '@exaleap/common'

export enum ActionType {
  ACTION_SET_CAMPUSES = 'ACTION_SET_CAMPUSES',
  ACTION_SET_CAMPUSES_RESOURCE = 'ACTION_SET_CAMPUSES_RESOURCE',
}

export type Action =
  | { type: ActionType.ACTION_SET_CAMPUSES, value: SimpleCampus[] }
  | { type: ActionType.ACTION_SET_CAMPUSES_RESOURCE, value: PolicyCampusesResource[] }

const systemInfoAction = (dispatch: Dispatch<Action>) => {
  const setCampuses = (value: SimpleCampus[]) =>
    dispatch({
      type: ActionType.ACTION_SET_CAMPUSES,
      value,
    })

  const setCampusesResource = (value: PolicyCampusesResource[]) =>
    dispatch({
      type: ActionType.ACTION_SET_CAMPUSES_RESOURCE,
      value,
    })

  return { setCampuses, setCampusesResource }
}

export default systemInfoAction
