import { AxiosInstance } from 'axios'
import APIRestfulProvider, {
  APIHTTPResponse,
  throwError,
  parseResults,
} from '../../restful-provider'
import { AccessRecordExportRequest, UserAccessRecord } from '../../../schema'

class AccessServiceEndpoints {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }
  subRoute = '/access-service'

  exportlExcel = (params: AccessRecordExportRequest) =>
    this.client
      .get<Blob>(`${this.subRoute}/pm/access-record/export/excel`, {
        params,
        responseType: 'blob',
      })
      .catch(throwError)

  getUserAccessRecords = (params: {
    end: number
    start: number
    user_id: string
  }) =>
    this.client
      .get<APIHTTPResponse<UserAccessRecord>>(`${this.subRoute}/pm/user-access-records/query`, {
        params,
      })
      .then(parseResults)
      .catch(throwError)
}

export default AccessServiceEndpoints
