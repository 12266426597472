import { LocalizationObject } from './base'
// import { Prize } from './asset'

export enum GameState {
  Scheduled = 'scheduled',
  Launched = 'launched',
  Ended = 'ended',
}

export interface GameHeroImage {
  id: string
  url: string
  index: number
}

export interface GameTemplateLocalizedFields {
  title: string
}
export interface GameTemplate extends GameTemplateLocalizedFields, LocalizationObject<GameTemplateLocalizedFields> {
  id: string
  type: string
  redeem_types: string[]
  title: string
}
export interface GameLocalizedFields {
  title: string
  introduction?: string
  description?: string
  terms?: string
}

export interface Game extends GameLocalizedFields, LocalizationObject<GameLocalizedFields> {
  id: string
  template_id: string
  type: string
  schedule_start_time: string
  schedule_end_time: string
  exchange_start_time: string
  exchange_end_time: string
  start_time: string
  end_time: string
  stamps: string[]
  stamps_amount: number
  stamps_collect_amount: number
  is_exchanged: boolean
  exchange_available: boolean
  pass_code: number
  user_access_permission: string
  author_id: string
  last_editor_id: string
  state: GameState
  launched_by: string
  ended_by: string
  templates: GameTemplate[]
  prizes: Prize[]
  heros: GameHeroImage[]
}

export interface GameForm
  extends Pick<
    Game,
    | 'localization'
    | 'type'
    | 'pass_code'
    | 'user_access_permission'
    | 'schedule_start_time'
    | 'schedule_end_time'
    | 'exchange_start_time'
    | 'exchange_end_time'
    | 'stamps_amount'
  > {
  prizes: PrizeForm[]
  picture_id: string
  picture?: {
    id: string
    url: string
  }
}

export interface PrizeLocalizedFields {
  display_name?: string
  description?: string
  terms?: string
}

export interface Prize extends PrizeLocalizedFields, LocalizationObject<PrizeLocalizedFields> {
  asset_id?: string
  type?: string
  amount: number
  low_amount?: number
  remain_amount?: number
  entity_type: string
  expiry_date: string
  merchant_id: string
  merchant_url?: string
  picture_id?: string
  picture_url?: string
  validity?: number
  remarks?: string
}

export interface PrizeForm
  extends Pick<
    Prize,
    'amount' | 'low_amount' | 'entity_type' | 'expiry_date' | 'merchant_id' | 'picture_id' | 'remarks' | 'localization'
  > {
  id?: string
  picture?: {
    id: string
    url: string
  }
}

export interface SearchForm {
  area_code: string
  phone_number: string
  filter_date: string
  gameId?: string
  game?: Game
}

export interface StampLookupRes {
  number_of_scan: string
  number_of_stamps: string
  user_id: string
}
