import * as R from 'ramda'
import { AxiosInstance } from 'axios'
import APIRestfulProvider, {
  APIHTTPResponse,
  throwError,
  PaginatedResponse,
  parseResults,
  parseResult,
} from '../../restful-provider'
import {
  ShortcutType,
  ScheduledPushNotificationList,
  ListRequest,
  ScheduledPushNotificationContent,
  ScheduledPushNotificationDetail,
} from '@exaleap/common'

/**
 * Restful endpoints for Visitor System module
 */
class HTNotification {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/notification-service'

  createPreviewUser = (id: string, area_code: string, phone_number: string) => {
    return this.client
      .post<APIHTTPResponse<any>>(`${this.subRoute}/lm/campuses/${id}/preview-users`, { area_code, phone_number })
      .then(parseResult)
      .catch(throwError)
  }

  deletePreviewUser = (id: string, user_ids: string[]) => {
    return this.client
      .delete<APIHTTPResponse<any>>(`${this.subRoute}/lm/campuses/${id}/preview-users`, { data: { user_ids } })
      .then(parseResult)
      .catch(throwError)
  }

  getShortcutsList = () => {
    return this.client
      .get<APIHTTPResponse<ShortcutType>>(`${this.subRoute}/lm/shortcut/codes`)
      .then(parseResults)
      .catch(throwError)
  }

  getShortcutsListByCms = (id: string) => {
    return this.client
      .get<APIHTTPResponse<ShortcutType>>(`${this.subRoute}/lm/campus/${id}/shortcuts`)
      .then(parseResults)
      .catch(throwError)
  }

  updateShortcutsList = (id: string, params: { order_number: number, code: string }[]) => {
    return this.client
      .put<APIHTTPResponse<any>>(`${this.subRoute}/lm/campus/${id}/shortcuts`, { items: params })
      .then(parseResult)
      .catch(throwError)
  }

  // 定时推送通知/推广信息
  getPushNotificationList = (
    params: ListRequest & {
      application_id: string,
      end_time?: string,
      start_time?: string,
    },
  ) => {
    const filter = R.reject(
      R.isEmpty,
      R.pick(
        [
          'page_number',
          'page_size',
          'sort_by',
          'order_by',
          'end_time',
          'start_time',
          'search',
          'application_id',
          'channel',
          'related_location_type',
          'related_locations',
          'status',
          'filter_time_by',
        ],
        params
      ),
    )

    return this.client
      .get<PaginatedResponse<ScheduledPushNotificationList>>(`${this.subRoute}/lm/notifications/messages`, {
        params: filter,
      })
      .then((d) => d.data)
      .catch(throwError)
  }

  createPushNotification = (params: ScheduledPushNotificationContent) => {
    return this.client
      .post<APIHTTPResponse<ScheduledPushNotificationDetail>>(`${this.subRoute}/lm/notifications/messages`, params)
      .then(parseResult)
      .catch(throwError)
  }

  updatePushNotification = (id: string, params: ScheduledPushNotificationContent) => {
    return this.client
      .put<APIHTTPResponse<ScheduledPushNotificationDetail>>(`${this.subRoute}/lm/notifications/messages/${id}`, params)
      .then(parseResult)
      .catch(throwError)
  }

  cancelPushNotification = (id: string) => {
    return this.client
      .patch<APIHTTPResponse<ScheduledPushNotificationDetail>>(
        `${this.subRoute}/lm/notifications/messages/${id}/cancel`,
      )
      .then(parseResult)
      .catch(throwError)
  }

  uploadFileNormal = (file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ url: string, key: string }>>(`${this.subRoute}/lm/pics/upload`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((d) => d.data.result)
      .catch(throwError)
}

export default HTNotification
