import { ACCESS_POLICY } from '@exaleap/common'

const HTMemberAndPointRoutes = [
  {
    path: '/app/leasing-mgmt/ht/member-and-point/qr-code',
    element: () => import('./ScanPoint'),
    auth: [ACCESS_POLICY.leasingManagement.scanPoint.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/member-and-point/member',
    element: () => import('./MemberDetail'),
    auth: [ACCESS_POLICY.leasingManagement.memberService.membership],
    // auth: [ACCESS_POLICY.leasingManagement.memberService.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/member-and-point/point-transaction',
    element: () => import('./PointTransaction'),
    auth: [ACCESS_POLICY.leasingManagement.memberService.pointTransaction],
    // auth: [ACCESS_POLICY.leasingManagement.memberService.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/member-and-point/invitation',
    element: () => import('./Invitation'),
    auth: [ACCESS_POLICY.leasingManagement.memberService.invitation],
    // auth: [ACCESS_POLICY.leasingManagement.memberService.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/member-and-point/settings/invitation-rule',
    element: () => import('./InvitationRules'),
    auth: [ACCESS_POLICY.leasingManagement.memberService.invitationRule],
    // auth: [ACCESS_POLICY.leasingManagement.memberService.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/member-and-point/spending-transaction',
    element: () => import('./SpendingTransaction'),
    auth: [ACCESS_POLICY.leasingManagement.selfServicePoints.selfServicePoints.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/member-and-point/settings/spending-transaction-deny-reason',
    element: () => import('./SpendingTransactionDenyReason'),
    auth: [ACCESS_POLICY.leasingManagement.selfServicePoints.selfServicePoints.fullAccess],
  },
  {
    path: '/app/leasing-mgmt/ht/member-and-point/settings/point-reward-rule',
    element: () => import('./PointRewardRule'),
    auth: [ACCESS_POLICY.leasingManagement.selfServicePoints.rewardRule.fullAccess],
  },
]

export default HTMemberAndPointRoutes
