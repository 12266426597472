import React from 'react'
import { useLocation, Outlet } from "react-router-dom"
import { useEffect } from "react"
import firebase from './firebase'

function FirebaseAnalytics() {
    const location = useLocation()
    useEffect(() => {
        const analytics = firebase.analytics
        if (typeof analytics === "function") {
            const page_path = location.pathname + location.search
            // console.log('testing analytics', page_path)
            analytics().setCurrentScreen(page_path)
            analytics().logEvent("page_view", { page_path })
        }
    }, [location])
    return <Outlet />
}

export default FirebaseAnalytics