export const AREA_CODES = [
  {
    display_number: '+86',
    value: '86',
    country: 'China',
    region: 'CN',
    length: 11,
  },
  {
    display_number: '+852',
    value: '852',
    country: 'China HK',
    region: 'HK',
    length: 8,
  },
  {
    display_number: '+853',
    value: '853',
    country: 'Macau',
    region: 'Macau',
    length: 8,
  },
  {
    display_number: '+886',
    value: '886',
    country: 'Taiwan',
    region: 'TW',
    length: 9,
  },
  {
    display_number: '+1',
    value: '1',
    country: 'USA',
    region: 'USA',
    length: 10,
  },
  {
    display_number: '+33',
    value: '33',
    country: 'France',
    region: 'France',
    length: 9,
  },
  {
    display_number: '+44',
    value: '44',
    country: 'UK',
    region: 'UK',
    length: 10,
  },
  {
    display_number: '+49',
    value: '49',
    country: 'Germany',
    region: 'Germany',
    length: 11,
  },
  {
    display_number: '+81',
    value: '81',
    country: 'Japan',
    region: 'Japan',
    length: 10,
  },
  {
    display_number: '+82',
    value: '82',
    country: 'South Korea',
    region: 'South Korea',
    length: 10,
  },
  {
    display_number: '+66',
    value: '66',
    country: 'Thailand',
    region: 'Thailand',
    length: 9,
  },
  {
    display_number: '+91',
    value: '91',
    country: 'India',
    region: 'India',
    length: 10,
  },
  {
    display_number: '+64',
    value: '64',
    country: 'New Zealand',
    region: 'New Zealand',
    length: 9,
  },
  {
    display_number: '+65',
    value: '65',
    country: 'Singapore',
    region: 'Singapore',
    length: 8,
  },
  {
    display_number: '+358',
    value: '358',
    country: 'Finland',
    region: 'Finland',
    length: 9,
  },
  {
    display_number: '+46',
    value: '46',
    country: 'Sweden',
    region: 'Sweden',
    length: 10,
  },
  {
    display_number: '+36',
    value: '36',
    country: 'Hungary',
    region: 'Hungary',
    length: 9,
  },
  {
    display_number: '+45',
    value: '45',
    country: 'Denmark',
    region: 'Denmark',
    length: 8,
  },
  {
    display_number: '+60',
    value: '60',
    country: 'Malaysia',
    region: 'Malaysia',
    length: 10,
  },
  {
    display_number: '+507',
    value: '507',
    country: 'Panama',
    region: 'Panama',
    length: 8,
  },
  {
    display_number: '+56',
    value: '56',
    country: 'Chile',
    region: 'Chile',
    length: 9,
  },
  {
    display_number: '+52',
    value: '52',
    country: 'Mexico',
    region: 'Mexico',
    length: 11,
  },
  {
    display_number: '+63',
    value: '63',
    country: 'Filipin',
    region: 'Filipin',
    length: 10,
  },
  {
    display_number: '+976',
    value: '976',
    country: 'Mongolia',
    region: 'Mongolia',
    length: 8,
  },
]

export const REGION_CODES = ['CN', 'HK']

export const LANGUAGE_CODE_SC = 'sc'
export const LANGUAGE_CODE_TC = 'tc'
export const LANGUAGE_CODE_EN = 'en'

export const SUPPORTED_LANGUAGE = [LANGUAGE_CODE_TC, LANGUAGE_CODE_SC, LANGUAGE_CODE_EN]

export const DEFAULT_LANGUAGE_CODE = LANGUAGE_CODE_SC

export const LANGUAGE_DATA_LIST: {
  languageId: string,
  locale: 'en' | 'tc' | 'sc',
  name: string,
  icon: string,
}[] = [
    {
      languageId: 'zh-TW',
      locale: LANGUAGE_CODE_TC,
      name: '繁體中文',
      icon: 'cn',
    },
    {
      languageId: 'zh-CN',
      locale: LANGUAGE_CODE_SC,
      name: '简体中文',
      icon: 'cn',
    },
    {
      languageId: 'en-US',
      locale: LANGUAGE_CODE_EN,
      name: 'English',
      icon: 'us',
    },
  ]

export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const REGEX_ID_CARD = /^[A-z,0-9]+$/
export const REGEX_ID_CARD_4 = /^[A-z,0-9]{4}$/
export const REGEX_NUMBERS = /^[0-9]+$/

export const H_CIRCLE_DEEP_LINK_SCHEME = 'hcircle'

export const QR_CODE_LIMITATIONS = ['NO_LIMIT', 'PER_USER_BY_DAY', 'PER_USER_BY_RULE']

export const HK_APP_ID = 'hc'
export const CHINA_APP_ID = 'ht'
