import * as R from 'ramda'
import { AxiosInstance } from 'axios'
import APIRestfulProvider, {
  APIHTTPResponse,
  throwError,
  PaginatedResponse,
  parseResults,
  parseResult,
} from '../../restful-provider'
import {
  Greeting,
  Campus,
  PrivacyPolicyList,
  PrivacyPolicyNew,
  ShortcutType,
  AdContent,
  AdDetail,
  AdList,
} from '@exaleap/common'
import { LEASING_MANAGEMENT_ROUTE } from '../../config'

/**
 * Restful endpoints for HTAPPFunction System module
 */
class HTAPPFunctionsServices {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = `/app-config-service${LEASING_MANAGEMENT_ROUTE}`

  // 隐私权政策列表
  getPrivacyPolicyByPage = (params) => {
    const args = R.reject(R.isEmpty, R.pick(['order_by', 'sort_by', 'page_number', 'page_size'], params))
    return this.client
      .get<PaginatedResponse<PrivacyPolicyList>>(`${this.subRoute}/privacy/policy`, { params: args })
      .then((d) => d.data)
      .catch(throwError)
  }

  getPrivacyPolicyById = (id: string) => {
    return this.client
      .get<APIHTTPResponse<PrivacyPolicyNew>>(`${this.subRoute}/privacy/policy/${id}`)
      .then(parseResult)
      .catch(throwError)
  }

  createPrivacyPolicy = (params) => {
    return this.client
      .post<APIHTTPResponse<PrivacyPolicyNew>>(`${this.subRoute}/privacy/policy`, params)
      .then(parseResult)
      .catch(throwError)
  }

  updatePrivacyPolicy = (params) => {
    const args = R.pick(['expected_release_time', 'url', 'version_name'], params)
    return this.client
      .put<APIHTTPResponse<PrivacyPolicyNew>>(`${this.subRoute}/privacy/policy/${params?.id}`, args)
      .then(parseResult)
      .catch(throwError)
  }

  uploadPdf = (file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ id: string, url: string }>>(`${this.subRoute}/privacy/policy/pdf/upload`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((d) => d.data.result)

  publishPrivacyPolicy = (id: string) => {
    return this.client
      .patch<APIHTTPResponse<PrivacyPolicyNew>>(`${this.subRoute}/privacy/policy/${id}`, { status: 'publish' })
      .then(parseResult)
      .catch(throwError)
  }

  disablePrivacyPolicy = (id: string) => {
    return this.client
      .patch<APIHTTPResponse<PrivacyPolicyNew>>(`${this.subRoute}/privacy/policy/${id}`, { status: 'disable' })
      .then(parseResult)
      .catch(throwError)
  }

  // 条款与细则 terms/conditions
  getTermsConditionsByPage = (params) => {
    const args = R.reject(R.isEmpty, R.pick(['order_by', 'sort_by', 'page_number', 'page_size'], params))
    return this.client
      .get<PaginatedResponse<PrivacyPolicyList>>(`${this.subRoute}/terms/conditions`, { params: args })
      .then((d) => d.data)
      .catch(throwError)
  }

  getTermsConditionsById = (id: string) => {
    return this.client
      .get<APIHTTPResponse<PrivacyPolicyNew>>(`${this.subRoute}/terms/conditions/${id}`)
      .then(parseResult)
      .catch(throwError)
  }

  createTermsConditions = (params) => {
    return this.client
      .post<APIHTTPResponse<PrivacyPolicyNew>>(`${this.subRoute}/terms/conditions`, params)
      .then(parseResult)
      .catch(throwError)
  }

  updateTermsConditions = (params) => {
    const args = R.pick(['expected_release_time', 'url', 'version_name'], params)
    return this.client
      .put<APIHTTPResponse<PrivacyPolicyNew>>(`${this.subRoute}/terms/conditions/${params?.id}`, args)
      .then(parseResult)
      .catch(throwError)
  }

  uploadImage = (file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ url: string, id: string }>>(`${this.subRoute}/pics/upload`, file, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(parseResults)
      .catch(throwError)

  uploadTermsConditionsPdf = (file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ id: string, url: string }>>(`${this.subRoute}/terms/conditions/pdf/upload`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((d) => d.data.result)

  publishTermsConditions = (id: string) => {
    return this.client
      .patch<APIHTTPResponse<PrivacyPolicyNew>>(`${this.subRoute}/terms/conditions/${id}`, { status: 'publish' })
      .then(parseResult)
      .catch(throwError)
  }

  disableTermsConditions = (id: string) => {
    return this.client
      .patch<APIHTTPResponse<PrivacyPolicyNew>>(`${this.subRoute}/terms/conditions/${id}`, { status: 'disable' })
      .then(parseResult)
      .catch(throwError)
  }

  // 問候語
  getGreetingByPage = (params) => {
    const args = R.reject(R.isEmpty, R.pick(['order_by', 'sort_by', 'page_number', 'page_size'], params))
    return this.client
      .get<PaginatedResponse<Greeting>>(`${this.subRoute}/greetings`, { params: args })
      .then((d) => d.data)
      .catch(throwError)
  }

  getGreetingById = (id: string) => {
    return this.client
      .get<APIHTTPResponse<Greeting>>(`${this.subRoute}/greeting/${id}`)
      .then(parseResult)
      .catch(throwError)
  }

  createGreeting = (params) => {
    return this.client
      .post<APIHTTPResponse<Greeting>>(`${this.subRoute}/greeting`, params)
      .then(parseResult)
      .catch(throwError)
  }

  updateGreeting = (params, id: string) => {
    return this.client
      .put<APIHTTPResponse<Greeting>>(`${this.subRoute}/greeting/${id}`, params)
      .then(parseResult)
      .catch(throwError)
  }

  deleteGreeting = (id: string) => {
    return this.client
      .delete<APIHTTPResponse<Greeting>>(`${this.subRoute}/greeting/${id}`)
      .then(parseResult)
      .catch(throwError)
  }

  getCampusList = () => {
    return this.client.get<APIHTTPResponse<Campus>>(`${this.subRoute}/campuses`).then(parseResults).catch(throwError)
  }

  updateCampusStatus = (id: string) => {
    return this.client
      .patch<APIHTTPResponse<Campus>>(`${this.subRoute}/campuses/${id}`)
      .then(parseResult)
      .catch(throwError)
  }

  getCampusPreviewUser = (id: string, query?: string) => {
    const queryStr = query ? `?query=${query}` : ''
    return this.client
      .get<APIHTTPResponse<any>>(`${this.subRoute}/campuses/${id}/preview-users${queryStr}`)
      .then(parseResults)
      .catch(throwError)
  }

  createPreviewUser = (id: string, area_code: string, phone_number: string) => {
    return this.client
      .post<APIHTTPResponse<any>>(`${this.subRoute}/campuses/${id}/preview-users`, { area_code, phone_number })
      .then(parseResult)
      .catch(throwError)
  }

  deletePreviewUser = (id: string, user_ids: string[]) => {
    return this.client
      .delete<APIHTTPResponse<any>>(`${this.subRoute}/campuses/${id}/preview-users`, { data: { user_ids } })
      .then(parseResult)
      .catch(throwError)
  }

  getShortcutsList = () => {
    return this.client
      .get<APIHTTPResponse<ShortcutType>>(`${this.subRoute}/shortcut/codes`)
      .then(parseResults)
      .catch(throwError)
  }

  getShortcutsListByCms = (id: string) => {
    return this.client
      .get<APIHTTPResponse<ShortcutType>>(`${this.subRoute}/campus/${id}/shortcuts`)
      .then(parseResults)
      .catch(throwError)
  }

  updateShortcutsList = (id: string, params: { order_number: number, code: string }[]) => {
    return this.client
      .put<APIHTTPResponse<any>>(`${this.subRoute}/campus/${id}/shortcuts`, { items: params })
      .then(parseResult)
      .catch(throwError)
  }

  // 廣告
  createAdvertisementsCampuses = (params: AdContent) => {
    return this.client
      .post<APIHTTPResponse<AdDetail>>(`${this.subRoute}/campuses/advertisements`, params)
      .then(parseResult)
      .catch(throwError)
  }

  updateAdvertisementsCampuses = (id: string, params: AdContent) => {
    return this.client
      .put<APIHTTPResponse<AdDetail>>(`${this.subRoute}/campuses/advertisements/${id}`, params)
      .then(parseResult)
      .catch(throwError)
  }

  getAdvertisementByIdCampuses = (id: string) => {
    return this.client
      .get<APIHTTPResponse<AdDetail>>(`${this.subRoute}/campuses/advertisements/${id}`)
      .then(parseResult)
      .catch(throwError)
  }

  getAdvertisementListCampuses = (params) => {
    const args = R.reject(
      R.isEmpty,
      R.pick(['order_by', 'sort_by', 'page_number', 'page_size', 'category', 'active', 'query'], params),
    )
    const campus_ids = params?.campusId
    return this.client
      .get<PaginatedResponse<AdList>>(`${this.subRoute}/campuses/advertisements`, {
        params: { campus_ids, ...args },
      })
      .then((d) => d.data)
      .catch(throwError)
  }

  createAdvertisements = (params: AdContent) => {
    return this.client
      .post<APIHTTPResponse<AdDetail>>(`${this.subRoute}/general/advertisements`, params)
      .then(parseResult)
      .catch(throwError)
  }

  updateAdvertisements = (id: string, params: AdContent) => {
    return this.client
      .put<APIHTTPResponse<AdDetail>>(`${this.subRoute}/general/advertisements/${id}`, params)
      .then(parseResult)
      .catch(throwError)
  }

  getAdvertisementById = (id: string) => {
    return this.client
      .get<APIHTTPResponse<AdDetail>>(`${this.subRoute}/general/advertisements/${id}`)
      .then(parseResult)
      .catch(throwError)
  }

  getAdvertisementList = (params) => {
    const args = R.reject(
      R.isEmpty,
      R.pick(['order_by', 'sort_by', 'page_number', 'page_size', 'category', 'active', 'query'], params),
    )
    return this.client
      .get<PaginatedResponse<AdList>>(`${this.subRoute}/general/advertisements`, { params: args })
      .then((d) => d.data)
      .catch(throwError)
  }
}

export default HTAPPFunctionsServices
