import IOTRestful from "./iot"
import APIRestfulProvider from "../../restful-provider"
import IAMRestful from "./iam"
import AccessRecordService from "./access-record"
import PermissionService from "./permission"
import SupplierManagementService from "./supplier"
import DevicesRestful from './devices'

/**
 * Platform Management Services
 */


class PlatformManagementEndpoints {

    iot: IOTRestful
    iam: IAMRestful
    access: AccessRecordService
    permission: PermissionService
    supplier: SupplierManagementService
    devices: DevicesRestful
    constructor(provider: APIRestfulProvider) {
        this.iot = new IOTRestful(provider)
        this.iam = new IAMRestful(provider)
        this.access = new AccessRecordService(provider)
        this.permission = new PermissionService(provider)
        this.supplier = new SupplierManagementService(provider)
        this.devices = new DevicesRestful(provider)
    }

}


export default PlatformManagementEndpoints