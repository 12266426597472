import { createIntl, createIntlCache } from 'react-intl'
import { format } from 'date-fns'
import { zhCN, zhTW, enUS } from 'date-fns/locale'

let cache
let intl

/**
 * Generate IntlShape object
 * @param {Object} props
 * @param {String} props.locale - User specified language
 * @param {Object} props.messages - Messages
 * @returns {Object}
 */
const generateIntl = props => {
  if (cache) {
    cache = null
  }

  cache = createIntlCache()

  intl = createIntl(props, cache)
  return intl
}

const getLocaleKey = () => {
  let locale
  switch (intl?.locale) {
    case 'en-US':
      locale = 'en'
      break
    case 'zh-TW':
      locale = 'tc'
      break
    case 'zh-CN':
    default:
      locale = 'sc'
  }
  return locale
}

const getLocale = (locale?: string) => {
  let localeFile
  switch (locale || intl?.locale) {
    case 'en-US':
      localeFile = enUS
      break
    case 'zh-TW':
      localeFile = zhTW
      break
    case 'zh-CN':
    default:
      localeFile = zhCN
  }
  return localeFile
}

const getDateLocateByIntlLocate = (intlLocate) => {
  let locale
  switch (intlLocate) {
    case 'en':
      locale = 'en-US'
      break
    case 'tc':
      locale = 'zh-TW'
      break
    case 'sc':
    default:
      locale = 'zh-CN'
  }
  return locale
}

const formatDate = (value: number | string, dateFormat = `yyyy/MM/dd ah:mm`, locale?: string) => {
  return format(new Date(value), dateFormat, { locale: getLocale(getDateLocateByIntlLocate(locale)) })
}

export { formatDate, generateIntl, getLocale, intl, getLocaleKey }
