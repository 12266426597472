import { call, delay, put, select, takeEvery } from 'redux-saga/effects'
import { intl } from 'util/IntlHelper'
import { getLanguageData } from 'util/localeHelper'
import apiService from '../../services/apiService'
import localStorageService from '../../services/localStorageService'
import jwtAuthService from '../../services/jwtAuthService'
import { solveChallenge } from '../../services/altchaChallengeService'
import { Action, ACTION_KEY_AUTH, AuthActions, NotificationActionFunctions } from '../actions'
import { switchLanguage } from '../actions/hrdp-web/Setting'


function* watchRequestSMS(action: Action) {
  console.log('[SAGA] watch Login')
  // get the challenge for the slider captcha 
  const challengeData = yield call(apiService.platform.account.getCaptchaChallenge)
  const { algorithm, challenge, salt, signature } = challengeData?.challenge || {}
  const solvedChallengeService = solveChallenge(challenge, salt, algorithm, signature)
  const solvedChallengeData = yield call(() => solvedChallengeService.promise)

  const authorizedChallengeData = {
    ...challengeData?.challenge,
    number: solvedChallengeData?.number
  }
  const { area_code, phone_number, organization_identifier } = action.payload
  try {
    const data = yield call(apiService.platform.account.requestSMS, area_code, phone_number, organization_identifier, authorizedChallengeData)
    yield put(AuthActions.requestToken.success(data))
    yield put(
      // notification.sms_code.sent
      NotificationActionFunctions.enqueue({
        message: intl.formatMessage({ id: 'notification.sms_code.sent' }),
        type: 'success',
      }),
    )

    yield put(AuthActions.setTokenTimeer(60))

    // const { auth } = yield select()

    for (let i = 60; i >= 0; i--) {
      const { auth } = yield select()
      if (auth.smsTimer >= 0) {
        yield put(AuthActions.setTokenTimeer(i))
        yield delay(1000)
      }
    }
  } catch (error) {
    console.warn('[SAGA] request SMS', error)
    // const errorMessage =
    yield put(AuthActions.requestToken.failure(error))
    if (error?.code !== 'ERR100173') {
      yield put(
        NotificationActionFunctions.enqueue({
          message: error,
          type: 'error',
        }),
      )
    }
  }
}

function* watchVerifySMS(action: Action) {
  const { areaCode, phone, sms } = action.payload
  try {
    const data = yield call(jwtAuthService.loginWithSMS, areaCode, phone, sms)
    yield put(AuthActions.setCurrentAuthUser(data))
    yield put(AuthActions.verifyToken.success(data))
    yield put(AuthActions.setTokenTimeer(-1))
    const policiesRes = yield call(apiService.platform.account.getPolicies)
    const { policies } = policiesRes
    localStorageService.setItem('campusesResource', policies)
  } catch (error) {
    yield put(AuthActions.verifyToken.failure(error?.code || 'ERR10024'))
    // yield put(
    //   NotificationActionFunctions.enqueue({
    //     message: error,
    //     type: 'error',
    //   }),
    // )
  }
}

function* requestRefreshToken() {
  const token = jwtAuthService.getToken()
  if (!token) {
    yield put(AuthActions.refreshToken.failure('No stored token'))
  } else {
    const { refresh_token } = token
    try {
      const updatedToken = yield call(apiService.platform.account.refreshToken, refresh_token)
      const newToken = updatedToken[0]
      jwtAuthService.setSession(newToken)
      yield put(AuthActions.refreshToken.success(newToken))
    } catch (error) {
      yield put(AuthActions.refreshToken.failure(error))
      yield put(
        NotificationActionFunctions.enqueue({
          message: error,
          type: 'error',
        }),
      )
    }
  }
}

function* fetchProfile() {
  try {
    const result = yield call(apiService.platform.account.getProfile)
    yield put(AuthActions.requestProfile.success(result[0]))
    const langCd = result[0]?.preferred_language
    if (langCd) {
      yield put(switchLanguage(getLanguageData(langCd)))
    }
  } catch (error) {
    yield put(AuthActions.requestProfile.failure(error))
    yield put(
      NotificationActionFunctions.enqueue({
        message: error,
        type: 'error',
      }),
    )
  }
}

export const authSagas = [
  takeEvery(ACTION_KEY_AUTH.GET_PROFILE.REQUEST, fetchProfile),
  takeEvery(ACTION_KEY_AUTH.REFRESH_TOKEN.REQUEST, requestRefreshToken),
  takeEvery(ACTION_KEY_AUTH.REQUEST_SMS.REQUEST, watchRequestSMS),
  takeEvery(ACTION_KEY_AUTH.VERIFY_SMS.REQUEST, watchVerifySMS),
]
