import { AxiosInstance } from 'axios'
import {
  SimpleCampus,
  SimpleBuilding,
  SimpleFloor,
  SimpleSpace,
  SpaceType,
  LocationPermission,
} from '../../../schema'
import { ANONYMOUS_USER_ROUTE, REGISTER_USER_ROUTE, ACCESS_ROUTE } from '../../config'
import APIRestfulProvider, { APIHTTPResponse, parseResults, throwError } from '../../restful-provider'

const A_LOCATIONS_ROUTE = `${ANONYMOUS_USER_ROUTE}/locations`
const R_LOCATIONS_ROUTE = `${REGISTER_USER_ROUTE}/locations`
const PERMISSIONS_ROUTE = `${ACCESS_ROUTE}${REGISTER_USER_ROUTE}/accesses/permissions`

/**
 * Restful endpoints for the folloing
 * Accesses
 * Locations
 */
class BuildingServiceRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  /////////////////////////////// Campus

  getCampuses = (id?: string) =>
    this.client
      .get<APIHTTPResponse<SimpleCampus>>(`${R_LOCATIONS_ROUTE}/campuses`, {
        params: { id },
      })
      .then(parseResults)

  listAllCampuses = () =>
    this.client.get<APIHTTPResponse<SimpleCampus>>(`${A_LOCATIONS_ROUTE}/campuses`).then(parseResults)

  /////////////////////////////// Building

  getBuildings = (id?: string) =>
    this.client
      .get<APIHTTPResponse<SimpleBuilding>>(`${R_LOCATIONS_ROUTE}/buildings`, { params: { id } })
      .then(parseResults)

  getBuildingsInCampus = (campus_id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleBuilding>>(`${R_LOCATIONS_ROUTE}/campuses/${campus_id}/buildings`, {
        params: { id: campus_id },
      })
      .then(parseResults)

  /////////////////////////////// Floor

  getFloorById = (floor_id: string) =>
    this.client.get<APIHTTPResponse<SimpleFloor>>(`${R_LOCATIONS_ROUTE}/floors/${floor_id}`).then(parseResults)

  getFloorsInBuilding = (building_id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleFloor>>(`${R_LOCATIONS_ROUTE}/buildings/${building_id}/floors`)
      .then(parseResults)

  /////////////////////////////// Get Spaces

  // TO-DO: withTenant will be removed.
  getSpaceById = (space_id: string, withTenant?: boolean, types?: SpaceType[]) => {
    let params: any = { id: space_id }

    if (types) {
      params = { ...params, type: types.join(',') }
    }
    // const params = {id,  withTenant === undefined && ...{ tenant: withTenant}, ...{} }
    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${R_LOCATIONS_ROUTE}/spaces/${space_id}`, {
        params,
      })
      .then(parseResults)
  }

  // TO-DO: withTenant will be removed.
  getSpacesInCampus = (campus_id: string, withTenant?: boolean, type?: SpaceType[]) => {
    let params: any = { id: campus_id }

    if (type) {
      params = { ...params, type }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${R_LOCATIONS_ROUTE}/campuses/${campus_id}/spaces`, {
        params,
      })
      .then(parseResults)
  }

  // TO-DO: withTenant will be removed.
  getSpacesInBuilding = (building_id: string, withTenant?: boolean, type?: SpaceType[]) => {
    let params: any = { id: building_id }

    if (type) {
      params = { ...params, type }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${R_LOCATIONS_ROUTE}/buildings/${building_id}/spaces`, {
        params,
      })
      .then(parseResults)
  }

  // TO-DO: withTenant will be removed.
  getSpacesInFloor = (floor_id: string, withTenant?: boolean, type?: SpaceType[]) => {
    let params: any = { id: floor_id }

    if (type) {
      params = { ...params, type }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${R_LOCATIONS_ROUTE}/floors/${floor_id}/spaces`, {
        params,
      })
      .then(parseResults)
  }

  // TO-DO: withTenant will be removed.
  getSpacesInSpace = (space_id: string, withTenant?: boolean, type?: SpaceType[]) => {
    let params: any = { space_id }

    if (type) {
      params = { ...params, type }
    }

    return this.client
      .get<APIHTTPResponse<SimpleSpace>>(`${R_LOCATIONS_ROUTE}/spaces/${space_id}/spaces`, {
        params,
      })
      .then(parseResults)
  }

  getSpaceHierarchy = (ids: string[], type?: SpaceType[]) => {
    let params: any = { space_id: ids }

    if (type) {
      params = { ...params, type }
    }
    return this.client
      .get<APIHTTPResponse<any>>(`${R_LOCATIONS_ROUTE}/spaces/${ids.join(',')}/hierarchy`, {
        params,
      })
      .then(parseResults)
  }

  // access-control-service  no use
  accessQRCode = (building_id: string) =>
    this.client
      .get<APIHTTPResponse<{ id: string, code: string }>>(`${PERMISSIONS_ROUTE}/generic/grant/qrcode/campuses/${building_id}`)
      .then((d) => d.data.result)
      .catch(throwError)

  getAccessPermissions = () =>
    this.client
      .get<APIHTTPResponse<{ profile_picture_url: string, permissions: LocationPermission[] }>>(
        `${PERMISSIONS_ROUTE}/generic`,
      )
      .then((d) => d.data.result)
      .catch(throwError)
}

export default BuildingServiceRestful
