import * as R from 'ramda'
import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, PaginatedResponse, throwError } from '../../restful-provider'
import {
  DisplayUser,
  ValueAddedCampusSummary,
  ValueAddedOrder,
  ValueAddedOrderComment,
  ValueAddedOrderHistory,
  ValueAddedOrderRequest,
  ValueAddedOrderStatus,
  ValueAddedType,
  SimpleDisplayObject,
} from '../../../schema'
import { PMC_MANAGEMENT_ROUTE, ANONYMOUS_USER_ROUTE } from '../../config'
import { qureyString } from '../../../utils/helper'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result[0]
const parseResults = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

const subRoute = '/value-added-service'
const PMC_MANAGEMENT_SUBROUTE = `${subRoute}${PMC_MANAGEMENT_ROUTE}`
const ANONYMOUS_USER_SUBROUTE = `${subRoute}${ANONYMOUS_USER_ROUTE}`

class General {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }
  subRoute = subRoute

  appliedUsers = (building_id: string, campus_id: string) =>
    this.client
      .get<APIHTTPResponse<DisplayUser>>(`${PMC_MANAGEMENT_SUBROUTE}/orders/users`, {
        params: { campus_id, building_id },
      })
      .then(parseResults)
      .catch(throwError)

  attachFile = (id: string, params: { file_ids: string[] }) =>
    this.client
      .patch<APIHTTPResponse<any>>(`${PMC_MANAGEMENT_SUBROUTE}/orders/${id}/comments/actions/add-attachment`, {
        update_status: true,
        is_send_notification: true,
        file_ids: params?.file_ids || [],
      })
      .catch(throwError)

  comment = (
    id: string,
    params: {
      comment: string,
      current_status_id: ValueAddedOrderStatus,
      file_ids: string[],
      photo_ids: string[],
    },
  ) =>
    this.client
      .post<APIHTTPResponse<any>>(`${PMC_MANAGEMENT_SUBROUTE}/orders/${id}/comments`, params)
      .then(parseResult)
      .catch(throwError)

  deleteFile = (id: string) =>
    this.client.delete<APIHTTPResponse<any>>(`${this.subRoute}/file/${id}`).then(parseResult).catch(throwError)

  overview = () =>
    this.client
      .get<APIHTTPResponse<ValueAddedCampusSummary>>(`${PMC_MANAGEMENT_SUBROUTE}/orders/summaries?status=PENDING`)
      .then(parseResults)
      .catch(throwError)

  order = (id: string) =>
    this.client
      .get<APIHTTPResponse<ValueAddedOrder>>(`${PMC_MANAGEMENT_SUBROUTE}/orders/${id}`)
      .then(parseResult)
      .catch(throwError)

  orders = (params: ValueAddedOrderRequest) => {
    const args = R.reject(
      R.isEmpty,
      R.pick(['building_id', 'page_number', 'page_size', 'search'], params),
    )

    const tenant_ids_arr = qureyString(params, 'tenant_ids')
    const apply_user_ids_arr = qureyString(params, 'apply_user_ids')
    const status_ids_arr = qureyString(params, 'status_ids')
    const type_ids_arr = qureyString(params, 'type_ids')
    const repeatParams = [...status_ids_arr, ...type_ids_arr, ...tenant_ids_arr, ...apply_user_ids_arr].join('&')

    return this.client
      .get<PaginatedResponse<ValueAddedOrder>>(
        `${PMC_MANAGEMENT_SUBROUTE}/orders${!!repeatParams.length ? `?${repeatParams}` : ''}`,
        { params: args },
      )
      .then((d) => d.data)
      .catch(throwError)
  }

  orderComments = (id: string) =>
    this.client
      .get<APIHTTPResponse<ValueAddedOrderComment>>(`${PMC_MANAGEMENT_SUBROUTE}/orders/${id}/comments`)
      .then(parseResults)
      .catch(throwError)

  orderHistory = (id: string) =>
    this.client
      .get<APIHTTPResponse<ValueAddedOrderHistory>>(`${PMC_MANAGEMENT_SUBROUTE}/orders/${id}/histories`)
      .then(parseResults)
      .catch(throwError)

  setOrderStatus = (id: string, status: ValueAddedOrderStatus) =>
    this.client
      .patch<APIHTTPResponse<any>>(`${PMC_MANAGEMENT_SUBROUTE}/orders/${id}/actions/approval/${status}`)
      .then(parseResult)
      .catch(throwError)

  status = () =>
    this.client
      .get<APIHTTPResponse<SimpleDisplayObject>>(`${ANONYMOUS_USER_SUBROUTE}/orders/status`)
      .then(parseResults)
      .catch(throwError)

  tenants = (building_id: string, campus_id: string) =>
    this.client
      .get<APIHTTPResponse<SimpleDisplayObject>>(`${PMC_MANAGEMENT_SUBROUTE}/orders/tenants`, {
        params: { building_id, campus_id },
      })
      .then(parseResults)
      .catch(throwError)

  types = (campus_id: string) =>
    this.client
      .get<APIHTTPResponse<ValueAddedType>>(`${ANONYMOUS_USER_SUBROUTE}/orders/categories`, { params: { campus_id } })
      .then(parseResults)
      .catch(throwError)

  uploadFile = (file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ id: string, url: string }>>(`${this.subRoute}/file/upload`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(parseResult)
}

export default General
