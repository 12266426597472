import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, PaginatedResponse, throwError } from '../../restful-provider'
import { ListRequest, User, UserGroup, UserProfile } from '../../../schema'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for Visitor System module
 */
class TenantUserManagement {
    client: AxiosInstance = null
    constructor(provider: APIRestfulProvider) {
        this.client = provider.client
    }

    subRoute = '/tenant-service/user-management'

    getBindingRequests = (params?: ListRequest) => this.client
        .get<PaginatedResponse<UserProfile>>(`${this.subRoute}/get-user-binding-requests`, {
            params
        })
        .then(d => d.data)
        .catch(throwError)

    actionOnBindingRequest = (user_ids: string[], approve: boolean) => this.client
        .post<APIHTTPResponse<{ failure: number, success: number, total: number }>>(`${this.subRoute}/approve-user-binding-request`, { user_ids, approve })
        .then(parseResult)
        .catch(throwError)

}

export default TenantUserManagement
