import * as R from 'ramda'
import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { AREA_CODES, AuthActions, hrdpActions, LANGUAGE_DATA_LIST } from '@exaleap/common'
import {
  FormControl,
  MenuItem,
  Select,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  TextField,
  Box,
} from '@mui/material'
import {
  Theme,
  styled,
  useTheme,
} from '@mui/material/styles'

import {
  CommonDialog,
  CommonDialogActionsAtRight,
  CommonDialogContent,
  CommonDialogHeader,
  DialogInfoActionBtn,
  MuiIconButton,
  WPSliderCaptcha,
} from '@exaleap/react-component-lib'

import { useSelector } from 'state/root-store'

import LoginForm, { AppLoginContent, AppLoginForm, LoginSubmitBtn } from 'components/Login/LoginForm'

import { getLanguageData } from 'util/localeHelper'
import { getThemeColorByColorTxt } from 'util/styleHelper'
import { getPhoneMaxLength } from 'util/stringHelper'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme: { spacing, palette, shape } }: { theme: Theme }) => ({
  '.MuiToggleButtonGroup-grouped': {
    margin: spacing(0.5),
    border: 'none',
    '&.Mui-selected': {
      background: 'none',
      color: palette?.primary?.main,
      fontWeight: 'normal',
      '& .MuiToggleButton-label': {
        borderBottom: `2px solid ${palette?.primary?.main}`,
      },
    },
    '& .MuiToggleButton-label': { textTransform: 'none', fontWeight: 'lighter' },
    '&>button:not(button:first-of-type)': {
      borderRadius: shape.borderRadius,
    },
    '&button:first-of-type': {
      borderRadius: shape.borderRadius,
    },
  },
}))

const DPFSLegend = styled('legend')(() => ({}))

const Fieldset = styled('fieldset')(({ theme: { spacing, palette, typography } }: { theme: Theme }) => ({
  width: '100%',
  display: 'flex',
  padding: `0 ${spacing(0.5)} 0 ${spacing(2.5)}`,
  borderRadius: '4px',
  border: `2px solid ${getThemeColorByColorTxt('primary.main', palette)}`,
  '& legend': {
    fontSize: typography.pxToRem(12),
    lineHeight: '15px',
    fontWeight: 500,
    width: 'auto',
    padding: `0 ${spacing(0.5)}`,
    margin: 0,
    color: getThemeColorByColorTxt('primary.main', palette),
  },
  '&:not(:first-of-type)': {
    marginTop: spacing(2),
  },
  '& .MuiInput-underline:before, & .MuiInput-underline:after': {
    display: 'none',
  },
}))

const AppLoginContainer = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
}))

const AppLoginMainContent = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  backgroundColor: '#fff',
  boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.25)',
  borderRadius: 10,
  fontSize: 14,
  overflow: 'auto',
}))

const AppLoginHeader = styled(Box)(({ theme: { spacing } }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
}))

const SignIn = props => {
  const { spacing, palette } = useTheme()
  const navigate = useNavigate()
  const location = useLocation()

  const { loader, authUser, requestStatus, regionCd, failedError } = useSelector(
    (state) => state.auth,
  )
  const { message: showMessage } = useSelector(
    (state) => state.commonData,
  )

  const regionVersion = useRef(regionCd)
  regionVersion.current = useMemo(() => (R.includes('cn/signin', window?.location?.pathname ?? '') ? 'CN' : 'HK'), [])
  const [organization_identifier] = useState('henderson_hk')
  const { intl } = props
  // const [email, setEmail] = useState('wfc.tenant.adminsitrator@exaleap.ai')
  const [areaCode, setAreaCode] = useState(AREA_CODES.find(item => item.region === regionVersion.current)?.value)
  const [lang, setLang] = useState<string>(regionVersion.current)
  const [phone, setPhone] = useState('')
  const [isValidPhone, setIsValidPhone] = useState(true)
  // const [rCd, setRCd] = useState<string>(regionVersion.current)
  const [step, setStep] = useState('request-otp')
  const [maxLength, setMaxLength] = useState<number>(11)
  const [open, setOpen] = useState<boolean>(false)
  const [validCaptcha, setValidCaptcha] = useState<boolean>(false)
  const dispatch = useDispatch()
  const requestCount = useRef<number>(0)

  const handleChangeLanguage = lang => {
    dispatch(hrdpActions.switchLanguage(getLanguageData(lang)))
    setLang(lang)
  }

  const handleChangeAppTitle = (locale) => {
    if (locale !== 'tc') {
      document.title = intl.formatMessage({ id: 'app_title.CN' })
    }
  }

  const defaultLocale = regionVersion.current.toLowerCase() === 'cn' ? 'sc' : 'tc'
  useEffect(() => {
    handleChangeLanguage(defaultLocale)
    handleChangeAppTitle(defaultLocale)
  }, [])

  // 如果是本地环境或者测试环境，可修改地区和区号，否则不行
  // const disabled = !(
  //   R.includes('webportal.dev', window?.location?.hostname ?? '') ||
  //   R.includes('localhost', window?.location?.hostname ?? '')
  // )

  useEffect(() => {
    const length = getPhoneMaxLength(areaCode)
    setMaxLength(length)
  }, [areaCode])

  useEffect(() => {
    if (phone.length > 0) {
      setIsValidPhone(!/([^0-9])+/.test(phone))
    } else {
      setIsValidPhone(true)
    }
  }, [phone])

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hrdpActions.hideMessage())
      }, 100)
    }
    if (authUser !== null) {
      setStep('request-otp')
      navigate(location?.state?.from?.pathname ?? '/')
    }
  }, [showMessage, authUser, navigate, dispatch])

  useEffect(() => {
    if (!loader) {
      if (requestStatus === 'requested' && requestCount.current > 0) {
        setStep('login')
      }
      if (failedError?.code === 'ERR100173') {
        setOpen(true)
      }
    }
  }, [loader])

  const handleSendOtp = () => {
    setValidCaptcha(false)
    requestCount.current++
    dispatch(hrdpActions.showAuthLoader())
    dispatch(
      AuthActions.requestToken.request({
        areaCode,
        phone,
        organization_identifier,
        regionCd: regionVersion.current,
      }),
    )
  }

  return (
    <AppLoginContainer
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <div
        style={{
          height: 64,
          width: '100%',
          display: 'flex',
          lineHeight: '64px',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ marginLeft: spacing(8), color: palette?.primary?.dark, letterSpacing: 1, fontSize: 18 }}>
          {intl.formatMessage({ id: "header.hrdp_name" })}
        </div>
        <div style={{ marginRight: spacing(8) }}>
          <StyledToggleButtonGroup value={lang} >
            {LANGUAGE_DATA_LIST.map(({ locale }) => (
              <ToggleButton
                key={locale}
                value={locale}
                onClick={() => handleChangeLanguage(locale)}
                style={{ fontSize: 18 }}
              >
                {intl.formatMessage({ id: `generic.lang.short.${locale}` })}
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>
        </div>
      </div>

      <AppLoginMainContent className="animated slideInUpTiny animation-duration-3">
        {step === 'request-otp' && (
          <AppLoginContent>
            <AppLoginHeader>
              <h1 style={{ flex: 1, fontSize: 32 }}>
                {intl.formatMessage({ id: "appModule.login1" })}
              </h1>
            </AppLoginHeader>
            <AppLoginForm>
              <form>
                <Fieldset>
                  <DPFSLegend>
                    {intl.formatMessage({ id: "appModule.login.phone_number" })}
                  </DPFSLegend>
                  <FormControl
                    variant="standard"
                    style={{ width: '100%', display: 'flex', flexDirection: 'row', height: 32, alignItems: 'center', margin: spacing(1) }}
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      name="area_code"
                      value={areaCode}
                      style={{ width: 70, height: 32 }}
                      margin="dense"
                      onChange={e => setAreaCode(e.target.value as string)}
                    // disabled={disabled}
                    >
                      {AREA_CODES.map(code => (
                        <MenuItem key={code.value} value={code.value}>
                          {code.display_number}
                        </MenuItem>
                      ))}
                    </Select>
                    <Divider orientation="vertical" flexItem style={{ margin: `${spacing(1)} ${spacing(1)} ${spacing(1)} ${spacing(2)}` }} />
                    <TextField
                      variant='standard'
                      onChange={(event: any) => setPhone(event.target.value)}
                      onKeyDown={event => {
                        if (event.keyCode === 13 && !(!validCaptcha || phone.length === 0 || !isValidPhone)) {
                          handleSendOtp()
                          return
                        }
                        return false
                      }}
                      value={phone}
                      helperText={
                        (!isValidPhone && intl.formatMessage({ id: 'login.phone.format_invalid' })) ||
                        (phone?.length > maxLength && intl.formatMessage({ id: 'login.phone.max_lenth' }))
                      }
                      error={!isValidPhone || phone?.length > maxLength}
                      inputProps={{ maxLength }}
                      autoFocus={true}
                      InputProps={{
                        endAdornment: (
                          <MuiIconButton
                            iconName="Clear"
                            onClick={() => {
                              setPhone('')
                            }}
                          />
                        ),
                      }}
                      style={{
                        // width: '100%',
                        flexGrow: 1,
                        // background:'#f00'
                      }}
                    />
                  </FormControl>
                </Fieldset>
                <Box sx={{ mt: spacing(2) }}>
                  <WPSliderCaptcha
                    onVerifyCaptcha={(value) => setValidCaptcha(value)}
                    tipText={{
                      default: intl.formatMessage({ id: 'auth.slider.captcha.default' }),
                      loading: intl.formatMessage({ id: 'auth.slider.captcha.loading' }),
                      moving: intl.formatMessage({ id: 'auth.slider.captcha.moving' }),
                      verifying: intl.formatMessage({ id: 'auth.slider.captcha.verifying' }),
                      success: intl.formatMessage({ id: 'auth.slider.captcha.success' }),
                      error: intl.formatMessage({ id: 'auth.slider.captcha.error' }),
                    }}
                  />
                </Box>
                <LoginSubmitBtn
                  onClick={() => handleSendOtp()}
                  disabled={(!validCaptcha || phone.length === 0 || !isValidPhone || phone.length > maxLength)}
                  color="primary"
                  variant="contained"
                >
                  {
                    loader ? <CircularProgress size={20} sx={{ mr: spacing(2), color: '#fff' }} /> : intl.formatMessage({ id: "login.request_sms" })
                  }
                </LoginSubmitBtn>
              </form>
            </AppLoginForm>
          </AppLoginContent>
        )}
        {step === 'login' && (
          <LoginForm setStep={setStep} phone={phone} areaCode={areaCode} resendOtp={handleSendOtp} />
        )}
      </AppLoginMainContent>

      <div
        style={{
          backgroundColor: 'white',
          width: '100%',
          paddingTop: spacing(3),
        }}
      >
        {regionVersion.current === 'CN' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginLeft: spacing(3),
              marginRight: spacing(3),
              color: palette?.grey?.[300],
              fontSize: 12,
              alignItems: 'center',
            }}
          >
            {/* copyright */}
            <span style={{ marginRight: spacing(8) }}>
              <a
                href={require('assets/privacy.pdf')}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: palette?.grey?.[300], textDecoration: 'underline' }}
              >
                {intl.formatMessage({ id: "footer.privacy" })}
              </a>
            </span>
            |
            <span style={{ marginLeft: spacing(8), marginRight: spacing(8) }}>
              <a
                href={require('assets/copyright.pdf')}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: palette?.grey?.[300], textDecoration: 'underline' }}
              >
                {intl.formatMessage({ id: "footer.copyright" })}
              </a>
            </span>
            |
            <span style={{ marginLeft: spacing(8), marginRight: spacing(8) }}>
              <a
                href={require('assets/terms.pdf')}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: palette?.grey?.[300], textDecoration: 'underline' }}
              >
                {intl.formatMessage({ id: "footer.terms" })}
              </a>
            </span>
            |
            <span style={{ marginLeft: spacing(8), marginRight: spacing(8), display: 'flex', alignItems: 'center' }}>
              <img alt="" src={require("assets/images/gov_logo.png")} width={18} height={18} style={{ marginRight: 6 }} />
              <a
                href="https://beian.miit.gov.cn"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: palette?.grey?.[300], textDecoration: 'underline' }}
              >
                <span style={{ display: 'inline-block', marginRight: 12, textDecoration: 'underline' }}>粤公网安备 44030002002051号</span>
                ICP: 粤ICP备19125354号
              </a>
            </span>
          </div>
        )}
        <p style={{ fontSize: 12, color: palette?.grey?.[300], marginLeft: spacing(3), marginRight: spacing(3) }}>
          {intl.formatMessage({ id: "footer.support_browser" })}
        </p>

        <p style={{ display: 'none' }}>v2.0.2</p>
      </div>
      <CommonDialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {
          setOpen(false)
        }}
        maxWidth="sm"
      >
        <CommonDialogHeader
          header={<span style={{ color: palette.error?.main }}>{intl.formatMessage({ id: 'login.request.too.often' })}</span>}
          id="alert-dialog-title"
          handleClose={() => {
            setOpen(false)
            setValidCaptcha(true)
          }}
        />
        <CommonDialogContent style={{ backgroundColor: '#fff', height: 120, }}>
          <Box id="alert-dialog-description">
            {intl.formatMessage({ id: "api.error.ERR10022" })}
          </Box>
        </CommonDialogContent>
        <CommonDialogActionsAtRight>
          <DialogInfoActionBtn
            onClick={() => {
              setOpen(false)
              setValidCaptcha(true)
            }}
            color="primary"
          >
            {intl.formatMessage({ id: "generic.action.got_it" })}
          </DialogInfoActionBtn>
        </CommonDialogActionsAtRight>
      </CommonDialog>
    </AppLoginContainer >
  )
}

export default injectIntl(SignIn)
