import { SimpleBuilding } from '../../schema'
import { ACTION_KEY_GENERAL_DATA, Action } from '../actions'

interface GeneralDataState {
  buildings: SimpleBuilding[],
}

const initialState: GeneralDataState = {
  buildings: [],
}

const GeneralDataReducer = function (state = initialState, action: Action): GeneralDataState {
  if (!action.payload) return state

  switch (action.type) {
    case ACTION_KEY_GENERAL_DATA.BUILDINGS.SUCCESS: {
      const newData = action.payload
      return {
        ...state,
        buildings: newData,
      }
    }
    default: {
      return state
    }
  }
}

export default GeneralDataReducer
