import { SimpleCampus, SimpleBuilding } from '../../../schema'
import { Action, ACTION_KEY_OVERVIEW } from '../../actions'

interface OverviewState {
    // reuqest login status
    success: boolean,
    loading: boolean,
    error: any,

    buildings: SimpleBuilding[],
    campuses: SimpleCampus[],
    detail: SimpleCampus[],
}

const initialState: OverviewState = {
    success: false,
    loading: false,
    error: null,

    buildings: [],
    campuses: [],
    detail: null
}

const OverviewReducer = function (state = initialState, action: Action): OverviewState {
    switch (action.type) {
        case ACTION_KEY_OVERVIEW.OVERVIEW_CAMPUS_DETAIL.REQUEST: {
            return {
                ...state,
                loading: false,
                success: true,
            }
        }

        case ACTION_KEY_OVERVIEW.OVERVIEW_CAMPUS_DETAIL.SUCCESS: {
            const { campuses, detail, buildings } = action.payload
            return {
                ...state,
                success: true,
                loading: false,
                campuses,
                detail,
                buildings
            }
        }

        default: {
            return state
        }
    }
}

export default OverviewReducer
