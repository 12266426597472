import { SimpleBuilding, SimpleMerchant, SimpleFloor } from '@exaleap/common'
import { Action, ActionType } from '../actions/leasingActions'



export type State = {
  buildings: SimpleBuilding[],
  merchants: SimpleMerchant[],
  merchantsHt: SimpleMerchant[],
  floors: Record<string, SimpleFloor[]>,
}

const leasing = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.ACTION_SET_BUILDINGS:
      return {
        ...state,
        buildings: action.value,
      }
    case ActionType.ACTION_SET_MERCHANTS:
      return {
        ...state,
        merchants: action.value,
      }
    case ActionType.ACTION_SET_MERCHANTS_HT:
      return {
        ...state,
        merchantsHt: action.value,
      }
    case ActionType.ACTION_SET_FLOORS:
      const newFloors = action.value
      return {
        ...state,
        floors: { ...state.floors, ...newFloors }
      }
    default:
      return state
  }
}

export default leasing
