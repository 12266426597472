import * as R from 'ramda'
import { AxiosInstance } from 'axios'
import APIRestfulProvider, {
  APIHTTPResponse,
  throwError,
  parseResults,
  parseResult,
  PaginatedResponse,
} from '../../restful-provider'
import {
  PostRes,
  CMSSubCategory,
  CategoriesType,
  Templates,
  PostListRequired,
  Voucher,
  LocalizationObject,
  Asset,
  NotificationRecord,
  ListRequest,
  CampaignUser, //
  PostListType,
  LatestNewsType,
  SeesionRequestRes,
  SessionTransition,
  CommentList,
  VendingMachine
} from '../../../schema'
import { isArray } from 'lodash'

/**
 * Restful endpoints for BMS module
 */
class CMSRestful {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/content-service'

  getCategories = (params: { application_id: string }) =>
    this.client
      .get<APIHTTPResponse<CategoriesType>>(`${this.subRoute}/a/categories`, { params })
      .then(parseResults)
      .catch(throwError)

  getSubCategories = (category: string, enabled, params?: any) =>
    this.client
      .get<PaginatedResponse<CMSSubCategory>>(
        `${this.subRoute}/a/categories/${category}/sub_categories${enabled ? `?enabled=${enabled}` : ''}`,
      )
      .then((data) => data.data)
      .catch(throwError)

  createSubCategories = (category: string, params: Partial<CMSSubCategory>) =>
    this.client
      .post<APIHTTPResponse<CMSSubCategory>>(`${this.subRoute}/lm/categories/${category}/sub_category`, params)
      .then(parseResult)
      .catch(throwError)

  updateSubCategories = (category: string, sub_category: string, params: Partial<CMSSubCategory>) =>
    this.client
      .put<APIHTTPResponse<CMSSubCategory>>(
        `${this.subRoute}/lm/categories/${category}/sub_categories/${sub_category}`,
        params,
      )
      .then(parseResult)
      .catch(throwError)

  createPost = (params: Partial<{ post: LocalizationObject, template_id, global_location_type, related_locations }>) =>
    this.client.post<APIHTTPResponse<PostRes>>(`${this.subRoute}/lm/posts`, params).then(parseResults).catch(throwError)

  deletePost = (id: string) =>
    this.client.delete<APIHTTPResponse<PostRes>>(`${this.subRoute}/lm/posts/${id}`).then(parseResult).catch(throwError)

  duplicatePost = (
    id: string,
    params: Partial<{ post: LocalizationObject, template_id, global_location_type, related_locations }>,
  ) =>
    this.client
      .post<APIHTTPResponse<PostRes>>(`${this.subRoute}/lm/posts/${id}/duplicate`, params)
      .then(parseResults)
      .catch(throwError)

  getPostTemplates = (type: string) =>
    this.client
      .get<APIHTTPResponse<Templates[]>>(`${this.subRoute}/a/templates?application_id=${type}`)
      .then(parseResults)
      .catch(throwError)

  post = (id: string) =>
    this.client.get<APIHTTPResponse<PostRes>>(`${this.subRoute}/lm/posts/${id}`).then(parseResults).catch(throwError)

  posts = (campus_ids: string, params?: PostListRequired) => {
    const state = R.pick(['states'], params)?.states
    const states = !R.isEmpty(state) ? { states: isArray(state) ? state.join(',') : state } : {}
    return this.client
      .get<PaginatedResponse<PostListType>>(`${this.subRoute}/lm/posts`, {
        params: {
          campus_ids,
          ...states,
          ...R.reject(
            R.isEmpty,
            R.pick(['order_by', 'page_number', 'page_size', 'search', 'sort_by', 'category', 'sub_category'], params),
          ),
        },
      })
      .then((d) => d.data)
      .catch(throwError)
  }

  postsByParams = (params?: PostListRequired) => {
    const state = R.pick(['states'], params)?.states
    const states = !R.isEmpty(state) ? { states: isArray(state) ? state.join(',') : state } : {}
    return this.client
      .get<PaginatedResponse<PostListType>>(`${this.subRoute}/lm/posts`, {
        params: {
          ...states,
          ...R.reject(
            R.isEmpty,
            R.pick(
              [
                'order_by',
                'page_number',
                'page_size',
                'search',
                'sort_by',
                'category',
                'sub_category',
                'campus_ids',
                'building_ids',
              ],
              params,
            ),
          ),
        },
      })
      .then((d) => d.data)
      .catch(throwError)
  }

  updatePost = (id: string, params: Partial<PostRes>) =>
    this.client
      .put<APIHTTPResponse<PostRes>>(`${this.subRoute}/lm/posts/${id}`, params)
      .then(parseResults)
      .catch(throwError)

  uploadImage = (id: string, file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ url: string, id: string }>>(`${this.subRoute}/lm/posts/${id}/pics/upload`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((d) => d.data.result)
      .catch(throwError)

  uploadPdf = (file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ url: string, id: string }>>(`${this.subRoute}/lm/pdf/upload`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((d) => d.data.result)
      .catch(throwError)

  vouchers = (id: string, campaign_id: string) =>
    this.client
      .get<APIHTTPResponse<Voucher>>(`${this.subRoute}/r/campaigns/${campaign_id}/vouchers/${id}/redeem`)
      .then(parseResult)
      .catch(throwError)

  actionByEditor = (id: string, type: string) =>
    this.client
      .patch<APIHTTPResponse<PostRes>>(`${this.subRoute}/lm/posts/${id}/stage/draft?action=${type}`)
      .then(parseResults)
      .catch(throwError)

  actionByApprover = (id: string, type: string) =>
    this.client
      .patch<APIHTTPResponse<PostRes>>(`${this.subRoute}/lm/posts/${id}/stage/submitted?action=${type}`)
      .then(parseResults)
      .catch(throwError)

  uploadFile = (id: string, file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ url: string, id: string }>>(`${this.subRoute}/lm/posts/${id}/pics/upload`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((d) => d.data.result)
      .catch(throwError)

  uploadFileNormal = (file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ url: string, id: string }>>(`${this.subRoute}/lm/pics/upload`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((d) => d.data.result)
      .catch(throwError)

  uploadAssetFile = (file: FormData) =>
    this.client
      .post<APIHTTPResponse<{ url: string, id: string }>>(`${this.subRoute}/a/pics/upload`, file, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((d) => d.data.result)
      .catch(throwError)

  createAsset = (params: Partial<Asset>) => {
    return this.client.post<APIHTTPResponse<Asset>>(`${this.subRoute}/lm/assets`, params).then(parseResults)
  }

  assets = (type: string, campus_id?: string) =>
    this.client
      .get<APIHTTPResponse<Asset>>(`${this.subRoute}/lm/assets`, {
        params: { selectable: 1, type, ...(campus_id ? { campus_id } : {}) },
      })
      .then(parseResults)
      .catch(throwError)
  //  /content-service/lm/assets/:id

  getAssets = (id: string) =>
    this.client.get<APIHTTPResponse<Asset>>(`${this.subRoute}/lm/assets/${id}`).then(parseResults).catch(throwError)

  notifications = (params?: ListRequest) =>
    this.client
      .get<PaginatedResponse<NotificationRecord>>(`${this.subRoute}/notifications/push`, { params })
      .then((d) => d.data)
      .catch(throwError)

  pushNotification = (params: { data?: { post_id: string }, topic: string } & LocalizationObject) =>
    this.client
      .put<APIHTTPResponse<{ post_id: string }>>(`${this.subRoute}/notifications/push/send`, params)
      .then(parseResults)
      .catch(throwError)

  getCampaignUsers = (campaign_id: string, params) =>
    this.client
      .get<PaginatedResponse<CampaignUser[]>>(`${this.subRoute}/lm/campaigns/${campaign_id}/users`, {
        params: R.reject(R.isEmpty, R.pick(['order_by', 'page_number', 'page_size', 'sort_by'], params)),
      })
      .then((d) => d.data)
      .catch(throwError)

  downloadFile = (campaign_id: string, category: string, format: string) =>
    this.client
      .get<Blob>(`${this.subRoute}/lm/campaigns/${campaign_id}/redemption/${category}/export/${format}`, {
        responseType: 'blob',
      })
      .catch(throwError)

  getLatestNewsList = (campus_ids: string) =>
    this.client
      .get<APIHTTPResponse<LatestNewsType>>(`${this.subRoute}/lm/latest-posts?campus_ids=${campus_ids}`)
      .then((d) => d.data.result)
      .catch(throwError)

  updateLatestNewsList = (params) =>
    this.client
      .post<APIHTTPResponse<LatestNewsType>>(`${this.subRoute}/lm/latest-posts`, params)
      .then((d) => d.data.result)
      .catch(throwError)

  getTransactionsList = (campaign_id: string, session_id: string, params?: PostListRequired) => {
    const state = R.pick(['states'], params)?.states
    const states = !R.isEmpty(state) ? { states: isArray(state) ? state.join(',') : state } : {}
    return this.client
      .get<PaginatedResponse<SessionTransition>>(
        `${this.subRoute}/lm/campaigns/${campaign_id}/sessions/${session_id}/transactions`,
        {
          params: {
            ...states,
            ...R.reject(R.isEmpty, R.pick(['order_by', 'page_number', 'page_size', 'states', 'sort_by'], params)),
          },
        },
      )
      .then((d) => d.data)
      .catch(throwError)
  }

  refundPoint = (campaign_id: string, session_id: string, voucher_ids: string[]) =>
    this.client
      .post<APIHTTPResponse<any>>(`${this.subRoute}/lm/campaigns/${campaign_id}/sessions/${session_id}/refund`, {
        voucher_ids,
      })
      .then((d) => d.data.result)
      .catch(throwError)

  delayEvent = (campaign_id: string, session_id: string, notification) =>
    this.client
      .post<APIHTTPResponse<PostRes>>(`${this.subRoute}/lm/campaigns/${campaign_id}/sessions/${session_id}/delay`, {
        notification,
      })
      .then((d) => d.data.result)
      .catch(throwError)

  reloadEvent = (campaign_id: string, session_id: string, params) =>
    this.client
      .post<APIHTTPResponse<PostRes>>(
        `${this.subRoute}/lm/campaigns/${campaign_id}/sessions/${session_id}/reload`,
        params,
      )
      .then((d) => d.data.result)
      .catch(throwError)

  getSessionRequests = (campaign_id: string, session_id: string, params) =>
    this.client
      .get<APIHTTPResponse<SeesionRequestRes>>(
        `${this.subRoute}/lm/campaigns/${campaign_id}/sessions/${session_id}/requests`,
        { params },
      )
      .then((d) => d.data.result)
      .catch(throwError)

  requestsActions = (campaign_id: string, session_id: string, request_id: string, action: string, params) =>
    this.client
      .put<APIHTTPResponse<PostRes>>(
        `${this.subRoute}/lm/campaigns/${campaign_id}/sessions/${session_id}/requests/${request_id}/${action}`,
        params,
      )
      .then((d) => d.data.result)
      .catch(throwError)

  cancelAction = (campaign_id: string, session_id: string, notification) =>
    this.client
      .post<APIHTTPResponse<PostRes>>(`${this.subRoute}/lm/campaigns/${campaign_id}/sessions/${session_id}/cancel`, {
        notification,
      })
      .then((d) => d.data.result)
      .catch(throwError)

  getCommentList = (campaign_id: string, params?: PostListRequired) => {
    return this.client
      .get<PaginatedResponse<CommentList>>(`${this.subRoute}/lm/campaigns/${campaign_id}/comments`, {
        params: R.reject(R.isEmpty, R.pick(['order_by', 'page_number', 'page_size', 'sort_by'], params)),
      })
      .then((d) => d.data)
      .catch(throwError)
  }

  downloadComments = (campaign_id: string) =>
    this.client
      .get<Blob>(`${this.subRoute}/lm/campaigns/${campaign_id}/comments/export/csv`, {
        responseType: 'blob',
      })
      .catch(throwError)

  getBarCodeByVendingId = (vendingId: string) =>
    this.client
      .get<APIHTTPResponse<VendingMachine>>(
        `${this.subRoute}/lm/vendings/${vendingId}/sksu`,
      )
      .then((d) => d.data.result)
      .catch(throwError)
}

export default CMSRestful
