import {
    // all,
    // take,
    call,
    put,
    select,
    takeEvery,
} from 'redux-saga/effects'
import {
    WorkOrderDetailItem,
    // WorkOrderListItem
} from '../../schema'
import { Action, ACTION_KEY_WORK_ORDER, WorkOrderActions, NotificationActionFunctions } from '../actions'
import { apiService, hrdpActions } from '../../..'
import { WorkOrderFilter } from '../reducers/property-management/work-order'

function* overviewRequest() {
    console.log('[SAGA] [WORK ORDER] overviewRequest')

    yield put(hrdpActions.fetchStart())

    try {
        const overview = yield call(
            apiService.property.workOrder.getOrderStatusCounts
        )


        const workOrderState = yield select((state) => state.propertyMgmt.workOrder)
        let statuses = workOrderState.allStatuses
        if (!workOrderState.allStatuses) {
            statuses = yield call(apiService.property.workOrder.getStatuses)
        }

        console.log('result overview', overview)
        yield put(WorkOrderActions.workOrderOverview.success({ overview, statuses }))
        yield put(hrdpActions.fetchSuccess())
    } catch (error) {
        console.warn('[SAGA] [WORK ORDER] overviewRequest error', error)
        yield put(WorkOrderActions.workOrderOverview.failure(error))
        yield put(
            NotificationActionFunctions.enqueue({
                message: error,
                type: 'error',
            }),
        )
        yield put(hrdpActions.fetchError(error))
    }
}



function* listFilterChange(action: Action) {
    console.log('[SAGA] deviceListFilterChange')
    const filterSelector = (state) => state.propertyMgmt.workOrder.filter
    const filter: WorkOrderFilter = yield select(filterSelector)
    const { repair_area_type_ids, type_ids, status_id, campus_id, building_id, display_name, page_size: input_page_size, page_number } = filter

    yield put(hrdpActions.fetchStart())

    try {
        const { result: orders, current_page, page_size, total_records, total_pages } = yield call(
            apiService.property.workOrder.getOrders,
            {
                display_name,
                type_ids,
                campus_id,
                building_id,
                status_id,
                repair_area_type_ids,
                page_number,
                page_size: input_page_size
            }
        )


        const workOrderState = yield select((state) => state.propertyMgmt.workOrder)

        let types = workOrderState.orderTypes
        let areas = workOrderState.orderAreaTypes
        let statuses = workOrderState.allStatuses
        let campuses = workOrderState.campuses
        let overview = workOrderState.overview

        if (!workOrderState.orderTypes) {
            types = yield call(apiService.property.workOrder.getTypes)
        }
        if (!workOrderState.orderAreaTypes) {
            areas = yield call(apiService.property.workOrder.getRepairAreaTypes)
        }
        if (!workOrderState.allStatuses) {
            statuses = yield call(apiService.property.workOrder.getStatuses)
        }

        if (workOrderState.buildings) {
            // buildings = yield call(apiService.platform.platformManagement.getBuildingsInCampus,)
        }
        if (!workOrderState.overview) {
            overview = yield call(apiService.property.workOrder.getOrderStatusCounts)
        }

        if (!workOrderState.campuses) {
            campuses = yield call(apiService.platform.propertiesManagement.getCampuses)
        }

        campuses = campuses.filter(c => overview.find(o => o.campus_id === c.id))

        const pagination = {
            current_page,
            page_size,
            total_records,
            total_pages,
        }

        yield put(
            WorkOrderActions.changeFilterResult(action.payload, orders, pagination, types, statuses, campuses, areas, overview),
        )

        yield put(hrdpActions.fetchSuccess())
    } catch (error) {
        console.warn('[SAGA] deviceListRequest error', error)
        yield put(WorkOrderActions.changeFilterResultFailed(error))
        yield put(
            NotificationActionFunctions.enqueue({
                message: error,
                type: 'error',
            }),
        )
        yield put(hrdpActions.fetchError(error))
    }
}


function* detailRequest(action: Action) {
    console.group('[SAGA] deviceDetailRequest')
    const id = action.payload

    try {

        yield put(hrdpActions.fetchStart())

        const orders: WorkOrderDetailItem[] = yield call(apiService.property.workOrder.getOrder, id)
        const order = orders[0]

        console.log('order', order)

        yield put(WorkOrderActions.workOrderDetail.success(order))
        yield put(hrdpActions.fetchSuccess())
    } catch (error) {
        console.error('[SAGA] deviceDetailRequest', error)
        yield put(WorkOrderActions.workOrderDetail.failure(error))
        yield put(
            NotificationActionFunctions.enqueue({
                message: error,
                type: 'error',
            }),
        )
        yield put(hrdpActions.fetchError(error))
    }
    console.groupEnd()
}


export const workOrderSagas = [
    takeEvery(ACTION_KEY_WORK_ORDER.WORK_ORDER_OVERVIEW.REQUEST, overviewRequest),
    takeEvery(ACTION_KEY_WORK_ORDER.UPDATE_FILTER, listFilterChange),
    takeEvery(ACTION_KEY_WORK_ORDER.UPDATE_SEARCH_STRING, listFilterChange),
    takeEvery(ACTION_KEY_WORK_ORDER.WORK_ORDER_DETAIL.REQUEST, detailRequest),

]
