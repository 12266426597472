import React from 'react'
import { ACCESS_POLICY } from '@exaleap/common'
import { Navigate } from 'react-router-dom'


const TenantVisitor = ACCESS_POLICY.tenantService.visitorService

const TenantPortalRoutes = [
    {
        path: '/app/tenant/overview',
        element: () => import('./TenantOverview'),
        auth: [
            ACCESS_POLICY.tenantService.generalService.tenantInfo.fullAccess,
        ],
    },
    {
        path: '/app/tenant/edit',
        element: () => import('./TenantEdit'),
        auth: [
            ACCESS_POLICY.tenantService.generalService.tenantInfo.fullAccess,
        ],
    },
    {
        path: '/app/tenant/visitor/new',
        element: () => import('./VisitorRequestDetailPage'),
        auth: [
            TenantVisitor.approvalManagement,
            TenantVisitor.approval,
            TenantVisitor.myRequest,
            TenantVisitor.request,
        ],
    },
    {
        path: '/app/tenant/visitor/:id',
        element: () => import('./VisitorRequestDetailPage'),
        auth: [
            TenantVisitor.approvalManagement,
            TenantVisitor.approval,
            TenantVisitor.myRequest,
            TenantVisitor.request,
        ],
    },
    {
        path: '/app/tenant/visitor',
        element: () => import('./VisitorRequestList'),
        auth: [
            TenantVisitor.approvalManagement,
            TenantVisitor.approval,
            TenantVisitor.myRequest,
            TenantVisitor.request,
        ],
    },
    // {
    //     path: '/app/tenant/staff/:subpage',
    //     element: () => import('./TenantStaff'),
    //     auth: [
    //         ACCESS_POLICY.tenantService.userManagement.readOnly,
    //         ACCESS_POLICY.tenantService.userManagement.fullAccess,
    //     ],
    // },
    {
        path: '/app/tenant/staff/*',
        element: () => import('./TenantStaff'),
        auth: [
            ACCESS_POLICY.tenantService.userManagement.readOnly,
            ACCESS_POLICY.tenantService.userManagement.fullAccess,
        ],
    },
    {
        path: '/app/tenant',
        exact: true,
        element: <Navigate to="/app/tenant/overview" />,
    },
]

export default TenantPortalRoutes
