import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppBar, Toolbar, Box } from '@mui/material'
import { styled, Theme, useTheme } from '@mui/material/styles'

import { COLLAPSED_DRAWER, FIXED_DRAWER, hrdpActions } from '@exaleap/common'

import {
  MuiIconButton
} from '@exaleap/react-component-lib'

import UserInfo from 'components/User/UserInfo'
import { intl } from 'util/IntlHelper'

const drawerWidth = 260

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: prop => prop !== 'hasShift',
})<{ hasShift?: boolean }>(({ theme: { zIndex, palette, transitions }, hasShift }: { theme: Theme, hasShift?: boolean }) => ({
  zIndex: zIndex.drawer - 1,
  backgroundColor: palette?.primary?.dark,
  color: palette?.primary?.contrastText,
  ...hasShift && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: transitions.create(['margin', 'width'], {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen,
    }),
  }
}))

const StyledMenuLabel = styled(Box, {
  shouldForwardProp: prop => prop !== 'menuLabelStyle',
})<{ menuLabelStyle?: Object }>(({ theme: { spacing, palette }, menuLabelStyle }: { theme: Theme, menuLabelStyle?: Object }) => ({
  marginRight: spacing(3),
  height: '32px',
  lineHeight: '32px',
  padding: `0 ${spacing(2.5)}`,
  border: `1px solid ${palette?.primary?.contrastText}`,
  borderRadius: '5px',
  cursor: 'pointer',
  '& .MuiIconButton-root': {
    padding: 0,
    marginTop: spacing(-1),
    marginRight: spacing(1),
    color: palette?.primary?.contrastText,
  },
  ...menuLabelStyle
}))

const StyledToolbar = styled(Toolbar)(({ theme: { palette } }: { theme: Theme }) => ({
  backgroundColor: palette?.primary?.dark,
  color: palette?.primary?.contrastText,
  height: 48,
  maxHeight: 48,
  zIndex: 2,
  display: 'flex',
  justifyContent: " space-between"
}))


const getHeadMenuLabel = () => {
  const windowPathname = window.location.pathname
  let headMenuLabel = 'sidebar.platform_management'
  if (windowPathname.indexOf('/app/leasing-mgmt') > -1) {
    headMenuLabel = 'sidebar.leasing_management'
  }
  if (windowPathname.indexOf('/app/property-mgmt') > -1) {
    headMenuLabel = 'sidebar.property_management'
  }
  if (windowPathname.indexOf('/app/tenant') > -1) {
    headMenuLabel = 'sidebar.tenant_services'
  }
  return headMenuLabel
}

const Index = props => {
  const { breakpoints } = useTheme()
  const { navCollapsed } = props
  const dispatch = useDispatch()
  const { drawerType, width } = useSelector(({ settings }) => settings)

  const onToggleCollapsedNav = e => {
    const val = !navCollapsed
    if (!(drawerType.includes(COLLAPSED_DRAWER) && width > breakpoints.values.lg && val)) {
      dispatch(hrdpActions.toggleCollapsedNav(val))
    }
    if (width > breakpoints.values.lg && drawerType.includes(COLLAPSED_DRAWER)) {
      dispatch(hrdpActions.setDrawerType(FIXED_DRAWER))
    }
  }

  const menuLabelStyle =
    drawerType.includes(FIXED_DRAWER) && !navCollapsed
      ? {
        display: 'block',
        [breakpoints.up("lg")]: {
          display: 'none',
        }
      } : drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && navCollapsed)
        ? {
          display: 'block',
        }
        : {
          display: 'none',
        }

  return (
    <StyledAppBar
      position="fixed"
      hasShift={drawerType.includes(FIXED_DRAWER) && !navCollapsed && width > breakpoints.values.lg}
      elevation={0}
    >
      <StyledToolbar disableGutters={false} variant="dense">
        <Box display="flex" alignItems={"center"}>
          <StyledMenuLabel onClick={onToggleCollapsedNav} menuLabelStyle={menuLabelStyle}>
            <MuiIconButton
              aria-label="Menu"
              iconName="Menu"
            />
            <span
              style={{
                display: 'inline-block',
                letterSpacing: '1px',
              }}
            >
              {intl.formatMessage({ id: getHeadMenuLabel() })}
            </span>
          </StyledMenuLabel>
          <div style={{ letterSpacing: '1px' }}>
            {intl.formatMessage({ id: "header.hrdp_name" })}
          </div>
        </Box>
        <UserInfo />
      </StyledToolbar>
    </StyledAppBar>
  )
}

export default Index
