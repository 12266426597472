import React, { lazy, Suspense } from 'react'
import { Navigate } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import AppLayout from '../../containers/AppLayout'
import dashboardRoutes from './dashboard/DashboardRoutes'
import BMSRoutes from './bms/BMSRoutes'
import IOTRoutes from './platform-portal/iot/IOTRoutes'
import IAMRoutes from './iam/IAMRoutes'
import AccessControlRoutes from './access-control/AccessControlRoutes'
import TenantRoutes from './tenant-portal'
import PropertyRoutes from './property-portal'
import PlatformRoutes from './platform-portal'
import LeasingRoutes from './leasing-portal'
import ProfileRoutes from './profile'
import SettingRoutes from './setting'
import { useSelector } from 'state/root-store'

const UserRouting = () => {
  //
  const navigations: any[] = useSelector(s => s.navigation) as any[]

  // console.log('[Navigations] Default Routing', navigations)

  if (navigations.length > 0) {
    return <Navigate to={navigations[0].link} />
  }
  return <Navigate to="/app/dashboard" />
}

export type CustomRoute = {
  path: string,
  exact?: boolean,
  element: any,
  auth?: string[],
  ignoreRegionCds?: string[],
}

const mainAppRoute: CustomRoute[] = [
  ...dashboardRoutes,
  ...BMSRoutes,
  ...IOTRoutes,
  ...TenantRoutes,
  ...PropertyRoutes,
  ...LeasingRoutes,
  ...PlatformRoutes,
  ...IAMRoutes,
  ...AccessControlRoutes,
  ...ProfileRoutes,
  ...SettingRoutes,
  {
    path: "/app",
    exact: true,
    element: <UserRouting />
  },
]
const routes = [{
  path: "/app",
  element: <AppLayout routes={mainAppRoute} />, /// < Auth checking also here
  children: mainAppRoute
}]

const LoadingComponent = () => <div className="loader-view" style={{ height: 'calc(100vh - 200px)' }}>
  <CircularProgress />
</div>

const LazyElement = (props) => {
  const { importFunc } = props
  const LazyComponent = lazy(importFunc)
  return (
    <Suspense fallback={<LoadingComponent />}>
      <LazyComponent />
    </Suspense>
  )
}

const dealRoutes = (routesArr) => {
  if (routesArr && Array.isArray(routesArr) && routesArr.length > 0) {
    routesArr.forEach((route) => {
      if (route.element && typeof route.element == 'function' && route.path !== "/app") {
        const importFunc = route.element
        route.element = <LazyElement importFunc={importFunc} />
      }
      if (route.children) {
        dealRoutes(route.children)
      }
    })
  }
}

dealRoutes(routes)

export default routes
