import { NotificationActionFunctions, hrdpActions } from '@exaleap/common'

export enum NotiActionType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export const fetchStart = (dispatch) => {
  return () => {
    dispatch(hrdpActions.fetchStart())
  }
}

export const fetchSuccess = (dispatch) => {
  return () => {
    dispatch(hrdpActions.fetchSuccess())
  }
}

export const fetchError = (dispatch) => {
  return (payload: { type: string }) => {
    dispatch(hrdpActions.fetchError(payload))
  }
}

export const showErrorMsg = (dispatch) => {
  return (payload: string, element?: (key: any) => JSX.Element | string) => {
    dispatch(hrdpActions.fetchError(payload))
    dispatch(
      NotificationActionFunctions.enqueue({
        action: element,
        message: payload,
        type: NotiActionType.ERROR,
      }),
    )
    return true
  }
}

export const showSuccessMsg = (dispatch) => {
  return (payload: string, element?: (key: any) => JSX.Element | string) => {
    fetchSuccess(dispatch)
    dispatch(
      NotificationActionFunctions.enqueue({
        action: element,
        message: payload,
        type: NotiActionType.SUCCESS,
      }),
    )
    return false
  }
}

export const showMsg = (dispatch) => {
  return (payload: { msg: string, type: "success" | "error" }) => {
    dispatch(
      NotificationActionFunctions.enqueue({
        message: payload?.msg,
        type: payload?.type,
      }),
    )
  }
}

export const notiClose = (dispatch) => {
  return (key: string) => {
    dispatch(NotificationActionFunctions.close(key))
  }
}

export const notiExit = (dispatch) => {
  return (key: string | number) => {
    dispatch(NotificationActionFunctions.remove(key))
  }
}


