import { SimpleBase, ListRequest } from './base'
import { BaseUser, LocationPermission } from './iam'

export type TransactionRequestStatus = 'SUCCESS' | 'PENDING' | 'FAILED'
export interface TransactionRequestResponse {
  id: string,
}

export enum TransactionFilterBy {
  EndTime = 'to_time',
  StartTime = 'from_time',
  Updated_at = 'updated_at',
}

export interface TransactionRequired extends ListRequest {
  from_time?: string,
  to_time?: string,
  order_by?: 'asc' | 'desc',
  sort_by?: 'modified',
}

export interface TransactionRequest {
  accesses: { location: LocationPermission, service: string }[],
  number_of_access: number,
  access_notification: boolean,
  source: string,
  requester: BaseUser,
  response_status: TransactionRequestStatus,
}

export interface Transaction extends SimpleBase {
  area_code: string,
  ccy: string,
  description: string,
  order_date: string,
  order_no: string,
  payment_id: string,
  payment_method: string,
  phone_number: string,
  price: string,
  qty: number,
  state: TransactionState,
  terms: string,
  user_id: string,
  user_name: string,
  txn_time: string,
  txn_failure_code: string,
  first_name: string,
  last_name: string,
}

export enum TransactionState {
  Success = 'completed',
  Pending = 'new',
  Failed = 'cancelled',
}
