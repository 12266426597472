import { call, put, takeEvery, all } from 'redux-saga/effects'
import {
    Action,
    NotificationActionFunctions,
    ACTION_KEY_ACCESS_CONTROL,
    AccessControlActions,
    ACTION_KEY_VISITOR,
    VisitorActions,
} from '../actions'
import { apiService } from '../../..'
import { VisitorRequest, VisitorEntity } from '../../schema'
import { SERVICE_DESTINATION } from '../../services/apiService'

// function* requestVisitorRecords(action: Action) {
//     console.warn('[SAGA] requestVisitorRecords')

//     const destination: SERVICE_DESTINATION = action.payload.destination

//     let buildings,
//         tenants,
//         requests = null

//     try {
//         buildings = yield call(apiService.platform.buildingService.getBuildings)

//         switch (destination) {
//             case 'tenant-service':
//                 requests = yield call(apiService.tenant.visitor.listMyRequests)
//                 break

//             case 'platform-management':
//                 // tenants = yield call(apiService.platformManagement.)
//                 break

//             case 'properties-management-service':
//                 requests = yield call(apiService.property.visitor.listRequests)
//                 tenants = yield call(apiService.platform.propertiesManagement.getTenantList)

//                 break

//             case 'leasing-management':
//                 // requests = yield call(apiService.leasing.tenantVisitorManagement.)
//                 tenants = yield call(apiService.leasing.tenantManagement.list)
//                 break

//             default:
//                 break
//         }

//         yield put(VisitorActions.visitorRequestList.success(buildings, tenants, requests))
//     } catch (error) {
//         console.warn('[SAGA] requestVisitorRecords error', error)
//         yield put(VisitorActions.visitorRequestList.failure(error))
//         yield put(
//             NotificationActionFunctions.enqueue({
//                 message: error,
//                 type: 'error',
//             }),
//         )
//     }
// }

// function* requestVisitorDetail(action: Action) {
//     console.warn('[SAGA] requestVisitorDetail')

//     const { id, destination } = action.payload

//     try {
//         let detail: VisitorRequest = null
//         if (destination === 'properties-management-service') {
//         }

//         switch (destination) {
//             case 'tenant-service':
//                 detail = yield call(apiService.tenant.visitor.listTenantRequests, id)
//                 break

//             case 'platform-management':
//                 // tenants = yield call(apiService.platformManagement.)
//                 break

//             case 'properties-management-service':
//                 detail = yield call(apiService.property.visitor.listRequests, id)
//                 break

//             case 'leasing-management':
//                 break

//             default:
//                 break
//         }

//         yield put(VisitorActions.visitorRequestDetail.success(detail))
//     } catch (error) {
//         console.warn('[SAGA] requestVisitorDetail error', error)
//         yield put(VisitorActions.visitorRequestDetail.failure(error))
//         yield put(
//             NotificationActionFunctions.enqueue({
//                 message: error,
//                 type: 'error',
//             }),
//         )
//     }
// }

// function* requestListApprove(action: Action) {
//     console.warn('[SAGA] requestListApprove')
//     const request: VisitorRequest = { ...action.payload, status: 'Granted' }
//     yield put(VisitorActions.listingRequestApprove.success(request))
// }

// function* requestListReject(action: Action) {
//     console.warn('[SAGA] requestListReject')
//     const request: VisitorRequest = { ...action.payload, status: 'Rejected' }
//     yield put(VisitorActions.listingRequestReject.success(request))
// }

// function* requestDetailReject(action: Action) {
//     console.warn('[SAGA] requestDetailReject')
//     const request: VisitorRequest = { ...action.payload, status: 'Rejected' }
//     yield put(VisitorActions.detailRequestReject.success(request))
// }

// function* requestVisitorReject(action: Action) {
//     console.warn('[SAGA] requestVisitorReject')
//     const visitor: VisitorEntity = { ...action.payload.visitor, approved: false, reject_reason: action.payload.reason }
//     yield put(VisitorActions.detailRequestRejectSingleVisitor.success(visitor))
// }

export const visitorSagas = [
    // takeEvery(ACTION_KEY_VISITOR.VISITOR_REQUEST_LIST.REQUEST, requestVisitorRecords),
    // takeEvery(ACTION_KEY_VISITOR.VISITOR_REQUEST_DETAIL.REQUEST, requestVisitorDetail),

    // takeEvery(ACTION_KEY_VISITOR.VISITOR_REQUEST_LIST_APPROVE.REQUEST, requestListApprove),
    // takeEvery(ACTION_KEY_VISITOR.VISITOR_REQUEST_LIST_REJECT.REQUEST, requestListReject),

    // takeEvery(ACTION_KEY_VISITOR.VISITOR_REQUEST_DETAIL_REJECT.REQUEST, requestDetailReject),
    // takeEvery(ACTION_KEY_VISITOR.VISITOR_REQUEST_DETAIL_REJECT_VISITOR.REQUEST, requestVisitorReject),
]
