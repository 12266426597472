import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  DARK_THEME,
  DRAWER_TYPE,
  FIXED_DRAWER,
  HORIZONTAL_MENU_POSITION,
  INSIDE_THE_HEADER,
  SWITCH_LANGUAGE,
  THEME_COLOR,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION,
  WINDOW_WIDTH,
  DEFAULT_LANGUAGE_CODE,
  COLLAPSED_DRAWER,
  GET_AVAILABLE_CAMPUSES,
  SET_CURRENT_MENU_ITEMS
} from '@exaleap/common'
import { getLanguageData } from 'util/localeHelper'
import { GREEN } from '../../util/constants'

const rltLocale = ['ar']
const initialSettings = {
  navCollapsed: false,
  drawerType: FIXED_DRAWER,
  themeColor: GREEN,
  darkTheme: false,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  locale: getLanguageData(DEFAULT_LANGUAGE_CODE),
  availableCampuses: [],
  currentMenuItem: [],
}

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      if (state.drawerType.includes(COLLAPSED_DRAWER)) {
        return {
          ...state,
          navCollapsed: false
        }
      }
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed || false
      }
    case DRAWER_TYPE:
      return {
        ...state,
        drawerType: action.drawerType
      }
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width
      }
    case THEME_COLOR:
      return {
        ...state,
        darkTheme: false,
        themeColor: action.color
      }
    case DARK_THEME:
      return {
        ...state,
        darkTheme: !state.darkTheme
      }
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale)
      }
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL

      }

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload
      }


    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload
      }

    case GET_AVAILABLE_CAMPUSES:
      return {
        ...state,
        availableCampuses: action.payload
      }

    case SET_CURRENT_MENU_ITEMS:
      return {
        ...state,
        currentMenuItem: action.payload
      }

    default:
      return state
  }
}

export default settings
