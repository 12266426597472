import 'assets/vendors/style'

import React, { useContext, useEffect } from 'react'
import MomentUtils from '@date-io/moment'
import { IntlProvider } from 'react-intl'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { localStorageService } from '@exaleap/common'

import { createTheme } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'

import { ThemeProvider as ThemeProviderV4 } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import StateProvider from 'contexts/provider/StateProvider'
import { useSelector } from 'state/root-store'

import greenTheme from './themes/greenTheme'
import AppLocale from '../lngProvider'
import RTL from 'util/RTL'
import Notifier from './Notifier'
import { AppContext } from '../MainApp'
import { generateIntl, intl } from '../util/IntlHelper'

const App = () => {
  const { locale, isDirectionRTL } = useSelector(s => s.settings)

  const { routes } = useContext(AppContext)
  const router = createBrowserRouter(routes)

  const applyTheme = createTheme(greenTheme)

  if (isDirectionRTL) {
    applyTheme.direction = 'rtl'
    document.body.classList.add('rtl')
  } else {
    document.body.classList.remove('rtl')
    applyTheme.direction = 'ltr'
  }

  const currentAppLocale = AppLocale[locale.locale]

  generateIntl({ locale: currentAppLocale.locale, messages: currentAppLocale.messages })

  useEffect(() => {
    const regionCd = localStorageService.getItem('regionCd')
    if (regionCd === 'CN') {
      document.title = intl.formatMessage({ id: `app_title.${regionCd}` })
    }
  }, [locale])

  return (
    <ThemeProvider theme={applyTheme}>
      <ThemeProviderV4 theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <StateProvider>
              <RTL>
                <div className="app-main">
                  <SnackbarProvider>
                    <Notifier />
                    <RouterProvider router={router} />
                  </SnackbarProvider>
                </div>
              </RTL>
            </StateProvider>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </ThemeProviderV4>
    </ThemeProvider >
  )
}

export default App
