import { Templates, CategoriesType } from '../../schema/cms'
import { ACTION_KEY_CMS, Action } from '../actions'
import { GET_AVAILABLE_CAMPUSES, SET_VENDING_ACCOUNT } from '../../constants/ActionTypes'
import { Campus } from '../../schema'

interface ContentServiceState {
  categoryList: CategoriesType[],
  templates: Templates[],
  availableCampuses: Campus[],
  vendingAccount: number,
}

const initialState: ContentServiceState = {
  categoryList: [],
  templates: [],
  availableCampuses: [],
  vendingAccount: null
}

const CMSReducer = function (state = initialState, action: Action): ContentServiceState {
  if (!action.payload) return state

  switch (action.type) {
    case ACTION_KEY_CMS.TEMPLATES.SUCCESS: {
      const newTemplates = action.payload
      return {
        ...state,
        templates: newTemplates,
      }
    }
    case ACTION_KEY_CMS.CATEGORY_LIST.SUCCESS: {
      const categoryList = action.payload
      return {
        ...state,
        categoryList: categoryList,
      }
    }
    case GET_AVAILABLE_CAMPUSES: {
      const availableCampuses = action.payload
      return {
        ...state,
        availableCampuses,
      }
    }
    case SET_VENDING_ACCOUNT: {
      const vendingAccount = action.payload
      console.log('action.payload', action.payload)
      return {
        ...state,
        vendingAccount,
      }
    }

    default: {
      return state
    }
  }
}

export default CMSReducer
