import { action } from './index'
import { Tenant, SimpleBuilding } from '../../schema'
import { createRequestActionStrings } from './helper'

// Access Control Related
export const ACTION_KEY_ACCESS_CONTROL = {
    ACCESS_CONTROL_TRANSCATIONS: createRequestActionStrings('ACCESS_CONTROL_TRANSCATIONS'),
    VISITOR_REQUEST_LIST: createRequestActionStrings('VISITOR_REQUEST_LIST'),
}

// Access Control Record List
export const accessControlRecordList = {
    request: () => action(ACTION_KEY_ACCESS_CONTROL.ACCESS_CONTROL_TRANSCATIONS.REQUEST),
    success: (records: Tenant[]) => action(ACTION_KEY_ACCESS_CONTROL.ACCESS_CONTROL_TRANSCATIONS.SUCCESS, records),
    failure: (error) => action(ACTION_KEY_ACCESS_CONTROL.ACCESS_CONTROL_TRANSCATIONS.FAILURE, error),
}



export const AccessControlActions = {
    accessControlRecordList
}
