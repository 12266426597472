import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Menu, MenuItem } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { apiService, AuthActions, User, UserProfile } from '@exaleap/common'

import {
  MuiIcon,
  UserAvatar
} from '@exaleap/react-component-lib'

import { useSelector } from 'state/root-store'

import { intl } from 'util/IntlHelper'
import { getValueWithLocale } from 'util/localeHelper'

const UserInfo = () => {
  const { spacing } = useTheme()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [anchorE1, setAnchorE1] = useState(null)
  const [open, setOpen] = useState(false)

  const profile: UserProfile = useSelector((s) => s.auth.profile)

  const handleClick = event => {
    setOpen(true)
    setAnchorE1(event.currentTarget)
  }

  const handleRequestClose = () => setOpen(false)

  return (
    <div>

      <UserAvatar
        userName={getValueWithLocale('last_name', profile)[0] || getValueWithLocale('display_name', profile)[0]}
        userProfilePic={profile?.profile_picture_url}
        onClick={e => handleClick(e)}

        style={{
          width: 40,
          cursor: 'pointer',
          backgroundColor: '#6FCF97'
        }}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorE1}
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            minWidth: 120,
            paddingTop: 0,
            paddingBottom: 0
          }
        }}
      >
        <MenuItem onClick={() => {
          handleRequestClose()
          navigate('/app/profile')
        }}>
          <MuiIcon iconName="Person" style={{ fontSize: 16, marginRight: spacing(2) }} />
          {intl.formatMessage({ id: "generic.menu.profile" })}
        </MenuItem>

        <MenuItem onClick={() => {
          handleRequestClose()
          navigate('/app/setting')
        }}>
          <MuiIcon iconName="Settings" style={{ fontSize: 16, marginRight: spacing(2) }} />
          {intl.formatMessage({ id: "generic.menu.setting" })}
        </MenuItem>

        <MenuItem onClick={() => {
          handleRequestClose()
          apiService.platform.account.logout()
          apiService.removeAuth()
          dispatch(AuthActions.logoutAuthUser())
        }}>
          <MuiIcon iconName="ExitToApp" style={{ fontSize: 16, marginRight: spacing(2) }} />
          {intl.formatMessage({ id: "generic.menu.logout" })}
        </MenuItem>
      </Menu>
    </div>
  )
}

export default UserInfo


