import { Dispatch } from 'react'
import { SimpleBuilding, SimpleCampus, SimpleFloor } from '@exaleap/common'

export enum ActionType {
  ACTION_SET_BUILDINGS = 'ACTION_SET_BUILDINGS',
  ACTION_SET_CAMPUSES = 'ACTION_SET_CAMPUSES',
  ACTION_SET_FLOORS_OF_BUILDING = 'ACTION_SET_FLOORS_OF_BUILDING',
}

export type Action =
  | { type: ActionType.ACTION_SET_BUILDINGS, value: SimpleBuilding[] }
  | { type: ActionType.ACTION_SET_CAMPUSES, value: SimpleCampus[] }
  | { type: ActionType.ACTION_SET_FLOORS_OF_BUILDING, value: { id: string, result: SimpleFloor[] } }

const pmcAction = (dispatch: Dispatch<Action>) => {
  const setBuildings = (value: SimpleBuilding[]) =>
    dispatch({
      type: ActionType.ACTION_SET_BUILDINGS,
      value,
    })

  const setCampuses = (value: SimpleCampus[]) =>
    dispatch({
      type: ActionType.ACTION_SET_CAMPUSES,
      value,
    })

  const setFloorsOfBuilding = (value: { id: string, result: SimpleFloor[] }) =>
    dispatch({
      type: ActionType.ACTION_SET_FLOORS_OF_BUILDING,
      value,
    })

  return { setBuildings, setCampuses, setFloorsOfBuilding }
}

export default pmcAction
