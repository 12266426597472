import { ACCESS_POLICY } from "@exaleap/common"

const IAMRoutes = [
  {
    path: "/app/iam/users",
    element: () => import("./UserList"),
    auth: [ACCESS_POLICY.platformManagement.iam.userManagement.fullAccess, ACCESS_POLICY.platformManagement.iam.fullAccess],
  }
]

export default IAMRoutes
