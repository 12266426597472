import { LocationHierarchy } from './bms'

export enum AccessRecordExportType {
  All = 'ALL',
  Gate = 'GATE',
  Lift = 'LIFT',
}

export interface AccessRecordExportRequest {
  campus_id: string
  date: string
  export_type: AccessRecordExportType
  search?: string
}

export interface UserAccessRecord {
  access_ts: number
  code_id: string
  daily_ts: number
  daily_ts_offset: number
  device_id: string
  device_name: string
  location: LocationHierarchy
  is_pass: boolean
  is_registered_user: true
  metadata: {
    current: string
    destination: string
    display_name: string
  }
  method: string
  reason: string
  record_type_id: string
  user_id: string
}