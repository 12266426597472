import { createRequestActionStrings } from './helper'
import { RequestActionFunctions, action } from './index'

export const ACTION_KEY_GENERAL_DATA = {
  BUILDINGS: createRequestActionStrings('BUILDINGS'),
}

const buildings: RequestActionFunctions = {
  request: () => action(ACTION_KEY_GENERAL_DATA.BUILDINGS.REQUEST),
  success: (templates) => action(ACTION_KEY_GENERAL_DATA.BUILDINGS.SUCCESS, templates),
  failure: (error) => action(ACTION_KEY_GENERAL_DATA.BUILDINGS.FAILURE, error),
}

export const generalDataActions = {
  buildings,
}
