import { AxiosInstance, AxiosResponse } from 'axios'
import APIRestfulProvider, { APIHTTPResponse, throwError } from '../../restful-provider'
import { ShuttleBusRequest, ShuttleBusCampus } from '../../../schema'
import { LocalizationObject } from '@exaleap/common'

const parseResult = <T>(d: AxiosResponse<APIHTTPResponse<T>>) => d.data.result

/**
 * Restful endpoints for Visitor System module
 */
class PropertyShuttleBusServices {
  client: AxiosInstance = null
  constructor(provider: APIRestfulProvider) {
    this.client = provider.client
  }

  subRoute = '/shuttle-bus-service/pmc-access'

  getCampuses = () =>
    this.client
      .get<APIHTTPResponse<ShuttleBusCampus>>(`${this.subRoute}/campuses`)
      .then((d) => d.data.result)
      .catch(throwError)

  getRoutesByCampus = (campusId: string) =>
    this.client
      .get<APIHTTPResponse<ShuttleBusRequest>>(`${this.subRoute}/routes?campus_id=${campusId}`)
      .then(parseResult)
      .catch(throwError)

  updateRouteById = (
    id: string,
    data: LocalizationObject & {
      enabled?: boolean
      show_notice?: boolean
    },
  ) =>
    this.client
      .put<APIHTTPResponse<any>>(`${this.subRoute}/route/${id}`, { ...data })
      .then(parseResult)
      .catch(throwError)
}

export default PropertyShuttleBusServices
